<template>
  <v-layer>
    <!-- <v-image :config="background" /> -->
    <v-line v-for="line in lines()" :key="line.id" :config="line"></v-line>
  </v-layer>
</template>

<script>
export default {
  props: {
    width: Number,
    height: Number,
    rows: Number,
    cols: Number,
    blockSize: Number,
  },
  data: () => {
    return {
      background: {
        image: null,
        // width: this.width,
        // height: this.height,
      },
    };
  },
  created() {
    const image = new window.Image();
    image.src = require("@/assets/background.jpg");
    image.onload = () => {
      // set image only when it is loaded
      this.background = {
        image,
        width: this.width,
        height: this.height,
      };
    };
  },
  methods: {
    lines: function() {
      let l = [];
      // const rows = Math.ceil(this.height / this.blockSize);
      // const cols = Math.ceil(this.width / this.blockSize);
      for (let i = 0; i <= this.cols; i++) {
        l.push({
          id: `v-${i}`,
          points: [
            i * this.blockSize,
            0,
            i * this.blockSize,
            this.rows * this.blockSize,
          ],
          stroke: "#0C9690",
          strokeWidth: 1,
        });
      }
      for (let i = 0; i <= this.rows; i++) {
        l.push({
          id: `h-${i}`,
          points: [
            0,
            i * this.blockSize,
            this.cols * this.blockSize,
            i * this.blockSize,
          ],
          stroke: "#0C9690",
          strokeWidth: 1,
        });
      }
      return l;
    },
  },
};
</script>

<style></style>
