<template>
  <div class="legacy-overview-grid">
    <div class="graph d-flex flex-column">
      <Graph class="border-bottom" />
      <div class="d-flex mt-2 mx-3">
        <TileInfo class="flex-1" title="Circulating Supply" small>
          20,685,735
        </TileInfo>
        <TileInfo class="flex-1" title="Market Cap" small>
          $1,062,210
        </TileInfo>
      </div>
      <div class="d-flex mt-2 mx-3">
        <TileInfo class="flex-1" title="Max Supply" small>
          42,000,000
        </TileInfo>
        <TileInfo class="flex-1" title="Burned 🔥" small>
          Coming Soon
        </TileInfo>
      </div>
      <div class="d-flex mt-2 mx-3">
        <a class="flex-1 link text-subtitle-2" @click="farmDialog = true">
          <span class="caron">></span> Space Farm
        </a>
        <!-- <a
          class="flex-1 link"
          href="https://1000blocks.medium.com/extremely-rare-extremely-powerful-1000blocks-first-smart-nft-collection-84e8562e59f8"
          target="_blank"
        >
          <span class="caron">></span> Docs
        </a> -->
      </div>

      <v-spacer />

      <!-- Buttons -->
      <div class="d-flex mt-2">
        <v-btn
          class="flex-1"
          large
          height="64"
          href="https://pancakeswap.finance/swap?outputCurrency=0x34Aa9099D924F3FB2377ff20D81b235311c15346"
          target="_blank"
        >
          <img src="@/assets/ils_PancakeSwap.svg" height="24" class="mr-2" />
          Buy
        </v-btn>
        <v-btn class="flex-1" large height="64" @click="addTokenToWallet">
          <img src="@/assets/ils_MetaMask.svg" height="24" class="mr-2" />
          Add
        </v-btn>
      </div>
    </div>

    <div class="sapes">
      <OverviewTile title="SApes" :icon="require('@/assets/Icon-Yield.svg')">
        <template v-slot:body>
          <OverviewApes />
          <div class="d-flex mt-2">
            <TileInfo class="flex-1" title="Total Supply" medium>
              {{ maxCount }}
            </TileInfo>
            <TileInfo class="flex-1" title="Circulating" medium>
              {{ mintedCount ? mintedCount : "-" }}
            </TileInfo>
          </div>
          <TileInfo
            class="mt-2"
            :title="`My Collection (${ownedCount})`"
            medium
          >
            <NftCollection />
          </TileInfo>

          <v-spacer />

          <!-- Buttons -->
          <div class="d-flex pa-3 pb-0 mt-2 mx-n3 top-border">
            <v-btn
              class="flex-1 mr-2"
              to="/mint/overview?mint=true"
              color="accent black--text"
            >
              Mint
            </v-btn>
            <v-btn
              class="flex-1"
              to="/mint/share2earn"
              color="yellow black--text"
            >
              Share2Earn
            </v-btn>
          </div>
        </template>
      </OverviewTile>
    </div>

    <div class="rewards-pool">
      <OverviewTile
        :info="true"
        title="Rewards Pool"
        :icon="require('@/assets/Icon-Pool.svg')"
      >
        <template v-slot:body>
          <div class="d-flex flex-flip">
            <TileInfo class="flex-grow-1" title="Inflow"
              >{{ rewardsPoolInflow }} BNB</TileInfo
            >
            <TileInfo class="flex-grow-1" title="Distributed"
              >{{ rewardsPoolOutflow }} BNB</TileInfo
            >
          </div>
        </template>

        <template v-slot:info>
          Rewards pool gets filled with BNB that was used to claim block areas.
          The more people are using application, more rewards come in.
        </template>
      </OverviewTile>
    </div>

    <div class="vault">
      <OverviewTile
        :info="true"
        title="Space Vault"
        :icon="require('@/assets/Icon-Vault.svg')"
      >
        <template v-slot:body>
          <div class="d-flex">
            <TileInfo class="flex-1 mr-2" title="Staked (BLS)" small>
              {{ vaultStaked }}
            </TileInfo>
            <TileInfo class="flex-1" title="Share" small>
              {{ vaultPercentage }}%
            </TileInfo>
          </div>

          <v-spacer />

          <!-- Buttons -->
          <div class="d-flex mt-2" v-if="connected">
            <v-btn class="flex-1 mr-2" small @click="vaultDialog = true">
              Stake
            </v-btn>
            <v-btn class="flex-1" small @click="vaultDialog = true">
              Withdraw
            </v-btn>
          </div>

          <div class="d-flex mt-6" v-else>
            <ConnectButton mini />
          </div>
        </template>

        <template v-slot:info>
          <p class="tooltip-text text-body-2">
            Stake BLS tokens in space vault and constantly earn BNB from Rewards
            Pool. More BLS, more BNB for you.
          </p>
          <p class="tooltip-text text-body-2">
            <strong>1%</strong> of the withdrawn BLS tokens are burned.
          </p>
        </template>
      </OverviewTile>
    </div>

    <div class="yields">
      <OverviewTile
        :info="true"
        title="Yields"
        :icon="require('@/assets/Icon-Yield.svg')"
      >
        <template v-slot:body>
          <div class="d-flex">
            <TileInfo class="flex-1 mr-2" title="BNB" small>
              <FastCounter
                class="flex-1 mr-2 font-weight-bold"
                :startAmount="vaultRewardNumber"
                :incrementPerSec="vaultRewardsPerSecNumber"
                :decimals="10"
              />
            </TileInfo>
            <TileInfo class="flex-1" title="BLS" small>
              <FastCounter
                class="flex-1 font-weight-bold"
                :startAmount="farmRewardNumber"
                :incrementPerSec="farmRewardsPerSecNumber"
                :decimals="4"
            /></TileInfo>
          </div>

          <v-spacer />

          <!-- Buttons -->
          <div class="d-flex mt-2 justify-items-end" v-if="connected">
            <v-btn class="flex-1 mr-2" small @click="vaultHarvestRewards">
              Harvest
            </v-btn>
            <v-btn class="flex-1" small @click="farmHarvestRewards">
              Harvest
            </v-btn>
          </div>

          <!-- Connect Button -->
          <div class="d-flex mt-6" v-else>
            <ConnectButton mini />
          </div>
        </template>

        <template v-slot:info>
          <p class="tooltip-text text-body-3">
            BNB Rewards received from staking BLS tokens in the Space Vault
          </p>
          <p class="tooltip-text text-body-3">
            BLS Rewards received from staking BLS tokens in the Space Farm
          </p>
        </template>
      </OverviewTile>
    </div>

    <div class="support">
      <OverviewTile
        title="Support the Project"
        :icon="require('@/assets/Icon-Pool.svg')"
      >
        <template v-slot:body>
          <div class="links-grid">
            <a
              v-for="link in links"
              :key="link.title"
              :href="link.href"
              target="_blank"
              class="d-flex align-center mr-4 grey--text text-decoration-none"
            >
              <img :src="link.icon" class="mr-4" />
              {{ link.title }}
            </a>

            <!-- <a
              href="https://twitter.com"
              target="_blank"
              class="d-flex align-center mr-4 grey--text text-decoration-none"
            >
              <img src="@/assets/Link-icon_TWITTER.svg" class="mr-4" />
              Share
            </a> -->
          </div>
        </template>
      </OverviewTile>
    </div>

    <DepositWithdrawDialog
      v-model="vaultDialog"
      title="Vault"
      :balance="vaultBalance"
      :staked="vaultStakedFull"
      :loading="vaultLoading"
      :approved="vaultApproved"
      :reward="vaultReward"
      tokenSymbol="BLS"
      @approve="vaultApprove"
      @confirmStake="vaultDeposit"
      @confirmWithdraw="vaultWithdraw"
      stakeMessage
      withdrawMessage
    />

    <DepositWithdrawDialog
      v-model="farmDialog"
      title="Farm"
      :balance="farmBalance"
      :staked="farmStakedFull"
      :loading="farmLoading"
      :approved="farmApproved"
      :reward="farmReward"
      tokenSymbol="BUSD-BLS"
      @approve="farmApprove"
      @confirmStake="farmDeposit"
      @confirmWithdraw="farmWithdraw"
      :liquidityPoolLink="lpUri"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import OverviewTile from "./OverviewTile.vue";
import TileInfo from "./TileInfo.vue";
import ConnectButton from "../cross-space/ConnectButton.vue";
import FastCounter from "../helpers/FastCounter.vue";
import OverviewApes from "./OverviewApes.vue";
import NftCollection from "../mint/NftCollection.vue";
import Graph from "./Graph.vue";
import DepositWithdrawDialog from "../dialogs/DepositWithdrawDialog.vue";
import { lpUri } from "../../crypto/contracts/lpToken";

export default {
  components: {
    OverviewTile,
    TileInfo,
    ConnectButton,
    FastCounter,
    OverviewApes,
    NftCollection,
    Graph,
    DepositWithdrawDialog,
  },
  data() {
    return {
      vaultDialog: false,
      farmDialog: false,

      lpUri,

      links: [
        {
          icon: require("@/assets/Link-icon_TWITTER.svg"),
          title: "Follow us on Twitter",
          href: "https://twitter.com/1000Blocks",
        },
        {
          icon: require("@/assets/Link-icon_TELEGRAM.svg"),
          title: "Join the community on Telegram",
          href: "https://t.me/thousandblocks",
        },
        {
          icon: require("@/assets/Link-icon_BUG.svg"),
          title: "Report a bug",
          href: "https://forms.gle/5h99We6VxLyUfdCy9",
        },
        {
          icon: require("@/assets/Link-icon_IDEA.svg"),
          title: "Suggest an idea/improvement",
          href: "https://forms.gle/5h99We6VxLyUfdCy9",
        },
      ],
    };
  },
  computed: {
    vaultTextSize() {
      return this.$vuetify.breakpoint.md ? "medium" : "large";
    },

    ...mapState("wallet", ["connected"]),
    ...mapGetters("wallet", {
      vaultBalance: "blsBalanceFull",
      farmBalance: "lpTokenBalanceFull",
    }),

    ...mapState("mint", ["ownedCount", "maxCount", "mintedCount"]),

    ...mapGetters("vault", [
      "tokenPrice",
      "rewardsPoolInflow",
      "rewardsPoolOutflow",
    ]),

    ...mapState("vault", {
      vaultLoading: "loading",
      vaultApproved: "approved",
    }),
    ...mapGetters("vault", {
      vaultStaked: "deposited",
      vaultStakedFull: "depositedFull",
      vaultPercentage: "vaultPercentage",
      vaultReward: "reward",
      vaultRewardsPerSec: "rewardsPerSec",
    }),
    vaultRewardNumber() {
      return parseFloat(this.vaultReward);
    },
    vaultRewardsPerSecNumber() {
      return parseFloat(this.vaultRewardsPerSec);
    },

    ...mapState("farm", {
      farmLoading: "loading",
      farmApproved: "approved",
    }),
    ...mapGetters("farm", {
      farmStaked: "deposited",
      farmStakedFull: "depositedFull",
      farmPercentage: "vaultPercentage",
      farmReward: "reward",
      farmRewardsPerSec: "rewardsPerSec",
      farmAPR: "APR",
    }),
    farmRewardNumber() {
      return parseFloat(this.farmReward);
    },
    farmRewardsPerSecNumber() {
      return parseFloat(this.farmRewardsPerSec);
    },
  },

  methods: {
    ...mapActions("wallet", ["connectWallet", "addTokenToWallet"]),

    ...mapActions("vault", {
      vaultHarvestRewards: "harvestRewards",
    }),
    ...mapActions("farm", {
      farmHarvestRewards: "harvestRewards",
    }),

    vaultApprove() {
      this.$store.dispatch("vault/approveVault");
    },
    vaultDeposit(value) {
      this.$store.dispatch("vault/deposit", value);
    },
    vaultWithdraw(value) {
      this.$store.dispatch("vault/withdraw", value);
    },

    farmApprove() {
      this.$store.dispatch("farm/approve"); // ??
    },
    farmDeposit(value) {
      this.$store.dispatch("farm/deposit", value);
    },
    farmWithdraw(value) {
      this.$store.dispatch("farm/withdraw", value);
    },
  },
};
</script>

<style lang="scss">
.flex-1 {
  flex: 1 1 0;
}

a.link {
  text-transform: uppercase;
  text-decoration-line: none;
  color: #a2fffb !important;

  .caron {
    color: #ffff00;
  }
}

.legacy-overview-grid {
  display: grid;
  margin-bottom: 25px;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-areas:
    "graph sapes pool    pool"
    "graph sapes vault   yields"
    "graph sapes support support";

  grid-gap: 16px;

  & > * {
    background-color: black;
  }

  .graph {
    grid-area: graph;

    border: 1px solid #444;
  }
  .sapes {
    grid-area: sapes;
  }
  .rewards-pool {
    grid-area: pool;
  }
  .vault {
    grid-area: vault;
  }
  .yields {
    grid-area: yields;
  }
  .support {
    grid-area: support;
  }
}

.links-grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-auto-columns: min-content;
  grid-auto-rows: max-content;
  row-gap: 6px;
}

.border-bottom {
  border-bottom: 1px solid #444;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .legacy-overview-grid {
    margin-bottom: 5px;
    grid-template-areas:
      "graph    sapes   pool"
      "graph    sapes   vault"
      "graph    sapes   yields"
      "support  support support";
  }

  .support {
    height: min-content;
  }

  .flex-flip {
    flex-direction: column;
  }

  .links-grid {
    grid-template-columns: repeat(3, auto);
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .legacy-overview-grid {
    grid-auto-rows: auto;
    margin-bottom: 5px;
    grid-template-areas:
      "pool     pool"
      "vault    yields"
      "graph    sapes"
      "graph    sapes"
      "graph    sapes"
      "support  support";
  }

  .flex-flip {
    flex-direction: row;
  }
  .rewards-pool,
  .vault,
  .yields {
    height: min-content;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .legacy-overview-grid {
    grid-auto-rows: auto;
    margin-bottom: 5px;
    grid-template-areas:
      "pool"
      "vault"
      "yields"
      "graph"
      "graph"
      "graph"
      "sapes"
      "sapes"
      "support";
  }
  .rewards-pool,
  .vault,
  .yields {
    height: min-content;
  }
  .support {
    display: none;
  }
  .links-grid {
    grid-template-columns: repeat(1, auto);
  }
}
</style>
