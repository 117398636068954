<template>
  <v-rect
    @click="select"
    @tap="select"
    :config="{
      x: y,
      y: x,
      width: blockSize,
      height: blockSize,
      fill: this.fill,
      strokeWidth: 0.1,
      stroke: 'grey',
    }"
    :onMouseEnter="onMouseEnter"
    :onMouseLeave="onMouseLeave"
  />
</template>

<script>
export default {
  name: "Block",
  props: {
    rowIndex: {
      type: Number,
      required: true,
      default: 0,
    },
    blockIndex: {
      type: Number,
      required: true,
      default: 0,
    },
    y: {
      type: Number,
      required: true,
      default: 0,
    },
    x: {
      type: Number,
      required: true,
      default: 0,
    },
    blockSize: {
      type: Number,
      required: true,
      default: 100,
    },
    color: {
      // Colour it will be
      type: String,
      required: true,
      default: "rgba(0, 0, 0, 1)",
    },
    prevColor: {
      // Colour it was
      type: String,
      required: true,
      default: "rgba(0, 0, 0, 1)",
    },
    modifyGrid: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      fill: "rgba(0, 0, 0, 1)", // Colour it currently is
      selected: false,
    };
  },
  methods: {
    reset() {
      this.fill = "rgba(0, 0, 0, 1)";
      this.selected = false;
    },
    select() {
      if (this.selected) {
        this.selected = false;
        this.fill = "rgba(0, 0, 0, 1)";
        this.modifyGrid(this.rowIndex, this.blockIndex, "rgba(0, 0, 0, 1)");
      } else {
        this.selected = true;
        this.fill = this.color;
        this.modifyGrid(this.rowIndex, this.blockIndex, this.color);
      }
    },
    onMouseEnter(e) {
      const container = e.target.getStage().container();
      container.style.cursor = "pointer";

      if (!this.selected) {
        this.fill = this.color;
      }
    },
    onMouseLeave(e) {
      const container = e.target.getStage().container();
      container.style.cursor = "default";

      if (!this.selected) {
        this.fill = "rgba(0, 0, 0, 1)";
      }
    },
  },
  mounted() {
    if (this.prevColor !== this.fill) {
      this.fill = this.prevColor;
      this.selected = true;
    }

    this.$root.$on("reset", () => {
      this.reset();
    });
  },
};
</script>
