<template>
  <router-link
    to="/profile"
    style="text-decoration: none;"
    v-if="connected"
    class="mr-4"
    :disabled="true"
  >
    <v-avatar size="38" :style="{ border: setHexBorder() }">
      <img
        :src="this.profilePicture"
        v-if="this.profilePicture"
        alt="profile icon"
      />
      <v-icon
        v-else
        class="cyan--text"
        style="border-radius: 25px;"
        large
        v-ripple="{ center: true }"
        >mdi-account-circle
      </v-icon>
    </v-avatar>
    <span v-if="username" class="text-h6 ml-3 white--text">{{
      displayName
    }}</span>
  </router-link>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    username: Boolean,
  },
  computed: {
    ...mapState("profile", ["profilePicture", "hexBorder", "displayName"]),
    ...mapState("wallet", ["connected", "account"]),
  },
  watch: {
    connected(val) {
      if (val) {
        this.loadProfile(this.account);
      } else {
        this.clearProfile();
      }
    },
  },
  methods: {
    ...mapActions("profile", ["loadProfile", "clearProfile"]),
    setHexBorder() {
      if (this.connected) {
        return `2px solid ${this.hexBorder} !important`;
      } else {
        return "none";
      }
    },
  },
};
</script>
