<template>
  <div class="app">
    <div v-if="loading" class="preloader-wrapper">
      <img src="@/assets/1000BLSP-Rectangle-Full-BLACK.png" width="220" />
      <Preloader></Preloader>
    </div>

    <v-app v-if="!loading">
      <AppBar />

      <!-- <Background /> -->
      <!-- <BackgroundStatic /> -->

      <v-main class="background">
        <router-view></router-view>
      </v-main>

      <v-snackbar
        :value="showError"
        bottom
        color="error"
        content-class="text-center"
      >
        {{ errorMessage }}
      </v-snackbar>
      <v-snackbar
        :value="showSuccess"
        bottom
        color="green"
        content-class="text-center"
      >
        {{ successMessage }}
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import Preloader from "./components/Preloader.vue";
import AppBar from "./components/cross-space/AppBar.vue";

export default {
  name: "App",
  components: {
    // InfoPanel,
    Preloader,
    AppBar,
    // Background,
    // BackgroundStatic,
  },
  data: function() {
    return {
      drawer: false,
      links: [
        ["mdi-border-all", "Spaces", "/spaces"],
        ["mdi-safe-square-outline", "Vault", "/vault"],
      ],
    };
  },
  computed: {
    ...mapState("error", [
      "showError",
      "errorMessage",
      "showSuccess",
      "successMessage",
    ]),
    ...mapState("poster", ["loading"]),
    ...mapState("wallet", ["ethers"]),
  },
  beforeCreate() {
    // Check directed more towards Metamask browser
    this.$store.dispatch("wallet/setUpEthers");

    // WalletConnect pre-check
    this.$store.dispatch("wallet/setUpWC");
  },
  created() {
    // this.$store.dispatch("poster/fetchPosters");
    this.$store.dispatch("vault/tokenPrice");
    this.$store.dispatch("pool/tokenPrice");
    this.$store.dispatch("vault/load");
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;

  font-weight: 400;

  /* font-size: 18px; */
}

body {
  background-color: #020e1f;
}

.app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-template-areas: "canvas";
  height: 100vh;
}

.preloader-wrapper {
  background-color: black;
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
}

/* .canvas {
  grid-area: canvas;
  justify-self: stretch;
  align-self: stretch;
} */

/* .info-panel {
  grid-area: panel;
  justify-self: stretch;
  align-self: stretch;
} */

.v-main {
  padding-right: 0px !important;
}

#WEB3_CONNECT_MODAL_ID {
  font-family: "Fira Mono", "Courier New", Courier, monospace !important;
}
</style>
