<template>
  <v-layer>
    <v-line v-for="line in lines()" :key="line.id" :config="line"></v-line>
  </v-layer>
</template>

<script>
export default {
  props: {
    width: Number,
    height: Number,
    rows: Number,
    cols: Number,
    blockSize: Number,
  },
  methods: {
    lines: function() {
      let l = [];
      // const rows = Math.ceil(this.height / this.blockSize);
      // const cols = Math.ceil(this.width / this.blockSize);
      for (let i = 0; i <= this.cols; i++) {
        l.push({
          id: `v-${i}`,
          points: [
            i * this.blockSize + 1,
            0,
            i * this.blockSize + 1,
            this.rows * this.blockSize,
          ],
          stroke: "#296966",
          strokeWidth: 1,
        });
      }
      for (let i = 0; i <= this.rows; i++) {
        l.push({
          id: `h-${i}`,
          points: [
            0,
            i * this.blockSize + 1,
            this.cols * this.blockSize,
            i * this.blockSize + 1,
          ],
          stroke: "#296966",
          strokeWidth: 1,
        });
      }
      return l;
    },
  },
};
</script>

<style></style>
