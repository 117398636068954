<template>
  <div class="d-flex flex-column align-center">
    <v-img
      class="my-16"
      src="@/assets/UI-Illustration_Click2Claim.png"
      width="160"
    />
    <p class="px-6 py-1 white--text text-body-2 text-center">
      Click on space to start claiming blocks.
    </p>
    <p class="px-6 py-1 grey--text text-body-2 text-center">
      You can claim max 42 blocks at once.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
