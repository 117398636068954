<template>
  <div class="image-upload">
    <input
      hidden
      type="file"
      id="file"
      ref="file"
      :accept="accept"
      @change="handleFileUpload()"
    />

    <div class="upload-button" v-if="!dataUrl" @click="openImageSelect">
      <img class="placeholder" src="@/assets/upload-dark.svg" />
    </div>

    <img v-if="dataUrl" class="preview" :src="dataUrl" alt="" />

    <div class="image-controls" v-if="dataUrl">
      <button
        class="flex-1"
        @click="togglePreview"
        :class="{ active: this.previewing }"
      >
        <img src="@/assets/preview-dark.svg" />
      </button>
      <button class="flex-1" @click="openImageSelect">
        <img src="@/assets/upload-dark.svg" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { resizeImage } from "../../services/imagesService";

export default {
  data() {
    return {
      file: null,
      dataUrl: "",
      previewing: false,
      supportedTypes: ["image/jpeg", "image/png"],
      maxFileSize: 40 * 1024 * 1024, // 40MB
      maxImageDimension: 512,
    };
  },
  props: ["value"],
  computed: {
    ...mapState("mode", ["mode"]),
    ...mapState("wallet", ["connected"]),
    ...mapState("pixel", ["pixelDataUrl", "pixelFile"]),
    accept() {
      return this.supportedTypes.join(",");
    },
  },
  watch: {
    pixelDataUrl(val) {
      this.dataUrl = val.base64;
    },
    pixelFile(val) {
      this.file = val;
    },
  },
  methods: {
    ...mapMutations("space", ["previewImage", "stopPreviewingImage"]),
    openImageSelect() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      const file = this.$refs.file.files[0];

      // Error check: max allowed file size
      if (file.size > this.maxFileSize) {
        this.$store.dispatch(
          "error/setErrorMessage",
          "Selected image is too big"
        );

        this.resetImageAndData();

        return;
      }

      // Error check: file mime type
      if (!this.supportedTypes.includes(file.type)) {
        this.$store.dispatch(
          "error/setErrorMessage",
          "Image type is not supported"
        );

        this.resetImageAndData();

        return;
      }

      const imageUploadObj = this; // we need reference of this object in nested code blocks

      var reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = function() {
          // Error check: image dimensions
          if (this.width < 50 || this.height < 50) {
            // error: image dimension too small
            imageUploadObj.$store.dispatch(
              "error/setErrorMessage",
              "Each dimension of the selected image must be 50px or more"
            );

            imageUploadObj.resetImageAndData();
          } else {
            const maxDim = imageUploadObj.maxImageDimension;
            // shrink needed
            if (this.width > maxDim || this.height > maxDim) {
              resizeImage(file, maxDim)
                .then((resizedImage) => {
                  // set references and emit file change
                  imageUploadObj.file = resizedImage[0];
                  imageUploadObj.dataUrl = resizedImage[1];
                  imageUploadObj.$emit("change", imageUploadObj.file);
                })
                .catch((err) => {
                  console.error(err);
                });
            } else {
              // no shrink needed
              // set references and emit file change
              imageUploadObj.file = file;
              imageUploadObj.dataUrl = e.target.result;
              imageUploadObj.$emit("change", imageUploadObj.file);
            }
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    togglePreview() {
      if (this.previewing) {
        this.stopPreviewingImage(this.dataUrl);
        this.$emit("preview", null);
      } else {
        this.previewImage(this.dataUrl);
        this.$emit("preview", this.dataUrl);
      }
      this.previewing = !this.previewing;
    },
    resetImageAndData() {
      // reset references
      this.file = null;
      this.dataUrl = null;

      this.$emit("change", null);
    },
    fileFromBase64(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      let mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      // write the bytes of the string to a typed array
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
  },
  async mounted() {
    const pixelBase64 = localStorage.getItem("pixelBase64");
    if (pixelBase64 && this.value) {
      const file = this.fileFromBase64(pixelBase64);
      file.isPixelArt = true;
      this.dataUrl = pixelBase64;

      this.$emit("change", await resizeImage(file, 512));
    }
  },
};
</script>

<style>
.image-upload {
  box-sizing: border-box;
  width: 100%;
  min-height: 180px;
  background-color: #2f3534;
  border: 1px solid #979797;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-button {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  /* border: grey dashed 2px; */
  transition: height 0.3s, width 0.3s, background-color 0.3s;
}

.upload-button:hover {
  background-color: #004038;
  color: aliceblue;
}

.upload-text {
  color: #555555;
}

.image-controls {
  display: flex;
  width: 100%;
}

.image-controls button {
  display: flex;
  align-items: center;
  justify-content: center;

  background: #2e2d2d;
  border: 1px solid #979797;
}

.image-controls button:focus {
  outline: 0;
}

.image-controls button.active {
  background: #505050;
}

.flex-1 {
  flex-grow: 1;
}

.placeholder {
  width: 20%;
  margin: 32px;
  fill: white;
}

.preview {
  width: 100%;
  height: 180px;
  object-fit: contain;
}
</style>
