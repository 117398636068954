<template>
  <div class="wrapper">
    <div class="moving-albert-1">
      <img :src="mascotImageUrl" width="180" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mascotImageUrl: String,
  },
};
</script>

<style>
.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden;
}

.moving-albert-1 {
  position: absolute;
  width: 100px;
  z-index: 2;
  left: 110%;
  top: 110%;
  animation: moveAlbert1 30s linear 30s infinite forwards;
}

@keyframes moveAlbert1 {
  0% {
    left: 130%;
    top: 120%;
  }
  38% {
    left: -40%;
    top: -20%;
    transform: scaleX(100%) rotate(0deg);

    z-index: 10;
  }
  50% {
    left: -30%;
    top: -30%;
    transform: scaleX(-100%) rotate(-55deg) scale(45%);
    z-index: 0;
  }
  95% {
    left: 130%;
    top: 105%;
    transform: scaleX(-100%) rotate(-55deg) scale(45%);
    z-index: 0;
  }
}
</style>
