<template>
  <div class="collection-grid mt-2">
    <!-- <div class="collection-item" v-for="i in 10" :key="i"> -->
    <!-- <v-skeleton-loader
        v-if="loading.tokens"
        type="image"
        height="100%"
      ></v-skeleton-loader> -->
    <!-- <v-img v-for="i in 10" :key="i" :src="tokenImage(i)">
      <template v-slot:placeholder>
        <v-row class="ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img> -->
    <img width="40" v-for="i in 5" :key="i" :src="tokenImage(i)" />
    <!-- <div
      v-for="i in 10"
      :key="i"
      style="background-color: white; width: 100%;aspect-ratio: 1"
    ></div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import IPFSGatewayTools from "@pinata/ipfs-gateway-tools/dist/browser";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("mint", ["loading", "tokensList"]),
  },
  methods: {
    tokenImage(i) {
      if (
        this.tokensList &&
        this.tokensList[i - 1] &&
        this.tokensList[i - 1].image
      ) {
        const gatewayTools = new IPFSGatewayTools();
        return gatewayTools.convertToDesiredGateway(
          this.tokensList[i - 1].image,
          "https://ipfs.io"
        );
      } else {
        return require("@/assets/no_nft.png");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.collection-grid {
  // position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  width: 91%;
}
</style>
