<template>
  <div class="pt-8 d-flex flex-column justify-center align-center">
    <Grid
      :size="this.size"
      :blockSize="this.blockSize"
      :color="this.currentColor"
    />
    <div
      class="mt-4 pa-2 blue-grey rounded-pill darken-3 flex-row justify-center align-center"
    >
      <PixelColor
        :currentColor="this.currentColor"
        :color="'whitesmoke'"
        :setColor="this.setColor"
      />
      <PixelColor
        :currentColor="this.currentColor"
        :color="'cyan'"
        :setColor="this.setColor"
      />
      <PixelColor
        :currentColor="this.currentColor"
        :color="'gold'"
        :setColor="this.setColor"
      />
      <PixelColor
        :currentColor="this.currentColor"
        :color="'royalblue'"
        :setColor="this.setColor"
      />
      <PixelColor
        :currentColor="this.currentColor"
        :color="'hotpink'"
        :setColor="this.setColor"
      />
    </div>
    <div
      class="mt-4 pa-2 blue-grey darken-3 flex-row justify-center align-center"
    >
      <PixelColorPicker :setColor="this.setColor" />
    </div>
  </div>
</template>

<script>
import Grid from "./PixelGrid.vue";
import PixelColor from "./PixelColor.vue";
import PixelColorPicker from "./PixelColorPicker.vue";

export default {
  name: "PixelDrawer",
  components: {
    Grid,
    PixelColor,
    PixelColorPicker,
  },
  data() {
    return {
      currentColor: "whitesmoke",
      size: 12,
      blockSize: 28,
    };
  },
  methods: {
    setColor(color) {
      this.currentColor = color;
    },
  },
  created() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        this.size = 12;
        break;
      case "sm":
        this.size = 16;
        break;
      case "md":
        this.size = 24;
        break;
      case "lg":
        this.size = 26;
        break;
      case "xl":
        this.size = 28;
        break;
      default:
        this.size = 12;
    }
  },
};
</script>
