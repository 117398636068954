import Vue from "vue";
import VueRouter from "vue-router";
import Overview from "../views/Overview.vue";
import LegacyOverview from "../views/LegacyOverview.vue";
import CanvasWrapper from "../views/CanvasWrapper.vue";
import Space from "../views/Space.vue";
import Leaderboard from "../views/Leaderboard.vue";
import Timeline from "../views/Timeline.vue";
import Poster from "../views/Poster.vue";
import Profile from "../views/Profile.vue";
import PublicProfile from "../views/PublicProfile.vue";
import MintSpace from "../views/mint/MintSpace.vue";
import MintOverview from "../views/mint/MintOverview.vue";
import MintCollection from "../views/mint/MintCollection.vue";
import MintPromo from "../views/mint/MintPromo.vue";
import CollectionWrapper from "../views/mint/CollectionWrapper.vue";
import NftDetails from "../views/mint/NftDetails.vue";

import store from "../store";

Vue.use(VueRouter);

const env = process.env.VUE_APP_ENV
  ? process.env.VUE_APP_ENV.toUpperCase()
  : null;
const title = "1000Blocks.Space" + (env && env != "PROD" ? ` - ${env}` : "");

const routes = [
  {
    path: "/",
    name: "Overview",
    component: Overview,
    meta: {
      title,
    },
  },

  {
    path: "/legacy",
    name: "LegacyOverview",
    component: LegacyOverview,
    meta: {
      title,
    },
  },

  {
    path: "/mint",
    component: MintSpace,
    redirect: "/mint/overview",
    beforeEnter: (to, from, next) => {
      if (to.query.ref) {
        store.dispatch("mint/registerReferral", to.query.ref);
      }
      return next();
    },
    meta: {
      title,
    },
    children: [
      {
        path: "overview",
        name: "MintOverview",
        component: MintOverview,
      },
      {
        path: "collection",
        name: "",
        component: CollectionWrapper,
        children: [
          {
            path: "",
            name: "MintCollection",
            component: MintCollection,
          },
          {
            path: ":spaceApeId",
            name: "NftDetails",
            component: NftDetails,
            props: {
              details: {
                name: "Albert 2 - SpaceApe #390",
                description:
                  "SpaceApes by 1000Blocks.space - The Alberts collection. Extremely rare, extremely powerful smart NFTs",
                image: "ipfs://NewUriToReplace/390.png",
                attributes: [
                  {
                    trait_type: "Space",
                    value: "Saturn Light 2",
                  },
                  {
                    trait_type: "Generation",
                    value: "2",
                  },
                  {
                    trait_type: "Backpack",
                    value: "Jetpack Blue",
                  },
                  {
                    trait_type: "Lineage",
                    value: "Albert 2",
                  },
                  {
                    trait_type: "Expression",
                    value: "Rotten Teeth",
                  },
                  {
                    trait_type: "Look",
                    value: "Serios",
                  },
                  {
                    trait_type: "Face Cover",
                    value: "Pirat Eyepatch",
                  },
                  {
                    trait_type: "Suit",
                    value: "Winter Onesie",
                  },
                  {
                    trait_type: "Helmet",
                    value: "Glass",
                  },
                  {
                    trait_type: "Gloves and Tools",
                    value: "Commander",
                  },
                ],
              },
            },
          },
        ],
      },
      {
        path: "share2earn",
        name: "Share2Earn",
        component: MintPromo,
      },
    ],
  },

  {
    path: "/spaceapes",
    redirect: (to) => {
      if (to.query.ref) {
        store.dispatch("mint/registerReferral", to.query.ref);
      }
      return "/mint";
    },
  },

  {
    path: "/spaces",
    name: "Spaces",
    redirect: "/",
  },
  {
    path: "/profile",
    component: Profile,
    name: "Profile",
    meta: {
      title,
    },
  },
  {
    path: "/profile/:uid",
    component: PublicProfile,
    name: "PublicProfile",
    meta: {
      title,
    },
  },
  {
    path: "/:spaceSlug",
    component: Space,
    name: "Space",
    meta: {
      title,
    },
    children: [
      {
        path: "",
        component: CanvasWrapper,
        name: "CanvasWrapper",
        meta: {
          title,
        },
      },
      {
        path: "leaderboard",
        name: "Leaderboard",
        component: Leaderboard,
        meta: {
          title,
        },
      },
      {
        path: "timeline",
        name: "Timeline",
        component: Timeline,
        meta: {
          title,
        },
      },
      {
        path: "/:spaceSlug/:posterId",
        name: "Poster",
        component: Poster,
        meta: {
          title,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
