<template>
  <div class="d-flex flex-column justify-center ma-6">
    <MiniCanvas
      :width="selected.size ? selected.size.width : 0"
      :height="selected.size ? selected.size.height : 0"
      :cols="currentSpace.config.maxLimits.width"
      :rows="currentSpace.config.maxLimits.height"
    />
    <v-divider class="my-4" />
    <v-btn block color="accent" class="black--text" @click="setMode('buy')"
      >Confirm selection</v-btn
    >
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import MiniCanvas from "../../space/MiniCanvas.vue";
export default {
  components: {
    MiniCanvas,
  },
  computed: {
    ...mapState("space", ["currentSpace"]),
    ...mapGetters("space", ["selected"]),
  },
  methods: {
    ...mapActions("mode", ["setMode"]),
  },
};
</script>

<style></style>
