<template>
  <div class="background-container">
    <div class="stars" :style="style"></div>
    <div class="twinkling"></div>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    backgroundImageUrl: String,
  },
  computed: {
    style() {
      return {
        "--color": this.color || "#1A3837",
        "--background-image": "url(" + this.backgroundImageUrl + ")",
      };
    },
  },
};
</script>

<style scoped>
.background-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: radial-gradient(ellipse at bottom, var(--color) 0%, #090a0f 100%);
}

.stars {
  background: var(--background-image) repeat,
    radial-gradient(ellipse at bottom, var(--color) 0%, #090a0f 100%);
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 0;
}

img {
  height: 70vh;
  width: 70vh;
  position: absolute;
  z-index: 3;
  right: 20px;
}
</style>
