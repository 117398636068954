<template>
  <div id="preloader"></div>
</template>

<script>
export default {};
</script>

<style>
#preloader {
  position: relative;
  /* margin: 150px 0 0 45%; */
}

#preloader:before {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #000;
  content: "";
  position: absolute;
  background: #393e46;

  animation: preloader_before 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

#preloader:after {
  width: 20px;
  height: 20px;
  left: 15%;
  border-radius: 50%;
  content: "";
  position: absolute;
  background: #5f646c;

  animation: preloader_after 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes preloader_before {
  0% {
    transform: translateX(0px) rotate(0deg);
  }
  50% {
    transform: translateX(100px) scale(1.5) rotate(264deg);
    background: #00adb5;
    border-radius: 0;
  }
  100% {
    transform: translateX(0px) rotate(0deg);
  }
}

@keyframes preloader_after {
  0% {
    transform: translateX(0px) rotate(0deg);
  }
  50% {
    transform: translateX(-100px) scale(1.5) rotate(-264deg);
    background: #00fff5;
    border-radius: 0;
  }
  100% {
    transform: translateX(0px);
  }
}
</style>
