<template>
  <v-tabs
    color="white"
    background-color="black"
    class="d-flex justify-center"
    v-if="!isPoster()"
  >
    <v-tab :to="'/' + $route.params.spaceSlug">
      <v-icon class="pr-1" :color="getIconColor('CanvasWrapper')">
        mdi-view-comfy
      </v-icon>

      <div :class="$vuetify.breakpoint.mobile && 'is-mobile'">
        Space
      </div>
    </v-tab>

    <v-tab
      v-if="currentSpace.config && currentSpace.config.voting"
      :to="'/' + $route.params.spaceSlug + '/leaderboard'"
    >
      <v-icon class="pr-1 rotate-and-flip" :color="getIconColor('Leaderboard')"
        >mdi-signal-cellular-3
      </v-icon>

      <div :class="$vuetify.breakpoint.mobile && 'is-mobile'">leaderboard</div>
    </v-tab>
    <v-tab :to="'/' + $route.params.spaceSlug + '/timeline'">
      <v-icon class="pr-1" :color="getIconColor('Timeline')"
        >mdi-view-list
      </v-icon>

      <div :class="$vuetify.breakpoint.mobile && 'is-mobile'">Timeline</div>
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("space", ["currentSpace"]),
  },
  methods: {
    isPoster() {
      return this.$route.name === "Poster";
    },
    getIconColor(action) {
      return this.$route.name == action ? "yellow" : "";
    },
  },
};
</script>

<style scoped>
.is-mobile {
  display: none;
}

.rotate-and-flip {
  transform: rotate(90deg) scaleX(-1);
}
</style>
