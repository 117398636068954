<template>
  <v-container class="d-flex align-start">
    <MintGrid />
    <!-- <MintPromo /> -->

    <div class="mint-sidebar" v-if="!$isMobile()">
      <template v-if="overview">
        <div class="mint-title">
          SPACEAPES-D1
        </div>
        <div class="section bottom-border d-flex flex-column">
          <div class="label">Codename</div>
          <div class="value">The Alberts</div>
          <v-btn
            class="mt-4"
            href="https://1000blocks.medium.com/extremely-rare-extremely-powerful-1000blocks-first-smart-nft-collection-84e8562e59f8"
            target="_blank"
          >
            Learn more
          </v-btn>
        </div>

        <ConnectButton mini v-if="!connected" class="ma-2 align-self-center" />

        <template v-else>
          <div class="section d-flex flex-column" v-if="!allowedToMint">
            <div class="label">Mint start</div>
            <div class="value">
              <vue-countdown-timer
                :start-time="mintStart"
                :end-time="mintStart"
                :interval="1000"
                :seconds-txt="null"
                :end-text="'Let the mint begin!'"
              >
                <template slot="countdown" slot-scope="scope">
                  <span>{{ scope.props.days }}</span>
                  {{ scope.props.dayTxt }}
                  <span>
                    {{ scope.props.hours }}
                  </span>
                  {{ scope.props.hourTxt }}
                  <span>
                    {{ scope.props.minutes }}
                  </span>
                  {{ scope.props.minutesTxt }}
                  <span>
                    {{ scope.props.seconds }}
                  </span>
                  {{ scope.props.secondsTxt }}
                </template>
              </vue-countdown-timer>
            </div>
          </div>

          <div
            class="section d-flex flex-column"
            v-else-if="allowedToMint && whitelist"
          >
            <div class="label">Whitelisted only until</div>
            <div class="value">
              <vue-countdown-timer
                :start-time="mintStart + whitelistTime"
                :end-time="mintStart + whitelistTime"
                :interval="1000"
                :seconds-txt="null"
              >
                <template slot="countdown" slot-scope="scope">
                  <span>{{ scope.props.days }}</span>
                  {{ scope.props.dayTxt }}
                  <span>
                    {{ scope.props.hours }}
                  </span>
                  {{ scope.props.hourTxt }}
                  <span>
                    {{ scope.props.minutes }}
                  </span>
                  {{ scope.props.minutesTxt }}
                  <span>
                    {{ scope.props.seconds }}
                  </span>
                  {{ scope.props.secondsTxt }}
                </template>
              </vue-countdown-timer>
            </div>
          </div>

          <div class="section bottom-border d-flex flex-column">
            <!-- <div class="label">Minted</div>
            <div class="value">{{ mintedCount }}/{{ maxCount }}</div> -->
            <div class="value-small">Mint your own Space Apes</div>
            <v-btn
              class="mt-4"
              color="accent black--text"
              @click="goToMint"
              :disabled="!allowedToMint"
              >Mint</v-btn
            >
            <v-btn class="mt-4" color="yellow black--text" to="/mint/share2earn"
              >Share2Earn</v-btn
            >
          </div>
          <div class="section bottom-border d-flex flex-column">
            <div class="label label-accent">My Collection</div>
            <div class="collection-grid mt-4">
              <div class="collection-item" v-for="i in 10" :key="i">
                <v-skeleton-loader
                  v-if="loading.tokens"
                  type="image"
                  height="100%"
                ></v-skeleton-loader>
                <v-img v-else width="100%" :src="tokenImage(i)">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </div>
            <v-btn class="mt-4" to="/mint/collection">Full Overview</v-btn>
          </div>
        </template>
      </template>

      <template v-else-if="minting">
        <MintSelect @cancel="goToOverview" />
      </template>
    </div>

    <template v-if="minting && $isMobile()">
      <MintDialog @cancel="goToOverview" />
    </template>

    <v-footer v-if="$isMobile()" fixed padless inset>
      <MintFooter @goToMint="goToMint" />
    </v-footer>
  </v-container>
</template>

<script>
import IPFSGatewayTools from "@pinata/ipfs-gateway-tools/dist/browser";
import { mapGetters, mapState } from "vuex";
import MintFooter from "../../components/mint/MintFooter.vue";
import MintGrid from "../../components/mint/MintGrid.vue";
import ConnectButton from "../../components/cross-space/ConnectButton.vue";
import MintSelect from "../../components/mint/MintSelect.vue";
import MintDialog from "../../components/mint/MintDialog.vue";
// import MintPromo from "../../components/mint/MintPromo.vue";
export default {
  data() {
    return {
      overview: true,
      minting: false,
    };
  },
  components: {
    MintFooter,
    MintGrid,
    ConnectButton,
    MintSelect,
    MintDialog,
    // MintPromo,
  },
  mounted() {
    if (this.$route.query.mint) {
      this.goToMint();
    }
  },

  computed: {
    ...mapState("mint", [
      "loading",
      "maxCount",
      "mintedCount",
      "tokensList",
      "allowedToMint",
      "whitelist",
      "mintStart",
      "whitelistTime",
    ]),
    ...mapGetters("mint", ["priceOfOne", "priceOfMany"]),
    ...mapState("wallet", ["connected"]),
  },
  methods: {
    goToMint() {
      this.overview = false;
      this.minting = true;
    },

    goToOverview() {
      this.overview = true;
      this.minting = false;
    },

    tokenImage(i) {
      if (
        this.tokensList &&
        this.tokensList[i - 1] &&
        this.tokensList[i - 1].image
      ) {
        const gatewayTools = new IPFSGatewayTools();
        return gatewayTools.convertToDesiredGateway(
          this.tokensList[i - 1].image,
          "https://ipfs.io"
        );
      } else {
        return require("@/assets/no_nft.png");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mint-sidebar {
  width: 280px;
  margin: 24px;
  margin-left: 0;
  z-index: 0;
  background-color: black;
  border: 1px solid #555555;

  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.mint-title {
  color: #1abfb8;
  padding: 12px;
  border-bottom: 1px solid #555555;
  font-size: 1.125rem;
}

.collection-grid {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 20%;
  gap: 6px;
  .collection-item,
  .mint-item {
    width: calc(20% - 5px);
    aspect-ratio: 1;
    background-color: #2e2e2e;
    border: 1px solid #555555;
  }
  .mint-item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.selected-item {
      background-color: #015653;
      border: 1px solid #05827d;
    }
  }
}
</style>
