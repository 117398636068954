<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    width="500"
    :fullscreen="$isMobile()"
  >
    <v-card tile :loading="loading">
      <RetroCardTitle closable @close="$emit('input', false)"
        >Emergency upload</RetroCardTitle
      >
      <v-card-text>
        <v-row class="mt-1" justify="center">
          <v-col cols="12">
            <h1 class="accent--text text-body-1 text-uppercase">
              Upload Photo
            </h1>
            <ImageUpload v-on:change="file = $event" :value="value" />

            <h1 class="mt-3 accent--text text-body-1 text-uppercase">Text</h1>
            <input
              class="text-input"
              v-model="posterText"
              :maxlength="textMaxLength"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-row class="mb-1" justify="center">
          <v-col cols="12" xl="6">
            <v-btn
              color="accent"
              class="black--text"
              block
              :disabled="file == null"
              :loading="loading"
              @click.native="upload()"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RetroCardTitle from "../helpers/RetroCardTitle.vue";
import ImageUpload from "../info-panel/ImageUpload.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    RetroCardTitle,
    ImageUpload,
  },
  props: ["value"],
  data() {
    return {
      internalValue: 0,
      disabled: false,
      textMaxLength: 64,
      file: null,
      purchasePrice: 0,
      posterText: "",
    };
  },
  computed: {
    ...mapState("space", ["selected"]),
    ...mapGetters("space", ["minPosterPrice", "loading"]),
    size() {
      return this.selected.size
        ? this.selected.size.width * this.selected.size.height
        : 0;
    },
  },
  methods: {
    ...mapActions("space", ["emergencyUpload"]),

    upload() {
      this.emergencyUpload({
        file: this.file,
        text: this.posterText,
        emergencyUpload: true,
      });
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss"></style>
