export default {
  blocksWidth: 42,
  blocksHeight: 24,

  blocksMocks: function() {
    let b = [];
    for (let j = 0; j < this.blocksHeight; j++) {
      for (let i = 0; i < this.blocksWidth; i++) {
        const id = i * 100 + j;
        b.push({
          id,
          ...this.blockLocation(id)
        });
      }
    }
    return b;
  },

  blocks: function() {
    return this.blocks();
  },

  // 101 = x:1, y:1
  // 0 = x:0, y:0
  // 4123 = x:41, y:23
  blockLocation: function(id) {
    return {
      x: Math.floor(id / 100),
      y: id % 100
    };
  },

  blockId: function(location) {
    return location.x * 100 + location.y;
  }
};
