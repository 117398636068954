<template>
  <div
    :class="
      $vuetify.breakpoint.mobile
        ? 'd-flex flex-column align-start justify-center pa-3'
        : 'd-flex flex-row align-start justify-center pa-5'
    "
  >
    <v-dialog v-model="nameDialog" :width="width">
      <v-card>
        <v-card-title style="border-bottom: 1px solid grey;">
          <v-icon class="accent--text text--darken-2 mr-4" dense
            >mdi-circle
          </v-icon>
          <span class="accent--text text-h6">
            Change display name
          </span>
        </v-card-title>

        <v-text-field
          label="Display name (Max 15 Characters)"
          class="pa-5"
          v-model="name"
          :maxLength="15"
          required
        ></v-text-field>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-2" text @click="nameDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="accent black--text"
            text
            v-on:click="updateDisplayName()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card
      :width="width"
      :class="$vuetify.breakpoint.mobile ? 'mb-5' : 'mr-5'"
      style="box-sizing: border-box; border: 1px solid grey; background: black;"
      elevation="5"
    >
      <v-card-title
        :class="$vuetify.breakpoint.mobile && 'pa-2'"
        style="border-bottom: 1px solid grey;"
      >
        <v-icon class="accent--text text--darken-2 mr-4" dense
          >mdi-circle
        </v-icon>
        <span :class="titleStyle">
          MY PROFILE
        </span>
      </v-card-title>
      <div
        :class="
          $vuetify.breakpoint.mobile
            ? 'd-flex flex-row align-center justify-center pa-5'
            : 'd-flex flex-row align-center justify-space-between pa-5'
        "
        style="border-bottom: 1px solid grey;"
      >
        <v-avatar
          size="180"
          color="grey darken-2"
          :style="{ border: setHexBorder() }"
        >
          <img
            v-if="this.profilePicture !== '' && connected"
            :src="this.profilePicture"
          />
          <img v-else src="@/assets/no_nft.png" />
        </v-avatar>
        <v-card-actions>
          <v-btn
            color="grey darken-2"
            :width="$vuetify.breakpoint.mobile ? '100' : '150'"
            v-if="connected && !$vuetify.breakpoint.mobile"
            @click="selectNFT = !selectNFT"
          >
            {{ selectNFT ? "CANCEL" : "CHANGE" }}
          </v-btn>
          <ConnectButton
            mini
            v-if="!connected && !$vuetify.breakpoint.mobile"
          />
        </v-card-actions>
      </div>
      <v-card-text
        :class="
          $vuetify.breakpoint.mobile
            ? 'd-flex flex-row align-end justify-center text-center pa-2 '
            : 'd-flex flex-row align-end justify-space-between'
        "
        style="border-bottom: 1px solid grey;"
      >
        <div
          class="d-flex flex-column align-left justify-space-between"
          style="overflow: hidden;"
        >
          <span class="yellow--text text-subtitle-1">DISPLAY NAME</span>
          <span class="white--text text-h6" v-if="!connected">@NAME</span>
          <span class="white--text text-h6" v-else-if="!shouldDisplayName">{{
            readableAddress
          }}</span>

          <span
            class="white--text text-h6"
            style="white-space: nowrap;"
            v-else
            >{{ this.displayName }}</span
          >
        </div>
        <v-card-actions class="pb-0">
          <v-btn
            color="grey darken-2"
            :width="$vuetify.breakpoint.mobile ? '100' : '150'"
            v-if="connected && !$vuetify.breakpoint.mobile"
            @click="changeName()"
          >
            CHANGE
          </v-btn>
          <ConnectButton
            mini
            v-if="!connected && !$vuetify.breakpoint.mobile"
          />
        </v-card-actions>
      </v-card-text>
      <v-card-text
        :class="
          $vuetify.breakpoint.mobile
            ? 'd-flex flex-row align-end justify-center text-center pa-2'
            : 'd-flex flex-row align-end justify-space-between'
        "
        style="border-bottom: 1px solid grey;"
      >
        <div class="d-flex flex-column align-left justify-space-between">
          <span class="yellow--text text-subtitle-1">TWITTER HANDLE</span>
          <span
            class="white--text text-h6"
            v-if="this.twitterHandle === '' || !connected"
            >@HANDLE</span
          >
          <span class="white--text text-h6" v-else>{{
            this.twitterHandle
          }}</span>
        </div>
        <v-card-actions class="pb-0">
          <v-btn
            color="grey darken-2"
            :width="$vuetify.breakpoint.mobile ? '100' : '150'"
            v-if="connected && !$vuetify.breakpoint.mobile"
            v-on:click="linkTwitter()"
          >
            LINK
          </v-btn>
          <ConnectButton
            mini
            v-if="!connected && !$vuetify.breakpoint.mobile"
          />
        </v-card-actions>
      </v-card-text>
      <v-card-text
        :class="
          $vuetify.breakpoint.mobile
            ? 'd-flex flex-row align-center justify-space-between pa-2'
            : 'd-flex flex-row align-center justify-space-between'
        "
      >
        <span
          class="text-uppercase text-caption grey--text"
          style="white-space:nowrap;"
          >Update your profile via Metamask Desktop</span
        >
        <v-card-actions class="pb-0">
          <v-btn
            color="grey darken-2"
            :width="$vuetify.breakpoint.mobile ? '100' : '150'"
            v-if="connected && !$vuetify.breakpoint.mobile"
            v-on:click="clearProfile()"
          >
            RESET
          </v-btn>
          <ConnectButton
            mini
            v-if="!connected && !$vuetify.breakpoint.mobile"
          />
        </v-card-actions>
      </v-card-text>
    </v-card>
    <v-card
      :width="width"
      height="auto"
      style="box-sizing: border-box; border: 1px solid grey; background: black;"
      elevation="5"
      loader-height="10"
      :loading="loadingMintData"
    >
      <v-card-title
        style="border-bottom: 1px solid grey;"
        class="d-flex flex-row align-center justify-space-between pa-2"
      >
        <div>
          <v-icon class="accent--text text--darken-2" dense>mdi-circle </v-icon>
          <span :class="titleStyle">
            MY SPACE APES CREW ({{ crewCount }})
          </span>
        </div>
        <v-card-actions class="pa-0 ma-0">
          <v-btn
            :class="$vuetify.breakpoint.mobile ? 'pa-0 ma-0 mr-1' : 'pa-0 ma-0'"
            color="accent lighten-2 black--text"
            :width="$vuetify.breakpoint.mobile ? '75' : '125'"
            :height="$vuetify.breakpoint.mobile ? '30' : '50'"
            v-if="connected"
            v-on:click="mintRedirect()"
          >
            <span class="font-weight-medium">MINT</span>
          </v-btn>
          <ConnectButton mini v-if="!connected" />
        </v-card-actions>
      </v-card-title>
      <v-card-text v-if="!connected" class="mt-5">
        Your minted NFTs will be displayed here and can be used as your profile
        picture.
      </v-card-text>
      <v-card-text v-if="connected && myNfts.length === 0" class="mt-5">
        Any minted Space Ape NFTs will appear here, if you have none begin by
        minting.
      </v-card-text>
      <v-row class="pa-0" no-gutters v-if="connected && myNfts.length > 0">
        <v-col v-for="(item, i) in myNfts" :key="i" cols="12" sm="4">
          <v-card
            :max-width="width"
            height="auto"
            class="pa-3"
            :style="
              (i + 1) % 3 === 0
                ? 'box-sizing: border-box; border-bottom: 1px solid grey;'
                : 'box-sizing: border-box; border-bottom: 1px solid grey; border-right: 1px solid grey;'
            "
          >
            <v-img
              :width="$vuetify.breakpoint.mobile ? 'auto' : '250'"
              height="auto"
              :src="item.imageThumbnailUrl"
              class="mb-2"
              :alt="item.name"
            />
            <div class="d-flex flex-column">
              <span class="mb-2 accent--text text-h6 text-uppercase">{{
                item.name
              }}</span>

              <span class="yellow--text">Rarity</span>
              <v-rating
                background-color="grey darken-2"
                color="accent"
                empty-icon="mdi-square pa-0"
                full-icon="mdi-square pa-0"
                class="mb-2 d-flex flex-wrap"
                :readonly="true"
                length="10"
                size="20"
                :value="item.rarity / 10"
              />
              <span class="yellow--text">Special Trait</span>
              <span
                class="text-uppercase text-caption grey--text"
                style="white-space:nowrap;"
                >{{ specialTrait(item.attributes) }}</span
              >
            </div>
            <v-card-actions class="pa-0 ma-0 mt-2">
              <v-btn
                class="pa-0 ma-0"
                color="accent lighten-2 black--text"
                :width="$vuetify.breakpoint.mobile ? '100' : '125'"
                height="30"
                v-if="selectNFT"
                v-on:click="setAsProfilePic(item.profilePic)"
              >
                <span class="font-weight-medium">SELECT</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";

import {
  getNfts,
  requestAccount,
  getUserNonce,
  signRequest,
} from "../services/profileService";
import ConnectButton from "../components/cross-space/ConnectButton.vue";

export default {
  name: "Profile",
  components: {
    ConnectButton,
  },
  data: () => ({
    nameDialog: false,
    name: "",
    selectNFT: false,
    myNfts: [],
    crewCount: 0,
    loadingMintData: false,
  }),
  computed: {
    ...mapState("mint", ["loading", "tokens", "tokensList"]),
    ...mapState("wallet", ["connected", "account"]),
    ...mapState("profile", [
      "profilePicture",
      "displayName",
      "twitterHandle",
      "hexBorder",
    ]),
    ...mapGetters("wallet", ["readableAddress"]),
    titleStyle() {
      return this.$vuetify.breakpoint.mobile
        ? "accent--text text-body-2"
        : "accent--text text-h6";
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return 500;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
        default:
          return 700;
      }
    },
    shouldDisplayName() {
      return this.displayName !== "" && this.connected;
    },
  },
  watch: {
    connected(val) {
      if (val) {
        this.loadProfile(this.account);
        this.loadMintData();
        this.loadingMintData = true;
        this.setSuccessMessage("Wallet profile connected!");
      } else {
        this.clearProfile();
        this.loadingMintData = false;
        this.crewCount = 0;
      }
    },
    async tokensList(val) {
      const idList = val.map((element) => element.tokenId);

      const userNfts = await getNfts(idList);

      this.myNfts = userNfts;
      this.crewCount = userNfts.length;
      this.loadingMintData = false;
    },
  },
  methods: {
    ...mapActions("mint", ["loadMintData"]),
    ...mapActions("profile", [
      "setDisplayName",
      "setTwitterHandle",
      "setProfilePicture",
      "loadProfile",
      "clearProfile",
      "resetProfile",
    ]),
    ...mapActions("error", ["setErrorMessage", "setSuccessMessage"]),
    mintRedirect() {
      this.$router.push("/mint/collection");
    },
    async getSignature() {
      const [address] = await requestAccount();
      const { nonce } = await getUserNonce(address);
      const signature = await signRequest(address, nonce);

      return signature;
    },
    async clearProfile() {
      const signature = await this.getSignature();

      this.resetProfile({
        wallet: this.account,
        signature: signature,
      });
    },
    async setAsProfilePic(picUrl) {
      this.selectNFT = false;
      const signature = await this.getSignature();

      this.setProfilePicture({
        wallet: this.account,
        signature: signature,
        profilePicture: picUrl, // Url
      });
    },
    changeName() {
      this.nameDialog = true;
    },
    async updateDisplayName() {
      this.nameDialog = false;
      const signature = await this.getSignature();

      this.setDisplayName({
        wallet: this.account,
        signature: signature,
        displayName: this.name,
      });
    },
    async linkTwitter() {
      const provider = new firebase.auth.TwitterAuthProvider();
      const signature = await this.getSignature();

      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          this.setTwitterHandle({
            wallet: this.account,
            signature: signature,
            twitterHandle: result.additionalUserInfo.username,
          });

          this.setSuccessMessage(
            `Twitter account has been linked!, thanks ${result.additionalUserInfo.username}`
          );
        })
        .catch((error) => {
          // Handle Errors here.
          this.setErrorMessage(error.message);
        });
    },
    setHexBorder() {
      if (this.connected) {
        return `5px solid ${this.hexBorder} !important`;
      } else {
        return "5px solid #00a79f !important";
      }
    },
    specialTrait(attributes) {
      let rarestTrait = {
        title: "None",
        occurance: 100.0,
      };

      attributes.forEach((trait) => {
        if (trait.occurance <= rarestTrait.occurance) {
          rarestTrait.title = trait.value;
          rarestTrait.occurance = trait.occurance;
        }
      });

      return rarestTrait.title;
    },
  },
  created() {
    if (this.connected) {
      this.loadProfile(this.account);
      this.loadMintData();
      this.loadingMintData = true;
    } else {
      this.setErrorMessage("Connect your wallet to view profile");
    }
  },
};
</script>
