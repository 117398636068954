export const SpaceConfig = (options) => {
  const type = options.type || 'standard'

  const spaceId = options.spaceId || 0
  const name = options.name || ''
  const subtitle = options.subtitle || ''
  const active = options.active || false
  const disabled = options.disabled || false
  const hidden = options.hidden || false
  
  // URL slug to use when showing the space
  const slug = options.slug || name.toLowerCase()
              .replace(/ /g, '-')
              .replace(/[^\w-]+/g, '')

  // Flying mascot
  const mascot = options.mascot || false
  const mascotImageUrl = options.mascotImageUrl || undefined
  
  // Space image used instead of the build canvas image
  const spaceImageUrl = options.spaceImageUrl || undefined
  const spaceIconUrl = options.spaceIconUrl || undefined

  const canvasImageUrl = options.canvasImageUrl || require("@/assets/1000BLSP-Rectangle-Full-BLACK.png")
  const backgroundImageUrl = options.backgroundImageUrl || require("@/assets/stars.png");

  const rules = options.rules || undefined

  // Limits of the size of block area you can claim
  const maxLimits = options.maxLimits || {width: 7, height: 7, area: 42}

  // Each claim has a minimum fee
  const claimable = options.claimable || false
  const claimFixed = options.claimFixed || false
  const claimToken = options.claimToken || 'BNB'

  // Takeover
  const takeover = options.takeover || false
  const takeoverToken = options.takeoverToken || 'BLS'

  // Rewards system
  // Space yield information
  const rewards = options.rewards || false
  const rewardsToken = options.rewardsToken || 'BLS'
  const rewardsPerBlock = options.rewardsPerBlock || '24'

  // TODO: Limit the accessability of the space to members only (proof of membership needed)
  const membersOnly = options.membersOnly || false
  const memberCheck = options.memberCheck || function() {return false};
  const membershipType = options.membershipType || 'twitter';
  const twitterHandle = options.twitterHandle || '';

  // Displays the thumbs up voting system
  const voting = options.voting || false

  // TODO: space lifecycle state

  const pixelArt = options.pixelArt || false

  const spaceAddress = options.spaceAddress || undefined
  const managerAddress = options.managerAddress || undefined

  const sort = options.sort || 0

  return {type, 
    spaceId, 
    name, 
    subtitle,
    slug, 

    mascot,
    mascotImageUrl,

    spaceImageUrl,
    spaceIconUrl,

    canvasImageUrl,
    backgroundImageUrl,

    active, 
    disabled,
    hidden,

    rules,

    maxLimits, 

    claimable,
    claimToken,
    claimFixed,
    takeover, 
    takeoverToken,
    
    rewards,
    rewardsToken,
    rewardsPerBlock,

    membersOnly,
    memberCheck,
    membershipType,
    twitterHandle,
    voting,

    pixelArt,
    
    spaceAddress,
    managerAddress,

    sort
  }
};

