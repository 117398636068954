const VueCookie = require('vue-cookie');
import store from "../store"
import { db } from "../services/firebase";

export async function deposit(amount) {
  const vaultContract = store.state.wallet.ethers.signedVaultContract;

  const transaction = await vaultContract.deposit(amount);
  const receipt = await transaction.wait();

  if (!receipt.status) {
    throw Error("Transaction was not successful")
  }
}

export async function withdraw() {
  const vaultContract = store.state.wallet.ethers.signedVaultContract;
  
  const transaction = await vaultContract.withdraw();
  const receipt = await transaction.wait();

  if (!receipt.status) {
    throw Error("Transaction was not successful")
  }
}

export async function harvestRewards() {
  const vaultContract = store.state.wallet.ethers.signedVaultContract;
  
  const transaction = await vaultContract.claim();
  const receipt = await transaction.wait();

  if (!receipt.status) {
    throw Error("Transaction was not successful")
  }
}

export async function currentReward(address) {
  const vaultContract = store.state.wallet.ethers.vaultContract;
  
  const currentReward = await vaultContract.pendingRewards(address);
  return currentReward;
}

export async function userInfo(address) {
  const vaultContract = store.state.wallet.ethers.vaultContract;
  
  // {amount = deposited tokens, totalRewardPerTokenSnapshot}
  const userInfo = await vaultContract.userInfo(address);
  return userInfo;
}

export async function rewardsPerBlockPerToken() {
  const vaultContract = store.state.wallet.ethers.vaultContract;
  
  const rewardsPerBlockPerToken = await vaultContract.rewardsPerBlockPerToken();
  return rewardsPerBlockPerToken;
}

export async function rewardsPoolFlows() {
  const cookieName = "flowsData";
  
  let flowsData = VueCookie.get(cookieName);

  if (!flowsData) {

    // default values
    flowsData = {
      Inflow: 0,
      Outflow: 0
    };

    // read data from Firestore
    const docRef = db.collection("data").doc("0");
    const doc = await docRef.get();
    if (doc.exists) {
      const data = doc.data();
      if (data.flowsData) {
        flowsData.Inflow = data.flowsData.Inflow;
        flowsData.Outflow = data.flowsData.Outflow;
      }
    }

    VueCookie.set(
      cookieName,
      JSON.stringify(flowsData),
      {
        expires: '15m'
      });
  } else {
    
    flowsData = JSON.parse(flowsData);
  }

  return flowsData;
}