<template>
  <div>
    <v-color-picker
      dot-size="20"
      hide-inputs
      :canvas-height="$vuetify.breakpoint.mobile ? '30' : '100'"
      hide-mode-switch
      mode="hexa"
      v-model="color"
      width="200"
    ></v-color-picker>
  </div>
</template>

<script>
export default {
  name: "PixelColorPicker",
  props: {
    setColor: {
      type: Function,
      required: true,
    },
  },
  computed: {
    color: {
      get() {
        return "hex";
      },
      set(v) {
        this.setColor(v);
      },
    },
  },
};
</script>
