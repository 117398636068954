<template>
  <v-app-bar
    app
    clipped-right
    color="black"
    elevation="6"
    dark
    style="z-index: 1000"
    v-if="!isPoster()"
  >
    <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->
    <div class="d-flex align-center mr-3">
      <router-link to="/spaces">
        <v-img
          v-if="spaceSwitcher"
          alt="1000Blocks Logo"
          class="shrink mr-2"
          contain
          src="@/assets/1000BS-Logo-Mini.svg"
          transition="scale-transition"
          width="40"
        />
        <v-img
          v-else
          alt="Back to Overview"
          class="shrink mr-2"
          contain
          src="@/assets/UI-icon_BACK.svg"
          transition="scale-transition"
          width="40"
        />
      </router-link>
    </div>
    <BreakingNews />

    <v-spacer></v-spacer>

    <!-- MOBILE -->
    <div class="d-flex align-center" v-if="$isMobile() && isSpace()">
      <h3 v-if="currentSpace.config" class="text-uppercase font-weight-bold">
        {{ currentSpace.config.name }}
      </h3>

      <img class="bls-symbol ma-2 mr-2" src="@/assets/info.svg" width="20" />
    </div>

    <!-- DESKTOP -->
    <template v-if="!$isMobile()">
      <OverviewToolbar v-if="isOverview()" />
      <SpaceToolbar v-if="isSpace()" />
      <MintToolbar v-if="isMint()" />
    </template>
    <v-spacer></v-spacer>

    <ConnectButton />
  </v-app-bar>
  <v-app-bar
    v-else
    app
    clipped-right
    color="black"
    elevation="6"
    dark
    style="z-index: 1000;"
    width="100%"
  >
    <div
      class="d-flex flex-row align-center justify-space-between"
      style="width: 100%;"
    >
      <v-img
        alt="1000Blocks Logo"
        class="shrink mr-2"
        contain
        src="@/assets/1000BS-Logo-Mini.svg"
        transition="scale-transition"
        width="40"
      />
      <span class="text-body-1 font-weight-bold"
        >{{ this.$route.params.spaceSlug }} /
        {{ this.$route.params.posterId.substring(0, 3) }}...{{
          this.$route.params.posterId.substring(
            this.$route.params.posterId.length - 3
          )
        }}</span
      >
      <v-icon large @click="this.redirectToTimeline" style="cursor:pointer;">
        mdi-close
      </v-icon>
    </div>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BreakingNews from "./BreakingNews.vue";
import SpaceToolbar from "./SpaceToolbar.vue";
import ConnectButton from "./ConnectButton.vue";
import MintToolbar from "../mint/MintToolbar.vue";
import OverviewToolbar from "../overview/OverviewToolbar.vue";

export default {
  components: {
    SpaceToolbar,
    ConnectButton,
    BreakingNews,
    MintToolbar,
    OverviewToolbar,
  },
  data: () => {
    return {
      depositWithdrawDialog: false,
    };
  },
  computed: {
    ...mapState("wallet", ["connected"]),
    ...mapGetters("wallet", ["readableAddress", "bnbBalance", "blsBalance"]),
    ...mapState("space", ["currentSpace", "spaceSwitcher"]),

    ...mapGetters("vault", ["deposited", "reward", "rewardsPerSec"]),
    bnbRewardNumber() {
      return parseFloat(this.reward);
    },
    bnbRewardsPerSecNumber() {
      return parseFloat(this.rewardsPerSec);
    },

    ...mapGetters("harvest", ["userReward", "blsPerSec"]),
    blsRewardNumber() {
      return parseFloat(this.userReward);
    },
    blsRewardsPerSecNumber() {
      return parseFloat(this.blsPerSec);
    },
  },
  methods: {
    ...mapActions("wallet", ["connectWallet", "disconnectWallet"]),
    ...mapActions("vault", ["approveVault", "deposit", "withdraw"]),
    isPoster() {
      return this.$route.name === "Poster";
    },
    redirectToTimeline() {
      this.$router.push({ path: `/${this.$route.params.spaceSlug}/timeline` });
    },
    isSpace() {
      return ["CanvasWrapper", "Leaderboard", "Timeline"].includes(
        this.$route.name
      );
    },
    isOverview() {
      return ["Overview", "LegacyOverview"].includes(this.$route.name);
    },
    isMint() {
      return ["MintOverview", "MintCollection", "Share2Earn"].includes(
        this.$route.name
      );
    },
  },
};
</script>

<style scoped>
.tooltip-text {
  width: 16rem;
}

.pot-button-label {
  font-size: 0.8rem;
}
.pot-wallet-text {
  font-size: 0.9rem;
  line-height: 1rem;
}
</style>
