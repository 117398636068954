export const ghost28x28 = [
  [
    { y: 0, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 196, prevColor: "#FFEEFFFF" },
    { y: 336, x: 196, prevColor: "#FFEEFFFF" },
    { y: 364, x: 196, prevColor: "#FFEEFFFF" },
    { y: 392, x: 196, prevColor: "#FFEEFFFF" },
    { y: 420, x: 196, prevColor: "#FFEEFFFF" },
    { y: 448, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 224, prevColor: "#FFEEFFFF" },
    { y: 308, x: 224, prevColor: "#FFEEFFFF" },
    { y: 336, x: 224, prevColor: "#F7F7F7FF" },
    { y: 364, x: 224, prevColor: "#FFFFFFFF" },
    { y: 392, x: 224, prevColor: "#FFFFFFFF" },
    { y: 420, x: 224, prevColor: "#FFFFFFFF" },
    { y: 448, x: 224, prevColor: "#FFEEFFFF" },
    { y: 476, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 252, prevColor: "#FFEEFFFF" },
    { y: 280, x: 252, prevColor: "#FFEEFFFF" },
    { y: 308, x: 252, prevColor: "#F7F7F7FF" },
    { y: 336, x: 252, prevColor: "#FFFFFFFF" },
    { y: 364, x: 252, prevColor: "#FFFFFFFF" },
    { y: 392, x: 252, prevColor: "#FFFFFFFF" },
    { y: 420, x: 252, prevColor: "#FFFFFFFF" },
    { y: 448, x: 252, prevColor: "#FFFFFFFF" },
    { y: 476, x: 252, prevColor: "#FFEEFFFF" },
    { y: 504, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 280, prevColor: "#FFEEFFFF" },
    { y: 280, x: 280, prevColor: "#F7F7F7FF" },
    { y: 308, x: 280, prevColor: "#FFFFFFFF" },
    { y: 336, x: 280, prevColor: "#E60909FF" },
    { y: 364, x: 280, prevColor: "#FFFFFFFF" },
    { y: 392, x: 280, prevColor: "#FFFFFFFF" },
    { y: 420, x: 280, prevColor: "#FFFFFFFF" },
    { y: 448, x: 280, prevColor: "#E60909FF" },
    { y: 476, x: 280, prevColor: "#FFEEFFFF" },
    { y: 504, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 308, prevColor: "#FFEEFFFF" },
    { y: 280, x: 308, prevColor: "#F7F7F7FF" },
    { y: 308, x: 308, prevColor: "#FFFFFFFF" },
    { y: 336, x: 308, prevColor: "#E60909FF" },
    { y: 364, x: 308, prevColor: "#E60909FF" },
    { y: 392, x: 308, prevColor: "#FFFFFFFF" },
    { y: 420, x: 308, prevColor: "#E60909FF" },
    { y: 448, x: 308, prevColor: "#E60909FF" },
    { y: 476, x: 308, prevColor: "#FFEEFFFF" },
    { y: 504, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 336, prevColor: "#FFEEFFFF" },
    { y: 280, x: 336, prevColor: "#F7F7F7FF" },
    { y: 308, x: 336, prevColor: "#FFFFFFFF" },
    { y: 336, x: 336, prevColor: "#FFFFFFFF" },
    { y: 364, x: 336, prevColor: "#FFFFFFFF" },
    { y: 392, x: 336, prevColor: "#FFFFFFFF" },
    { y: 420, x: 336, prevColor: "#FFFFFFFF" },
    { y: 448, x: 336, prevColor: "#FFFFFFFF" },
    { y: 476, x: 336, prevColor: "#FFEEFFFF" },
    { y: 504, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 364, prevColor: "#FFEEFFFF" },
    { y: 280, x: 364, prevColor: "#F7F7F7FF" },
    { y: 308, x: 364, prevColor: "#FFFFFFFF" },
    { y: 336, x: 364, prevColor: "#FFFFFFFF" },
    { y: 364, x: 364, prevColor: "#E60909FF" },
    { y: 392, x: 364, prevColor: "#E60909FF" },
    { y: 420, x: 364, prevColor: "#FFFFFFFF" },
    { y: 448, x: 364, prevColor: "#FFFFFFFF" },
    { y: 476, x: 364, prevColor: "#FFEEFFFF" },
    { y: 504, x: 364, prevColor: "#FFEEFFFF" },
    { y: 532, x: 364, prevColor: "#FFEEFFFF" },
    { y: 560, x: 364, prevColor: "#FFEEFFFF" },
    { y: 588, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 392, prevColor: "#FFEEFFFF" },
    { y: 280, x: 392, prevColor: "#F7F7F7FF" },
    { y: 308, x: 392, prevColor: "#FFFFFFFF" },
    { y: 336, x: 392, prevColor: "#FFFFFFFF" },
    { y: 364, x: 392, prevColor: "#E60909FF" },
    { y: 392, x: 392, prevColor: "#E60909FF" },
    { y: 420, x: 392, prevColor: "#FFFFFFFF" },
    { y: 448, x: 392, prevColor: "#FFFFFFFF" },
    { y: 476, x: 392, prevColor: "#FFFFFFFF" },
    { y: 504, x: 392, prevColor: "#FFFFFFFF" },
    { y: 532, x: 392, prevColor: "#FFFFFFFF" },
    { y: 560, x: 392, prevColor: "#FFEEFFFF" },
    { y: 588, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 420, prevColor: "#FFEEFFFF" },
    { y: 112, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 420, prevColor: "#FFEEFFFF" },
    { y: 280, x: 420, prevColor: "#F7F7F7FF" },
    { y: 308, x: 420, prevColor: "#FFFFFFFF" },
    { y: 336, x: 420, prevColor: "#FFFFFFFF" },
    { y: 364, x: 420, prevColor: "#FFFFFFFF" },
    { y: 392, x: 420, prevColor: "#FFFFFFFF" },
    { y: 420, x: 420, prevColor: "#FFFFFFFF" },
    { y: 448, x: 420, prevColor: "#FFFFFFFF" },
    { y: 476, x: 420, prevColor: "#FFFFFFFF" },
    { y: 504, x: 420, prevColor: "#FFFFFFFF" },
    { y: 532, x: 420, prevColor: "#FFEEFFFF" },
    { y: 560, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 448, prevColor: "#FFEEFFFF" },
    { y: 112, x: 448, prevColor: "#FFEEFFFF" },
    { y: 140, x: 448, prevColor: "#FFEEFFFF" },
    { y: 168, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 448, prevColor: "#FFEEFFFF" },
    { y: 252, x: 448, prevColor: "#F7F7F7FF" },
    { y: 280, x: 448, prevColor: "#F7F7F7FF" },
    { y: 308, x: 448, prevColor: "#FFFFFFFF" },
    { y: 336, x: 448, prevColor: "#FFFFFFFF" },
    { y: 364, x: 448, prevColor: "#FFFFFFFF" },
    { y: 392, x: 448, prevColor: "#FFFFFFFF" },
    { y: 420, x: 448, prevColor: "#FFFFFFFF" },
    { y: 448, x: 448, prevColor: "#FFFFFFFF" },
    { y: 476, x: 448, prevColor: "#FFEEFFFF" },
    { y: 504, x: 448, prevColor: "#FFEEFFFF" },
    { y: 532, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 476, prevColor: "#FFEEFFFF" },
    { y: 112, x: 476, prevColor: "#F7F7F7FF" },
    { y: 140, x: 476, prevColor: "#F7F7F7FF" },
    { y: 168, x: 476, prevColor: "#FFEEFFFF" },
    { y: 196, x: 476, prevColor: "#FFEEFFFF" },
    { y: 224, x: 476, prevColor: "#FFEEFFFF" },
    { y: 252, x: 476, prevColor: "#F7F7F7FF" },
    { y: 280, x: 476, prevColor: "#FFFFFFFF" },
    { y: 308, x: 476, prevColor: "#FFFFFFFF" },
    { y: 336, x: 476, prevColor: "#FFFFFFFF" },
    { y: 364, x: 476, prevColor: "#FFFFFFFF" },
    { y: 392, x: 476, prevColor: "#FFFFFFFF" },
    { y: 420, x: 476, prevColor: "#FFFFFFFF" },
    { y: 448, x: 476, prevColor: "#FFFFFFFF" },
    { y: 476, x: 476, prevColor: "#FFEEFFFF" },
    { y: 504, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 504, prevColor: "#FFEEFFFF" },
    { y: 112, x: 504, prevColor: "#F7F7F7FF" },
    { y: 140, x: 504, prevColor: "#FFFFFFFF" },
    { y: 168, x: 504, prevColor: "#F7F7F7FF" },
    { y: 196, x: 504, prevColor: "#F7F7F7FF" },
    { y: 224, x: 504, prevColor: "#F7F7F7FF" },
    { y: 252, x: 504, prevColor: "#FFFFFFFF" },
    { y: 280, x: 504, prevColor: "#FFFFFFFF" },
    { y: 308, x: 504, prevColor: "#FFFFFFFF" },
    { y: 336, x: 504, prevColor: "#FFFFFFFF" },
    { y: 364, x: 504, prevColor: "#FFFFFFFF" },
    { y: 392, x: 504, prevColor: "#FFFFFFFF" },
    { y: 420, x: 504, prevColor: "#FFFFFFFF" },
    { y: 448, x: 504, prevColor: "#FFEEFFFF" },
    { y: 476, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 532, prevColor: "#FFEEFFFF" },
    { y: 140, x: 532, prevColor: "#FFFFFFFF" },
    { y: 168, x: 532, prevColor: "#FFFFFFFF" },
    { y: 196, x: 532, prevColor: "#FFFFFFFF" },
    { y: 224, x: 532, prevColor: "#FFFFFFFF" },
    { y: 252, x: 532, prevColor: "#FFFFFFFF" },
    { y: 280, x: 532, prevColor: "#FFFFFFFF" },
    { y: 308, x: 532, prevColor: "#FFFFFFFF" },
    { y: 336, x: 532, prevColor: "#FFFFFFFF" },
    { y: 364, x: 532, prevColor: "#FFFFFFFF" },
    { y: 392, x: 532, prevColor: "#FFFFFFFF" },
    { y: 420, x: 532, prevColor: "#FFEEFFFF" },
    { y: 448, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 560, prevColor: "#FFEEFFFF" },
    { y: 168, x: 560, prevColor: "#FFEEFFFF" },
    { y: 196, x: 560, prevColor: "#F7F7F7FF" },
    { y: 224, x: 560, prevColor: "#FFFFFFFF" },
    { y: 252, x: 560, prevColor: "#FFFFFFFF" },
    { y: 280, x: 560, prevColor: "#FFFFFFFF" },
    { y: 308, x: 560, prevColor: "#FFFFFFFF" },
    { y: 336, x: 560, prevColor: "#FFFFFFFF" },
    { y: 364, x: 560, prevColor: "#FFFFFFFF" },
    { y: 392, x: 560, prevColor: "#FFEEFFFF" },
    { y: 420, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 588, prevColor: "#FFEEFFFF" },
    { y: 224, x: 588, prevColor: "#FFEEFFFF" },
    { y: 252, x: 588, prevColor: "#FFEEFFFF" },
    { y: 280, x: 588, prevColor: "#FFEEFFFF" },
    { y: 308, x: 588, prevColor: "#FFEEFFFF" },
    { y: 336, x: 588, prevColor: "#FFEEFFFF" },
    { y: 364, x: 588, prevColor: "#FFEEFFFF" },
    { y: 392, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
  ],
];

export const jellyfish28x28 = [
  [
    { y: 0, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 0, prevColor: "#4A4A4AFF" },
    { y: 308, x: 0, prevColor: "#4A4A4AFF" },
    { y: 336, x: 0, prevColor: "#4A4A4AFF" },
    { y: 364, x: 0, prevColor: "#4A4A4AFF" },
    { y: 392, x: 0, prevColor: "#4A4A4AFF" },
    { y: 420, x: 0, prevColor: "#4A4A4AFF" },
    { y: 448, x: 0, prevColor: "#4A4A4AFF" },
    { y: 476, x: 0, prevColor: "#4A4A4AFF" },
    { y: 504, x: 0, prevColor: "#4A4A4AFF" },
    { y: 532, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 28, prevColor: "#4A4A4AFF" },
    { y: 280, x: 28, prevColor: "#A6E8FFFF" },
    { y: 308, x: 28, prevColor: "#A6E8FFFF" },
    { y: 336, x: 28, prevColor: "#A6E8FFFF" },
    { y: 364, x: 28, prevColor: "#A6E8FFFF" },
    { y: 392, x: 28, prevColor: "#A6E8FFFF" },
    { y: 420, x: 28, prevColor: "#A6E8FFFF" },
    { y: 448, x: 28, prevColor: "#A6E8FFFF" },
    { y: 476, x: 28, prevColor: "#A6E8FFFF" },
    { y: 504, x: 28, prevColor: "#A6E8FFFF" },
    { y: 532, x: 28, prevColor: "#4A4A4AFF" },
    { y: 560, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 56, prevColor: "#4A4A4AFF" },
    { y: 252, x: 56, prevColor: "#4A4A4AFF" },
    { y: 280, x: 56, prevColor: "whitesmoke" },
    { y: 308, x: 56, prevColor: "whitesmoke" },
    { y: 336, x: 56, prevColor: "#10BAF7FF" },
    { y: 364, x: 56, prevColor: "#10BAF7FF" },
    { y: 392, x: 56, prevColor: "#10BAF7FF" },
    { y: 420, x: 56, prevColor: "#10BAF7FF" },
    { y: 448, x: 56, prevColor: "#10BAF7FF" },
    { y: 476, x: 56, prevColor: "#A6E8FFFF" },
    { y: 504, x: 56, prevColor: "#A6E8FFFF" },
    { y: 532, x: 56, prevColor: "#A6E8FFFF" },
    { y: 560, x: 56, prevColor: "#4A4A4AFF" },
    { y: 588, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 84, prevColor: "#4A4A4AFF" },
    { y: 224, x: 84, prevColor: "#A6E8FFFF" },
    { y: 252, x: 84, prevColor: "whitesmoke" },
    { y: 280, x: 84, prevColor: "whitesmoke" },
    { y: 308, x: 84, prevColor: "#10BAF7FF" },
    { y: 336, x: 84, prevColor: "#10BAF7FF" },
    { y: 364, x: 84, prevColor: "#08B5F2FF" },
    { y: 392, x: 84, prevColor: "#08B5F2FF" },
    { y: 420, x: 84, prevColor: "#08B5F2FF" },
    { y: 448, x: 84, prevColor: "#08B5F2FF" },
    { y: 476, x: 84, prevColor: "#08B5F2FF" },
    { y: 504, x: 84, prevColor: "#9DE5FFFF" },
    { y: 532, x: 84, prevColor: "#A6E8FFFF" },
    { y: 560, x: 84, prevColor: "#A6E8FFFF" },
    { y: 588, x: 84, prevColor: "#4A4A4AFF" },
    { y: 616, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 112, prevColor: "#4A4A4AFF" },
    { y: 196, x: 112, prevColor: "#A6E8FFFF" },
    { y: 224, x: 112, prevColor: "whitesmoke" },
    { y: 252, x: 112, prevColor: "whitesmoke" },
    { y: 280, x: 112, prevColor: "whitesmoke" },
    { y: 308, x: 112, prevColor: "#10BAF7FF" },
    { y: 336, x: 112, prevColor: "#08B5F2FF" },
    { y: 364, x: 112, prevColor: "#08B5F2FF" },
    { y: 392, x: 112, prevColor: "#08B5F2FF" },
    { y: 420, x: 112, prevColor: "#08B5F2FF" },
    { y: 448, x: 112, prevColor: "#08B5F2FF" },
    { y: 476, x: 112, prevColor: "#08B5F2FF" },
    { y: 504, x: 112, prevColor: "#08B5F2FF" },
    { y: 532, x: 112, prevColor: "#A6E8FFFF" },
    { y: 560, x: 112, prevColor: "#A6E8FFFF" },
    { y: 588, x: 112, prevColor: "#A6E8FFFF" },
    { y: 616, x: 112, prevColor: "#4A4A4AFF" },
    { y: 644, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 140, prevColor: "#4A4A4AFF" },
    { y: 196, x: 140, prevColor: "#A6E8FFFF" },
    { y: 224, x: 140, prevColor: "whitesmoke" },
    { y: 252, x: 140, prevColor: "whitesmoke" },
    { y: 280, x: 140, prevColor: "#08B5F2FF" },
    { y: 308, x: 140, prevColor: "whitesmoke" },
    { y: 336, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 140, prevColor: "#08B5F2FF" },
    { y: 392, x: 140, prevColor: "#08B5F2FF" },
    { y: 420, x: 140, prevColor: "#08B5F2FF" },
    { y: 448, x: 140, prevColor: "whitesmoke" },
    { y: 476, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 140, prevColor: "#08B5F2FF" },
    { y: 532, x: 140, prevColor: "#08B5F2FF" },
    { y: 560, x: 140, prevColor: "#A8DDF0FF" },
    { y: 588, x: 140, prevColor: "#A6E8FFFF" },
    { y: 616, x: 140, prevColor: "#252626FF" },
    { y: 644, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 168, prevColor: "#4A4A4AFF" },
    { y: 168, x: 168, prevColor: "#A6E8FFFF" },
    { y: 196, x: 168, prevColor: "#A6E8FFFF" },
    { y: 224, x: 168, prevColor: "whitesmoke" },
    { y: 252, x: 168, prevColor: "#08B5F2FF" },
    { y: 280, x: 168, prevColor: "#08B5F2FF" },
    { y: 308, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 168, prevColor: "#08B5F2FF" },
    { y: 392, x: 168, prevColor: "#08B5F2FF" },
    { y: 420, x: 168, prevColor: "#08B5F2FF" },
    { y: 448, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 168, prevColor: "#08B5F2FF" },
    { y: 532, x: 168, prevColor: "#08B5F2FF" },
    { y: 560, x: 168, prevColor: "#9DE5FFFF" },
    { y: 588, x: 168, prevColor: "#A6E8FFFF" },
    { y: 616, x: 168, prevColor: "#A6E8FFFF" },
    { y: 644, x: 168, prevColor: "#4A4A4AFF" },
    { y: 672, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 196, prevColor: "#4A4A4AFF" },
    { y: 168, x: 196, prevColor: "#A6E8FFFF" },
    { y: 196, x: 196, prevColor: "#A6E8FFFF" },
    { y: 224, x: 196, prevColor: "#9DE5FFFF" },
    { y: 252, x: 196, prevColor: "#08B5F2FF" },
    { y: 280, x: 196, prevColor: "#08B5F2FF" },
    { y: 308, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 196, prevColor: "#08B5F2FF" },
    { y: 392, x: 196, prevColor: "#08B5F2FF" },
    { y: 420, x: 196, prevColor: "#08B5F2FF" },
    { y: 448, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 196, prevColor: "#08B5F2FF" },
    { y: 532, x: 196, prevColor: "#08B5F2FF" },
    { y: 560, x: 196, prevColor: "#08B5F2FF" },
    { y: 588, x: 196, prevColor: "#A6E8FFFF" },
    { y: 616, x: 196, prevColor: "#A6E8FFFF" },
    { y: 644, x: 196, prevColor: "#4A4A4AFF" },
    { y: 672, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 224, prevColor: "#4A4A4AFF" },
    { y: 140, x: 224, prevColor: "#A6E8FFFF" },
    { y: 168, x: 224, prevColor: "#A6E8FFFF" },
    { y: 196, x: 224, prevColor: "#9DE5FFFF" },
    { y: 224, x: 224, prevColor: "#08B5F2FF" },
    { y: 252, x: 224, prevColor: "#08B5F2FF" },
    { y: 280, x: 224, prevColor: "#08B5F2FF" },
    { y: 308, x: 224, prevColor: "#08B5F2FF" },
    { y: 336, x: 224, prevColor: "#08B5F2FF" },
    { y: 364, x: 224, prevColor: "#08B5F2FF" },
    { y: 392, x: 224, prevColor: "#08B5F2FF" },
    { y: 420, x: 224, prevColor: "#08B5F2FF" },
    { y: 448, x: 224, prevColor: "#08B5F2FF" },
    { y: 476, x: 224, prevColor: "#08B5F2FF" },
    { y: 504, x: 224, prevColor: "#08B5F2FF" },
    { y: 532, x: 224, prevColor: "#08B5F2FF" },
    { y: 560, x: 224, prevColor: "#08B5F2FF" },
    { y: 588, x: 224, prevColor: "#A8DDF0FF" },
    { y: 616, x: 224, prevColor: "#A8DDF0FF" },
    { y: 644, x: 224, prevColor: "#4A4A4AFF" },
    { y: 672, x: 224, prevColor: "#4A4A4AFF" },
    { y: 700, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 252, prevColor: "#4A4A4AFF" },
    { y: 140, x: 252, prevColor: "#A6E8FFFF" },
    { y: 168, x: 252, prevColor: "#A6E8FFFF" },
    { y: 196, x: 252, prevColor: "whitesmoke" },
    { y: 224, x: 252, prevColor: "whitesmoke" },
    { y: 252, x: 252, prevColor: "#08B5F2FF" },
    { y: 280, x: 252, prevColor: "#08B5F2FF" },
    { y: 308, x: 252, prevColor: "#08B5F2FF" },
    { y: 336, x: 252, prevColor: "#08B5F2FF" },
    { y: 364, x: 252, prevColor: "#08B5F2FF" },
    { y: 392, x: 252, prevColor: "#08B5F2FF" },
    { y: 420, x: 252, prevColor: "#08B5F2FF" },
    { y: 448, x: 252, prevColor: "#08B5F2FF" },
    { y: 476, x: 252, prevColor: "#08B5F2FF" },
    { y: 504, x: 252, prevColor: "#08B5F2FF" },
    { y: 532, x: 252, prevColor: "#08B5F2FF" },
    { y: 560, x: 252, prevColor: "#08B5F2FF" },
    { y: 588, x: 252, prevColor: "#9DE5FFFF" },
    { y: 616, x: 252, prevColor: "#A8DDF0FF" },
    { y: 644, x: 252, prevColor: "#A8DDF0FF" },
    { y: 672, x: 252, prevColor: "#4A4A4AFF" },
    { y: 700, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 280, prevColor: "#4A4A4AFF" },
    { y: 140, x: 280, prevColor: "#A6E8FFFF" },
    { y: 168, x: 280, prevColor: "#A6E8FFFF" },
    { y: 196, x: 280, prevColor: "whitesmoke" },
    { y: 224, x: 280, prevColor: "whitesmoke" },
    { y: 252, x: 280, prevColor: "#08B5F2FF" },
    { y: 280, x: 280, prevColor: "#08B5F2FF" },
    { y: 308, x: 280, prevColor: "#08B5F2FF" },
    { y: 336, x: 280, prevColor: "#08B5F2FF" },
    { y: 364, x: 280, prevColor: "#08B5F2FF" },
    { y: 392, x: 280, prevColor: "#08B5F2FF" },
    { y: 420, x: 280, prevColor: "#08B5F2FF" },
    { y: 448, x: 280, prevColor: "#08B5F2FF" },
    { y: 476, x: 280, prevColor: "#08B5F2FF" },
    { y: 504, x: 280, prevColor: "#08B5F2FF" },
    { y: 532, x: 280, prevColor: "#08B5F2FF" },
    { y: 560, x: 280, prevColor: "#08B5F2FF" },
    { y: 588, x: 280, prevColor: "#9DE5FFFF" },
    { y: 616, x: 280, prevColor: "#A8DDF0FF" },
    { y: 644, x: 280, prevColor: "#A8DDF0FF" },
    { y: 672, x: 280, prevColor: "#4A4A4AFF" },
    { y: 700, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 308, prevColor: "#4A4A4AFF" },
    { y: 112, x: 308, prevColor: "#A6E8FFFF" },
    { y: 140, x: 308, prevColor: "#A6E8FFFF" },
    { y: 168, x: 308, prevColor: "#A8DDF0FF" },
    { y: 196, x: 308, prevColor: "#9DE5FFFF" },
    { y: 224, x: 308, prevColor: "#08B5F2FF" },
    { y: 252, x: 308, prevColor: "#08B5F2FF" },
    { y: 280, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 308, prevColor: "#10BAF7FF" },
    { y: 336, x: 308, prevColor: "#08B5F2FF" },
    { y: 364, x: 308, prevColor: "#08B5F2FF" },
    { y: 392, x: 308, prevColor: "#08B5F2FF" },
    { y: 420, x: 308, prevColor: "#08B5F2FF" },
    { y: 448, x: 308, prevColor: "#08B5F2FF" },
    { y: 476, x: 308, prevColor: "#08B5F2FF" },
    { y: 504, x: 308, prevColor: "#10BAF7FF" },
    { y: 532, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 308, prevColor: "#08B5F2FF" },
    { y: 588, x: 308, prevColor: "#9DE5FFFF" },
    { y: 616, x: 308, prevColor: "#A8DDF0FF" },
    { y: 644, x: 308, prevColor: "#A8DDF0FF" },
    { y: 672, x: 308, prevColor: "#4A4A4AFF" },
    { y: 700, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 336, prevColor: "#4A4A4AFF" },
    { y: 112, x: 336, prevColor: "#A6E8FFFF" },
    { y: 140, x: 336, prevColor: "#A6E8FFFF" },
    { y: 168, x: 336, prevColor: "#A8DDF0FF" },
    { y: 196, x: 336, prevColor: "#A8DDF0FF" },
    { y: 224, x: 336, prevColor: "#10BAF7FF" },
    { y: 252, x: 336, prevColor: "#08B5F2FF" },
    { y: 280, x: 336, prevColor: "#08B5F2FF" },
    { y: 308, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 336, prevColor: "#08B5F2FF" },
    { y: 560, x: 336, prevColor: "#10BAF7FF" },
    { y: 588, x: 336, prevColor: "#9DE5FFFF" },
    { y: 616, x: 336, prevColor: "#A8DDF0FF" },
    { y: 644, x: 336, prevColor: "#A8DDF0FF" },
    { y: 672, x: 336, prevColor: "#A8DDF0FF" },
    { y: 700, x: 336, prevColor: "#4A4A4AFF" },
    { y: 728, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 364, prevColor: "#4A4A4AFF" },
    { y: 112, x: 364, prevColor: "#A6E8FFFF" },
    { y: 140, x: 364, prevColor: "#A6E8FFFF" },
    { y: 168, x: 364, prevColor: "#A8DDF0FF" },
    { y: 196, x: 364, prevColor: "#A8DDF0FF" },
    { y: 224, x: 364, prevColor: "#9DE5FFFF" },
    { y: 252, x: 364, prevColor: "#10BAF7FF" },
    { y: 280, x: 364, prevColor: "#08B5F2FF" },
    { y: 308, x: 364, prevColor: "#10BAF7FF" },
    { y: 336, x: 364, prevColor: "#10BAF7FF" },
    { y: 364, x: 364, prevColor: "#08B5F2FF" },
    { y: 392, x: 364, prevColor: "#10BAF7FF" },
    { y: 420, x: 364, prevColor: "#08B5F2FF" },
    { y: 448, x: 364, prevColor: "#08B5F2FF" },
    { y: 476, x: 364, prevColor: "#08B5F2FF" },
    { y: 504, x: 364, prevColor: "#08B5F2FF" },
    { y: 532, x: 364, prevColor: "#10BAF7FF" },
    { y: 560, x: 364, prevColor: "#9DE5FFFF" },
    { y: 588, x: 364, prevColor: "#A8DDF0FF" },
    { y: 616, x: 364, prevColor: "#A8DDF0FF" },
    { y: 644, x: 364, prevColor: "#A8DDF0FF" },
    { y: 672, x: 364, prevColor: "#A8DDF0FF" },
    { y: 700, x: 364, prevColor: "#4A4A4AFF" },
    { y: 728, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 392, prevColor: "#4A4A4AFF" },
    { y: 112, x: 392, prevColor: "#A6E8FFFF" },
    { y: 140, x: 392, prevColor: "#A6E8FFFF" },
    { y: 168, x: 392, prevColor: "#A8DDF0FF" },
    { y: 196, x: 392, prevColor: "#A8DDF0FF" },
    { y: 224, x: 392, prevColor: "#A8DDF0FF" },
    { y: 252, x: 392, prevColor: "#A8DDF0FF" },
    { y: 280, x: 392, prevColor: "#10BAF7FF" },
    { y: 308, x: 392, prevColor: "#08B5F2FF" },
    { y: 336, x: 392, prevColor: "#08B5F2FF" },
    { y: 364, x: 392, prevColor: "#08B5F2FF" },
    { y: 392, x: 392, prevColor: "#08B5F2FF" },
    { y: 420, x: 392, prevColor: "#08B5F2FF" },
    { y: 448, x: 392, prevColor: "#08B5F2FF" },
    { y: 476, x: 392, prevColor: "#08B5F2FF" },
    { y: 504, x: 392, prevColor: "#10BAF7FF" },
    { y: 532, x: 392, prevColor: "#A8DDF0FF" },
    { y: 560, x: 392, prevColor: "#A8DDF0FF" },
    { y: 588, x: 392, prevColor: "#A8DDF0FF" },
    { y: 616, x: 392, prevColor: "#A8DDF0FF" },
    { y: 644, x: 392, prevColor: "#A8DDF0FF" },
    { y: 672, x: 392, prevColor: "#A8DDF0FF" },
    { y: 700, x: 392, prevColor: "#4A4A4AFF" },
    { y: 728, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 420, prevColor: "#4A4A4AFF" },
    { y: 112, x: 420, prevColor: "#A6E8FFFF" },
    { y: 140, x: 420, prevColor: "#A6E8FFFF" },
    { y: 168, x: 420, prevColor: "#A6E8FFFF" },
    { y: 196, x: 420, prevColor: "#A6E8FFFF" },
    { y: 224, x: 420, prevColor: "#A6E8FFFF" },
    { y: 252, x: 420, prevColor: "#A8DDF0FF" },
    { y: 280, x: 420, prevColor: "#A8DDF0FF" },
    { y: 308, x: 420, prevColor: "#A8DDF0FF" },
    { y: 336, x: 420, prevColor: "#A8DDF0FF" },
    { y: 364, x: 420, prevColor: "#A8DDF0FF" },
    { y: 392, x: 420, prevColor: "#A8DDF0FF" },
    { y: 420, x: 420, prevColor: "#A8DDF0FF" },
    { y: 448, x: 420, prevColor: "#A8DDF0FF" },
    { y: 476, x: 420, prevColor: "#A8DDF0FF" },
    { y: 504, x: 420, prevColor: "#A8DDF0FF" },
    { y: 532, x: 420, prevColor: "#A8DDF0FF" },
    { y: 560, x: 420, prevColor: "#A8DDF0FF" },
    { y: 588, x: 420, prevColor: "#A8DDF0FF" },
    { y: 616, x: 420, prevColor: "#A8DDF0FF" },
    { y: 644, x: 420, prevColor: "#A8DDF0FF" },
    { y: 672, x: 420, prevColor: "#A8DDF0FF" },
    { y: 700, x: 420, prevColor: "#4A4A4AFF" },
    { y: 728, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 448, prevColor: "#4A4A4AFF" },
    { y: 112, x: 448, prevColor: "#4A4A4AFF" },
    { y: 140, x: 448, prevColor: "#4A4A4AFF" },
    { y: 168, x: 448, prevColor: "#4A4A4AFF" },
    { y: 196, x: 448, prevColor: "#A6E8FFFF" },
    { y: 224, x: 448, prevColor: "#A6E8FFFF" },
    { y: 252, x: 448, prevColor: "#A8DDF0FF" },
    { y: 280, x: 448, prevColor: "#A8DDF0FF" },
    { y: 308, x: 448, prevColor: "#A8DDF0FF" },
    { y: 336, x: 448, prevColor: "#A8DDF0FF" },
    { y: 364, x: 448, prevColor: "#A8DDF0FF" },
    { y: 392, x: 448, prevColor: "#A8DDF0FF" },
    { y: 420, x: 448, prevColor: "#A8DDF0FF" },
    { y: 448, x: 448, prevColor: "#A8DDF0FF" },
    { y: 476, x: 448, prevColor: "#A8DDF0FF" },
    { y: 504, x: 448, prevColor: "#A8DDF0FF" },
    { y: 532, x: 448, prevColor: "#A8DDF0FF" },
    { y: 560, x: 448, prevColor: "#A8DDF0FF" },
    { y: 588, x: 448, prevColor: "#A8DDF0FF" },
    { y: 616, x: 448, prevColor: "#4A4A4AFF" },
    { y: 644, x: 448, prevColor: "#4A4A4AFF" },
    { y: 672, x: 448, prevColor: "#4A4A4AFF" },
    { y: 700, x: 448, prevColor: "#4A4A4AFF" },
    { y: 728, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 476, prevColor: "#4A4A4AFF" },
    { y: 224, x: 476, prevColor: "#1DBAF2FF" },
    { y: 252, x: 476, prevColor: "#4A4A4AFF" },
    { y: 280, x: 476, prevColor: "#3B3B3BFF" },
    { y: 308, x: 476, prevColor: "#4A4A4AFF" },
    { y: 336, x: 476, prevColor: "#1DBAF2FF" },
    { y: 364, x: 476, prevColor: "#4A4A4AFF" },
    { y: 392, x: 476, prevColor: "#3B3B3BFF" },
    { y: 420, x: 476, prevColor: "#4A4A4AFF" },
    { y: 448, x: 476, prevColor: "#1DBAF2FF" },
    { y: 476, x: 476, prevColor: "#4A4A4AFF" },
    { y: 504, x: 476, prevColor: "#3B3B3BFF" },
    { y: 532, x: 476, prevColor: "#4A4A4AFF" },
    { y: 560, x: 476, prevColor: "#1DBAF2FF" },
    { y: 588, x: 476, prevColor: "#4A4A4AFF" },
    { y: 616, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 504, prevColor: "#4A4A4AFF" },
    { y: 224, x: 504, prevColor: "#1DBAF2FF" },
    { y: 252, x: 504, prevColor: "#4A4A4AFF" },
    { y: 280, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 504, prevColor: "#4A4A4AFF" },
    { y: 336, x: 504, prevColor: "#1DBAF2FF" },
    { y: 364, x: 504, prevColor: "#4A4A4AFF" },
    { y: 392, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 504, prevColor: "#4A4A4AFF" },
    { y: 448, x: 504, prevColor: "#1DBAF2FF" },
    { y: 476, x: 504, prevColor: "#4A4A4AFF" },
    { y: 504, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 504, prevColor: "#4A4A4AFF" },
    { y: 560, x: 504, prevColor: "#1DBAF2FF" },
    { y: 588, x: 504, prevColor: "#4A4A4AFF" },
    { y: 616, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 532, prevColor: "#4A4A4AFF" },
    { y: 224, x: 532, prevColor: "#1DBAF2FF" },
    { y: 252, x: 532, prevColor: "#4A4A4AFF" },
    { y: 280, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 532, prevColor: "#4A4A4AFF" },
    { y: 336, x: 532, prevColor: "#1DBAF2FF" },
    { y: 364, x: 532, prevColor: "#4A4A4AFF" },
    { y: 392, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 532, prevColor: "#4A4A4AFF" },
    { y: 448, x: 532, prevColor: "#1DBAF2FF" },
    { y: 476, x: 532, prevColor: "#4A4A4AFF" },
    { y: 504, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 532, prevColor: "#4A4A4AFF" },
    { y: 560, x: 532, prevColor: "#1DBAF2FF" },
    { y: 588, x: 532, prevColor: "#4A4A4AFF" },
    { y: 616, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 560, prevColor: "#4A4A4AFF" },
    { y: 196, x: 560, prevColor: "#1DBAF2FF" },
    { y: 224, x: 560, prevColor: "#4A4A4AFF" },
    { y: 252, x: 560, prevColor: "#4A4A4AFF" },
    { y: 280, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 560, prevColor: "#4A4A4AFF" },
    { y: 336, x: 560, prevColor: "#1DBAF2FF" },
    { y: 364, x: 560, prevColor: "#4A4A4AFF" },
    { y: 392, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 560, prevColor: "#4A4A4AFF" },
    { y: 448, x: 560, prevColor: "#1DBAF2FF" },
    { y: 476, x: 560, prevColor: "#4A4A4AFF" },
    { y: 504, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 560, prevColor: "#4A4A4AFF" },
    { y: 588, x: 560, prevColor: "#1DBAF2FF" },
    { y: 616, x: 560, prevColor: "#4A4A4AFF" },
    { y: 644, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 588, prevColor: "#4A4A4AFF" },
    { y: 168, x: 588, prevColor: "#4A4A4AFF" },
    { y: 196, x: 588, prevColor: "#A6E8FFFF" },
    { y: 224, x: 588, prevColor: "#4A4A4AFF" },
    { y: 252, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 588, prevColor: "#4A4A4AFF" },
    { y: 308, x: 588, prevColor: "#1DBAF2FF" },
    { y: 336, x: 588, prevColor: "#4A4A4AFF" },
    { y: 364, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 588, prevColor: "#4A4A4AFF" },
    { y: 476, x: 588, prevColor: "#1DBAF2FF" },
    { y: 504, x: 588, prevColor: "#4A4A4AFF" },
    { y: 532, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 588, prevColor: "#4A4A4AFF" },
    { y: 588, x: 588, prevColor: "#A6E8FFFF" },
    { y: 616, x: 588, prevColor: "#4A4A4AFF" },
    { y: 644, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 616, prevColor: "#4A4A4AFF" },
    { y: 168, x: 616, prevColor: "#A6E8FFFF" },
    { y: 196, x: 616, prevColor: "#4A4A4AFF" },
    { y: 224, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 616, prevColor: "#4A4A4AFF" },
    { y: 308, x: 616, prevColor: "#A6E8FFFF" },
    { y: 336, x: 616, prevColor: "#4A4A4AFF" },
    { y: 364, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 616, prevColor: "#4A4A4AFF" },
    { y: 476, x: 616, prevColor: "#A6E8FFFF" },
    { y: 504, x: 616, prevColor: "#4A4A4AFF" },
    { y: 532, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 616, prevColor: "#4A4A4AFF" },
    { y: 616, x: 616, prevColor: "#A6E8FFFF" },
    { y: 644, x: 616, prevColor: "#4A4A4AFF" },
    { y: 672, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 644, prevColor: "#4A4A4AFF" },
    { y: 168, x: 644, prevColor: "#A6E8FFFF" },
    { y: 196, x: 644, prevColor: "#4A4A4AFF" },
    { y: 224, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 644, prevColor: "#4A4A4AFF" },
    { y: 280, x: 644, prevColor: "#A6E8FFFF" },
    { y: 308, x: 644, prevColor: "#A6E8FFFF" },
    { y: 336, x: 644, prevColor: "#4A4A4AFF" },
    { y: 364, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 644, prevColor: "#4A4A4AFF" },
    { y: 476, x: 644, prevColor: "#A6E8FFFF" },
    { y: 504, x: 644, prevColor: "#4A4A4AFF" },
    { y: 532, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 644, prevColor: "#4A4A4AFF" },
    { y: 616, x: 644, prevColor: "#A6E8FFFF" },
    { y: 644, x: 644, prevColor: "#4A4A4AFF" },
    { y: 672, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 672, prevColor: "#4A4A4AFF" },
    { y: 140, x: 672, prevColor: "#A6E8FFFF" },
    { y: 168, x: 672, prevColor: "#4A4A4AFF" },
    { y: 196, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 672, prevColor: "#4A4A4AFF" },
    { y: 280, x: 672, prevColor: "#A6E8FFFF" },
    { y: 308, x: 672, prevColor: "#4A4A4AFF" },
    { y: 336, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 672, prevColor: "#4A4A4AFF" },
    { y: 504, x: 672, prevColor: "#A6E8FFFF" },
    { y: 532, x: 672, prevColor: "#4A4A4AFF" },
    { y: 560, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 672, prevColor: "#4A4A4AFF" },
    { y: 644, x: 672, prevColor: "#A6E8FFFF" },
    { y: 672, x: 672, prevColor: "#4A4A4AFF" },
    { y: 700, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 700, prevColor: "#4A4A4AFF" },
    { y: 112, x: 700, prevColor: "#A6E8FFFF" },
    { y: 140, x: 700, prevColor: "#4A4A4AFF" },
    { y: 168, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 700, prevColor: "#4A4A4AFF" },
    { y: 252, x: 700, prevColor: "#A6E8FFFF" },
    { y: 280, x: 700, prevColor: "#4A4A4AFF" },
    { y: 308, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 700, prevColor: "#4A4A4AFF" },
    { y: 532, x: 700, prevColor: "#A6E8FFFF" },
    { y: 560, x: 700, prevColor: "#4A4A4AFF" },
    { y: 588, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 700, prevColor: "#4A4A4AFF" },
    { y: 672, x: 700, prevColor: "#A6E8FFFF" },
    { y: 700, x: 700, prevColor: "#4A4A4AFF" },
    { y: 728, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 728, prevColor: "#4A4A4AFF" },
    { y: 112, x: 728, prevColor: "#4A4A4AFF" },
    { y: 140, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 728, prevColor: "#4A4A4AFF" },
    { y: 252, x: 728, prevColor: "#4A4A4AFF" },
    { y: 280, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 728, prevColor: "#4A4A4AFF" },
    { y: 560, x: 728, prevColor: "#4A4A4AFF" },
    { y: 588, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 728, prevColor: "#4A4A4AFF" },
    { y: 700, x: 728, prevColor: "#4A4A4AFF" },
    { y: 728, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
  ],
];

export const fish28x28 = [
  [
    { y: 0, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 0, prevColor: "#4A4A4AFF" },
    { y: 308, x: 0, prevColor: "#4A4A4AFF" },
    { y: 336, x: 0, prevColor: "#4A4A4AFF" },
    { y: 364, x: 0, prevColor: "#4A4A4AFF" },
    { y: 392, x: 0, prevColor: "#4A4A4AFF" },
    { y: 420, x: 0, prevColor: "#4A4A4AFF" },
    { y: 448, x: 0, prevColor: "#4A4A4AFF" },
    { y: 476, x: 0, prevColor: "#4A4A4AFF" },
    { y: 504, x: 0, prevColor: "#4A4A4AFF" },
    { y: 532, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 28, prevColor: "#4A4A4AFF" },
    { y: 280, x: 28, prevColor: "#A6E8FFFF" },
    { y: 308, x: 28, prevColor: "#A6E8FFFF" },
    { y: 336, x: 28, prevColor: "#A6E8FFFF" },
    { y: 364, x: 28, prevColor: "#A6E8FFFF" },
    { y: 392, x: 28, prevColor: "#A6E8FFFF" },
    { y: 420, x: 28, prevColor: "#A6E8FFFF" },
    { y: 448, x: 28, prevColor: "#A6E8FFFF" },
    { y: 476, x: 28, prevColor: "#A6E8FFFF" },
    { y: 504, x: 28, prevColor: "#A6E8FFFF" },
    { y: 532, x: 28, prevColor: "#4A4A4AFF" },
    { y: 560, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 56, prevColor: "#4A4A4AFF" },
    { y: 252, x: 56, prevColor: "#4A4A4AFF" },
    { y: 280, x: 56, prevColor: "whitesmoke" },
    { y: 308, x: 56, prevColor: "whitesmoke" },
    { y: 336, x: 56, prevColor: "#10BAF7FF" },
    { y: 364, x: 56, prevColor: "#10BAF7FF" },
    { y: 392, x: 56, prevColor: "#10BAF7FF" },
    { y: 420, x: 56, prevColor: "#10BAF7FF" },
    { y: 448, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 56, prevColor: "#A6E8FFFF" },
    { y: 504, x: 56, prevColor: "#A6E8FFFF" },
    { y: 532, x: 56, prevColor: "#A6E8FFFF" },
    { y: 560, x: 56, prevColor: "#4A4A4AFF" },
    { y: 588, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 84, prevColor: "#4A4A4AFF" },
    { y: 224, x: 84, prevColor: "#A6E8FFFF" },
    { y: 252, x: 84, prevColor: "whitesmoke" },
    { y: 280, x: 84, prevColor: "whitesmoke" },
    { y: 308, x: 84, prevColor: "#10BAF7FF" },
    { y: 336, x: 84, prevColor: "#10BAF7FF" },
    { y: 364, x: 84, prevColor: "#08B5F2FF" },
    { y: 392, x: 84, prevColor: "#4F2201FF" },
    { y: 420, x: 84, prevColor: "#4F2201FF" },
    { y: 448, x: 84, prevColor: "#08B5F2FF" },
    { y: 476, x: 84, prevColor: "#08B5F2FF" },
    { y: 504, x: 84, prevColor: "#9DE5FFFF" },
    { y: 532, x: 84, prevColor: "#A6E8FFFF" },
    { y: 560, x: 84, prevColor: "#A6E8FFFF" },
    { y: 588, x: 84, prevColor: "#4A4A4AFF" },
    { y: 616, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 112, prevColor: "#4A4A4AFF" },
    { y: 196, x: 112, prevColor: "#A6E8FFFF" },
    { y: 224, x: 112, prevColor: "whitesmoke" },
    { y: 252, x: 112, prevColor: "whitesmoke" },
    { y: 280, x: 112, prevColor: "whitesmoke" },
    { y: 308, x: 112, prevColor: "#10BAF7FF" },
    { y: 336, x: 112, prevColor: "#08B5F2FF" },
    { y: 364, x: 112, prevColor: "#4F2201FF" },
    { y: 392, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 112, prevColor: "#08B5F2FF" },
    { y: 476, x: 112, prevColor: "#08B5F2FF" },
    { y: 504, x: 112, prevColor: "#08B5F2FF" },
    { y: 532, x: 112, prevColor: "#A6E8FFFF" },
    { y: 560, x: 112, prevColor: "#A6E8FFFF" },
    { y: 588, x: 112, prevColor: "#A6E8FFFF" },
    { y: 616, x: 112, prevColor: "#4A4A4AFF" },
    { y: 644, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 140, prevColor: "#4A4A4AFF" },
    { y: 196, x: 140, prevColor: "#A6E8FFFF" },
    { y: 224, x: 140, prevColor: "whitesmoke" },
    { y: 252, x: 140, prevColor: "whitesmoke" },
    { y: 280, x: 140, prevColor: "#08B5F2FF" },
    { y: 308, x: 140, prevColor: "whitesmoke" },
    { y: 336, x: 140, prevColor: "#4F2201FF" },
    { y: 364, x: 140, prevColor: "#08B5F2FF" },
    { y: 392, x: 140, prevColor: "#08B5F2FF" },
    { y: 420, x: 140, prevColor: "#08B5F2FF" },
    { y: 448, x: 140, prevColor: "whitesmoke" },
    { y: 476, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 140, prevColor: "#08B5F2FF" },
    { y: 532, x: 140, prevColor: "#08B5F2FF" },
    { y: 560, x: 140, prevColor: "#A8DDF0FF" },
    { y: 588, x: 140, prevColor: "#A6E8FFFF" },
    { y: 616, x: 140, prevColor: "#252626FF" },
    { y: 644, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 168, prevColor: "#4A4A4AFF" },
    { y: 168, x: 168, prevColor: "#A6E8FFFF" },
    { y: 196, x: 168, prevColor: "#A6E8FFFF" },
    { y: 224, x: 168, prevColor: "whitesmoke" },
    { y: 252, x: 168, prevColor: "#08B5F2FF" },
    { y: 280, x: 168, prevColor: "#08B5F2FF" },
    { y: 308, x: 168, prevColor: "#4F2201FF" },
    { y: 336, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 168, prevColor: "#08B5F2FF" },
    { y: 392, x: 168, prevColor: "#08B5F2FF" },
    { y: 420, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 168, prevColor: "#08B5F2FF" },
    { y: 532, x: 168, prevColor: "#08B5F2FF" },
    { y: 560, x: 168, prevColor: "#9DE5FFFF" },
    { y: 588, x: 168, prevColor: "#A6E8FFFF" },
    { y: 616, x: 168, prevColor: "#A6E8FFFF" },
    { y: 644, x: 168, prevColor: "#4A4A4AFF" },
    { y: 672, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 196, prevColor: "#4A4A4AFF" },
    { y: 168, x: 196, prevColor: "#A6E8FFFF" },
    { y: 196, x: 196, prevColor: "#A6E8FFFF" },
    { y: 224, x: 196, prevColor: "#9DE5FFFF" },
    { y: 252, x: 196, prevColor: "#08B5F2FF" },
    { y: 280, x: 196, prevColor: "#4F2201FF" },
    { y: 308, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 196, prevColor: "#08B5F2FF" },
    { y: 392, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 196, prevColor: "#08B5F2FF" },
    { y: 532, x: 196, prevColor: "#08B5F2FF" },
    { y: 560, x: 196, prevColor: "#08B5F2FF" },
    { y: 588, x: 196, prevColor: "#A6E8FFFF" },
    { y: 616, x: 196, prevColor: "#A6E8FFFF" },
    { y: 644, x: 196, prevColor: "#4A4A4AFF" },
    { y: 672, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 224, prevColor: "#4A4A4AFF" },
    { y: 140, x: 224, prevColor: "#A6E8FFFF" },
    { y: 168, x: 224, prevColor: "#A6E8FFFF" },
    { y: 196, x: 224, prevColor: "#9DE5FFFF" },
    { y: 224, x: 224, prevColor: "#08B5F2FF" },
    { y: 252, x: 224, prevColor: "#4F2201FF" },
    { y: 280, x: 224, prevColor: "#08B5F2FF" },
    { y: 308, x: 224, prevColor: "#08B5F2FF" },
    { y: 336, x: 224, prevColor: "#08B5F2FF" },
    { y: 364, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 224, prevColor: "cyan" },
    { y: 448, x: 224, prevColor: "#08B5F2FF" },
    { y: 476, x: 224, prevColor: "#08B5F2FF" },
    { y: 504, x: 224, prevColor: "#08B5F2FF" },
    { y: 532, x: 224, prevColor: "#08B5F2FF" },
    { y: 560, x: 224, prevColor: "#08B5F2FF" },
    { y: 588, x: 224, prevColor: "#A8DDF0FF" },
    { y: 616, x: 224, prevColor: "#A8DDF0FF" },
    { y: 644, x: 224, prevColor: "#4A4A4AFF" },
    { y: 672, x: 224, prevColor: "#4A4A4AFF" },
    { y: 700, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 252, prevColor: "#4A4A4AFF" },
    { y: 140, x: 252, prevColor: "#A6E8FFFF" },
    { y: 168, x: 252, prevColor: "#A6E8FFFF" },
    { y: 196, x: 252, prevColor: "whitesmoke" },
    { y: 224, x: 252, prevColor: "#4F2201FF" },
    { y: 252, x: 252, prevColor: "#08B5F2FF" },
    { y: 280, x: 252, prevColor: "#08B5F2FF" },
    { y: 308, x: 252, prevColor: "#08B5F2FF" },
    { y: 336, x: 252, prevColor: "#08B5F2FF" },
    { y: 364, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 252, prevColor: "cyan" },
    { y: 420, x: 252, prevColor: "cyan" },
    { y: 448, x: 252, prevColor: "#4F2201FF" },
    { y: 476, x: 252, prevColor: "#08B5F2FF" },
    { y: 504, x: 252, prevColor: "#08B5F2FF" },
    { y: 532, x: 252, prevColor: "#08B5F2FF" },
    { y: 560, x: 252, prevColor: "#08B5F2FF" },
    { y: 588, x: 252, prevColor: "#9DE5FFFF" },
    { y: 616, x: 252, prevColor: "#A8DDF0FF" },
    { y: 644, x: 252, prevColor: "#A8DDF0FF" },
    { y: 672, x: 252, prevColor: "#4A4A4AFF" },
    { y: 700, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 280, prevColor: "#4A4A4AFF" },
    { y: 140, x: 280, prevColor: "#A6E8FFFF" },
    { y: 168, x: 280, prevColor: "#A6E8FFFF" },
    { y: 196, x: 280, prevColor: "#4F2201FF" },
    { y: 224, x: 280, prevColor: "whitesmoke" },
    { y: 252, x: 280, prevColor: "#08B5F2FF" },
    { y: 280, x: 280, prevColor: "#08B5F2FF" },
    { y: 308, x: 280, prevColor: "#08B5F2FF" },
    { y: 336, x: 280, prevColor: "cyan" },
    { y: 364, x: 280, prevColor: "cyan" },
    { y: 392, x: 280, prevColor: "cyan" },
    { y: 420, x: 280, prevColor: "cyan" },
    { y: 448, x: 280, prevColor: "cyan" },
    { y: 476, x: 280, prevColor: "cyan" },
    { y: 504, x: 280, prevColor: "#08B5F2FF" },
    { y: 532, x: 280, prevColor: "#08B5F2FF" },
    { y: 560, x: 280, prevColor: "#08B5F2FF" },
    { y: 588, x: 280, prevColor: "#9DE5FFFF" },
    { y: 616, x: 280, prevColor: "#A8DDF0FF" },
    { y: 644, x: 280, prevColor: "#A8DDF0FF" },
    { y: 672, x: 280, prevColor: "#4A4A4AFF" },
    { y: 700, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 308, prevColor: "#4A4A4AFF" },
    { y: 112, x: 308, prevColor: "#A6E8FFFF" },
    { y: 140, x: 308, prevColor: "#A6E8FFFF" },
    { y: 168, x: 308, prevColor: "#4F2201FF" },
    { y: 196, x: 308, prevColor: "#9DE5FFFF" },
    { y: 224, x: 308, prevColor: "#08B5F2FF" },
    { y: 252, x: 308, prevColor: "cyan" },
    { y: 280, x: 308, prevColor: "cyan" },
    { y: 308, x: 308, prevColor: "cyan" },
    { y: 336, x: 308, prevColor: "cyan" },
    { y: 364, x: 308, prevColor: "cyan" },
    { y: 392, x: 308, prevColor: "cyan" },
    { y: 420, x: 308, prevColor: "cyan" },
    { y: 448, x: 308, prevColor: "cyan" },
    { y: 476, x: 308, prevColor: "cyan" },
    { y: 504, x: 308, prevColor: "cyan" },
    { y: 532, x: 308, prevColor: "cyan" },
    { y: 560, x: 308, prevColor: "#08B5F2FF" },
    { y: 588, x: 308, prevColor: "#9DE5FFFF" },
    { y: 616, x: 308, prevColor: "#A8DDF0FF" },
    { y: 644, x: 308, prevColor: "#A8DDF0FF" },
    { y: 672, x: 308, prevColor: "#4A4A4AFF" },
    { y: 700, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 336, prevColor: "#4A4A4AFF" },
    { y: 112, x: 336, prevColor: "#A6E8FFFF" },
    { y: 140, x: 336, prevColor: "#4F2201FF" },
    { y: 168, x: 336, prevColor: "#A8DDF0FF" },
    { y: 196, x: 336, prevColor: "#A8DDF0FF" },
    { y: 224, x: 336, prevColor: "cyan" },
    { y: 252, x: 336, prevColor: "cyan" },
    { y: 280, x: 336, prevColor: "cyan" },
    { y: 308, x: 336, prevColor: "cyan" },
    { y: 336, x: 336, prevColor: "cyan" },
    { y: 364, x: 336, prevColor: "cyan" },
    { y: 392, x: 336, prevColor: "cyan" },
    { y: 420, x: 336, prevColor: "cyan" },
    { y: 448, x: 336, prevColor: "cyan" },
    { y: 476, x: 336, prevColor: "cyan" },
    { y: 504, x: 336, prevColor: "cyan" },
    { y: 532, x: 336, prevColor: "cyan" },
    { y: 560, x: 336, prevColor: "cyan" },
    { y: 588, x: 336, prevColor: "#9DE5FFFF" },
    { y: 616, x: 336, prevColor: "#A8DDF0FF" },
    { y: 644, x: 336, prevColor: "#A8DDF0FF" },
    { y: 672, x: 336, prevColor: "#A8DDF0FF" },
    { y: 700, x: 336, prevColor: "#4A4A4AFF" },
    { y: 728, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 364, prevColor: "#4A4A4AFF" },
    { y: 112, x: 364, prevColor: "#4F2201FF" },
    { y: 140, x: 364, prevColor: "#A6E8FFFF" },
    { y: 168, x: 364, prevColor: "#A8DDF0FF" },
    { y: 196, x: 364, prevColor: "cyan" },
    { y: 224, x: 364, prevColor: "cyan" },
    { y: 252, x: 364, prevColor: "cyan" },
    { y: 280, x: 364, prevColor: "cyan" },
    { y: 308, x: 364, prevColor: "cyan" },
    { y: 336, x: 364, prevColor: "cyan" },
    { y: 364, x: 364, prevColor: "cyan" },
    { y: 392, x: 364, prevColor: "cyan" },
    { y: 420, x: 364, prevColor: "cyan" },
    { y: 448, x: 364, prevColor: "cyan" },
    { y: 476, x: 364, prevColor: "cyan" },
    { y: 504, x: 364, prevColor: "cyan" },
    { y: 532, x: 364, prevColor: "cyan" },
    { y: 560, x: 364, prevColor: "cyan" },
    { y: 588, x: 364, prevColor: "cyan" },
    { y: 616, x: 364, prevColor: "#A8DDF0FF" },
    { y: 644, x: 364, prevColor: "#A8DDF0FF" },
    { y: 672, x: 364, prevColor: "#A8DDF0FF" },
    { y: 700, x: 364, prevColor: "#4A4A4AFF" },
    { y: 728, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 392, prevColor: "#4F2201FF" },
    { y: 140, x: 392, prevColor: "#A6E8FFFF" },
    { y: 168, x: 392, prevColor: "cyan" },
    { y: 196, x: 392, prevColor: "cyan" },
    { y: 224, x: 392, prevColor: "cyan" },
    { y: 252, x: 392, prevColor: "cyan" },
    { y: 280, x: 392, prevColor: "cyan" },
    { y: 308, x: 392, prevColor: "cyan" },
    { y: 336, x: 392, prevColor: "cyan" },
    { y: 364, x: 392, prevColor: "cyan" },
    { y: 392, x: 392, prevColor: "cyan" },
    { y: 420, x: 392, prevColor: "cyan" },
    { y: 448, x: 392, prevColor: "cyan" },
    { y: 476, x: 392, prevColor: "cyan" },
    { y: 504, x: 392, prevColor: "cyan" },
    { y: 532, x: 392, prevColor: "cyan" },
    { y: 560, x: 392, prevColor: "cyan" },
    { y: 588, x: 392, prevColor: "cyan" },
    { y: 616, x: 392, prevColor: "cyan" },
    { y: 644, x: 392, prevColor: "#A8DDF0FF" },
    { y: 672, x: 392, prevColor: "#A8DDF0FF" },
    { y: 700, x: 392, prevColor: "#4A4A4AFF" },
    { y: 728, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 420, prevColor: "#4F2201FF" },
    { y: 112, x: 420, prevColor: "#A6E8FFFF" },
    { y: 140, x: 420, prevColor: "#A6E8FFFF" },
    { y: 168, x: 420, prevColor: "cyan" },
    { y: 196, x: 420, prevColor: "whitesmoke" },
    { y: 224, x: 420, prevColor: "whitesmoke" },
    { y: 252, x: 420, prevColor: "cyan" },
    { y: 280, x: 420, prevColor: "cyan" },
    { y: 308, x: 420, prevColor: "cyan" },
    { y: 336, x: 420, prevColor: "cyan" },
    { y: 364, x: 420, prevColor: "cyan" },
    { y: 392, x: 420, prevColor: "cyan" },
    { y: 420, x: 420, prevColor: "cyan" },
    { y: 448, x: 420, prevColor: "cyan" },
    { y: 476, x: 420, prevColor: "cyan" },
    { y: 504, x: 420, prevColor: "cyan" },
    { y: 532, x: 420, prevColor: "cyan" },
    { y: 560, x: 420, prevColor: "cyan" },
    { y: 588, x: 420, prevColor: "cyan" },
    { y: 616, x: 420, prevColor: "cyan" },
    { y: 644, x: 420, prevColor: "#A8DDF0FF" },
    { y: 672, x: 420, prevColor: "#A8DDF0FF" },
    { y: 700, x: 420, prevColor: "#4A4A4AFF" },
    { y: 728, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 448, prevColor: "#4A4A4AFF" },
    { y: 112, x: 448, prevColor: "#4A4A4AFF" },
    { y: 140, x: 448, prevColor: "cyan" },
    { y: 168, x: 448, prevColor: "cyan" },
    { y: 196, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 448, prevColor: "whitesmoke" },
    { y: 252, x: 448, prevColor: "cyan" },
    { y: 280, x: 448, prevColor: "cyan" },
    { y: 308, x: 448, prevColor: "#A8DDF0FF" },
    { y: 336, x: 448, prevColor: "#A8DDF0FF" },
    { y: 364, x: 448, prevColor: "cyan" },
    { y: 392, x: 448, prevColor: "cyan" },
    { y: 420, x: 448, prevColor: "#A8DDF0FF" },
    { y: 448, x: 448, prevColor: "cyan" },
    { y: 476, x: 448, prevColor: "cyan" },
    { y: 504, x: 448, prevColor: "cyan" },
    { y: 532, x: 448, prevColor: "cyan" },
    { y: 560, x: 448, prevColor: "cyan" },
    { y: 588, x: 448, prevColor: "cyan" },
    { y: 616, x: 448, prevColor: "cyan" },
    { y: 644, x: 448, prevColor: "#4A4A4AFF" },
    { y: 672, x: 448, prevColor: "#4A4A4AFF" },
    { y: 700, x: 448, prevColor: "#4A4A4AFF" },
    { y: 728, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 476, prevColor: "#FFEEFFFF" },
    { y: 112, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 476, prevColor: "cyan" },
    { y: 168, x: 476, prevColor: "cyan" },
    { y: 196, x: 476, prevColor: "cyan" },
    { y: 224, x: 476, prevColor: "cyan" },
    { y: 252, x: 476, prevColor: "cyan" },
    { y: 280, x: 476, prevColor: "#FFFFFFFF" },
    { y: 308, x: 476, prevColor: "#4A4A4AFF" },
    { y: 336, x: 476, prevColor: "#1DBAF2FF" },
    { y: 364, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 476, prevColor: "cyan" },
    { y: 420, x: 476, prevColor: "#4A4A4AFF" },
    { y: 448, x: 476, prevColor: "#1DBAF2FF" },
    { y: 476, x: 476, prevColor: "cyan" },
    { y: 504, x: 476, prevColor: "cyan" },
    { y: 532, x: 476, prevColor: "cyan" },
    { y: 560, x: 476, prevColor: "cyan" },
    { y: 588, x: 476, prevColor: "cyan" },
    { y: 616, x: 476, prevColor: "cyan" },
    { y: 644, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 504, prevColor: "#FFEEFFFF" },
    { y: 112, x: 504, prevColor: "cyan" },
    { y: 140, x: 504, prevColor: "cyan" },
    { y: 168, x: 504, prevColor: "cyan" },
    { y: 196, x: 504, prevColor: "cyan" },
    { y: 224, x: 504, prevColor: "cyan" },
    { y: 252, x: 504, prevColor: "#4A4A4AFF" },
    { y: 280, x: 504, prevColor: "#FFFFFFFF" },
    { y: 308, x: 504, prevColor: "#4A4A4AFF" },
    { y: 336, x: 504, prevColor: "#1DBAF2FF" },
    { y: 364, x: 504, prevColor: "#4A4A4AFF" },
    { y: 392, x: 504, prevColor: "#FFFFFFFF" },
    { y: 420, x: 504, prevColor: "#4A4A4AFF" },
    { y: 448, x: 504, prevColor: "#1DBAF2FF" },
    { y: 476, x: 504, prevColor: "cyan" },
    { y: 504, x: 504, prevColor: "cyan" },
    { y: 532, x: 504, prevColor: "cyan" },
    { y: 560, x: 504, prevColor: "cyan" },
    { y: 588, x: 504, prevColor: "cyan" },
    { y: 616, x: 504, prevColor: "cyan" },
    { y: 644, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 532, prevColor: "cyan" },
    { y: 140, x: 532, prevColor: "cyan" },
    { y: 168, x: 532, prevColor: "cyan" },
    { y: 196, x: 532, prevColor: "#4A4A4AFF" },
    { y: 224, x: 532, prevColor: "#1DBAF2FF" },
    { y: 252, x: 532, prevColor: "#4A4A4AFF" },
    { y: 280, x: 532, prevColor: "#FFFFFFFF" },
    { y: 308, x: 532, prevColor: "#4A4A4AFF" },
    { y: 336, x: 532, prevColor: "#1DBAF2FF" },
    { y: 364, x: 532, prevColor: "#4A4A4AFF" },
    { y: 392, x: 532, prevColor: "#FFFFFFFF" },
    { y: 420, x: 532, prevColor: "#4A4A4AFF" },
    { y: 448, x: 532, prevColor: "#1DBAF2FF" },
    { y: 476, x: 532, prevColor: "#4A4A4AFF" },
    { y: 504, x: 532, prevColor: "cyan" },
    { y: 532, x: 532, prevColor: "cyan" },
    { y: 560, x: 532, prevColor: "cyan" },
    { y: 588, x: 532, prevColor: "cyan" },
    { y: 616, x: 532, prevColor: "cyan" },
    { y: 644, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 560, prevColor: "#FFEEFFFF" },
    { y: 168, x: 560, prevColor: "#4A4A4AFF" },
    { y: 196, x: 560, prevColor: "#1DBAF2FF" },
    { y: 224, x: 560, prevColor: "#4A4A4AFF" },
    { y: 252, x: 560, prevColor: "#4A4A4AFF" },
    { y: 280, x: 560, prevColor: "#FFFFFFFF" },
    { y: 308, x: 560, prevColor: "#4A4A4AFF" },
    { y: 336, x: 560, prevColor: "#1DBAF2FF" },
    { y: 364, x: 560, prevColor: "#4A4A4AFF" },
    { y: 392, x: 560, prevColor: "#FFEEFFFF" },
    { y: 420, x: 560, prevColor: "#4A4A4AFF" },
    { y: 448, x: 560, prevColor: "#1DBAF2FF" },
    { y: 476, x: 560, prevColor: "#4A4A4AFF" },
    { y: 504, x: 560, prevColor: "cyan" },
    { y: 532, x: 560, prevColor: "cyan" },
    { y: 560, x: 560, prevColor: "cyan" },
    { y: 588, x: 560, prevColor: "cyan" },
    { y: 616, x: 560, prevColor: "cyan" },
    { y: 644, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 588, prevColor: "#4A4A4AFF" },
    { y: 168, x: 588, prevColor: "#4A4A4AFF" },
    { y: 196, x: 588, prevColor: "#A6E8FFFF" },
    { y: 224, x: 588, prevColor: "#4A4A4AFF" },
    { y: 252, x: 588, prevColor: "#FFEEFFFF" },
    { y: 280, x: 588, prevColor: "#4A4A4AFF" },
    { y: 308, x: 588, prevColor: "#1DBAF2FF" },
    { y: 336, x: 588, prevColor: "#4A4A4AFF" },
    { y: 364, x: 588, prevColor: "#FFEEFFFF" },
    { y: 392, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 588, prevColor: "cyan" },
    { y: 504, x: 588, prevColor: "cyan" },
    { y: 532, x: 588, prevColor: "cyan" },
    { y: 560, x: 588, prevColor: "cyan" },
    { y: 588, x: 588, prevColor: "cyan" },
    { y: 616, x: 588, prevColor: "cyan" },
    { y: 644, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 616, prevColor: "#4A4A4AFF" },
    { y: 168, x: 616, prevColor: "#A6E8FFFF" },
    { y: 196, x: 616, prevColor: "#4A4A4AFF" },
    { y: 224, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 616, prevColor: "#4A4A4AFF" },
    { y: 308, x: 616, prevColor: "#A6E8FFFF" },
    { y: 336, x: 616, prevColor: "#4A4A4AFF" },
    { y: 364, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 616, prevColor: "cyan" },
    { y: 476, x: 616, prevColor: "cyan" },
    { y: 504, x: 616, prevColor: "cyan" },
    { y: 532, x: 616, prevColor: "cyan" },
    { y: 560, x: 616, prevColor: "cyan" },
    { y: 588, x: 616, prevColor: "cyan" },
    { y: 616, x: 616, prevColor: "#A6E8FFFF" },
    { y: 644, x: 616, prevColor: "#4A4A4AFF" },
    { y: 672, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 644, prevColor: "#4A4A4AFF" },
    { y: 168, x: 644, prevColor: "#A6E8FFFF" },
    { y: 196, x: 644, prevColor: "#4A4A4AFF" },
    { y: 224, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 644, prevColor: "#4A4A4AFF" },
    { y: 280, x: 644, prevColor: "#A6E8FFFF" },
    { y: 308, x: 644, prevColor: "#A6E8FFFF" },
    { y: 336, x: 644, prevColor: "#4A4A4AFF" },
    { y: 364, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 644, prevColor: "cyan" },
    { y: 476, x: 644, prevColor: "cyan" },
    { y: 504, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 644, prevColor: "cyan" },
    { y: 560, x: 644, prevColor: "cyan" },
    { y: 588, x: 644, prevColor: "cyan" },
    { y: 616, x: 644, prevColor: "#A6E8FFFF" },
    { y: 644, x: 644, prevColor: "#4A4A4AFF" },
    { y: 672, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 672, prevColor: "#4A4A4AFF" },
    { y: 140, x: 672, prevColor: "#A6E8FFFF" },
    { y: 168, x: 672, prevColor: "#4A4A4AFF" },
    { y: 196, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 672, prevColor: "#4A4A4AFF" },
    { y: 280, x: 672, prevColor: "#A6E8FFFF" },
    { y: 308, x: 672, prevColor: "#4A4A4AFF" },
    { y: 336, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 672, prevColor: "cyan" },
    { y: 448, x: 672, prevColor: "cyan" },
    { y: 476, x: 672, prevColor: "cyan" },
    { y: 504, x: 672, prevColor: "#A6E8FFFF" },
    { y: 532, x: 672, prevColor: "#4A4A4AFF" },
    { y: 560, x: 672, prevColor: "cyan" },
    { y: 588, x: 672, prevColor: "cyan" },
    { y: 616, x: 672, prevColor: "cyan" },
    { y: 644, x: 672, prevColor: "#A6E8FFFF" },
    { y: 672, x: 672, prevColor: "#4A4A4AFF" },
    { y: 700, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 700, prevColor: "#4A4A4AFF" },
    { y: 112, x: 700, prevColor: "#A6E8FFFF" },
    { y: 140, x: 700, prevColor: "#4A4A4AFF" },
    { y: 168, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 700, prevColor: "#4A4A4AFF" },
    { y: 252, x: 700, prevColor: "#A6E8FFFF" },
    { y: 280, x: 700, prevColor: "#4A4A4AFF" },
    { y: 308, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 700, prevColor: "cyan" },
    { y: 420, x: 700, prevColor: "cyan" },
    { y: 448, x: 700, prevColor: "cyan" },
    { y: 476, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 700, prevColor: "#4A4A4AFF" },
    { y: 532, x: 700, prevColor: "#A6E8FFFF" },
    { y: 560, x: 700, prevColor: "#4A4A4AFF" },
    { y: 588, x: 700, prevColor: "cyan" },
    { y: 616, x: 700, prevColor: "cyan" },
    { y: 644, x: 700, prevColor: "cyan" },
    { y: 672, x: 700, prevColor: "#A6E8FFFF" },
    { y: 700, x: 700, prevColor: "#4A4A4AFF" },
    { y: 728, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 728, prevColor: "#4A4A4AFF" },
    { y: 112, x: 728, prevColor: "#4A4A4AFF" },
    { y: 140, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 728, prevColor: "#4A4A4AFF" },
    { y: 252, x: 728, prevColor: "#4A4A4AFF" },
    { y: 280, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 728, prevColor: "cyan" },
    { y: 420, x: 728, prevColor: "cyan" },
    { y: 448, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 728, prevColor: "#4A4A4AFF" },
    { y: 560, x: 728, prevColor: "#4A4A4AFF" },
    { y: 588, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 728, prevColor: "cyan" },
    { y: 644, x: 728, prevColor: "cyan" },
    { y: 672, x: 728, prevColor: "#4A4A4AFF" },
    { y: 700, x: 728, prevColor: "#4A4A4AFF" },
    { y: 728, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 728, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 728, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 756, x: 756, prevColor: "rgba(0, 0, 0, 1)" },
  ],
];
