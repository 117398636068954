<template>
  <v-dialog
    value="true"
    fullscreen
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <template v-slot:default="dialog">
      <v-card>
        <RetroCardTitle
          closable
          @close="
            dialog.value = false;
            setMode('view');
          "
        >
          LOADING...
        </RetroCardTitle>
        <v-card-text>
          <LoadingInfo centered />
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import RetroCardTitle from "../../helpers/RetroCardTitle.vue";
import LoadingInfo from "../side-panel/LoadingInfo.vue";
export default {
  components: {
    RetroCardTitle,
    LoadingInfo,
  },
  methods: {
    ...mapActions("mode", ["setMode"]),
  },
};
</script>

<style></style>
