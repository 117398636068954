import Web3Modal from "web3modal";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import { providers } from "ethers";

import { NETWORKS } from "./networks.js";

export function isOnCorrectNetwork(networkId) {
  const network = NETWORKS[process.env.VUE_APP_NETWORK];
  return network.id === networkId;
}

export async function requestNetworkChange() {
  const network = NETWORKS[process.env.VUE_APP_NETWORK];

  if (network.id) {
    const hexNetworkId = "0x" + network.id.toString(16);

    let { ethereum, web3 } = window;
    if (!ethereum) {
      ethereum = web3;
    }
    if (!ethereum) {
      throw new Error(
        "Please, use a browser that has dApp support, install Metamask or use Wallet Connect."
      );
    }

    await ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: hexNetworkId, // A 0x-prefixed hexadecimal string
          chainName: network.name,
          nativeCurrency: {
            name: network.currency,
            symbol: network.currency, // 2-6 characters long
            decimals: 18,
          },
          rpcUrls: [network.rpcUrl],
        },
      ],
    });

    watchOnNetworkChange();
  }
}

export function watchOnNetworkChange() {
  const { ethereum } = window;
  ethereum.on("chainChanged", () => window.location.reload());
}

export function watchOnAccountChange() {
  const { ethereum } = window;
  ethereum.on("accountsChanged", () => window.location.reload());
}

export function watchOnNetworkChangeWC(provider) {
  try {
    provider.on("chainChanged", () => {
      window.location.reload();
    });
  } catch (err) {
    console.log(err);
  }
}

export function watchOnAccountChangeWC(provider) {
  try {
    provider.on("accountsChanged", () => {
      window.location.reload();
    });
  } catch (err) {
    console.log(err);
  }
}

export async function connect() {
  // const network = NETWORKS[process.env.VUE_APP_NETWORK];

  const providerOptions = {
    // walletconnect: {
    //   package: WalletConnectProvider,
    //   options: {
    //     infuraId: "304aa465565e4e4abae333b06883e4d7", // required
    //     rpc: {
    //       [network.id]: network.rpcUrl
    //     }
    //   }
    // }
  };

  const web3Modal = new Web3Modal({
    // network: "BSC", // optional
    // cacheProvider: true, // optional
    providerOptions, // required
    // theme: "dark",
    theme: {
      background: "rgb(19, 19, 19)",
      main: "rgb(199, 199, 199)",
      secondary: "rgb(136, 136, 136)",
      border: "rgba(195, 195, 195, 0.14)",
      hover: "rgb(32, 32, 32)",
    },
  });

  try {
    const rawProvider = await web3Modal.connect();
    const provider = new providers.Web3Provider(rawProvider);
    // const provider = new ethers.providers.Web3Provider(window.ethereum || window.web3);
    const signer = provider.getSigner();
    return {
      provider,
      signer,
    };
  } catch (err) {
    throw new Error("Unable to connect to your wallet");
  }
}

export async function addTokenToMetamask(dispatch) {
  const tokenAddress = process.env.VUE_APP_CONTRACT_ADDRESS_TOKEN;
  const tokenSymbol = "BLS";
  const tokenDecimals = 18;
  const tokenImage =
    "https://s2.coinmarketcap.com/static/img/coins/64x64/11558.png";

  // wasAdded is a boolean. Like any RPC method, an error may be thrown.
  const wasAdded = await window.ethereum.request({
    method: "wallet_watchAsset",
    params: {
      type: "ERC20", // Initially only supports ERC20, but eventually more!
      options: {
        address: tokenAddress, // The address that the token is at.
        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
        decimals: tokenDecimals, // The number of decimals in the token
        image: tokenImage, // A string url of the token logo
      },
    },
  });

  if (wasAdded) {
    dispatch(
      "error/setSuccessMessage",
      "BLS Token asking to be added to Metamask, lets go! 🚀",
      { root: true }
    );
  } else {
    dispatch("error/setErrorMessage", "BLS Token was not added to Metamask", {
      root: true,
    });
  }
}
