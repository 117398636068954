<template>
  <div class="price-picker">
    <div class="custom">
      <input
        class="custom-input"
        :disabled="disabled"
        :value="value"
        @input="$emit('input', $event.target.value)"
      />
      <span class="symbol-right pa-2 mb-0">{{ symbol }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    symbol: String,
    disabled: Boolean,
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
.price-picker {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  .custom {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  input.custom-input {
    background-color: #2f3534;
    border: 1px solid #979797;
    padding: 8px;
    color: white;
    width: 100%;

    &[disabled] {
      background-color: #757575;
    }
  }

  .symbol-right {
    background-color: #979797;
    border: 1px solid #979797;
    color: black;
  }
}
</style>
