<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    width="512"
    content-class="elevation-0"
  >
    <Poster :poster="poster" />
  </v-dialog>
</template>

<script>
import Poster from "../cross-space/Poster.vue";

export default {
  name: "PosterDialog",
  components: {
    Poster,
  },
  props: ["value", "poster"],
};
</script>
