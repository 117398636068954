import { SpaceConfig } from "./spaceService.js";
import twitterFollowsCheck from "./space-specific/twitterFollowsCheck.js";
import memberCheckAlpaca from "./space-specific/alpaca.js";

export const spaces = [
  SpaceConfig({
    spaceId: 0,
    active: true,
    claimable: false,
    hidden: true,
    name: "Space-01",
    subtitle: "Infinity",

    rewards: true,
    rewardsPerBlock: "0",
    rewardsToken: "BLS",

    rules: [
      "You can claim a minimum of 1 and a maximum of 42 blocks at once.",
      "The minimal price to claim a block or multiple blocks is set by its previous user.",
      "The block claimer has to invest more than the previous owner.",
      "As the owner of the block, if someone covers you, you get 25% of his/her investment.",
      "After claiming blocks you need to wait 12 hours until you can claim again.",
      "As long as your blocks are visible, you will yield 0 BLS per block per day.",
    ],

    spaceAddress: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE01,
    managerAddress: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER01,
  }),

  SpaceConfig({
    spaceId: 1,
    active: true,
    claimable: true,
    claimFixed: true,
    takeover: true,
    name: "SAPES Basecamp",
    subtitle: "Infinite Burn",

    rewards: true,
    rewardsPerBlock: "30",
    rewardsToken: "BLS",

    voting: true,

    rules: [
      {
        title: "Claiming Blocks",
        content: [
          "A Space Ape can claim up to 42 Blocks at a time.",
          "A Space Ape can cover those Blocks with a picture of his choice.",
          "A Space Ape can claim blocks unlimited times BUT a minimum of 24min needs to pass between the claims (restricted per wallet).",
          "To claim a Block there will be a fixed fee: 0.0042BNB. If it’s an empty block or someone already claimed it. It always stays the same. 0.0042BNB",
        ],
      },
      {
        title: "Takeovers",
        content: [
          "If Block/-s already have a previous owner (is covered) a “Take over fee” (TOF) applies.",
          "The TOF is to be paid in BLS tokens and it is dynamic, depending on time!",
          "The TOF will start at 240BLS per Block and decrease every second until it hits 0 BLS after 24h.",
          "Once the TOF of a Block is 0 BLS, it will stay 0 BLS for 1h.",
          "After 1h the TOF will bounce back to 2 BLS per Block.",
        ],
      },
    ],

    spaceAddress: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE02,
    managerAddress: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER02,
  }),

  SpaceConfig({
    spaceId: 2,
    hidden: false,
    active: false,
    claimable: false,
    claimFixed: true,
    name: "X-nftmall",
    subtitle: "NFT Mall",
    type: "exclusive",
    pixelArt: true,
    takeover: false,
    voting: true,
    membersOnly: true,
    memberCheck: twitterFollowsCheck,
    membershipType: "twitter",
    twitterHandle: "NFTmall",
    mascot: false,
    spaceImageUrl: require("@/assets/exclusive/nftmall-img.png"),
    spaceIconUrl: require("@/assets/exclusive/nftmall-icon.svg"),
    canvasImageUrl: require("@/assets/exclusive/nftmall-image.svg"),
    rules: [
      {
        title: "Claiming blocks",
        image: require("@/assets/ils_Blocks.png"),
        content: [
          "One can claim up to 42 blocks every 42min. Claim Fee is set at 0.0042 BNB/Block.",
        ],
      },
      {
        title: "Covering Blocks",
        image: require("@/assets/ils_Poster.png"),
        content: [
          "As the owner of Blocks, you are able to cover them with an image of your choice.",
        ],
      },
      {
        title: "Special Prize",
        image: require("@/assets/ils_Prize.png"),
        content: ["Top 3 photos by popular vote split $1000."],
      },
      {
        title: "Theme",
        image: require("@/assets/ils_Theme.png"),
        content: [
          "The theme is everything Christmas. Only Christmas images are eligible for prizes.",
        ],
      },
    ],

    spaceAddress: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE03,
    managerAddress: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER03,
  }),

  SpaceConfig({
    spaceId: 3,
    hidden: false,
    active: true,
    claimable: true,
    claimFixed: true,
    name: "X-alpaca",
    subtitle: "Alpies",
    type: "exclusive",
    pixelArt: true,
    takeover: false,
    voting: true,
    maxLimits: { width: 8, height: 8, area: 32 },
    mascot: true,
    mascotImageUrl: require("@/assets/exclusive/alpie-light.gif"),
    spaceImageUrl: require("@/assets/exclusive/alpaca-img.png"),
    spaceIconUrl: require("@/assets/exclusive/alpaca-icon.png"),
    canvasImageUrl: require("@/assets/exclusive/alpaca-canvas-image.png"),
    spaceAddress: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE04,
    managerAddress: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER03,

    membersOnly: true,
    membershipType: "ownsOnchain",
    memberCheck: memberCheckAlpaca,

    rules: [
      {
        title: "Claiming blocks",
        image: require("@/assets/ils_Blocks.png"),
        content: [
          "One can claim up to 18 blocks every 42min. Claim Fee is set at 0.001 BNB/Block.",
        ],
      },
      {
        title: "Covering Blocks",
        image: require("@/assets/ils_Poster.png"),
        content: [
          "As the owner of Blocks, you are able to cover them with an image of your choice.",
        ],
      },
      {
        title: "Takeover Fee",
        image: require("@/assets/ils_Takeover.png"),
        content: [
          "If you’d like to take over someone else's block, the fee would be 0.1 BNB per block instead of 0.001 BNB.",
        ],
      },
      {
        title: "Special Prize",
        image: require("@/assets/ils_Prize.png"),
        content: [
          "Top 5 Alpies on the leaderboard get 50/40/30/20/10 $ALPACA. This co-creation canvas will be minted as an NFT. To get it, you must acquire at least a 8 blocks (eg. 2x4 or 4x2) area and vote at least 3 times.",
        ],
      },
      {
        title: "Theme",
        image: require("@/assets/ils_Theme.png"),
        content: [
          "Cover with any alpie that you think resembles someone (you can take inspirations from movies, TV shows, anime, pop culture or whatever you want) and post them side-by-side.",
        ],
      },
      {
        title: "Prepare image",
        image: require("@/assets/ils_Theme.png"),
        content: [
          "Go to https://alpies.alpacafinance.org/gallery to find an alpie. Use this editor https://pinetools.com/merge-images to create an image that contains both the alpie and the resembled character.",
        ],
      },
    ],
  }),

  SpaceConfig({
    spaceId: 4,
    hidden: false,
    active: false,
    claimable: false,
    claimFixed: true,
    name: "X-clam-island",
    subtitle: "Clam Island",
    type: "exclusive",
    pixelArt: true,
    takeover: false,
    voting: true,
    membersOnly: true,
    memberCheck: twitterFollowsCheck,
    membershipType: "twitter",
    twitterHandle: "clam_island",
    mascot: false,
    spaceImageUrl: require("@/assets/exclusive/clam-img.png"),
    spaceIconUrl: require("@/assets/exclusive/clam-icon.png"),
    canvasImageUrl: require("@/assets/exclusive/clam-image.png"),
    spaceAddress: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE05,
    managerAddress: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER05,
    rules: [
      {
        title: "Claiming blocks",
        image: require("@/assets/ils_Blocks.png"),
        content: [
          "One can claim up to 42 blocks every 42min. Claim Fee is set at 0.0042 BNB/Block.",
        ],
      },
      {
        title: "Covering Blocks",
        image: require("@/assets/ils_Poster.png"),
        content: [
          "As the owner of Blocks, you are able to cover them with an image of your choice.",
        ],
      },
      {
        title: "Special Prize",
        image: require("@/assets/ils_Prize.png"),
        content: [
          "There will be 7 Clams up for grabs. The winners will be decided by community vote, and first prize will be a Maxima Clam",
        ],
      },
      {
        title: "Theme",
        image: require("@/assets/ils_Theme.png"),
        content: [
          "Anything Clam Island related will be eligible to receive a reward. You can post anything from an image of your rare Clam or Pearl to original content based on Clam Island characters.",
        ],
      },
    ],
  }),

  SpaceConfig({
    spaceId: 6,
    hidden: false,
    active: false,
    claimable: false,
    claimFixed: true,
    name: "X-bunny",
    subtitle: "Pancake Bunny",
    type: "exclusive",
    pixelArt: true,
    takeover: false,
    voting: true,
    maxLimits: { width: 3, height: 3, area: 9 },
    membersOnly: true,
    memberCheck: twitterFollowsCheck,
    membershipType: "twitter",
    twitterHandle: "PancakeBunnyFin",
    mascot: false,
    spaceImageUrl: require("@/assets/exclusive/bunny-img.jpg"),
    spaceIconUrl: require("@/assets/exclusive/bunny-icon.svg"),
    canvasImageUrl: require("@/assets/exclusive/bunny-image.jpg"),
    spaceAddress: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE07,
    managerAddress: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER07,
    rules: [
      {
        title: "Claiming blocks",
        image: require("@/assets/ils_Blocks.png"),
        content: [
          "One can claim up to 9 blocks every 42min. Claim Fee is set at 0.0042 BNB/Block.",
        ],
      },
      {
        title: "Covering Blocks",
        image: require("@/assets/ils_Poster.png"),
        content: [
          "As the owner of Blocks, you are able to cover them with an image of your choice.",
        ],
      },
      {
        title: "Prizes",
        image: require("@/assets/ils_Prize.png"),
        content: [
          "The Five most liked masterpieces will be minted and airdropped together with Five Busy Bunnies NFTs given to the associated creators. One Busy Bunnies NFTs will be airdropped to ten randomly selected participants.",
        ],
      },
      {
        title: "Theme",
        image: require("@/assets/ils_Theme.png"),
        content: [
          "The theme is anything related to Bunny (Busy Bunnies, Crypto Heroes Cab, etc), your New Year’s Resolution or any other Mound-related creative artwork",
        ],
      },
    ],
  }),

  SpaceConfig({
    spaceId: 7,
    hidden: false,
    active: false,
    claimable: false,
    claimFixed: true,
    name: "X-vite",
    subtitle: "Vite",
    type: "exclusive",
    pixelArt: true,
    takeover: false,
    voting: true,
    membersOnly: true,
    memberCheck: twitterFollowsCheck,
    membershipType: "twitter",
    twitterHandle: "vitelabs",
    mascot: false,
    spaceImageUrl: require("@/assets/exclusive/vite-img.jpg"),
    spaceIconUrl: require("@/assets/exclusive/vite-logo.jpg"),
    canvasImageUrl: require("@/assets/exclusive/vite-img-canvas.jpg"),
    spaceAddress: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE08,
    managerAddress: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER08,
    rules: [
      {
        title: "Claiming blocks",
        image: require("@/assets/ils_Blocks.png"),
        content: [
          "One can claim up to 42 blocks every 42min. Claim Fee is set at 0.0042 BNB/Block.",
        ],
      },
      {
        title: "Special Prize",
        image: require("@/assets/ils_Prize.png"),
        content: [
          "Two images with the most LIKES will be rewarded at the end of the event. Prize Pool is 3000 VITE tokens! 🤩",
        ],
      },
      {
        title: "Special Surprise",
        image: require("@/assets/ils_Poster.png"),
        content: [
          "One random participant will receive Space Ape smart NFT. 🐒 Special surprise reward paid out in BLS tokens for the image that the 1000Blocks team will like the most! 🤑",
        ],
      },
      {
        title: "Theme",
        image: require("@/assets/ils_Theme.png"),
        content: [
          "The meaning of VITE = FAST, so the theme is anything that has to do with speed. Example: 'fast and furious', 'speed memes', 'movie speed', 'speed transactions', etc.",
        ],
      },
    ],
  }),

  SpaceConfig({
    spaceId: 9,
    hidden: false,
    active: false,
    claimable: false,
    claimFixed: true,
    name: "X-altura",
    subtitle: "Altura",
    type: "exclusive",

    rewards: true,
    rewardsPerBlock: "2.6",
    rewardsToken: "ALU",

    pixelArt: true,
    voting: true,
    membersOnly: true,
    memberCheck: twitterFollowsCheck,
    membershipType: "twitter",
    twitterHandle: "altura_nft",
    spaceImageUrl: require("@/assets/exclusive/altura-img.png"),
    spaceIconUrl: require("@/assets/exclusive/altura-logo.png"),
    canvasImageUrl: require("@/assets/exclusive/altura-canvas-img.png"),
    rules: [
      {
        title: "Claiming blocks",
        image: require("@/assets/ils_Blocks.png"),
        content: [
          "One can claim up to 42 blocks every 42min. Claim Fee is set at 0.0042 BNB/Block.",
        ],
      },
      {
        title: "Covering Blocks",
        image: require("@/assets/ils_Takeover.png"),
        content: [
          "As the owner of Blocks, you are able to cover them with an image of your choice.",
        ],
      },
      {
        title: "Yield",
        image: require("@/assets/ils_Compensation.png"),
        content: [
          "Earn $ALU rewards. $2,500 in $ALU rewards will be yielded by those who own blocks in the space.",
        ],
      },
      {
        title: "Prize",
        image: require("@/assets/ils_Prize.png"),
        content: [
          "Additional $2,500 in $ALU will be split for the top five most liked images in the space.",
        ],
      },
      {
        title: "Special Prize",
        image: require("@/assets/ils_Poster.png"),
        content: [
          "All participants will be entered for a chance to win a Space Apes Smart NFT!",
        ],
      },
      {
        title: "Theme",
        image: require("@/assets/ils_Theme.png"),
        content: ["Show off you penguin-themed art."],
      },
    ],

    spaceAddress: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE10,
    managerAddress: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER10,
  }),

  SpaceConfig({
    sort: 10,
    spaceId: 5,
    hidden: true,
    active: true,
    claimable: false,
    claimFixed: true,
    name: "X-defi-for-you",
    subtitle: "DeFi For You",
    type: "exclusive",
    pixelArt: true,
    takeover: false,
    voting: true,
    membersOnly: true,
    memberCheck: twitterFollowsCheck,
    membershipType: "twitter",
    twitterHandle: "DeFiForYou",
    mascot: false,
    spaceImageUrl: require("@/assets/exclusive/defiforyou-img.jpg"),
    spaceIconUrl: require("@/assets/exclusive/defiforyou-logo.png"),
    canvasImageUrl: require("@/assets/exclusive/defiforyou-image.png"),
    spaceAddress: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE06,
    managerAddress: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER06,
    rules: [
      {
        title: "Claiming blocks",
        image: require("@/assets/ils_Blocks.png"),
        content: [
          "One can claim up to 42 blocks every 42min. Claim Fee is set at 0.0042 BNB/Block.",
        ],
      },
      {
        title: "Covering Blocks",
        image: require("@/assets/ils_Poster.png"),
        content: [
          "As the owner of Blocks, you are able to cover them with an image of your choice.",
        ],
      },
      {
        title: "Special Prize",
        image: require("@/assets/ils_Prize.png"),
        content: ["Top 3 photos by popular vote split $1000."],
      },
      {
        title: "Theme",
        image: require("@/assets/ils_Theme.png"),
        content: [
          "The theme is everything Christmas. Only Christmas images are eligible for prizes.",
        ],
      },
    ],
  }),

  SpaceConfig({
    sort: 9,
    spaceId: 10,
    active: false,
    claimable: false,
    claimFixed: true,
    name: "X-wizard",
    subtitle: "Wizard",
    type: "exclusive",

    rewards: true,
    rewardsPerBlock: "0.0625",
    rewardsToken: "WIZARD",

    pixelArt: true,
    takeover: false,
    voting: true,
    membersOnly: true,
    memberCheck: twitterFollowsCheck,
    membershipType: "twitter",
    twitterHandle: "WIZARD_BSC",
    mascot: false,
    spaceImageUrl: require("@/assets/exclusive/wizard-img.jpg"),
    spaceIconUrl: require("@/assets/exclusive/wizard-icon.png"),
    canvasImageUrl: require("@/assets/exclusive/wizard-img-canvas.jpg"),
    spaceAddress: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE11,
    managerAddress: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER11,
    rules: [
      {
        title: "Claiming blocks",
        image: require("@/assets/ils_Blocks.png"),
        content: [
          "One can claim up to 42 blocks every 42min. Claim Fee is set at 0.0042 BNB/Block.",
        ],
      },
      {
        title: "Covering Blocks",
        image: require("@/assets/ils_Poster.png"),
        content: [
          "As the owner of Blocks, you are able to cover them with an image of your choice.",
        ],
      },
      {
        title: "Yield",
        image: require("@/assets/ils_Compensation.png"),
        content: [
          "Earn $WIZARD tokens. $500 in WIZARD tokens will be available for yield!",
        ],
      },
      {
        title: "Special Prize",
        image: require("@/assets/ils_Prize.png"),
        content: [
          "Five images with the most Likes will be rewarded with an Allocation to an IWO - Initial Wizard Offering",
        ],
      },
      {
        title: "Special Prize",
        image: require("@/assets/ils_Poster.png"),
        content: [
          "All participants will be entered for a chance to win a Space Apes Smart NFT!",
        ],
      },
      {
        title: "Theme",
        image: require("@/assets/ils_Theme.png"),
        content: [
          "The theme of the Wizard Space is ... Yes, you guessed it right! WIZARDS 🧙🥰",
        ],
      },
    ],
  }),

  SpaceConfig({
    spaceId: 11,
    sort: 11,
    hidden: false,
    active: false,
    claimable: false,
    claimFixed: true,
    name: "X-cryptoblades",
    subtitle: "CryptoBlades",
    type: "exclusive",

    rewards: true,
    rewardsPerBlock: "3.6",
    rewardsToken: "KING",

    pixelArt: true,
    voting: true,
    membersOnly: true,
    memberCheck: twitterFollowsCheck,
    membershipType: "twitter",
    twitterHandle: "CryptoBlades",
    spaceImageUrl: require("@/assets/exclusive/cryptoblades-img.png"),
    spaceIconUrl: require("@/assets/exclusive/cryptoblades-icon.png"),
    canvasImageUrl: require("@/assets/exclusive/cryptoblades-image.png"),
    rules: [
      {
        title: "Claiming blocks",
        image: require("@/assets/ils_Blocks.png"),
        content: [
          "One can claim up to 42 blocks every 42min. Claim Fee is set at 0.0042 BNB/Block.",
        ],
      },
      {
        title: "Covering Blocks",
        image: require("@/assets/ils_Takeover.png"),
        content: [
          "As the owner of Blocks, you are able to cover them with an image of your choice.",
        ],
      },
      {
        title: "Yield",
        image: require("@/assets/ils_Compensation.png"),
        content: [
          "$2,000 in $KING Rewards will be yielded by those who claim blocks in the Space and post images on it (more blocks, more rewards).",
        ],
      },
      {
        title: "Prize",
        image: require("@/assets/ils_Prize.png"),
        content: [
          "$3,000 in $CryptoBlades NFTs will be shared with the participants. There will be around 50 winners for CryptoBlades NFTs.",
        ],
      },
      {
        title: "Special Prize",
        image: require("@/assets/ils_Poster.png"),
        content: [
          "One random participant (decided by the 1000Blocks team) will receive Space Ape smart NFT",
        ],
      },
      {
        title: "Theme",
        image: require("@/assets/ils_Theme.png"),
        content: [
          "Post anything related to Knights, Swords, and Shields or anything related to CryptoBlades and CryptoBlades Kingdoms.",
        ],
      },
    ],

    spaceAddress: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE12,
    managerAddress: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER12,
  }),

  SpaceConfig({
    spaceId: 12,
    sort: 12,
    hidden: false,
    active: false,
    claimable: false,
    claimFixed: true,
    name: "X-bolstyr",
    subtitle: "Bolstyr",
    type: "exclusive",

    rewards: true,
    rewardsPerBlock: "0.07",
    rewardsToken: "BUSD",

    pixelArt: true,
    voting: true,
    membersOnly: true,
    memberCheck: twitterFollowsCheck,
    membershipType: "twitter",
    twitterHandle: "bolstyr",
    spaceImageUrl: require("@/assets/exclusive/bolstyr-img.png"),
    spaceIconUrl: require("@/assets/exclusive/bolstyr-logo.png"),
    canvasImageUrl: require("@/assets/exclusive/bolstyr-image.png"),
    rules: [
      {
        title: "Claiming blocks",
        image: require("@/assets/ils_Blocks.png"),
        content: [
          "One can claim up to 42 blocks every 42min. Claim Fee is set at 0.0042 BNB/Block.",
        ],
      },
      {
        title: "Covering Blocks",
        image: require("@/assets/ils_Takeover.png"),
        content: [
          "Other participants can takeover your Blocks and you can do the same. Plan your strategy beforehand and play the game smart.",
        ],
      },
      {
        title: "Yield",
        image: require("@/assets/ils_Compensation.png"),
        content: [
          "$500 in BUSD Rewards will be yielded by those who claim blocks in the Space and post images on it (more blocks, more rewards).",
        ],
      },
      {
        title: "Prize",
        image: require("@/assets/ils_Prize.png"),
        content: [
          "One random participant (best image decided by the 1000Blocks team) will receive Space Ape smart NFT valued at 0.42BNB.",
        ],
      },
      {
        title: "Theme",
        image: require("@/assets/ils_Theme.png"),
        content: [
          "Show us your favorite INFLUENCERS. Those that you would love to see as ambassadors for your favorite project.",
        ],
      },
      {
        title: "Co-create",
        image: require("@/assets/ils_Poster.png"),
        content: [
          "Most importantly, have a lot of FUN in co-creating unique NFT art. 🥰",
        ],
      },
    ],

    spaceAddress: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE13,
    managerAddress: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER13,
  }),
];
export const getSpaceById = (spaceId) =>
  spaces.find((space) => space.spaceId === spaceId);
export const getSpaceBySlug = (slug) =>
  spaces.find((space) => space.slug === slug);
