<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    width="500"
    persistent
  >
    <v-card>
      <RetroCardTitle closable @close="$emit('input', false)">
        Missing text
      </RetroCardTitle>

      <v-card-text class="pa-4">
        Do you really want to post the block area without text?
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="accent" class="black--text" @click="confirm()"
          >Continue without text</v-btn
        >
        <v-btn @click="$emit('input', false)">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RetroCardTitle from "../helpers/RetroCardTitle.vue";
export default {
  components: { RetroCardTitle },
  props: ["value"],
  data() {
    return {};
  },
  computed: {},
  methods: {
    confirm() {
      this.$emit("confirm", true);
      this.$emit("input", false);
    },
  },
};
</script>

<style></style>
