import { render, staticRenderFns } from "./MintOverview.vue?vue&type=template&id=48110151&scoped=true&"
import script from "./MintOverview.vue?vue&type=script&lang=js&"
export * from "./MintOverview.vue?vue&type=script&lang=js&"
import style0 from "./MintOverview.vue?vue&type=style&index=0&id=48110151&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48110151",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VContainer,VFooter,VImg,VProgressCircular,VRow,VSkeletonLoader})
