<template>
  <v-card
    class="space-update d-flex flex-column justify-space-between"
    height="100%"
  >
    <div>
      <v-card-title class="black--text mb-2">UPDATE</v-card-title>
      <v-card-subtitle class="black--text">{{ spaceMessage }}</v-card-subtitle>
      <v-card-text>
        <v-img v-if="imageUrl" width="100%" :src="imageUrl" :aspect-ratio="1" />
      </v-card-text>
    </div>
    <v-card-actions class="mx-2 mb-2">
      <v-btn block @click="closeSpaceUpdate">GOT IT</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    closeSpaceUpdate: {
      type: Function,
      required: true,
      default: () => false,
    },
    spaceMessage: {
      type: String,
      required: true,
      default: "Some news that we want to push to the community",
    },
    imageUrl: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style>
.space-update {
  background-color: #1abfb8 !important;
}
</style>
