<template>
  <div class="overview-tile d-flex flex-column" @mouseleave="infoShown = false">
    <div class="title-bar d-flex align-center">
      <img class="ma-3" :src="icon" width="28" style="aspect-ratio: 1" />
      <h1 class="accent--text text--darken-2 text-uppercase text-subtitle-1">
        {{ title }}
      </h1>
      <v-spacer />

      <img
        class="info-show ma-3"
        src="@/assets/info.svg"
        width="18"
        v-if="info"
        @click="infoShown = true"
      />
    </div>

    <div class="tile-body d-flex flex-column mx-3 mb-3 fill-height">
      <slot name="body"></slot>
    </div>

    <transition name="fade">
      <div class="info-overlay d-flex align-start pa-3" v-if="infoShown">
        <div class="flex-grow-1">
          <slot name="info"></slot>
        </div>
        <div class="info-close my-1" @click="infoShown = false"></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String,
    info: Boolean,
  },
  data() {
    return {
      infoShown: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.overview-tile {
  position: relative;
  // height: 180px;
  height: 100%;
  background: black;
  border: 1px solid #444;
  font-size: 14px;
}

button {
  border: 1px solid #444;
}

.tile-title-1 {
  color: var(--v-accent-darken2);
  text-transform: uppercase;
}

.tile-title-2 {
  color: yellow;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.info-overlay {
  position: absolute;
  background-color: #444;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.info-show {
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
}

.info-close {
  background-color: black;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  flex-shrink: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
