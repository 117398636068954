import * as profileService from "../services/profileService.js";

export default {
  namespaced: true,
  state: {
    profilePicture: "",
    displayName: "",
    twitterHandle: "",
    hexBorder: "#00FFFF", // Default Cyan border
  },
  mutations: {
    setProfilePicture(state, profilePicture) {
      state.profilePicture = profilePicture;
    },
    setTwitterHandle(state, twitterHandle) {
      state.twitterHandle = twitterHandle;
    },
    setDisplayName(state, displayName) {
      state.displayName = displayName;
    },
    setProfile(state, profile) {
      state.profilePicture = profile.profilePicture;
      state.displayName = profile.displayName;
      state.twitterHandle = profile.twitterHandle;
      state.hexBorder = profile.hexBorder;
    },
  },
  actions: {
    resetProfile({ dispatch, commit }, { wallet, signature }) {
      try {
        commit("setProfile", {
          profilePicture: "",
          displayName: "",
          twitterHandle: "",
          hexBorder: "",
        });

        profileService.setProfile(wallet, signature, {
          profilePicture: "",
          displayName: "",
          twitterHandle: "",
          hexBorder: "#00FFFF", // Default Cyan border
        });
        dispatch("error/setSuccessMessage", "Profile reset", {
          root: true,
        });
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      }
    },
    setProfilePicture(
      { dispatch, commit },
      { wallet, signature, profilePicture }
    ) {
      try {
        commit("setProfilePicture", profilePicture);

        profileService.setProfile(wallet, signature, { profilePicture });
        dispatch("error/setSuccessMessage", "Profile picture updated", {
          root: true,
        });
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      }
    },
    setTwitterHandle(
      { dispatch, commit },
      { wallet, signature, twitterHandle }
    ) {
      try {
        commit("setTwitterHandle", twitterHandle);

        profileService.setProfile(wallet, signature, { twitterHandle });
        dispatch("error/setSuccessMessage", "Twitter updated", {
          root: true,
        });
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      }
    },
    setDisplayName({ dispatch, commit }, { wallet, signature, displayName }) {
      try {
        if (displayName === "") {
          dispatch(
            "error/setErrorMessage",
            "Your display name cannot be empty",
            { root: true }
          );
          return;
        }

        commit("setDisplayName", displayName);

        profileService.setProfile(wallet, signature, { displayName });
        dispatch("error/setSuccessMessage", "Display name updated", {
          root: true,
        });
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      }
    },
    async loadProfile({ dispatch, commit }, wallet) {
      try {
        const profile = await profileService.getProfile(wallet);

        commit("setProfile", {
          profilePicture: profile.profilePicture,
          displayName: profile.displayName,
          twitterHandle: profile.twitterHandle,
          hexBorder: profile.hexBorder,
        });
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      }
    },
    clearProfile({ commit }) {
      commit("setProfile", {
        profilePicture: "",
        displayName: "",
        twitterHandle: "",
        hexBorder: "",
      });
    },
  },
};
