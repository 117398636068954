import axios from 'axios';

export default async (userHandle, projectHandle) => {

    let follows = false;
    if(userHandle){
        const userToCheck = userHandle.replace('@','');
        const res  = await axios.get(`https://api.1000blocks.space/followers?first=${userToCheck}&second=${projectHandle}`);
        const data = res.data;
        if(data.relationship){
            follows = data.relationship.source.following;
        }
    }
    return follows;
};