<template>
  <div
    class="d-flex flex-column align-center justify-center pt-5"
    :class="$vuetify.breakpoint.mobile && 'pt-9'"
  >
    <Poster
      v-for="poster in sortedBlockAreas"
      :key="poster.posterUniqueId"
      :poster="poster"
    />
    <!-- when v-card intersects into the view, we load more posters  -->
    <v-card v-intersect="infinityScrolling"></v-card>
    <v-progress-circular
      :size="50"
      :width="7"
      color="cyan"
      indeterminate
      class="ma-5"
      v-if="this.loading"
    ></v-progress-circular>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Poster from "../components/cross-space/Poster.vue";

export default {
  name: "Timelime",
  components: {
    Poster,
  },
  data() {
    return {
      loading: false,
      latestN: 5,
    };
  },
  computed: {
    ...mapState("space", ["currentSpace"]),
    sortedBlockAreas() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loading = false;

      return this.currentSpace.timeline;
    },
  },
  methods: {
    ...mapActions("space", ["loadTimeline"]),
    infinityScrolling() {
      // Give user feel of loading more content
      this.loading = true;
      setTimeout(() => {
        // Fetch 5 at a time
        const nextSet = this.latestN + 5;
        this.latestN = nextSet;

        this.loadTimeline({
          spaceId: this.currentSpace.spaceId,
          latestN: nextSet,
          order: "desc",
        });

        this.loading = false;
      }, 2000);
    },
  },
  created() {
    this.loadTimeline({
      spaceId: this.currentSpace.spaceId,
      latestN: this.latestN,
      order: "desc",
    });
  },
};
</script>
