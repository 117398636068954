<template>
  <v-dialog
    value="true"
    fullscreen
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <template v-slot:default="dialog">
      <v-card>
        <RetroCardTitle
          closable
          @close="
            dialog.value = false;
            $emit('cancel');
          "
        >
          <!-- CLAIM BLOCKS -->
        </RetroCardTitle>
        <v-card-text>
          <MintSelect @cancel="$emit('cancel')" class="mt-4" />
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import RetroCardTitle from "../helpers/RetroCardTitle.vue";
import MintSelect from "./MintSelect.vue";
export default {
  components: {
    RetroCardTitle,
    MintSelect,
  },
  props: {},
  methods: {
    ...mapActions("mode", ["setMode"]),
  },
};
</script>

<style></style>
