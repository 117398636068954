import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers, providers } from "ethers";
import wait from "waait";

import * as token from "./contracts/token.js";
import * as LPtoken from "./contracts/lpToken.js";
import * as nft from "./contracts/nft.js";

import * as farm from "./contracts/farm.js";
import * as staking from "./contracts/staking.js";

import * as connect from "./connect.js";

// import * as space01 from "./contracts/space01";
// import * as space02 from "./contracts/space02";
// import * as space03 from "./contracts/space03";
// import * as space04 from "./contracts/space04";
// import * as space05 from "./contracts/space05";
// import * as space06 from "./contracts/space06";
// import * as space07 from "./contracts/space07";
// import * as space08 from "./contracts/space08";
// // import * as space09 from "./contracts/space09";
// import * as space10 from "./contracts/space10";
import {space01, space02, space03, space04, space05, space06, space07, space08, space10, space11, space12, space13} from "./contracts/space-multiple";
import {pool01} from "./contracts/pool-multiple"

export async function getProvider() {
  if (
    typeof window.ethereum !== "undefined" ||
    typeof window.web3 !== "undefined"
  ) {
    const provider = new ethers.providers.Web3Provider(
      window.ethereum || window.web3
    );
    const signer = provider.getSigner();
    return {
      provider,
      signer,
    };
  } else {
    // We assume the mobile browser window cannot be injected with web3 or eth
    throw new Error("Unable to connect to Metamask automatically");
  }
}

async function getNetworkId(provider) {
  try {
    const network = await provider.getNetwork();
    return network.chainId;
  } catch (err) {
    throw new Error("Unable to retrieve network ID");
  }
}

// function getContract(provider) {
//   return new ethers.Contract(space.address, space.abi, provider)
// }

function getContract(provider, space) {
  return new ethers.Contract(space.address, space.abi, provider);
}

function getSpacesContracts(provider) {
  return {
    0: {
      space: getContract(provider, space01.space),
      manager: getContract(provider, space01.manager),
    },
    1: {
      space: getContract(provider, space02.space),
      manager: getContract(provider, space02.manager),
      managerOld: getContract(provider, space02.managerOld),
    },
    2: {
      space: getContract(provider, space03.space),
      manager: getContract(provider, space03.manager),
    },
    3: {
      space: getContract(provider, space04.space),
      manager: getContract(provider, space04.manager),
    },
    4: {
      space: getContract(provider, space05.space),
      manager: getContract(provider, space05.manager),
    },
    5: {
      space: getContract(provider, space06.space),
      manager: getContract(provider, space06.manager),
    },
    6: {
      space: getContract(provider, space07.space),
      manager: getContract(provider, space07.manager),
    },
    7: {
      space: getContract(provider, space08.space),
      manager: getContract(provider, space08.manager),
    },
    // 8: {
    //   space: getContract(provider, space09.space),
    //   manager: getContract(provider, space09.manager),
    // },
    9: {
      space: getContract(provider, space10.space),
      manager: getContract(provider, space10.manager),
    },
    10: {
      space: getContract(provider, space11.space),
      manager: getContract(provider, space11.manager),
    },
    11: {
      space: getContract(provider, space12.space),
      manager: getContract(provider, space12.manager),
    },
    12: {
      space: getContract(provider, space13.space),
      manager: getContract(provider, space13.manager),
    },
  };
}

function getPoolsContracts(provider) {
  return {
    0: getContract(provider, pool01),
  };
}

function getContracts(provider) {
  return {
    tokenContract: getContract(provider, token),
    LPTokenContract: getContract(provider, LPtoken),
    farmContract: getContract(provider, farm),
    vaultContract: getContract(provider, staking),
    nftContract: getContract(provider, nft)
  }
}

// function getSignedContract(signer, contract) {
//   return contract.connect(signer);
// }

function signSpacesContracts(signer, spacesContracts) {
  return Object.assign(
    ...Object.keys(spacesContracts).map((k) => ({
      [k]: {
        space: spacesContracts[k].space.connect(signer),
        manager: spacesContracts[k].manager.connect(signer),
        managerOld: spacesContracts[k].managerOld
          ? spacesContracts[k].managerOld.connect(signer)
          : null,
      },
    }))
  );
}

function signPoolsContracts(signer, poolsContracts) {
  return Object.assign(
    ...Object.keys(poolsContracts).map((k) => ({
      [k]: poolsContracts[k].connect(signer),
    }))
  );
}

function signContracts(signer, contracts) {
  return Object.assign(
    ...Object.keys(contracts).map((k) => {
      const name = "signed" + k.charAt(0).toUpperCase() + k.slice(1);
      return {[name]: contracts[k].connect(signer)}
    })
  );
}

// eslint-disable-next-line no-unused-vars
export async function getAccount() {
  //try {
  await connect.connect();
  // window.ethereum or window.web3
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  return accounts[0]; // By default it should have only 1 account
  //} catch (err) {
  //  throw new Error('Unable to retrieve the wallet account')
  //}
}

// eslint-disable-next-line no-unused-vars
export async function getBalance(provider, account) {
  try {
    return await provider.getBalance(account);
  } catch (err) {
    throw new Error("Unable to retrieve the account balance");
  }
}

export async function getEthers() {
  const { provider, signer } = await getProvider();
  const networkId = await getNetworkId(provider);

  const spacesContracts = getSpacesContracts(provider);
  const poolsContracts = getPoolsContracts(provider);
  // const tokenContract = getTokenContract(provider);
  // const LPTokenContract = getLPTokenContract(provider);
  // const farmContract = getFarmContract(provider);
  // const vaultContract = getVaultContract(provider);

  const contracts = getContracts(provider)

  return {
    provider,
    signer,
    networkId,

    spacesContracts,
    poolsContracts,

    ...contracts
  };
}

export async function connectToContracts() {
  const { provider, signer } = await getProvider();
  const networkId = await getNetworkId(provider);

  const spacesContracts = getSpacesContracts(provider);
  const signedSpacesContracts = signSpacesContracts(signer, spacesContracts);
  
  const poolsContracts = getPoolsContracts(provider);
  const signedPoolsContracts = signPoolsContracts(signer, poolsContracts);

  const contracts = getContracts(provider)
  const signedContracts = signContracts(signer, contracts);

  return {
    provider,
    signer,
    networkId,

    spacesContracts,
    signedSpacesContracts,

    poolsContracts,
    signedPoolsContracts,

    ...contracts, 
    ...signedContracts,
  };
}

/**
 * Checks agaisnt our target chain
 * @returns boolean
 */

async function wcChainId(provider, dispatch) {
  const { chainId } = await provider.getNetwork();

  if (chainId === parseInt(process.env.VUE_APP_NETWORK)) {
    dispatch("error/setSuccessMessage", "Correct network", { root: true });

    return true;
  } else {
    return false;
  }
}

/**
 * We need to alert the user to switch chain in mobile app manually
 * as programmatical provider request wallet_switchEthereumChain
 * is not working on the Metamask mobile app
 */

async function switchWCChain(provider, dispatch) {
  const { chainId } = await provider.getNetwork();

  dispatch(
    "error/setErrorMessage",
    `Incorrect Metamask Network, your network: ${chainId}, requested network: ${process.env.VUE_APP_NETWORK}. Please change network and try to connect again.`,
    { root: true }
  );

  localStorage.removeItem("walletconnect");
}

/**
 * It uses WalletConnect RPC to supply a provider to ethers
 * @returns Object - Provider
 */

async function getWCProvider() {
  try {
    const provider = new WalletConnectProvider({
      rpc: {
        [process.env.VUE_APP_NETWORK]: process.env.VUE_APP_RPC, // Binance RPC
      },
      qrcodeModalOptions: {
        mobileLinks: ["metamask", "trust"], // Limit to metamask
      },
      chainId: process.env.VUE_APP_NETWORK,
    });

    await provider.enable();

    //  Wrap with Web3Provider from ethers.js
    const web3Provider = new providers.Web3Provider(provider);
    return web3Provider;
  } catch (err) {
    throw new Error(err.message);
  }
}

/**
 * It interfaces with contracts and returns state
 * @returns Object - Sets root state
 */

export async function connectWC(dispatch) {
  try {
    const provider = await getWCProvider();

    const correctChain = await wcChainId(provider, dispatch);

    if (correctChain) {
      // Retrieve additional objects
      const signer = provider.getSigner();
      const networkId = await getNetworkId(provider);

      // Interface to contracts with Web3Provider
      const spacesContracts = getSpacesContracts(provider);
      const signedSpacesContracts = signSpacesContracts(
        signer,
        spacesContracts
      );

      
      const poolsContracts = getPoolsContracts(provider);
      const signedPoolsContracts = signPoolsContracts(signer, poolsContracts);

      const contracts = getContracts(provider)
      const signedContracts = signContracts(signer, contracts);

      dispatch(
        "error/setSuccessMessage",
        "Connected Metamask via WalletConnect",
        { root: true }
      );

      wcEvents(provider, dispatch);

      return {
        provider,
        signer,
        networkId,

        spacesContracts,
        signedSpacesContracts,

        poolsContracts,
        signedPoolsContracts,

        ...contracts,
        ...signedContracts,
      };
    } else {
      switchWCChain(provider, dispatch);
    }
  } catch (err) {
    throw new Error(err.message);
  }
}

/**
 * Preliminary check to see if our WalletConnect
 * connection was previously set up, then re-establish
 * @returns Object - Sets root state
 */

export async function isWCConnected(dispatch) {
  try {
    let wcDetails = localStorage.getItem("walletconnect");
    wcDetails = JSON.parse(wcDetails);

    if (wcDetails) {
      const provider = await getWCProvider();
      const correctChain = await wcChainId(provider, dispatch);

      if (correctChain) {
        // Retrieve additional objects
        const signer = provider.getSigner();
        const networkId = await getNetworkId(provider);

        // Interface to contracts with Web3Provider
        const spacesContracts = getSpacesContracts(provider);
        const signedSpacesContracts = signSpacesContracts(
          signer,
          
          spacesContracts
        );

              
        const poolsContracts = getPoolsContracts(provider);
        const signedPoolsContracts = signPoolsContracts(signer, poolsContracts);

        const contracts = getContracts(provider)
        const signedContracts = signContracts(signer, contracts);

        dispatch(
          "error/setSuccessMessage",
          "WalletConnect detected, please retry connect",
          { root: true }
        );

        return {
          provider,
          signer,
          networkId,

          spacesContracts,
          signedSpacesContracts,

          poolsContracts,
          signedPoolsContracts,

        ...contracts,
        ...signedContracts,
        };
      } else {
        switchWCChain(provider, dispatch);
      }
    }
  } catch (err) {
    throw new Error(err.message);
  }
}

/**
 * It responds to events from a user's wallet
 */
export function wcEvents({ provider }, dispatch) {
  if (provider) {
    // Subscribe to accounts change
    provider.on("accountsChanged", async (accounts) => {
      dispatch(
        "error/setErrorMessage",
        `Your Metamask account changed, ${accounts[0]}. Reloading...`,
        { root: true }
      );
      localStorage.removeItem("walletconnect");
      await wait(2000);
      window.location.reload();
    });

    // Subscribe to chainId change
    provider.on("chainChanged", async (chainId) => {
      dispatch(
        "error/setErrorMessage",
        `Your Metamask network changed, ${chainId}. Reloading...`,
        { root: true }
      );
      localStorage.removeItem("walletconnect");
      await wait(2000);
      window.location.reload();
    });

    // Subscribe to session disconnection
    provider.on("disconnect", async () => {
      dispatch(
        "error/setErrorMessage",
        "Metamask was disconnected. Reloading...",
        { root: true }
      );
      localStorage.removeItem("walletconnect");
      await wait(2000);
      window.location.reload();
    });
  }
}
