<template>
  <v-container :class="containerStyle" class="my-4">
    <v-row
      v-for="poster in sortedBlockAreas"
      :key="poster.posterUniqueId"
      class="row"
    >
      <v-col
        cols="2"
        :offset="columnsPadding"
        class="image-column d-flex align-center justify-center"
      >
        <img
          :src="poster.publicUrlBig"
          :height="imageHeight"
          class="image"
          @click="posterPage(poster.posterUniqueId)"
        />
      </v-col>

      <v-col :cols="columnsInfoColumn" v-bind:class="infoColumnClass">
        <v-row>
          <v-col :cols="columnsLeft" class="col-upper">
            <div class="yellow--text title-text">ID</div>
            <div class="value-text">
              {{ poster.posterUniqueId.substring(0, 4) }}...{{
                poster.posterUniqueId.substring(
                  poster.posterUniqueId.length - 4
                )
              }}
            </div>
          </v-col>
          <v-col :cols="columnsRight" class="col-upper">
            <div class="yellow--text title-text">Live since</div>
            <div class="value-text">{{ poster.timestamp | formatDate }}</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="columnsLeft" class="col-lower">
            <div class="yellow--text title-text">Blocks</div>
            <div class="value-text">
              {{ blocksCount(poster.blockStart, poster.blockEnd) }}
            </div>
          </v-col>

          <v-col :cols="columnsRight" class="col-lower">
            <div class="yellow--text title-text">Likes</div>
            <div class="value-text">{{ poster.likes }}</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      containerStyle: this.$isMobile()
        ? "container-style-mobile"
        : "container-style",
      infoColumnClass: this.$isMobile() ? "info-column-mobile" : "info-column",
      columnsPadding: this.$isMobile() ? 1 : 2,
      columnsInfoColumn: this.$isMobile() ? 8 : 6,
      imageHeight: this.$isMobile() ? 65 : 127,
      columnsLeft: this.$isMobile() ? 5 : 4,
      columnsRight: this.$isMobile() ? 7 : 8,
    };
  },
  computed: {
    ...mapState("space", ["currentSpace"]),
    ...mapGetters("space", ["sortedBlockAreasByLikesDesc"]),
    sortedBlockAreas() {
      const postersCount = 10; // show only N posters
      const posters = this.currentSpace.blockAreas;
      const sortedPosters = posters.sort((a, b) => b.likes - a.likes);

      // take N with likes > 0
      const postersFiltered = [];
      for (var i = 0; i < sortedPosters.length; i++) {
        if (sortedPosters[i].likes > 0) {
          postersFiltered.push(sortedPosters[i]);
        }
        if (postersFiltered.length == postersCount) break;
      }

      return postersFiltered;
    },
  },
  methods: {
    ...mapActions("space", ["loadSpace"]),
    posterPage(posterUniqueId) {
      this.$router.push(`/${this.$route.params.spaceSlug}/${posterUniqueId}`);
    },
    blocksCount(blockStart, blockEnd) {
      const width = blockEnd.x - blockStart.x + 1;
      const height = blockEnd.y - blockStart.y + 1;
      return width * height;
    },
    created() {
      this.loadSpace(this.spaceId);
    },
  },
  filters: {
    formatDate: function(date) {
      return moment(date).format("DD. MMM YYYY");
    },
  },
};
</script>

<style lang="scss">
.container-style {
  padding-top: 0px;
}

.container-style-mobile {
  padding-top: 24px;
}

.title-text {
  font-size: 0.7 rem;
}

.value-text {
  font-weight: bold;
}

.image-column {
  background: #222222;
  padding: 0px;
  text-align: center;
  border: 0.5px solid #545454;
  border-right: none;
}

.image {
  max-width: -webkit-fill-available;
  object-fit: contain;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.info-column {
  background: black;
  border: 0.5px solid #545454;
  padding-top: 0px;
  padding-bottom: 8px;
  padding-left: 20px;
}

.info-column-mobile {
  @extend .info-column;
  font-size: 0.6rem;
}

.col-upper {
  padding-bottom: 0px;
}

.col-lower {
  padding-top: 0px;
}

.row {
  padding-top: 10px;
}

.background {
  background: #071b25;
}
</style>
