import IPFSGatewayTools from "@pinata/ipfs-gateway-tools/dist/browser";
import axios from "axios";
import { utils } from "ethers";
import { truncate } from "../services/helpers";
import { database, db, increment } from "../services/firebase";
import { whitelistReferral } from "../services/posterService";
import toplist from "./toplist.json";
import whitelist from "./whitelist.json";

const getDefaultState = () => {
  return {
    allowedToMint: false,
    whitelist: true,

    mintStart: Date.parse("2021-12-22T23:00:00.000+01:00"),
    whitelistTime: 31320000, // Whitelist lasts 8:42 (UNIX 31320 sec)

    priceOfOne: utils.parseEther("0.42"),

    discount: 0,
    dscountType: null,

    listOfTopWallets: toplist || [],
    listOfWhitelistedWallets: whitelist.map((a) => a.wallet) || [],

    baseURI: null,

    maxCount: 2101,
    mintedCount: 0,
    ownedCount: 0,

    tokensList: [],
    tokens: {},

    loading: {
      mint: false,
      tokens: false,
    },

    refLink: "",
    referredUser: false,
    referredBy: null,
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    fullPriceOfOne(state) {
      return utils.formatEther(state.priceOfOne);
    },
    discountedPriceOfOne(state) {
      return state.priceOfOne.mul(100 - state.discount).div(100);
    },
    priceOfOne(state, getters) {
      return utils.formatEther(getters.discountedPriceOfOne);
    },
    priceOfMany: (state, getters) => (amount) =>
      utils.formatEther(getters.discountedPriceOfOne.mul(amount)),
  },

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },

    setDiscount(state, value) {
      state.discount = value.discount;
      state.discountType = value.type;
    },

    setWhitelist(state, value) {
      state.whitelist = value;
    },

    setTimes(state, { mintStart, whitelistTime }) {
      state.mintStart = mintStart;
      state.whitelistTime = whitelistTime;
    },

    setAllowedToMint(state, value) {
      state.allowedToMint = value;
    },

    setBaseURI(state, value) {
      state.baseURI = value;
    },

    setMaxCount(state, value) {
      state.maxCount = value;
    },
    setMintedCount(state, value) {
      state.mintedCount = value;
    },
    setOwnedCount(state, value) {
      state.ownedCount = value;
    },

    setTokens(state, value) {
      state.tokensList = value;
    },
    setToken(state, { index, token }) {
      state.tokens = {
        ...state.tokens,
        [index]: token,
      };
    },

    setLoadingMint(state, value) {
      state.loading.mint = value;
    },
    setLoadingTokens(state, value) {
      state.loading.tokens = value;
    },

    setLoadingToken(state, { token, loading }) {
      state.loading.tokenList = {
        ...state.loading.tokenList,
        [token]: loading,
      };
    },

    setRefLink(state, value) {
      state.refLink = value;
    },

    setReferredUser(state, value) {
      state.referredUser = value;
    },

    setReferredBy(state, value) {
      state.referredBy = value;
    },
  },

  actions: {
    loadMintData({ dispatch }) {
      dispatch("calculateDiscount");
      dispatch("getAllowedToMint");
      dispatch("getBaseURI");
      dispatch("getMaxCount");
      dispatch("getMintedCount");
      dispatch("getTokensForOwner");
    },

    resetMintState ({ commit }) {
      commit('resetState')
    },

    calculateDiscount({ commit, state, rootState }) {
      if (
        rootState.wallet.account &&
        walletIncludedIn(rootState.wallet.account, state.listOfTopWallets)
      ) {
        commit("setDiscount", { discount: 20, type: "top" });
      } else if (
        rootState.wallet.account &&
        walletIncludedIn(
          rootState.wallet.account,
          state.listOfWhitelistedWallets
        )
      ) {
        const address = whitelist.find(
          (a) =>
            utils.getAddress(a.wallet) ===
            utils.getAddress(rootState.wallet.account)
        );
        if (address) {
          commit("setDiscount", {
            discount: address.ticketCount,
            type: "whitelist",
          });
        }
      } else if (state.referredUser) {
        commit("setDiscount", { discount: 5, type: "referral" });
      }
    },

    async getAllowedToMint({ commit, state, rootState }) {
      // Logic to allow onoly whitelisted wallets to mint for a set period of time
      let mintStart = state.mintStart;
      let whitelistTime = state.whitelistTime;

      try {
        await database.ref("mint").once("value", (mint) => {
          if (mint && mint.val()) {
            mintStart = Date.parse(mint.val().startTime);
            whitelistTime = mint.val().whitelistTime;

            commit("setTimes", { mintStart, whitelistTime });
          }
        });
      } catch (err) {
        // eslint-disable
      }

      const currentTime = Date.now();

      const mintStarted = currentTime > mintStart;
      const whitelistEnded = currentTime > mintStart + whitelistTime;

      if (mintStarted) {
        if (whitelistEnded) {
          commit("setAllowedToMint", true);
          commit("setWhitelist", false);
        } else if (
          walletIncludedIn(
            rootState.wallet.account,
            state.listOfWhitelistedWallets
          ) ||
          walletIncludedIn(rootState.wallet.account, state.listOfTopWallets)
        ) {
          commit("setAllowedToMint", true);
        }
      }
    },

    async getBaseURI({ commit, rootState }) {
      const nftContract = rootState.wallet.ethers.nftContract;
      const baseURI = await nftContract.baseTokenURI();
      commit("setBaseURI", baseURI);
    },

    async getMaxCount({ commit, rootState }) {
      const nftContract = rootState.wallet.ethers.nftContract;
      const mintedCountBN = await nftContract.MAX_SPACE_APES();
      const mintedCount = mintedCountBN.toNumber();
      commit("setMaxCount", mintedCount);
    },

    async getMintedCount({ commit, rootState }) {
      const nftContract = rootState.wallet.ethers.nftContract;
      const allCountBN = await nftContract.totalSupply();
      const allCount = allCountBN.toNumber();
      commit("setMintedCount", allCount);
    },

    async getTokensForOwner({ commit, rootState }) {
      commit("setLoadingTokens", true);

      const address = utils.getAddress(rootState.wallet.account);
      const nftContract = rootState.wallet.ethers.nftContract;
      const allCountBN = await nftContract.balanceOf(address);
      const allCount = allCountBN.toNumber();
      commit("setOwnedCount", allCount);

      for (let i = 0; i < allCount; i++) {
        commit("setLoadingToken", { token: i, loading: true });
      }

      let tokens = [];

      const allRuns = [...Array(allCount).keys()]
        .map((i) => i)
        .map((i) => run(nftContract, address, i));

      allRuns.forEach((run) => {
        run.then((result) => {
          commit("setToken", { index: result.i, token: result });
          commit("setLoadingToken", { token: result.i, loading: false });
        });
      });

      tokens = await Promise.all(allRuns);

      // for (let i  = 0; i < allCount; i++) {
      //   const tokenId = await nftContract.tokenOfOwnerByIndex(address, i)
      //   const tokenURI = await nftContract.tokenURI(tokenId.toNumber())

      //   // Fake the tokenURI
      //   // const tokenURI = `ipfs://QmWXJXRdExse2YHRY21Wvh4pjRxNRQcWVhcKw4DLVnqGqs/${tokenId}`

      //   const gatewayTools = new IPFSGatewayTools();
      //   const gatewayTokenURI = gatewayTools.convertToDesiredGateway(tokenURI, 'https://ipfs.io');

      //   // console.log("tokenId", tokenURI, gatewayTokenURI, i)
      //   const res = await axios.get(gatewayTokenURI);
      //   tokens.push(res.data)
      // }

      commit("setTokens", tokens);
      commit("setLoadingTokens", false);
    },

    // async mint({commit, dispatch, rootState}) {
    async mint({ commit, dispatch, getters, state, rootState }, amount) {
      const price = getters.discountedPriceOfOne.mul(amount);

      commit("setLoadingMint", true);

      if (state.referredUser) {
        const address = utils.getAddress(rootState.wallet.account);
        await whitelistReferral(address);
      }

      try {
        const signedNftContract = rootState.wallet.ethers.signedNftContract;
        const transaction = await signedNftContract.mint(amount, {
          value: price,
        });
        // const transaction = await signedNftContract.mint(address)

        const receipt = await transaction.wait();

        dispatch("saveReferredMint", {
          ref: state.referredBy,
          amount,
          reward: Number(truncate(utils.formatEther(price), 4, false)) / 10,
        });

        if (!receipt.status) {
          throw Error("Minting was not successful");
        } else {
          await nftReveal();
        }
      } catch (err) {
        dispatch("error/setErrorMessage", err.data.message, { root: true });
      } finally {
        commit("setLoadingMint", false);
        dispatch("loadMintData");
      }
    },

    async createReferral({ commit, dispatch, state }, wallet) {
      const address = utils.getAddress(wallet);

      const docRef = db.collection("nft-referral").doc(address);
      // When connecting the wallet, check if the referral link exists,
      // otherwise create the unique link and store in the Firestore

      // If the link does not exist and he was referred, register the referral
      // const getRefLink = (refCode) => "https://app.1000blocks.space/spaceapes?ref=" + refCode;
      const getRefLink = (refCode) =>
        `${window.location.origin}/spaceapes?ref=${refCode}`;

      try {
        const doc = await docRef.get();

        if (doc.exists) {
          if (state.referredUser) {
            try {
              await docRef.update({
                referredUser: state.referredUser,
                referredBy: state.referredBy,
              });
            } catch (err) {
              console.error("Error writing document: ", err);
            }
          }

          const data = doc.data();
          const refLink = getRefLink(data.refCode);
          commit("setRefLink", refLink);
          commit("setReferredUser", data.referredUser || state.referredUser);
          commit("setReferredBy", data.referredBy || state.referredBy);
          dispatch("calculateDiscount");
        } else {
          const refCode =
            Date.now() +
            Math.random()
              .toString(32)
              .substring(2, 15);
          const refLink = getRefLink(refCode);

          try {
            await docRef.set({
              address,
              refCode,
              refCount: 0,
              refReward: 0,
              referredUser: state.referredUser,
              referredBy: state.referredBy,
            });
            commit("setRefLink", refLink);
          } catch (err) {
            console.error("Error writing document: ", err);
          }
        }
      } catch (err) {
        console.log("Error getting document:", err);
      }
    },

    registerReferral({ commit, dispatch }, ref) {
      commit("setReferredUser", true);
      commit("setReferredBy", ref);
      dispatch("calculateDiscount");
    },

    // eslint-disable-next-line no-empty-pattern
    async saveReferredMint({}, { ref, amount, reward }) {
      // eslint-disable-line
      // Save referral by and increment the count on the
      try {
        const snapshot = await db
          .collection("nft-referral")
          .where("refCode", "==", ref)
          .get();
        snapshot.forEach((doc) => {
          doc.ref.update({
            refCount: increment(amount),
            refReward: increment(reward),
          });
        });
      } catch (err) {
        console.log("Error getting documents: ", err);
      }
    },
  },
};

function walletIncludedIn(wallet, listOfWallets) {
  return listOfWallets.some(
    (w) => utils.getAddress(wallet) === utils.getAddress(w)
  );
}

async function run(nftContract, address, i) {
  let tokenId;
  let tokenURI;
  try {
    tokenId = await nftContract.tokenOfOwnerByIndex(address, i);
    tokenURI = await nftContract.tokenURI(tokenId.toNumber());
  } catch (err) {
    return {
      i,
      name: "SpaceApes by 1000Blocks.space",
      description: "Smart NFTs by 1000Blocks.space",
      image:
        "ipfs://bafkreigosmke3pnx4gtfizcwwi2pokqbmli7su6bkj5v2q5otvuexpghc4",
      author: "1000Blocks.space",
      collection: "The Alberts",
    };
  }

  let gatewayTokenURI;
  try {
    const gatewayTools = new IPFSGatewayTools();
    gatewayTokenURI = gatewayTools.convertToDesiredGateway(
      tokenURI,
      "https://gateway.pinata.cloud"
    );
  } catch (err) {
    gatewayTokenURI = tokenURI;
  }
  // console.log("tokenId", tokenURI, gatewayTokenURI, i)
  const res = await axios.get(gatewayTokenURI);

  return { tokenId: tokenId.toNumber(), i, ...res.data };
}

async function nftReveal() {
  const baseUri = process.env.VUE_APP_FUNCTIONS_URI;
  await axios.get(`${baseUri}/nftReveal`);
}
