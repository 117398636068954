<template>
  <div
    class="d-flex flex-column align-center ma-6"
    v-bind:class="{ centered: centered }"
  >
    <img src="@/assets/UI-icon_LG_CONFIRM.svg" width="33%" class="ma-4" />
    <div class="summary-text">
      <h2 class="text-center text-subtitle-1 font-weight-bold">
        Congratz!
      </h2>
      <p class="text-body-2 text-center mx-4">
        Your blocks are on the blockchain!
      </p>
    </div>
    <!-- <p class="text-body-2 text-center mx-6 mt-6 mb-0">
      You can claim more blocks in:
    </p>
    <p class="text-body-2 text-center yellow--text">21h 23m 34s</p> -->

    <v-btn color="accent" class="black--text mt-6" @click="setMode('view')"
      >Back to overview</v-btn
    >
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    centered: Boolean,
  },
  methods: {
    ...mapActions("mode", ["setMode"]),
  },
};
</script>

<style lang="scss">
.summary-text {
  position: relative;
}
.centered {
  height: 90vh;
  justify-content: center;
}
</style>
