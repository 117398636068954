<template>
  <v-tabs
    color="white"
    background-color="black"
    class="d-flex justify-center"
    mobile-breakpoint="xs"
    v-if="!isPoster()"
  >
    <v-tab :to="'/'">
      <v-icon class="pr-1" :color="getIconColor('Overview')">
        mdi-view-comfy
      </v-icon>
      <div>
        Overview
      </div>
    </v-tab>
    <v-tab :to="'/legacy'">
      <v-icon class="pr-1" :color="getIconColor('LegacyOverview')">
        mdi-view-comfy
      </v-icon>
      <div>
        Legacy dashboard
      </div>
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("space", ["currentSpace"]),
  },
  methods: {
    isPoster() {
      return this.$route.name === "Poster";
    },
    getIconColor(action) {
      return this.$route.name == action ? "yellow" : "";
    },
  },
};
</script>

<style scoped>
.is-mobile {
  display: none;
}

.rotate-and-flip {
  transform: rotate(90deg) scaleX(-1);
}
</style>
