import store from "../store";
import { BigNumber } from "ethers";

export async function deposit(amount) {
  const farmContract = store.state.wallet.ethers.signedFarmContract;

  const transaction = await farmContract.deposit(amount);
  const receipt = await transaction.wait();

  if (!receipt.status) {
    throw Error("Transaction was not successful");
  }
}

export async function withdraw(amount) {
  const farmContract = store.state.wallet.ethers.signedFarmContract;

  const transaction = await farmContract.withdraw(amount);
  const receipt = await transaction.wait();

  if (!receipt.status) {
    throw Error("Transaction was not successful");
  }
}

export async function harvestRewards() {
  const farmContract = store.state.wallet.ethers.signedFarmContract;

  const transaction = await farmContract.withdraw(BigNumber.from(0));
  const receipt = await transaction.wait();

  if (!receipt.status) {
    throw Error("Transaction was not successful");
  }
}

export async function currentReward(address) {
  const farmContract = store.state.wallet.ethers.farmContract;

  const currentReward = await farmContract.pendingReward(address);
  return currentReward;
}

export async function userInfo(address) {
  const farmContract = store.state.wallet.ethers.farmContract;

  // {amount = deposited tokens, rewardDebt}
  const userInfo = await farmContract.userInfo(address);
  return userInfo;
}

export async function rewardsPerBlock() {
  const farmContract = store.state.wallet.ethers.farmContract;

  const rewardsPerBlock = await farmContract.rewardPerBlock();
  return rewardsPerBlock;
}

export async function totalStakedTokens() {
  const farmContract = store.state.wallet.ethers.farmContract;

  const totalStakedTokens = await farmContract.totalStaked();
  return totalStakedTokens;
}

// export async function rewardsPerBlockPerToken() {
//   const vaultContract = store.state.wallet.ethers.vaultContract;

//   const rewardsPerBlockPerToken = await vaultContract.rewardsPerBlockPerToken();
//   return rewardsPerBlockPerToken;
// }

// export async function rewardsPoolBalance() {
//   const provider = store.state.wallet.ethers.provider;
//   const vaultContract = store.state.wallet.ethers.vaultContract;

//   const rewardsPoolBalance = await provider.getBalance(vaultContract.address);
//   return rewardsPoolBalance;
// }
