<template>
  <v-card
    :class="
      $vuetify.breakpoint.mobile
        ? 'black d-flex justify-center align-center flex-column mb-5 pt-5'
        : 'black d-flex flex-column pa-3 mb-5 mr-5'
    "
    :max-width="width"
    height="300"
    :style="!$vuetify.breakpoint.mobile && 'border: 1px solid grey !important;'"
  >
    <!-- Retrieve image ASAP from any source -->
    <v-img
      :src="imageFromServer"
      v-if="imageFromServer"
      :width="$vuetify.breakpoint.mobile ? '250' : '100%'"
      max-height="180"
      class="pa-0 ma-0"
      style="border: 1px solid grey !important; box-sizing: border-box;"
      alt="NFT Image powered by BLS"
    />
    <v-img
      :src="imageFromIPFS"
      v-else-if="imageFromIPFS"
      :width="$vuetify.breakpoint.mobile ? '250' : '100%'"
      max-height="180"
      class="pa-0 ma-0"
      style="border: 1px solid grey !important; box-sizing: border-box;"
      alt="NFT Image powered by IPFS"
    />
    <v-img
      :src="imageFromInfura"
      v-else-if="imageFromInfura"
      :width="$vuetify.breakpoint.mobile ? '250' : '100%'"
      max-height="180"
      class="pa-0 ma-0"
      style="border: 1px solid grey !important; box-sizing: border-box;"
      alt="NFT Image powered by Infura"
    />
    <v-img
      :src="imageFromPinata"
      v-else-if="imageFromPinata"
      :width="$vuetify.breakpoint.mobile ? '250' : '100%'"
      max-height="180"
      class="pa-0 ma-0"
      style="border: 1px solid grey !important; box-sizing: border-box;"
      alt="NFT Image powered by Pinata"
    />
    <v-img
      :src="this.placeholder()"
      v-else
      :width="$vuetify.breakpoint.mobile ? '250' : '100%'"
      max-height="180"
      class="pa-0 ma-0"
      position="right center"
      style="border: 1px solid grey !important; box-sizing: border-box;"
      alt="NFT"
    />
    <div
      v-if="nft.attributes"
      :class="$vuetify.breakpoint.mobile && 'text-center'"
    >
      <div class="accent--text text-body-2 text--darken-1 mt-2">
        {{ this.name }}
      </div>
      <div class="yellow--text text-body-2 text--darken-1 mt-2 mb-1">
        TRAITS
      </div>
      <v-rating
        background-color="grey darken-2"
        color="accent"
        empty-icon="mdi-square pa-0"
        full-icon="mdi-square pa-0"
        class="mb-2 d-flex flex-wrap"
        :readonly="true"
        length="15"
        size="10"
        :value="traits.length"
      />
    </div>
    <div
      v-else
      class="text-center flex-grow-1 my-4 d-flex align-center justify-center"
    >
      Not revealed yet
    </div>
  </v-card>
</template>

<script>
import IPFSGatewayTools from "@pinata/ipfs-gateway-tools/dist/browser";
import { getNfts } from "../../services/profileService";

export default {
  name: "FastNFT",
  props: ["nft"],
  data() {
    return {
      loading: true,
      imageFromServer: "",
      imageFromIPFS: "",
      imageFromInfura: "",
      imageFromPinata: "",
      name: "",
      traits: [],
    };
  },
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return 150;
        case "md":
          return 200;
        case "lg":
          return 250;
        case "xl":
          return 300;
        default:
          return 300;
      }
    },
  },
  methods: {
    async checkImage(url) {
      const res = await fetch(url);
      const buff = await res.blob();

      return buff.type.startsWith("image/");
    },
    imageFromOfficialGateway(ipfsId) {
      return `https://gateway.ipfs.io/ipfs/${ipfsId}#x-ipfs-companion-no-redirect`;
    },
    imageFromPinataGateway() {
      const gatewayTools = new IPFSGatewayTools();
      const pinataResponse = gatewayTools.convertToDesiredGateway(
        this.nft.image,
        "https://gateway.pinata.cloud"
      );

      return pinataResponse;
    },
    imageFromInfuraGateway(ipfsId) {
      return `https://${ipfsId}.ipfs.infura-ipfs.io/`;
    },
    placeholder() {
      return require("@/assets/loading_nft.png");
    },
  },
  async created() {
    const [response] = await getNfts([this.nft.tokenId]); // server
    const ipfsId = this.nft.image.replace("ipfs://", "");

    this.name = this.nft.name;
    this.traits = this.nft.attributes;

    // Attempts to retrieve from server
    // Tries IPFS gateways if not available
    // Image show logic in template
    if (response && response.imageUrl) {
      this.imageFromServer = response.imageUrl;
    } else {
      const fromIPFS = await this.imageFromOfficialGateway(ipfsId);
      const fromInfura = await this.imageFromInfuraGateway(ipfsId);
      try {
        const fromPinata = await this.imageFromPinataGateway();
        if (await this.checkImage(fromPinata)) {
          this.imageFromPinata = fromPinata;
        }
      } catch {
        console.log(
          `Could not retrieve NFT ID ${this.nft.tokenId} from Pinata network`
        );
      }

      if (await this.checkImage(fromIPFS)) {
        this.imageFromIPFS = fromIPFS;
      }

      if (await this.checkImage(fromInfura)) {
        this.imageFromInfura = fromIPFS;
      }
    }
  },
};
</script>
