<template>
  <v-btn
    @click="select()"
    rounder="true"
    class="ma-1"
    x-small
    fab
    :style="{
      border:
        color === currentColor
          ? '2px solid red !important'
          : '1px solid lightgray',
      background: color,
    }"
  />
</template>

<script>
export default {
  name: "PixelColor",
  props: {
    color: {
      type: String,
      required: true,
      default: "black",
    },
    currentColor: {
      type: String,
      required: true,
      default: "black",
    },
    setColor: {
      type: Function,
      required: true,
    },
  },
  methods: {
    select() {
      this.setColor(this.color);
    },
  },
};
</script>
