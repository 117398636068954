import * as drawingService from '../services/drawingService.js'

export default { 
  namespaced: true,
  state: {      
    newBlockArea: {
      drawing: false,
      start: undefined,
      end: undefined,
      drawStart: undefined,
    },

    suppressDrawing: false,

    drawing: false,
    drawStart: undefined,

    start: undefined,
    end: undefined,
    size: undefined
  },
  getters: {

  },
  mutations: {
    setDrawing(state, drawing) {
      state.newBlockArea.drawing = drawing;
      state.drawing = drawing;
    },
    setInitialStartCoor(state, drawStart) {
      state.newBlockArea.drawStart = drawStart;
      state.drawStart = drawStart;
    },

    setCoors(state, {start, end}) {
      state.start = start;
      state.end = end;
      state.size = {
        width: end.x - start.x + 1,
        height: end.y - start.y + 1,
      };
    },

    clearDrawing(state) {
      state.newBlockArea = {
        drawing: false,
        start: undefined,
        end: undefined,
        drawStart: undefined,
      };
      state.start = undefined;
      state.end = undefined;
      state.size = undefined;
      state.drawing = false;
    }
  },
  actions: {
    suppressDrawing({state}, value) {
      state.suppressDrawing = value;
    },

    startDrawing({commit, state}, coor) {
      if (state.suppressDrawing === true) { // drawing is suppressed
        state.suppressDrawing = false; // enable back drawing
        return; // but just skip drawing this time
      }

      commit("space/clearSelection", null, {root: true});

      // Mark drawing mode
      commit("setDrawing", true)

      // Calculate normalized coors
      const nCoor = coor;

      // Store normalized coors
      commit("setInitialStartCoor", nCoor);

      // Set drawing box coors
      commit("setCoors", {start: nCoor, end: nCoor});
      // this.drawBox(nCoor, nCoor);
    },

    drawChange({commit, state, rootState}, coor) {
      // Check if in drawing mode
      if (state.drawing) {
        // Calculate normalized coors
        const nCoor = coor;

        // Flip the selection so start is always top left & end is always bottom right
        let start = drawingService.trueStart(state.drawStart, nCoor);
        let end = drawingService.trueEnd(state.drawStart, nCoor);

        const maxLimits = rootState.space.currentSpace.config.maxLimits;

        // Validate size
        if (!drawingService.validateSize(start, end, maxLimits)) {
          const constrained = drawingService.constrainStartEnd(
            start,
            end,
            state.drawStart,
            maxLimits
          );
          start = constrained.start;
          end = constrained.end;
        }

        commit("setCoors", {start, end});
      }
    },
    stopDrawing({commit, dispatch, state, rootState}, coor) {
      // Mark stop drawing mode
      if (state.drawing) {
        commit("setDrawing", false)

        // Calculate normalized coors
        const nCoor = coor;

        // Flip the selection so start is always top left & end is always bottom right
        let start = drawingService.trueStart(state.drawStart, nCoor);
        let end = drawingService.trueEnd(state.drawStart, nCoor);

        const maxLimits = rootState.space.currentSpace.config.maxLimits;

        // Validate size
        if (!drawingService.validateSize(start, end, maxLimits)) {
          const constrained = drawingService.constrainStartEnd(
            start,
            end,
            state.drawStart,
            maxLimits
          );
          start = constrained.start;
          end = constrained.end;
        }

        commit("setCoors", {start, end});

        dispatch("space/selectBlockArea", {
          startCoor: state.start,
          endCoor: state.end,
          size: state.size,
        }, {root: true})
      }
    },

    selectBlockArea({commit, dispatch, state, rootState}, {startCoor, endCoor}) {
        if (state.suppressDrawing === true) { // drawing is suppressed
          state.suppressDrawing = false; // enable back drawing
          return; // but just skip drawing this time
        }
  
        commit("space/clearSelection", null, {root: true});
      
        // Store normalized coors
        commit("setInitialStartCoor", startCoor);
  
        // Flip the selection so start is always top left & end is always bottom right
        let start = drawingService.trueStart(state.drawStart, endCoor);
        let end = drawingService.trueEnd(state.drawStart, endCoor);

        const maxLimits = rootState.space.currentSpace.config.maxLimits;

        // Validate size
        if (!drawingService.validateSize(start, end, maxLimits)) {
          const constrained = drawingService.constrainStartEnd(
            start,
            end,
            state.drawStart,
            maxLimits
          );
          start = constrained.start;
          end = constrained.end;
        }

        commit("setCoors", {start, end});

        dispatch("space/selectBlockArea", {
          startCoor: state.start,
          endCoor: state.end,
          size: state.size,
        }, {root: true})
    }
  }
};
