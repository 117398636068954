<template>
  <router-link to="/mint" class="promo">
    <img src="@/assets/mint/promo-title.svg" class="title ma-3" />
    <img src="@/assets/mint/albert4.svg" class="albert" />

    <div class="cta pa-2 mb-3">
      <div class="black--text text-uppercase ma-2">
        Mint
        <span class="black--text text-uppercase font-weight-bold"> here</span>!
      </div>
    </div>

    <div
      class="fact black--text text-uppercase ml-3 mb-3 mt-2"
      :class="{
        'text-subtitle-2 mb-4': $vuetify.breakpoint.lgAndDown,
        'text-h6 mb-8': $vuetify.breakpoint.xlOnly,
      }"
    >
      <div class="ml-5">
        <span class="accent--text">Extremely</span>
        <span class="white--text font-weight-bold">rare</span>
      </div>
      <div class="ml-1">
        <span class="accent--text">Extremely</span>
        <span class="yellow--text font-weight-bold">powerful</span>
      </div>
      <div class="ml-6">
        <span class="accent--text">Smart</span>
        <span class="white--text font-weight-bold text-none">NFTs</span>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
a:link {
  text-decoration: none;
}

.promo-wrapper {
  width: 100%;
}
.promo {
  background-color: #000;
  border: 1px solid #444;
  text-decoration: none;
  transition: border-width 0.1s;

  position: relative !important;
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: 1fr auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  position: absolute;
  overflow: hidden;

  &:hover {
    border-width: 3px;
  }

  .albert {
    position: absolute;
    right: -50px;
    top: -30px;
    width: 60%;
    max-width: 250px;
  }

  .title {
    grid-row: 1;
    min-width: 120px;
    max-width: 180px;
  }

  .fact {
    grid-row: 3;
  }

  .cta {
    grid-row: 2;
    grid-column: 1 / span 3;
    background-color: #ffff00;
    font-size: 1.6rem;
    line-height: 1rem;
  }

  @import "~vuetify/src/styles/settings/_variables";

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .albert {
      max-width: 160px;
    }
    .cta {
      margin-top: 12px;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-only')} {
    .albert {
      position: absolute;
      right: -40px;
      top: -70px;
      width: 50%;
      max-width: 250px;
    }
    .title {
      grid-row: 1;
      min-width: 140px;
      max-width: 180px;
    }

    .fact {
      grid-row: 1;
      grid-column: 2;
    }

    .cta {
      display: flex;
      gap: 20px;
      margin-top: -10px;
    }
  }

  @media #{map-get($display-breakpoints, 'md-only')} {
    .albert {
      top: -14px;
    }
  }
  @media #{map-get($display-breakpoints, 'lg-only')} {
    .albert {
      top: -14px;
    }
  }

  @media #{map-get($display-breakpoints, 'xl-only')} {
    .albert {
      right: -66px;
      max-width: 240px;
    }
    .title {
      grid-row: 1;
      min-width: 150px;
      max-width: 200px;
    }
    .cta {
      font-size: 2.4rem;
      line-height: 2rem;
    }
  }
}
</style>
