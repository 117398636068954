import Vue from "vue";
import Vuex from "vuex";

import SpaceStore from "./SpaceStore";
import WalletStore from "./WalletStore";
import ModeStore from "./ModeStore";
import PosterStore from "./PosterStore";
import RewardManagerStore from "./RewardManagerStore";
import ErrorStore from "./ErrorStore";
import DrawingStore from "./DrawingStore";
import StakingStore from "./StakingStore";
import FarmStore from "./FarmStore";
import PoolStore from "./PoolStore";
import DialogStore from "./DialogStore";
import PixelDrawerStore from "./PixelDrawerStore";
import MintStore from "./MintStore";
import ProfileStore from "./ProfileStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    dialog: DialogStore,
    space: SpaceStore,
    mode: ModeStore,
    wallet: WalletStore,
    poster: PosterStore,
    harvest: RewardManagerStore,
    drawing: DrawingStore,
    error: ErrorStore,
    vault: StakingStore,
    farm: FarmStore,
    pool: PoolStore,
    pixel: PixelDrawerStore,
    mint: MintStore,
    profile: ProfileStore
  },
});
