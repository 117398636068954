import store from "../store"
import { ethers } from "ethers";

export async function blocksOwned(managerContract, address) {
  const spaceInfo = await managerContract.userInfo(0, address);
  if(spaceInfo.amount) {
    return spaceInfo.amount;
  } else {
    return spaceInfo;
  }
}

export async function lastRewardsBlock(managerContract) { 
  const rewardsFinishedBlock = await managerContract.blsLastRewardsBlock();
  return rewardsFinishedBlock;
}

export async function blsPerBlockAreaPerBlock(managerContract) {
  const spaceInfo = await managerContract.spaceInfo(0);
  return spaceInfo.blsPerBlockAreaPerBlock;
}

export async function pendingBlsTokens(managerContract) {
  const address = ethers.utils.getAddress( store.state.wallet.account );

  try {
    const pendingBlsTokens = await managerContract.pendingBlsTokens(0, address);
    return pendingBlsTokens;
  } catch(err) {
    console.log(err)
  }
}

export async function claimBlsTokens(signedManagerContract) { 
  const transaction = await signedManagerContract.claim(0);
  const receipt = await transaction.wait();
  if (!receipt.status) {
    throw Error("Transaction was not successful")
  }
}

export async function claimBlsTokensOld(signedManagerContract) {
  const transaction = await signedManagerContract.claim(0);
  const receipt = await transaction.wait();
  if (!receipt.status) {
    throw Error("Transaction was not successful")
  }
}