<template>
  <v-card
    v-if="messageVisible && !alreadySeen"
    class="green lighten-2 d-flex flex-row justify-space-between align-flex-start pa-2"
    style="position: absolute; top: 0; z-index: 1; border: 2px solid #00FF18 !important"
    :style="
      $vuetify.breakpoint.mobile
        ? 'left: 0; width: 100%; transform: translate(0%, 0%);'
        : 'width: 50%; transform: translate(50%, 0%); border-radius: 10px; margin-top: 5px;'
    "
  >
    <vue-markdown
      class="breaking-news-message black--text font-weight-medium"
      style="margin: 0 auto;"
    >
      {{ message }}
    </vue-markdown>
    <v-img
      style="cursor: pointer;"
      max-height="25"
      max-width="25"
      class="ml-2"
      src="../../assets/UI-icon_CLOSE.svg"
      @click="closeNews"
    />
  </v-card>
</template>

<script>
import { database } from "../../services/firebase";
import VueMarkdown from "@adapttive/vue-markdown";

export default {
  name: "BreakingNews",
  components: {
    VueMarkdown,
  },
  data() {
    return {
      messageVisible: false,
      message: "Space Apes - Stay tuned for your breaking news live!",
    };
  },
  methods: {
    closeNews() {
      this.messageVisible = false;
      localStorage[this.message.hashCode()] = true;
    },
  },
  computed: {
    alreadySeen() {
      return localStorage[this.message.hashCode()] || false;
    },
  },
  mounted() {
    database.ref("message").on("value", (message) => {
      this.messageVisible = message.val().visible;
      this.message = message.val().text;
    });
  },
};

String.prototype.hashCode = function() {
  var hash = 0,
    i,
    chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
</script>

<style scoped>
.breaking-news-message :last-child {
  margin-bottom: 0;
}
</style>
