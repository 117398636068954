import {ethers, utils} from "ethers"


export async function getBLSPrice() {
  const router = getRouterContract()
  const amounts = await router.getAmountsOut(utils.parseEther("1"), [BLS_CONTRACT_ADDRESS, BUSD_CONTRACT_ADDRESS])
  const price = amounts.slice(-1)[0]
  return price
}

function getRouterContract() {
  const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org/")
  return new ethers.Contract(ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT_ABI, provider)
}

const BLS_CONTRACT_ADDRESS = "0x34Aa9099D924F3FB2377ff20D81b235311c15346"
// const WUSD_CONTRACT_ADDRESS = "0x3fF997eAeA488A082fb7Efc8e6B9951990D0c3aB"
const BUSD_CONTRACT_ADDRESS = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
// const ROUTER_CONTRACT_ADDRESS = "0xD48745E39BbED146eEC15b79cBF964884F9877c2"
const ROUTER_CONTRACT_ADDRESS = "0x10ED43C718714eb63d5aA57B78B54704E256024E"
const ROUTER_CONTRACT_ABI = [
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "amountIn",
              "type": "uint256"
          },
          {
              "internalType": "address[]",
              "name": "path",
              "type": "address[]"
          }
      ],
      "name": "getAmountsOut",
      "outputs": [
          {
              "internalType": "uint256[]",
              "name": "amounts",
              "type": "uint256[]"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  }
]
