<template>
  <v-card
    :class="
      $vuetify.breakpoint.mobile
        ? 'black d-flex justify-center align-center flex-column mb-5 pt-5'
        : 'black d-flex flex-column pa-3 mb-5 mr-5'
    "
    :max-width="width"
    height="300"
    :style="!$vuetify.breakpoint.mobile && 'border: 1px solid grey !important;'"
  >
    <v-img
      :src="this.placeholder()"
      :width="$vuetify.breakpoint.mobile ? '250' : '100%'"
      max-height="180"
      class="pa-0 ma-0"
      style="border: 1px solid grey !important; box-sizing: border-box;"
    />

    <div class="yellow--text text-body-1 text--darken-1 mt-2">
      MINT PRICE
    </div>
    <div class="grey--text text-body-2 text--darken-1  mt-1">
      {{ priceOfOne }} BNB
    </div>
    <v-btn
      class="pa-0 ma-0 mt-2"
      height="5"
      block
      @click="this.mintNft"
      :loading="processing"
      :disabled="!connected || !allowedToMint"
      >MINT NOW</v-btn
    >
  </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "EmptyNFT",
  data() {
    return {
      processing: false,
    };
  },
  computed: {
    ...mapState("wallet", ["connected"]),
    ...mapState("error", ["showError"]),
    ...mapState("mint", ["loading", "allowedToMint"]),
    ...mapGetters("mint", ["priceOfOne"]),
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "auto";
        case "sm":
          return 150;
        case "md":
          return 200;
        case "lg":
          return 250;
        case "xl":
          return 300;
        default:
          return 300;
      }
    },
  },
  watch: {
    loading(val) {
      this.processing = val.mint;
    },
    showError(val) {
      if (val) {
        this.processing = false;
      }
    },
  },
  methods: {
    ...mapActions("mint", ["mint"]),
    placeholder() {
      return require("@/assets/no_nft.png");
    },
    mintNft() {
      this.mint(1);
      this.processing = this.loading.mint;
    },
  },
};
</script>
