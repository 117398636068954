<template>
  <div>
    <h2 class="tile-title-2">{{ title }}</h2>
    <div class="font-weight-bold" :class="[fontSize]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    large: Boolean,
    medium: Boolean,
    small: Boolean,
  },
  computed: {
    fontSize() {
      if (this.large) return "text-h4";
      if (this.medium) return "text-h5";
      if (this.small) return "text-subtitle-1";
      return "text-h5";
    },
  },
};
</script>

<style>
.tile-title-2 {
  color: yellow;
  text-transform: uppercase;
  font-size: 0.75rem;
}
</style>
