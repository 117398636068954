import {ethers} from 'ethers';

export default async (input) => {

    if(!input){
        return false;
    }
    const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org");
    const providerETH = new ethers.providers.JsonRpcProvider("https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161");

    const contract = new ethers.Contract("0x57A7c5d10c3F87f5617Ac1C60DA60082E44D539e", [{"inputs":[{"internalType":"address","name":"owner","type":"address"}],"name":"balanceOf","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}], provider);
    const contractETH = new ethers.Contract("0x57A7c5d10c3F87f5617Ac1C60DA60082E44D539e", [{"inputs":[{"internalType":"address","name":"owner","type":"address"}],"name":"balanceOf","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}], providerETH);

    // call smart contract method and retreive UserState by provided wallet
    let userBSCBalance = contract.balanceOf(`${input}`);
    let userETHBalance = contractETH.balanceOf(`${input}`);
    let amountOfAlpies = 0;

    let [ethState, bscState] = await Promise.allSettled([userETHBalance, userBSCBalance]);

    if(ethState.status === 'fulfilled'){
      amountOfAlpies += ethState.value.toNumber();
    }else{
      console.log(ethState.reason);
    }
    
    if(bscState.status === 'fulfilled'){
      amountOfAlpies += bscState.value.toNumber();
    }else{
      console.log(bscState.reason);
    }
    return amountOfAlpies > 0;
};