<template>
  <v-tabs color="white" background-color="black" class="d-flex justify-center">
    <v-tab :to="'/mint/overview'">
      <v-icon class="pr-1" :color="getIconColor('MintOverview')">
        mdi-view-comfy-outline
      </v-icon>

      <div v-if="!$vuetify.breakpoint.mobile">Mint Overview</div>
    </v-tab>

    <v-tab :to="'/mint/collection'">
      <v-icon
        class="pr-1 rotate-and-flip"
        :color="getIconColor('MintCollection')"
        >mdi-format-list-text
      </v-icon>

      <div v-if="!$vuetify.breakpoint.mobile">My Collection</div>
    </v-tab>

    <v-tab :to="'/mint/share2earn'">
      <v-icon class="pr-1 rotate-and-flip" :color="getIconColor('Share2Earn')"
        >mdi-share-variant
      </v-icon>

      <div v-if="!$vuetify.breakpoint.mobile">Share2Earn</div>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  methods: {
    getIconColor(action) {
      return this.$route.name == action ? "yellow" : "";
    },
  },
};
</script>

<style></style>
