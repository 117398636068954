import { ethers, BigNumber } from "ethers";

export async function tokenBalance(tokenContract, dirtyAddress) {
  const address = ethers.utils.getAddress(dirtyAddress);

  const balance = await tokenContract.balanceOf(address);
  return balance;
}

export async function allStakedTokens(tokenContract, contract) {
  const balance = await tokenContract.balanceOf(contract.address);
  return balance;
}

export async function approve(signedTokenContract, contract) {
  // set amount to 42000000 BLS tokens (max amount of tokens)
  const transaction = await signedTokenContract.approve(
    contract.address,
    ethers.utils.parseEther("42000000")
  );
  const receipt = await transaction.wait();

  if (!receipt.status) {
    throw Error("Transaction was not successful");
  }
}

export async function allowance(tokenContract, address, contract) {
  const allowance = await tokenContract.allowance(address, contract.address);
  return allowance;
}

export async function hasAllowance(tokenContract, address, contract) {
  const allowed = await allowance(tokenContract, address, contract);
  return allowed.gt(1);
}

export async function getReserve(tokenContract) {
  try {
    const reserves = await tokenContract.getReserves();

    return reserves._reserve0.lt(reserves._reserve1)
      ? reserves._reserve0
      : reserves._reserve1;
  } catch (err) {
    return BigNumber.from(0);
  }
}

export async function totalSupply(tokenContract) {
  const totalSupply = await tokenContract.totalSupply();
  return totalSupply;
}