<template>
  <v-container>
    <v-row v-if="rulesContent">
      <v-col>
        <div class="side-label">Space rules:</div>
        <div class="value yellow--text">
          <a class="value yellow--text" @click="rulesDialog = true">
            Read more >>>
          </a>
        </div>
      </v-col>
    </v-row>

    <template v-if="connected">
      <v-row>
        <v-col>
          <div class="side-label">Block areas:</div>
          <div class="value yellow--text">
            {{ currentSpace.blockAreas.length }}
          </div>
        </v-col>
      </v-row>

      <v-row v-if="rewards">
        <v-col>
          <div class="side-label">Space yields:</div>
          <div class="value yellow--text">
            {{ rewardsPerBlock }} {{ rewardsToken }} / block / day
          </div>
        </v-col>
      </v-row>

      <v-row v-if="rewards">
        <v-col>
          <div class="side-label">Space rewards:</div>
          <div class="value yellow--text">
            <FastCounter
              class="ma-0 yellow--text"
              :startAmount="blsRewardNumber"
              :incrementPerSec="blsRewardsPerSecNumber"
              :decimals="6"
            />
            {{ rewardsToken }}
          </div>
          <v-btn
            @click="claimBlsReward()"
            :loading="loading.harvest"
            outlined
            block
            color="accent darken-1"
            class="mt-2"
            >Harvest</v-btn
          >
        </v-col>
      </v-row>

      <!-- Legacy stuff: Claiming blocks from previous contract -->
      <v-row v-if="blsRewardNumberOld && rewards">
        <v-col>
          <div class="side-label">
            Legacy rewards:
          </div>
          <div class="value yellow--text">
            <FastCounter
              class="ma-0 yellow--text"
              :startAmount="blsRewardNumberOld"
              :incrementPerSec="0.0"
              :decimals="6"
            />
            {{ rewardsToken }}
          </div>
          <v-btn
            @click="claimBlsRewardOld()"
            :loading="loading.harvestOld"
            outlined
            block
            color="accent darken-1"
            class="mt-2"
            >Harvest</v-btn
          >
        </v-col>
      </v-row>
    </template>

    <RulesDialog
      v-model="rulesDialog"
      :spaceName="spaceName"
      :content="rulesContent"
    />

    <!-- <component :is="rulesComponent" v-model="rulesDialog"></component> -->

    <EmergencyUploadDialog v-model="emergencyUploadDialog" />
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import RulesDialog from "../RulesDialog.vue";
import FastCounter from "../../helpers/FastCounter.vue";
import EmergencyUploadDialog from "../../dialogs/EmergencyUploadDialog.vue";

export default {
  components: {
    RulesDialog,
    EmergencyUploadDialog,
    FastCounter,
  },

  props: {
    rulesComponent: Object,
    rulesContent: Array,
    spaceName: String,
    rewardsPerBlock: String,
    rewards: Boolean,
    rewardsToken: String,
  },

  data() {
    return {
      rulesDialog: false,
      emergencyUploadDialog: false,
    };
  },

  computed: {
    ...mapState("space", ["currentSpace"]),
    ...mapState("wallet", ["connected"]),

    ...mapState("harvest", ["loading"]),
    ...mapGetters("harvest", ["userReward", "userRewardOld", "blsPerSec"]),
    blsRewardNumber() {
      return parseFloat(this.userReward);
    },
    blsRewardNumberOld() {
      return this.userRewardOld ? parseFloat(this.userRewardOld) : 0;
    },
    blsRewardsPerSecNumber() {
      return parseFloat(this.blsPerSec);
    },
  },
  methods: {
    claimBlsReward() {
      this.$store.dispatch("harvest/harvestReward");
    },
    claimBlsRewardOld() {
      this.$store.dispatch("harvest/harvestRewardOld");
    },
  },
};
</script>

<style>
.side-label {
  color: #0c9690;
}
</style>
