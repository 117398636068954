import { BigNumber, utils } from "ethers";
import { truncate } from '../services/helpers'
import * as tokenService from "../crypto/tokenService";
import * as farmService from "../crypto/farmService";

export default {
  namespaced: true,
  state: {
    approved: false,

    deposited: BigNumber.from(0),
    reward: BigNumber.from(0),

    loading: {
      approvalCheck: false,
      approve: false,
      deposit: false,
      withdraw: false,
      harvest: false,
    },

    secPerBlock: 3,
    secPerYear: 365 * 86400,
    baseRewardsPerSec: BigNumber.from(0),

    rewardsPerBlock: BigNumber.from(0),
    totalStakedTokens: BigNumber.from(0),

    // rewardsPoolBalance: BigNumber.from(0),
    allStakedTokens: BigNumber.from(0),

    token: {
      reserve: BigNumber.from(0),
      totalSupply: BigNumber.from(0)
    }
  },
  getters: {
    deposited: (state) => truncate(utils.formatEther(state.deposited), 6),
    depositedFull: (state) => utils.formatEther(state.deposited),
    reward: (state) => utils.formatEther(state.reward, 18),
    rewardsPerSec(state, getters) {
      return utils.formatUnits(
        getters.baseRewardsPerSec.mul(state.deposited),
        30
      ); // Multiply by number of deposited, then normalize to ether unit.
    },
    rewardsPoolBalance: (state) =>
      truncate(utils.formatUnits(state.rewardsPoolBalance, 18), 4),
    stakingReward: (state) => {
      return state.reward.eq(0)
        ? BigNumber.from(0)
        : utils.formatEther(state.reward.sub(state.takeoverReward));
    },
    allStakedTokens: (state) => utils.formatEther(state.allStakedTokens),
    vaultPercentage: (state) => {
      return state.allStakedTokens.eq(0)
        ? BigNumber.from(0)
        : utils.formatUnits(
            state.deposited.mul(10000).div(state.allStakedTokens),
            2
          );
    },

    rewardsPerBlockPerToken(state) {
      return state.totalStakedTokens.eq(0)
        ? BigNumber.from(0)
        : state.rewardsPerBlock.mul(1e12).div(state.totalStakedTokens);
    },

    rewardsPerYearPerToken(state, getters) {
      return getters.rewardsPerBlockPerToken.mul(getters.blocksPerYear);
    },

    baseRewardsPerSec(state, getters) {
      return getters.rewardsPerBlockPerToken.eq(0) || state.secPerBlock === 0
        ? BigNumber.from(0)
        : getters.rewardsPerBlockPerToken.div(state.secPerBlock);
    },

    blocksPerYear(state) {
      return state.secPerYear / state.secPerBlock;
    },

    LPTokenInUSD(state) {
      return state.token.totalSupply.eq(0)
        ? BigNumber.from(0)
        : state.token.reserve.mul(2).mul(1e12).div(state.token.totalSupply);
    },

    LPYieldPerYear(state, getters, rootState) {
      return getters.rewardsPerYearPerToken.mul(rootState.vault.tokenPrice)
    },

    APR(state, getters) {
      return nFormatter(getters.LPTokenInUSD.eq(0)
        ? BigNumber.from(0)
        : getters.LPYieldPerYear.mul(1e12).sub(getters.LPTokenInUSD).mul(100).div(getters.LPTokenInUSD).div(1e12).div(1e12).div(1e6), 2)
    }


  },
  mutations: {
    approve(state) {
      state.approved = true;
    },
    setDeposited(state, deposited) {
      state.deposited = deposited;
    },
    setFarmReward(state, reward) {
      state.reward = reward;
    },
    setRewardsPerBlock(state, rewardsPerBlock) {
      state.rewardsPerBlock = rewardsPerBlock;
    },
    setTotalStakedTokens(state, totalStakedTokens) {
      state.totalStakedTokens = totalStakedTokens;
    },
    // setRewardsPerBlockPerToken(state, rewardsPerBlockPerToken) {
    //   // Smart Contract Magic (divided by 1e12 in SC)
    //   //2227000000 * 1000000 = 2227000000000000 = 0.002227000000000000
    //   state.baseRewardsPerSec = rewardsPerBlockPerToken
    //     .mul(1e6)
    //     .div(state.secPerBlock);
    // },
    stopRewards(state) {
      clearInterval(state.interval);
      state.reward = BigNumber.from(0);
      state.baseRewardsPerSec = BigNumber.from(0);
    },
    setInterval(state, interval) {
      state.interval = interval;
    },

    setRewardsPoolBalance(state, balance) {
      state.rewardsPoolBalance = balance;
    },
    setAllStakedTokens(state, allStakedTokens) {
      state.allStakedTokens = allStakedTokens;
    },

    setTokenDetails(state, {reserve, totalSupply}) {
      state.token.reserve = reserve;
      state.token.totalSupply = totalSupply;
    },

    loadingApprovalCheck(state, loading) {
      state.loading.approvalCheck = loading;
    },
    loadingApprove(state, loading) {
      state.loading.approve = loading;
    },
    loadingDeposit(state, loading) {
      state.loading.deposit = loading;
    },
    loadingWithdraw(state, loading) {
      state.loading.withdraw = loading;
    },
    loadingHarverst(state, loading) {
      state.loading.harvest = loading;
    },
  },
  actions: {
    async checkForApproval({ commit, dispatch, rootState }) {
      commit("loadingApprovalCheck", true);
      const address = utils.getAddress(rootState.wallet.account);
      try {
        const LPTokenContract = rootState.wallet.ethers.LPTokenContract;
        const farmContract = rootState.wallet.ethers.farmContract;
        const hasAllowance = await tokenService.hasAllowance(
          LPTokenContract,
          address,
          farmContract
        );
        // There is some allowance, assume it's full
        if (hasAllowance) {
          commit("approve");
        }
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      } finally {
        commit("loadingApprovalCheck", false);
      }
    },

    async approve({ commit, dispatch, rootState }) {
      try {
        commit("loadingApprove", true);
        const signedLPTokenContract =
          rootState.wallet.ethers.signedLPTokenContract;
        const farmContract = rootState.wallet.ethers.farmContract;
        await tokenService.approve(signedLPTokenContract, farmContract);
        commit("approve");
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      } finally {
        commit("loadingApprove", false);
      }
    },

    async fetchUserInfo({ commit, dispatch, rootState }) {
      const address = utils.getAddress(rootState.wallet.account);
      try {
        const userInfo = await farmService.userInfo(address);
        commit("setDeposited", userInfo.amount);
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      }
    },

    async deposit({ commit, dispatch }, value) {
      const amount = utils.parseEther(value);
      try {
        commit("loadingDeposit", true);
        await farmService.deposit(amount);
        dispatch("fetchUserInfo");
        // dispatch("allStakedTokens");
        // dispatch("wallet/getBlsBalance", null, {root: true});
        // dispatch("wallet/getBnbBalance", null, {root: true});
        dispatch("wallet/getLPBalance", null, {root: true});
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      } finally {
        commit("loadingDeposit", false);
      }
    },

    async withdraw({ commit, dispatch }, value) {
      const amount = utils.parseEther(value);
      try {
        commit("loadingWithdraw", true);
        await farmService.withdraw(amount);
        dispatch("fetchUserInfo");
        // dispatch("allStakedTokens");
        dispatch("wallet/getBlsBalance", null, {root: true});
        dispatch("wallet/getLPBalance", null, {root: true});
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      } finally {
        commit("loadingWithdraw", false);
      }
    },

    async fetchRewards({ commit, rootState }) {
      const address = utils.getAddress(rootState.wallet.account);

      const interval = setIntervalAndExecute(async function() {
        const pendingFarmTokens = await farmService.currentReward(address);
        commit("setFarmReward", pendingFarmTokens);

        const rewardsPerBlock = await farmService.rewardsPerBlock();
        commit("setRewardsPerBlock", rewardsPerBlock);

        const totalStakedTokens = await farmService.totalStakedTokens();
        commit("setTotalStakedTokens", totalStakedTokens);

        const LPTokenContract = rootState.wallet.ethers.LPTokenContract;
        const reserve = await tokenService.getReserve(LPTokenContract);
        const totalSupply = await tokenService.totalSupply(LPTokenContract);
        commit("setTokenDetails", {reserve, totalSupply})

        // const rewardsPoolBalance = await stakingService.rewardsPoolBalance();
        // commit("setRewardsPoolBalance", rewardsPoolBalance);

        // dispatch("allStakedTokens")
      }, 6000);

      commit("setInterval", interval);
    },

    async harvestRewards({ commit, dispatch }) {
      try {
        commit("loadingHarverst", true);
        await farmService.harvestRewards();
        commit("setFarmReward", BigNumber.from(0));
        dispatch("wallet/getBnbBalance", null, { root: true });
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      } finally {
        commit("loadingHarverst", false);
      }
    },

    async allStakedTokens({ commit, dispatch, rootState }) {
      try {
        const LPTokenContract = rootState.wallet.ethers.LPTokenContract;
        const farmContract = rootState.wallet.ethers.farmContract;
        const allStakedTokens = await tokenService.allStakedTokens(
          LPTokenContract,
          farmContract
        );
        commit("setAllStakedTokens", allStakedTokens);
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      }
    },
  },
};

function setIntervalAndExecute(fn, t) {
  fn();
  return setInterval(fn, t);
}

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    // { value: 1e15, symbol: "P" },
    // { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}