export function normalizeCoor(coor, blockSize) {
  return {
    x: Math.floor(coor.x / blockSize),
    y: Math.floor(coor.y / blockSize),
  };
}

export function denormalizeCoor(coor, blockSize) {
  return {
    x: coor.x * blockSize,
    y: coor.y * blockSize,
  };
}

export function denormalizedSize(size) {
  if (size) {
    return {
      width: size.width * this.blockSize,
      height: size.height * this.blockSize,
    };
  } else {
    return undefined;
  }
}

export function trueStart(start, end) {
  return {
    x: Math.min(start.x, end.x),
    y: Math.min(start.y, end.y),
  };
}

export function trueEnd(start, end) {
  return {
    x: Math.max(start.x, end.x),
    y: Math.max(start.y, end.y),
  };
}

export function validateSize(startCoor, endCoor, maxLimits) {
  const blockWidth = endCoor.x - startCoor.x + 1;
  const blockHeight = endCoor.y - startCoor.y + 1;
  const blockArea = blockWidth * blockHeight;

  return blockWidth <= maxLimits.width && blockHeight <= maxLimits.height && blockArea <= maxLimits.area;
}

export function constrainStartEnd(startCoor, endCoor, drawStartCoor, maxLimits) {
  const blockWidth = endCoor.x - startCoor.x + 1;
  const blockHeight = endCoor.y - startCoor.y + 1;
  // const blockArea = blockWidth * blockHeight;

  let constrained = {
    start: {
      x: startCoor.x,
      y: startCoor.y,
    },
    end: {
      x: endCoor.x,
      y: endCoor.y,
    },
  };

  // When width is bigger than the limit
    // if we are moving to the right of the start point, constrain the right,
    // otherwise constrain the left
  if (blockWidth > maxLimits.width) {
    if (drawStartCoor.x === startCoor.x) {
      constrained.end.x = startCoor.x + (maxLimits.width - 1);
    } else {
      constrained.start.x = endCoor.x - (maxLimits.width - 1);
    }

    // Also, if we moved down from the start point, constrain the bottom,
    // otherwise constrain the top
    // 
    if (drawStartCoor.y === startCoor.y) {
      constrained.end.y = Math.min(endCoor.y, startCoor.y + (maxLimits.height - 1)); // ?? why 2
    } else {
      constrained.start.y = Math.max(startCoor.y, endCoor.y - (maxLimits.height - 1)); // ?? why 2
    }
  }

  if (blockHeight > maxLimits.height) {
    if (drawStartCoor.y === startCoor.y) {
      constrained.end.y = startCoor.y + (maxLimits.height - 1);
    } else {
      constrained.start.y = endCoor.y - (maxLimits.height - 1);
    }

    if (drawStartCoor.x === startCoor.x) {
      constrained.end.x = Math.min(endCoor.x, startCoor.x + (maxLimits.width - 1)); // ?? why 2
    } else {
      constrained.start.x = Math.max(startCoor.x, endCoor.x - (maxLimits.width - 1)); // ?? why 2
    }
  }

  if (blockWidth == maxLimits.width && blockHeight == maxLimits.height) {
    constrained.start.y = endCoor.y - (maxLimits.height - 1);
    constrained.start.x = Math.max(startCoor.x, endCoor.x - (maxLimits.width - 1));
  }

  const blockArea = (constrained.end.x - constrained.start.x + 1) * (constrained.end.y - constrained.start.y + 1);
  if (blockArea > maxLimits.area) {     
      const {width, height} = limitedWidthHeight(blockWidth, blockHeight, maxLimits)

      if (drawStartCoor.x === startCoor.x) {
        constrained.end.x = startCoor.x + (width - 1);
      } else {
        constrained.start.x = endCoor.x - (width - 1);
      }

      if (drawStartCoor.y === startCoor.y) {
        constrained.end.y = startCoor.y + (height - 1);
      } else {
        constrained.start.y = endCoor.y - (height - 1);
      }
  }

  return constrained;
}

export function constrainResize(startCoor, endCoor, maxLimits) {

 
  if (validateSize(startCoor, endCoor, maxLimits)) {
    return endCoor;
  } else {
    const realWidth = endCoor.x - startCoor.x;
    const realHeight = endCoor.y - startCoor.y;

    const {width, height} = limitedWidthHeight(realWidth, realHeight, maxLimits)

    if (realWidth + 1 > width && realHeight + 1 > height) {
      return coorOffset(startCoor, {x: width, y: height})
    } else if (realWidth >= width) {
      return coorOffset(startCoor, {x: width, y: realHeight})
    } else if (realHeight >= height) {
      return coorOffset(startCoor, {x: realWidth, y: height})
    } else {
      return coorOffset(startCoor, {x: width, y: height})
    }
  }

}

function coorOffset(coor, offset) {
  return {
    x: coor.x + offset.x,
    y: coor.y + offset.y
  }
} 

function findFirstFactor(number) {
  let lower = 1;
  for(let i = 1; i**2 < number; i++) {
    lower = i;
  } 
  return lower;
}

function findSecondFactor(firstFactor, number) {
  return Math.floor(number / firstFactor);
}

function limitedWidthHeight(realWidth, realHeight, maxLimits) {
  const long = Math.min(findFirstFactor(maxLimits.area), Math.max(maxLimits.width, maxLimits.height));
  const short = Math.min(findSecondFactor(long, maxLimits.area), Math.min(maxLimits.width, maxLimits.height));

  const widthLonger = realWidth < realHeight;

  return {
    width: widthLonger ? long : short,
    height: widthLonger ? short : long
  }
}