<template>
  <div class="grid" :class="{ 'mobile-grid': $isMobile() }">
    <!-- <div v-for="i in 2000" :key="i" class="box">
      <div class="content"></div>
    </div> -->

    <v-card
      v-if="referredUser"
      class="green lighten-0 d-flex flex-row justify-space-between align-center pa-2 mt-4"
      style="border: 2px solid #00FF38 !important; width: 100%"
      :class="{ 'text-subtitle-2 flex-column': $vuetify.breakpoint.smAndDown }"
    >
      <span
        >You got referred by a fellow ape! For this you will get 5% discount on
        all your mints!
        <span v-if="!connected">Please connect your wallet.</span>
      </span>
      <ConnectButton mini v-if="!connected" class="ma-2" />
    </v-card>

    <router-link class="text-decoration-none" to="/mint/share2earn">
      <v-row class="ma-0 mt-1">
        <v-col
          cols="12"
          class="d-flex align-center justify-center black yellow--text text-center pa-4"
          :class="{ 'text-subtitle-2': $vuetify.breakpoint.smAndDown }"
          >LET THE WORLD KNOW ABOUT SPACE APES AND EARN BNBs</v-col
        >
        <v-col
          cols="12"
          md="6"
          class="d-flex align-center justify-center accent darken-3 white--text text-center text-h5"
          :class="{ 'text-subtitle-1': $vuetify.breakpoint.smAndDown }"
        >
          <span>
            <strong>21</strong> BNBs REWARDS POOL FOR OUR TOP SUPPORTERS
          </span>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex align-center justify-center accent darken-1 white--text text-center text-h5"
          :class="{ 'text-subtitle-1': $vuetify.breakpoint.smAndDown }"
        >
          <span>
            <strong>10% of every mint</strong> airdropped to your wallet
          </span>
        </v-col>
      </v-row>
    </router-link>

    <a
      class="text-decoration-none"
      href="https://1000blocks.medium.com/extremely-rare-extremely-powerful-1000blocks-first-smart-nft-collection-84e8562e59f8"
      target="_blank"
    >
      <v-row no-gutters class="mt-1">
        <v-col
          cols="12"
          class="d-flex align-center justify-center black yellow--text text-center pa-4"
          :class="{ 'text-subtitle-2': $vuetify.breakpoint.smAndDown }"
        >
          MINT NOW AND GET ONE OF OUR UNIQUE SPACE APES
        </v-col>
        <v-col cols="12" md="6" style="line-height: 0">
          <img
            src="@/assets/mint/EDITED_mint-1.jpg"
            width="100%"
            class="hero"
          />
        </v-col>
        <v-col cols="12" md="6" style="line-height: 0">
          <img
            src="@/assets/mint/EDITED_mint-2.jpg"
            width="100%"
            class="hero"
          />
        </v-col>
        <v-col
          cols="12"
          class="d-flex align-center justify-center black white--text text-center pa-4"
          :class="{ 'text-subtitle-2': $vuetify.breakpoint.smAndDown }"
        >
          LEARN MORE >
        </v-col>
      </v-row>
    </a>

    <img
      v-if="!$isMobile()"
      class="promo-top"
      src="@/assets/mint/1000Blocks-SpaceApes-Banner1_1200x90.jpg"
      width="100%"
    />

    <img
      v-if="$isMobile()"
      class="promo-top"
      src="@/assets/mint/1000Blocks-SpaceApes-Banner1_550x174.jpg"
      width="100%"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConnectButton from "../cross-space/ConnectButton.vue";

export default {
  components: {
    ConnectButton,
  },
  computed: {
    ...mapState("mint", ["referredUser"]),
    ...mapState("wallet", ["connected"]),
  },
};
</script>

<style lang="scss" scoped>
$base-color: #0c9690;

.mobile-grid {
  padding-bottom: 86px;
}
.grid {
  margin: 24px;
  display: flex;
  flex-wrap: wrap;

  &:after {
    content: "";
    flex: auto;
    border-bottom: 1px solid $base-color;
  }
}

// .hero,
.box {
  border-left: 1px solid $base-color;
  border-bottom: 1px solid $base-color;
}

.hero {
  // height: 150px;
  width: 100%;
  flex: 1 0 auto;
}

.box {
  position: relative;
  flex-basis: calc(100% / 30);
  box-sizing: border-box;
  aspect-ratio: 1/1;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'xs-only')} {
  .grid {
    margin: 12px;
  }
  .box {
    flex-basis: calc(100% / 12);
  }
}

@media #{map-get($display-breakpoints, 'sm-only')} {
  .grid {
    margin: 12px;
  }
  .box {
    flex-basis: calc(100% / 16);
  }
}

@media #{map-get($display-breakpoints, 'md-only')} {
  .box {
    flex-basis: calc(100% / 22);
  }
}

@media #{map-get($display-breakpoints, 'lg-only')} {
  .box {
    flex-basis: calc(100% / 26);
  }
}

@media #{map-get($display-breakpoints, 'xl-only')} {
}
</style>
