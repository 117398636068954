<template>
  <div class="draw-panel-grid">
    <div class="info-box black d-flex flex-column align-center">
      <img src="@/assets/UI-icon_TAP.svg" />
      <p class="ma-0">Tap on the space to start claiming.</p>
      <p class="ma-0 grey--text">
        You can claim max {{ maxArea }} blocks at once.
      </p>
    </div>
    <v-btn
      large
      color="accent darken-1 primary--text pa-2"
      v-if="mode === 'draw'"
      @click="claimBlocks()"
      >Confirm</v-btn
    >
    <v-btn large color="primary pa-2" @click="setMode('view')">Cancel</v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    maxArea: Number,
  },
  computed: {
    ...mapState("mode", ["mode"]),
  },
  methods: {
    ...mapActions("mode", ["setMode"]),

    claimBlocks() {
      this.setMode("confirm");
    },
  },
};
</script>

<style scoped lang="scss">
.draw-panel-grid {
  display: grid;
  grid-template-rows: 6fr 2fr;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  padding-top: 2px;

  .info-box {
    grid-column: 1 / span 2;
  }
}
</style>
