<template>
  <v-card
    class="mb-5 black"
    style="box-sizing: border-box; border: 1px solid grey !important;"
    elevation="5"
    :width="width"
    :key="poster.posterUniqueId"
  >
    <v-card-title
      class="d-flex flex-row align-center justify-space-between pa-2"
      style="border-bottom: 1px solid grey"
    >
      <div class="d-flex flex-row align-center justify-center">
        <v-icon
          class="accent--text text--darken-2 mr-2"
          dense
          v-if="!this.profilePicture"
          >mdi-circle
        </v-icon>
        <v-avatar
          v-else
          :size="$vuetify.breakpoint.mobile ? '28' : '36'"
          :style="{ border: `3px solid ${this.hexBorder}`, cursor: 'pointer' }"
          class="mr-2"
          @click="toPublicProfile(poster.walletAddress)"
        >
          <img :src="this.profilePicture" alt="profile icon" />
        </v-avatar>
        <div class="grey--text text-body-2" v-if="!this.displayName">
          {{ poster.posterUniqueId.substring(0, 4) }}...{{
            poster.posterUniqueId.substring(poster.posterUniqueId.length - 4)
          }}
        </div>
        <span
          v-else
          style="cursor:pointer;"
          @click="toPublicProfile(poster.walletAddress)"
          :class="
            $vuetify.breakpoint.mobile
              ? 'grey--text text-caption'
              : 'grey--text text-body-2'
          "
          >{{ this.displayName }}</span
        >
      </div>
      <div
        class="d-flex flex-row align-center justify-center"
        style="font-size: 12px;"
      >
        <div class="mx-2">
          <v-icon class="grey--text text--darken-2 " dense
            >mdi-calendar-month
          </v-icon>
          {{ poster.timestamp | formatDate }}
        </div>
        <div class="mx-2">
          <v-icon class="grey--text text--darken-2 " dense>mdi-square </v-icon>
          {{ blocksCount(poster.blockStart, poster.blockEnd) }}
        </div>
        <v-icon class="copy" dense @click="copyUrl()">
          mdi-content-copy
        </v-icon>
      </div>
    </v-card-title>
    <div class="d-flex flex-column align-center justify-center">
      <img
        :src="poster.publicUrlBig"
        :class="$vuetify.breakpoint.mobile ? 'image-mobile' : 'image'"
        @click="oneClick(poster.posterUniqueId)"
      />
    </div>

    <v-card-subtitle
      class="d-flex flex-row align-center justify-center"
      v-if="poster.imageText"
    >
      <div :style="$isMobile() && 'max-width: 325px;'">
        {{ poster.imageText }}
      </div>

      <v-icon
        class="grey--text text--darken-2 mr-2"
        style="cursor:pointer;"
        dense
        v-if="isUrl(poster.imageText)"
        @click="openUrl(poster.imageText)"
        >mdi-chevron-right
      </v-icon>
    </v-card-subtitle>
    <v-card-text
      class="top-border"
      :class="
        $vuetify.breakpoint.mobile
          ? 'd-flex flex-column align-center justify-space-between pa-2 ma-0'
          : 'd-flex flex-row align-center justify-space-between pa-2 ma-0'
      "
    >
      <div
        class="d-flex flex-row align-center justify-center mx-2"
        style="font-size: 12px;"
      >
        <v-icon class="grey--text text--darken-2 mr-2" dense
          >mdi-thumb-up mdi-flip-h
        </v-icon>
        {{ poster.likes }}
      </div>
      <v-card-actions
        :style="$vuetify.breakpoint.mobile && 'width: 100% !important;'"
        :class="
          $vuetify.breakpoint.mobile
            ? 'd-flex flex-row align-center justify-center pa-0 mt-2'
            : 'pa-0 ma-0'
        "
      >
        <v-btn
          :class="
            alreadyLiked
              ? 'grey darken-4 black--text'
              : 'yellow darken-1 black--text'
          "
          :width="$vuetify.breakpoint.mobile ? '50%' : '125'"
          text
          v-if="connected"
          @click="likePoster(poster.posterUniqueId)"
          :loading="
            likesInProgress === true &&
              posterIdClicked === poster.posterUniqueId
          "
        >
          <span v-if="!alreadyLiked">LIKE</span>
          <v-icon v-else class="yellow--text text--darken-2 mr-2" dense
            >mdi-thumb-up mdi-flip-h
          </v-icon>
        </v-btn>
        <v-btn
          class="accent darken-1 black--text"
          :width="$vuetify.breakpoint.mobile ? '50%' : '125'"
          text
          v-if="connected"
          @click="tweet()"
          >TWEET</v-btn
        >
        <div v-if="!connected">
          <ConnectButton mini />
        </div>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ethers } from "ethers";
import moment from "moment";

import * as posterService from "../../services/posterService";
import * as profileService from "../../services/profileService";
import ConnectButton from "./ConnectButton.vue";

export default {
  name: "Poster",
  props: ["poster"],
  components: {
    ConnectButton,
  },
  data() {
    return {
      imageHeight: this.$isMobile() ? "auto" : "auto",
      posterIdClicked: "",
      isLikePressed: false,
      likesInProgress: false,
      isActive: true,
      delay: 700,
      clicks: 0,
      timer: null,
      alreadyLiked: false,
      profilePicture: "",
      displayName: "",
      hexBorder: "",
    };
  },
  computed: {
    ...mapState("wallet", ["connected", "account"]),
    ...mapState("space", ["currentSpace"]),
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return 600;
        case "md":
          return 700;
        case "lg":
          return 800;
        case "xl":
          return 800;
        default:
          return 220;
      }
    },
  },
  methods: {
    ...mapActions("space", ["loadTimeline", "loadPoster"]),
    ...mapActions("error", ["setErrorMessage", "setSuccessMessage"]),
    toPublicProfile(address) {
      const encodedAddress = btoa(address);
      this.$router.push(`/profile/${encodedAddress}`);
    },
    isPoster() {
      return this.$route.name === "Poster";
    },
    isUrl(s) {
      //eslint-disable-next-line
      const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
      return regexp.test(s);
    },
    copyUrl() {
      window.navigator.clipboard
        .writeText(
          `https://app.1000blocks.space/${this.$route.params.spaceSlug}/${this.poster.posterUniqueId}`
        )
        .then(
          () => {
            this.$store.dispatch(
              "error/setSuccessMessage",
              "Copied to clipboard!"
            );
          },
          (err) => {
            this.$store.dispatch("error/setErrorMessage", err.message);
          }
        );
    },
    openUrl(url) {
      window.open(url, "_newtab" + Date.now());
    },
    tweet() {
      let shareURL = "http://twitter.com/share?"; //url base
      //params
      const params = {
        url: `https://app.1000blocks.space/${this.$route.params.spaceSlug}/${this.poster.posterUniqueId}`,
        text: this.poster.imageText || "COMMUNITY POWERED NFT-CREATION",
        via: "1000Blocks",
        hashtags:
          "nft,blockchain,bls,1000blocks,binance,bnb,bsc,binancesmartchain",
      };

      for (let prop in params)
        shareURL += "&" + prop + "=" + encodeURIComponent(params[prop]);
      window.open(
        shareURL,
        "",
        "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
      );
    },
    oneClick(posterUniqueId) {
      // Redirect to poster view
      if (this.$route.name === "Timeline") {
        this.$router.push(`/${this.$route.params.spaceSlug}/${posterUniqueId}`);
        return;
      }

      if (this.$route.name === "CanvasWrapper") {
        this.$router.push(`/${this.$route.params.spaceSlug}/${posterUniqueId}`);
        return;
      }

      // Else
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(function() {
          this.clicks = 0;
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        if (this.connected) {
          this.likePoster(posterUniqueId);
        } else {
          this.$store.dispatch(
            "error/setErrorMessage",
            "Please connect your wallet first to like posters"
          );
        }
        this.clicks = 0;
      }
    },
    blocksCount(blockStart, blockEnd) {
      const width = blockEnd.x - blockStart.x + 1;
      const height = blockEnd.y - blockStart.y + 1;
      return width * height;
    },
    async likePoster(posterUniqueId) {
      this.isLikePressed = true;
      this.likesInProgress = true;
      this.posterIdClicked = posterUniqueId;

      const address = ethers.utils.getAddress(this.account);

      try {
        const returnData = await posterService.likePoster(
          this.currentSpace.spaceId,
          address,
          posterUniqueId
        );

        if (!returnData.code === 0) {
          this.$store.dispatch("error/setErrorMessage", returnData.message);
        } else {
          this.alreadyLiked = true;

          if (this.isPoster()) {
            this.loadPoster({
              spaceId: this.currentSpace.spaceId,
              posterId: this.$route.params.posterId,
            });
          }

          if (this.$route.name === "Timeline") {
            this.loadTimeline({
              spaceId: this.currentSpace.spaceId,
              latestN: this.currentSpace.timeline.length,
              order: "desc",
            });
          }
        }
      } catch (err) {
        this.$store.dispatch(
          "error/setErrorMessage",
          err.response.data.message
        );
      } finally {
        this.likesInProgress = false;
        this.posterIdClicked = "";
      }
    },
  },
  filters: {
    formatDate: function(date) {
      return moment(date).format("DD/MM/YY");
    },
  },
  async created() {
    const account = await profileService.getProfile(this.poster.walletAddress);

    this.profilePicture = account.profilePicture;
    this.displayName = account.displayName;
    this.hexBorder = account.hexBorder;
  },
};
</script>

<style lang="scss" scoped>
.image {
  max-width: -webkit-fill-available;
  object-fit: contain;
  box-sizing: border-box;
  padding: 20px 20px;
  opacity: 1;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.image-mobile {
  width: 100%;
  height: 100%;
  max-height: 500px;
}

.copy {
  margin-left: 5px;
  color: grey;

  &:hover {
    color: white !important;
    cursor: pointer;
  }
}
</style>
