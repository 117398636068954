<template>
  <v-dialog v-model="onboardingDialog" persistent width="800">
    <v-card outlined>
      <v-card-title class="d-flex flex-column align-center">
        <h1 class="text-h5 text-uppercase accent--text mt-2">
          Welcome to {{ subtitle }} space
        </h1>
        <h2 class="text-subtitle-1 yellow--text my-2" v-if="!entryGranted">
          Exclusive Space
        </h2>
        <!-- <p>{{account}}</p> -->
      </v-card-title>
      <v-card-text class="d-flex flex-column align-center" v-if="!nextStep">
        <v-img class="ma-8" :src="spaceIconUrl" width="200" contain></v-img>

        <div class="d-flex flex-column align-center ma-4">
          <p class="ma-0">This is a members only SPACE.</p>
          <template v-if="membershipType == 'ownsOnchain'">
            <p class="ma-0 mb-4">
              Your wallet {{readableAddress}} needs to hold at least one NFT Alpie.
            </p>
            <v-progress-circular
              class="ma-4"
              indeterminate
              v-if="loading"
              color="primary"
            ></v-progress-circular>
              <v-btn
                color="success"
                class="black--text font-weight-bold"
                block
                v-if="entryGranted === true"
                @click="goFurther"
              >
                Access granted
              </v-btn>
              <v-btn
                color="error"
                class="black--text font-weight-bold"
                block
                v-if="entryGranted === false"
                href="https://nftrade.com/assets/bsc/0x57a7c5d10c3f87f5617ac1c60da60082e44d539e"
                target="_blank"
              >
                Your wallet does not own Alpies, go get one
              </v-btn>
              <div v-if="!connected">
                <ConnectButton mini />
              </div>
          </template>
         
          <p v-if="membershipType == 'twitter'" class="ma-0">
            To gain access follow
            <a
              :href="fullTwitterLink"
              target="_blank"
              class="white--text"
              >{{ subtitle }} Twitter</a
            >
            and
            <a
              href="https://twitter.com/1000Blocks"
              target="_blank"
              class="white--text"
              >1000blocks Twitter</a
            >
          </p>
        </div>
        <div v-if="membershipType == 'twitter'">
          <v-text-field mclass="mb-0" outlined v-model="twitterHandleInput">
            <template v-slot:append>
              <p class="ma-1">Your Twitter handle</p>
            </template>
          </v-text-field>
          <v-btn
            color="accent darken-1"
            class="black--text font-weight-bold"
            block
            v-if="entryGranted === undefined"
            @click="checkEligibility"
          >
            Check eligibility
          </v-btn>
          <v-btn
            color="success"
            class="black--text font-weight-bold"
            block
            v-if="entryGranted === true"
            @click="goFurther"
          >
            Approved
          </v-btn>
          <v-btn
            color="error"
            class="black--text font-weight-bold"
            block
            v-if="entryGranted === false"
          >
            Access denied
          </v-btn>
        </div>
      </v-card-text>

      <v-card-text class="d-flex flex-column align-center" v-if="nextStep">
        <h2 class="text-subtitle-1 yellow--text my-2">
          Rules of the SPACE
        </h2>
        <v-container>
          <v-row width="100%" align="center">
            <v-col
              cols="12"
              sm="6"
              v-for="rule in rules"
              :key="rule.title"
              align-self="stretch"
            >
              <v-container class="rule-card ma-2 pa-2" style="height: 100%">
                <v-row style="height: 100%">
                  <v-col cols="3" class="ma-0" align-self="center">
                    <v-img width="100%" :src="rule.image" contain></v-img>
                  </v-col>
                  <v-col cols="9">
                    <h1 class="yellow--text text-subtitle-2 text-uppercase">
                      {{ rule.title }}
                    </h1>
                    <p class="text-body-2 ma-0">{{ rule.content[0] }}</p>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="d-flex flex-column align-center" v-if="nextStep">
        <v-container>
          <v-row width="100%">
            <v-col cols="6" offset="3">
              <v-btn
                color="accent darken-1"
                class="black--text font-weight-bold"
                block
                @click="continueToSpace"
              >
                Got it!
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ConnectButton from "../../components/cross-space/ConnectButton.vue";
export default {
  components: {
    ConnectButton
  },
  props: {
    subtitle: String,
    spaceIconUrl: String,
    memberCheck: Function,
    membershipType: String,
    spaceId: Number,
    rules: Array,
    twitterHandle: String,
  },
  data() {
    return {
      onboardingDialog: true,
      loading: false,
      nextStep: false,
      entryGranted: undefined,
      twitterHandleInput: "",
    };
  },

  async mounted() {
    const alreadyGranted = this.accessGranted;
    if (alreadyGranted) {
      this.onboardingDialog = false;
    }

    if(this.membershipType == 'ownsOnchain' && this.connected){
      await this.checkEligibilityOwnsOnchain();
    }
  },
  watch: {
    async connected(currentState) {
      if(currentState){
        await this.checkEligibilityOwnsOnchain();
      }
    },
  },
  computed: {
    ...mapState("wallet", ["account", "connected"]),
    ...mapGetters("wallet", ["readableAddress"]),
    localStorageName() {
      return `accessgranted-space-${this.spaceId}`;
    },
    accessGranted() {
      return localStorage[this.localStorageName] || false;
    },
    fullTwitterLink(){
      return `https://twitter.com/${this.twitterHandle}`;
    }
  },
  methods: {

    continueToSpace() {
      this.onboardingDialog = false;
    },
    goFurther() {
      this.nextStep = true;
    },
    storeAccessGrant() {
      localStorage[this.localStorageName] = true;
    },
    async checkEligibilityOwnsOnchain(){
        this.loading = true;
        let check = await this.memberCheck(this.account);
        this.loading = false;
        this.entryGranted = check;
        if (this.entryGranted) {
          this.storeAccessGrant();
          setTimeout(() => {
            this.nextStep = true;
          }, 2000);
        }
    },
    async checkEligibility() {
      this.loading = true;
      let check = await this.memberCheck(this.twitterHandleInput, this.twitterHandle);
      this.loading = false;
      this.entryGranted = check;
      if (this.entryGranted) {
        this.storeAccessGrant();
        setTimeout(() => {
          this.nextStep = true;
        }, 2000);
      } else {
        setTimeout(() => {
          this.entryGranted = undefined;
        }, 5000);
      }
    },
  },
};
</script>

<style>
.v-overlay.v-overlay--active {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.1);
}

.rule-card {
  background: #000;
}
</style>
