<template>
  <div
    :class="
      $vuetify.breakpoint.mobile
        ? 'd-flex flex-column align-start justify-center pa-3'
        : 'd-flex flex-row align-start justify-center pa-5'
    "
  >
    <v-card
      :width="width"
      :class="$vuetify.breakpoint.mobile ? 'mb-5' : 'mr-5'"
      style="box-sizing: border-box; border: 1px solid grey; background: black;"
      elevation="5"
      :loading="loading"
    >
      <v-card-title
        :class="$vuetify.breakpoint.mobile && 'pa-2'"
        style="border-bottom: 1px solid grey;"
      >
        <v-icon class="accent--text text--darken-2 mr-4" dense
          >mdi-circle
        </v-icon>
        <span
          :class="
            this.$vuetify.breakpoint.mobile
              ? 'accent--text text-body-2'
              : 'accent--text text-h6'
          "
        >
          PUBLIC PROFILE
        </span>
      </v-card-title>
      <div
        class="d-flex flex-row align-center justify-center pa-5"
        style="border-bottom: 1px solid grey;"
      >
        <v-avatar
          size="180"
          color="grey darken-2"
          :style="{ border: this.hexBorder }"
        >
          <img v-if="this.profilePicture !== ''" :src="this.profilePicture" />
          <img v-else src="@/assets/no_nft.png" />
        </v-avatar>
      </div>
      <v-card-text
        :class="
          $vuetify.breakpoint.mobile
            ? 'd-flex flex-row align-end justify-center text-center pa-2 '
            : 'd-flex flex-row align-end justify-center text-center'
        "
        style="border-bottom: 1px solid grey;"
      >
        <div
          class="d-flex flex-column align-left justify-space-between"
          style="overflow: hidden;"
        >
          <span class="yellow--text text-subtitle-1">DISPLAY NAME</span>
          <span class="white--text text-h6" v-if="!this.displayName">
            @NAME
          </span>
          <span
            class="white--text text-h6"
            style="white-space: nowrap;"
            v-else
            >{{ this.displayName }}</span
          >
        </div>
      </v-card-text>
      <v-card-text
        :class="
          $vuetify.breakpoint.mobile
            ? 'd-flex flex-row align-end justify-center text-center pa-2'
            : 'd-flex flex-row align-end justify-center text-center'
        "
        style="border-bottom: 1px solid grey;"
      >
        <div class="d-flex flex-column align-left justify-space-between">
          <span class="yellow--text text-subtitle-1">TWITTER HANDLE</span>
          <span class="white--text text-h6" v-if="this.twitterHandle === ''"
            >@HANDLE</span
          >
          <span class="white--text text-h6" v-else>{{
            this.twitterHandle
          }}</span>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getProfile } from "../services/profileService";

export default {
  name: "PublicProfile",
  data: () => ({
    loading: true,
    profilePicture: "",
    hexBorder: "5px solid #00a79f !important",
    displayName: "",
    twitterHandle: "",
    readableAddress: "",
  }),
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return 500;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
        default:
          return 700;
      }
    },
  },
  methods: {
    ...mapActions("error", ["setErrorMessage", "setSuccessMessage"]),
  },
  created() {
    const decodedAddress = atob(this.$route.params.uid);
    getProfile(decodedAddress)
      .then((profile) => {
        this.displayName = profile.displayName;
        this.hexBorder = `5px solid ${profile.hexBorder} !important`;
        this.profilePicture = profile.profilePicture;
        this.twitterHandle = profile.twitterHandle;
        this.loading = false;
      })
      .catch((err) => {
        this.setErrorMessage(err.response.data.message);
      });
  },
};
</script>
