import store from "../store"

export async function calculateBlockPrices(spaceContract, startBlockId, endBlockId) {
  const prices = await spaceContract.getPricesOfBlocks(`${startBlockId}`, `${endBlockId}`);
  return prices;
}

export async function buyBlockArea(spaceContract, startBlockId, endBlockId, hash, price, takeoverPrice) {
  let transaction;
  if (takeoverPrice) {
    transaction = await spaceContract.purchaseBlocksArea(startBlockId, endBlockId, hash, takeoverPrice, {value: price});
  } else {
    transaction = await spaceContract.purchaseBlocksArea(startBlockId, endBlockId, hash, {value: price});
  }

  // Wait - blockhain is good for a lot of things, just not for speed (loader)
  // Receive an event when ANY transfer occurs
  spaceContract.on("BlocksAreaPurchased", (posterOwner) => {
    // Check wallet address, to be the same as the event posterOwner
    // Take care of case sensitivity
    if (posterOwner.toLowerCase() === store.state.wallet.account.toLowerCase()) {
      return;
    }
  });

  const receipt = await transaction.wait();

  if (!receipt.status) {
    throw Error("Transaction was not successful");
  }
}
