<template>
  <div>
    <v-group ref="blockArea" :config="{ preventDefault: false }">
      <v-rect
        :config="background"
        @mouseover="selectPoster"
        @mouseout="unselectPoster"
        @tap="openBlockArea()"
        @click="openBlockArea()"
        :preventDefault="false"
      />
      <v-image :config="imageConf" :preventDefault="false" />
    </v-group>
    <PosterDialog v-if="dataUrl" v-model="blockAreaDialog" :poster="poster" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Konva from "konva";
import PosterDialog from "../dialogs/PosterDialog.vue";

export default {
  components: {
    PosterDialog,
  },
  props: {
    poster: Object,
    blockStart: {
      x: Number,
      y: Number,
    },
    blockEnd: {
      x: Number,
      y: Number,
    },
    imageHash: String,
    publicUrl: String,
    publicUrlBig: String,
    walletAddress: String,
    z: Number,

    id: Number,
    src: String,
    x: Number,
    y: Number,
    blockSize: Number,

    imageText: String,
    spaceId: String,
    posterUniqueId: String,
    likes: Number,

    imageElement: HTMLImageElement,
    dataUrl: String,

    scrolling: Boolean,
  },

  data() {
    return {
      blockAreaDialog: false,
      img: this.imageElement,
    };
  },

  computed: {
    ...mapState("mode", ["mode"]),
    ...mapState("space", ["currentSpace"]),

    blockArea() {
      return {
        x: this.blockStart.x * this.blockSize,
        y: this.blockStart.y * this.blockSize,
        width: (this.blockEnd.x - this.blockStart.x + 1) * this.blockSize,
        height: (this.blockEnd.y - this.blockStart.y + 1) * this.blockSize,
      };
    },

    background() {
      return {
        ...this.blockArea,
        fill: "black",
        shadowOpacity: 0.4,
      };
    },

    imageConf() {
      let img = this.img;

      if (img) {
        const scaleX = this.blockArea.width / img.naturalWidth;
        const scaleY = this.blockArea.height / img.naturalHeight;
        const scale = Math.min(scaleX, scaleY);

        const width = img.naturalWidth * scale;
        const height = img.naturalHeight * scale;

        const x = this.blockArea.x + (this.blockArea.width - width) / 2;
        const y = this.blockArea.y + (this.blockArea.height - height) / 2;

        return {
          x,
          y,
          width,
          height,
          image: this.img,
          listening: false,
        };
      } else {
        return {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
          image: null,
          listening: false,
        };
      }
    },

    textLength() {
      return this.imageText ? this.imageText.length : 0;
    },

    expandLabelBy() {
      const minBlockWidth = this.blockEnd.x - this.blockStart.x + 1;
      const neededPadding = Math.ceil(
        (this.textLength / 10 - minBlockWidth) / 2
      );
      return Math.max(0, neededPadding);
    },

    labelWidth() {
      const minBlockWidth = this.blockEnd.x - this.blockStart.x + 1;
      return minBlockWidth + this.expandLabelBy * 2;
    },

    labelOnBottom() {
      return this.blockEnd.y != 23;
    },
  },

  methods: {
    loadImage(src) {
      return new Promise((resolve) => {
        const image = new window.Image();
        image.src = src;
        image.onload = () => {
          this.img = image;
          resolve();
        };
      });
    },

    selectPoster() {
      const layer = this.$refs.blockArea.getNode().getLayer();
      this.showSelection(layer);
      if (this.textLength > 0) {
        this.showLabel(layer);
      }

      layer.draw();
    },

    unselectPoster() {
      const layer = this.$refs.blockArea.getNode().getLayer();
      this.hideSelection(layer);
      this.hideLabel(layer);

      layer.draw();
    },

    showSelection(layer) {
      const selection = new Konva.Rect({
        x: this.blockArea.x + 1,
        y: this.blockArea.y + 1,
        width: this.blockArea.width - 2,
        height: this.blockArea.height - 2,
        stroke: "white",
        strokeWidth: 2,
        name: "blockAreaSelection",
      });
      selection.hitFunc(function() {}); // Remove the hitbox to pass through events
      layer.add(selection);
    },

    hideSelection(layer) {
      layer
        .getChildren((node) => node.hasName("blockAreaSelection"))
        .forEach((label) => label.destroy());
    },

    showLabel(layer) {
      const label = new Konva.Label({
        x: (this.blockStart.x - this.expandLabelBy) * this.blockSize,
        y: this.labelOnBottom
          ? (this.blockEnd.y + 1) * this.blockSize
          : (this.blockStart.y - 1) * this.blockSize,
        width: this.labelWidth * this.blockSize,
        height: this.blockSize,
        name: "blockAreaLabel",
      });

      label.add(
        new Konva.Tag({
          fill: "white",
        })
      );

      label.add(
        new Konva.Text({
          fontSize: 10,
          fill: "black",
          align: "center",
          verticalAlign: "middle",
          width: this.labelWidth * this.blockSize,
          height: this.blockSize,
          opacity: 1,
          text: this.imageText,
        })
      );

      layer.add(label);
    },

    hideLabel(layer) {
      layer
        .getChildren((node) => node.hasName("blockAreaLabel"))
        .forEach((label) => label.destroy());
    },

    openBlockArea() {
      if (this.mode != "draw" && this.mode != "confirm" && !this.scrolling) {
        this.$analytics.logEvent("block_area_view", {
          value: this.walletAddress,
          walletAddress: `"${this.walletAddress}"`,
          z: this.z,
          url: this.publicUrlBig,
        });
        this.blockAreaDialog = true;
      }
    },
  },

  async mounted() {
    if (!this.img) {
      await this.loadImage(this.publicUrl);
    }
  },
};
</script>

<style></style>
