<template>
  <v-row>
    <!-- <v-col md="4" lg="3" cols="12" class="d-flex justify-stretch">
      <PromoTile />
    </v-col> -->
    <v-col
      md="4"
      lg="6"
      cols="12"
      v-for="(pool, index) in poolsList"
      :key="index"
    >
      <PoolTile v-bind="pool" />
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
import PoolTile from "./PoolTile.vue";
export default {
  components: {
    PoolTile,
  },
  data() {
    return {
      poolsList: [
        {
          name: "$BLS",
          active: true,
          image: require("@/assets/mock3.png"),
          to: "/space-02",
        },
      ],
    };
  },
};
</script>

<style></style>
