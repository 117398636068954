<template>
  <div class="">
    <!-- MOBILE -->
    <MintToolbar class="mobile-menu pt-2 pb-2 black" v-if="$isMobile()" />

    <router-view :class="{ 'lower-top': $isMobile() }"></router-view>
  </div>
</template>

<script>
import MintToolbar from "../../components/mint/MintToolbar.vue";

export default {
  components: {
    MintToolbar,
  },
};
</script>

<style lang="scss">
.lower-top {
  padding-top: 64px;
}
</style>
