<template>
  <v-label :config="labelConf">
    <v-text :config="priceConf" />
  </v-label>
</template>

<script>
import { truncate } from "../../services/helpers";
import blockService from "../../services/blockService";

export default {
  props: {
    blockId: Number,
    priceString: String,
    blockSize: Number,
  },
  computed: {
    labelConf() {
      const blockCoor = blockService.blockLocation(this.blockId);
      return {
        x: blockCoor.x * this.blockSize,
        y: blockCoor.y * this.blockSize,
        width: this.blockSize,
        height: this.blockSize,
      };
    },

    priceConf() {
      const blockCoor = blockService.blockLocation(this.blockId);

      return {
        x: blockCoor.x * this.blockSize,
        y: blockCoor.y * this.blockSize,
        fontSize: 8,
        fill: "black",
        align: "center",
        verticalAlign: "middle",
        width: this.blockSize,
        height: this.blockSize,
        opacity: this.$isMobile() ? 1 : 0.9,
        text: truncate(this.priceString, 4, false),
      };
    },
  },
};
</script>

<style></style>
