<template>
  <div>
    <v-stage
      :config="configKonva"
      ref="stage"
      @click="initiateStage"
      @tap="initiateStage"
      :style="{
        background: 'black',
        border: '1px solid cyan',
      }"
    >
      <v-layer :key="componentKey">
        <div v-for="(row, index) in grid" :key="index">
          <Block
            v-for="(block, blockIndex) in row"
            :key="blockIndex"
            :rowIndex="index"
            :blockIndex="blockIndex"
            :x="block.x"
            :y="block.y"
            :blockSize="blockSize"
            :color="color"
            :prevColor="block.prevColor"
            :modifyGrid="modifyGrid"
            ref="childComponentRef"
          />
        </div>
      </v-layer>
    </v-stage>
    <v-btn class="mr-2 mt-2" @click="this.clearGrid">Clear</v-btn>
    <v-btn class="mr-2 mt-2" @click="this.getDownload">Download</v-btn>
    <!-- <v-btn class="mr-2 mt-2" @click="this.useExistingDesign">Save Grid</v-btn> -->
    <v-btn
      class="mt-2"
      @click="this.randomize"
      v-if="this.size === 26 || this.size === 28"
      >Randomize</v-btn
    >
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  chicken26x26,
  monkey26x26,
  hippo26x26,
} from "./pixel-designs/random26x26.js";
import {
  fish28x28,
  ghost28x28,
  jellyfish28x28,
} from "./pixel-designs/random28x28.js";
import Block from "./PixelBlock.vue";

export default {
  name: "Grid",
  components: {
    Block,
  },

  props: {
    size: {
      type: Number,
      required: true,
      default: 24,
    },
    blockSize: {
      type: Number,
      required: true,
      default: 100,
    },
    color: {
      type: String,
      required: true,
      default: "rgba(0, 0, 0, 1)",
    },
  },
  data() {
    return {
      componentKey: 0,
      configKonva: {
        width: this.size * this.blockSize,
        height: this.size * this.blockSize,
      },
      grid: [],
    };
  },
  computed: {
    ...mapState("pixel", ["stage"]),
  },
  methods: {
    ...mapActions("pixel", ["setStage"]),

    clearGrid() {
      // set all blocks to rgba default
      this.$root.$emit("reset");
      // Remove localStorage items
      localStorage.removeItem("gridBackup");
      localStorage.removeItem("pixelBase64");

      this.generateGrid();
    },
    useExistingDesign() {
      // Creating a blob object from non-blob data using the Blob constructor
      const blob = new Blob([JSON.stringify(this.grid)], {
        type: "application/json",
      });
      const url = URL.createObjectURL(blob);
      // Create a new anchor element
      const a = document.createElement("a");
      a.href = url;
      a.download = "download";
      a.click();
      a.remove();
    },
    randomize() {
      this.generateGrid();
      let choices = [];

      if (this.size === 26) {
        choices = [chicken26x26, monkey26x26, hippo26x26];
      }

      if (this.size === 28) {
        choices = [jellyfish28x28, ghost28x28, fish28x28];
      }

      const random = Math.floor(Math.random() * choices.length);
      this.grid = choices[random];

      this.componentKey += 1;
    },
    getDownload() {
      if (this.stage) {
        let dataURL = this.stage.toDataURL({
          pixelRatio: 1,
        });
        this.downloadImage(dataURL, "pixelArt.jpeg");
      }
    },
    downloadImage(uri, name) {
      let link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    generateGrid() {
      const size = this.size;
      let grid = new Array(size);

      // For each row
      for (let row = 0; row < size; row++) {
        grid[row] = [];

        // Add block per column
        for (let block = 0; block < size; block++) {
          // Insert n size array of objects block
          grid[row].push({
            y: block * this.blockSize,
            x: row * this.blockSize,
            prevColor: "rgba(0, 0, 0, 1)",
          });
        }
      }

      return grid;
    },
    initiateStage(evt) {
      this.setStage(evt.target.getStage());
    },
    modifyGrid(row, block, color) {
      // Matrix update
      let updatedBlock = this.grid[row][block];
      updatedBlock.prevColor = color;

      let updatedRow = this.grid[row];
      updatedRow.splice(block, 1, updatedBlock);

      let newGrid = this.grid;
      newGrid[row] = updatedRow;

      // Set as new grid, back it up
      localStorage.setItem("gridBackup", JSON.stringify(newGrid));
      this.grid = newGrid;
    },
  },
  created() {
    const gridBackup = localStorage.getItem("gridBackup");

    if (gridBackup) {
      // Parse once
      const prevGrid = JSON.parse(gridBackup);
      // Only fill from previous if grid size matches
      if (prevGrid.length === this.size) {
        this.grid = JSON.parse(gridBackup);
      } else {
        this.grid = this.generateGrid();
      }
    } else {
      this.grid = this.generateGrid();
    }
  },
  mounted() {
    this.setStage(this.$refs.stage.getStage());
  },
};
</script>
