<template>
  <div
    :class="
      $vuetify.breakpoint.mobile
        ? 'black d-flex flex-column align-center justify-center pa-2'
        : 'black d-flex flex-column align-center justify-start pt-5'
    "
    style="height: 100%; width: 100%"
  >
    <SinglePoster v-if="currentSpace.poster" :poster="currentSpace.poster" />
    <v-progress-circular
      :size="50"
      :width="7"
      color="cyan"
      indeterminate
      class="ma-5"
      v-if="!currentSpace.poster"
    ></v-progress-circular>
    <span v-if="!currentSpace.poster">Loading poster</span>
    <v-btn
      v-if="!$vuetify.breakpoint.mobile && currentSpace.poster"
      class="grey darken-3 white--text mt-5"
      text
      @click="returnToSpace()"
    >
      BACK TO {{ spaceSlug }}
    </v-btn>
    <v-bottom-navigation
      grow
      app
      class="grey darken-3"
      v-if="$vuetify.breakpoint.mobile && currentSpace.poster"
    >
      <v-btn
        class="text-uppercase white--text text-button"
        text
        @click="returnToSpace()"
      >
        GO TO {{ spaceSlug }}</v-btn
      >
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Poster from "../components/cross-space/Poster.vue";

export default {
  name: "Poster",
  components: {
    SinglePoster: Poster,
  },
  metaInfo() {
    return {
      title: `1000Blocks.Space | ${this.spaceSlug.toUpperCase()} Participant`,
      meta: [
        {
          name: "title",
          content: `1000Blocks.Space | ${this.spaceSlug.toUpperCase()} Participant`,
        },
        {
          name: "description",
          content: `${this.imageText}`,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:url",
          content: `https://app.1000blocks.space/${this.spaceSlug}/${this.spaceId}/${this.posterId}`,
        },
        {
          property: "og:site_name",
          content: `1000Blocks.Space`,
        },
        {
          property: "og:title",
          content: `1000Blocks.Space | ${this.spaceSlug.toUpperCase()} Participant`,
        },
        {
          property: "og:description",
          content: `${this.imageText}`,
        },
        {
          property: "og:image",
          content: `${this.imageUrl}`,
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:url",
          content: `https://app.1000blocks.space/${this.spaceSlug}/${this.spaceId}/${this.posterId}`,
        },
        {
          property: "twitter:title",
          content: `1000Blocks.Space | ${this.spaceSlug.toUpperCase()} Participant`,
        },
        {
          property: "twitter:description",
          content: `${this.imageText}`,
        },
        {
          property: "twitter:image",
          content: `${this.imageUrl}`,
        },
        {
          name: "twitter:image:alt",
          content: "1000Blocks NFT Participant",
        },
        {
          name: "twitter:site",
          content: "@1000Blocks",
        },
      ],
    };
  },
  data() {
    return {
      spaceSlug: "",
      spaceId: null,
      posterId: null,
      imageText: "",
      imageUrl: "",
    };
  },
  computed: {
    ...mapState("space", ["currentSpace"]),
  },
  methods: {
    ...mapActions("space", ["loadPoster", "clearPoster"]),
    returnToSpace() {
      this.$router.push(`/${this.spaceSlug}`);
    },
  },
  beforeDestroy() {
    this.clearPoster(this.spaceId);
  },
  updated() {
    if (this.currentSpace.poster) {
      this.imageText = this.currentSpace.poster.imageText;
      this.imageUrl = this.currentSpace.poster.publicUrl;
    }
  },
  created() {
    this.spaceSlug = this.$route.params.spaceSlug;
    this.spaceId = this.currentSpace.spaceId;
    this.posterId = this.$route.params.posterId;

    this.loadPoster({
      spaceId: this.currentSpace.spaceId,
      posterId: this.$route.params.posterId,
    });
  },
};
</script>
