import { visibleImages } from '../services/imagesService'
import * as posterService from '../services/posterService'

const INIT_BLOCKS_COUNT = 1000;

export default {
  namespaced: true,
  state: {
    posters: [],
    initialBlocksCount: INIT_BLOCKS_COUNT,
    visiblePosters: [],
    loading: false
  },
  getters: {
    reversedPosters(state) {
      return state.posters.reverse();
    },
    sortedPosters(state) {
      return state.visiblePosters.sort((a,b) => a.z - b.z);
    },
  },
  mutations: {
    setPosters(state, posters) {
      state.posters = posters;
    },    
    
    setInitialBlocksCount(state, value) {
      state.initialBlocksCount = value;
    },

    setVisiblePosters(state, posters) {
      state.visiblePosters = posters;
    },
    

    loadingPosters(state, loading) {
      state.loading = loading;
    }
  },
  actions: {
    async fetchPosters({commit, dispatch}, spaceId) {
      commit("loadingPosters", true)

      const posters = await posterService.getPosters(spaceId);
      const reversePosters = posters.reverse();

      // Optimization filter
      const retObj = visibleImages(reversePosters, undefined, undefined, (poster) => poster.blockStart, (poster) => poster.blockEnd);

      commit("setPosters", posters);
      commit("setInitialBlocksCount", INIT_BLOCKS_COUNT - retObj.uniqueBlocksIndices);
      dispatch("preloadImages", retObj.filteredImages);
      dispatch("checkWalletForPoster")
    },

    async preloadImages({commit}, posters) {
            
      const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        }))

      const loadingImages = posters.map(poster => {
        return toDataURL(`${poster.publicUrl}?v=1`)
        .then(dataUrl => new Promise((resolve, reject) => {
          const image = new window.Image();
          image.src = dataUrl;
          image.onload = () => {
            resolve({...poster, imageElement: image, dataUrl});
          };
          image.onerror = () => {
            reject();
          };
        }))
        .catch(err => console.log(err))
      });

      try {
        const postersWithImages = await Promise.all(loadingImages);
        commit("setVisiblePosters", postersWithImages);
      } finally {
        commit("loadingPosters", false);
      }
    },

    checkWalletForPoster({commit, state, rootState}) {
      if (rootState.wallet.account) {
        const accountPoster = state.posters.find(poster => poster.walletAddress.toLowerCase() === rootState.wallet.account.toLowerCase());
        if (accountPoster) {
          commit("wallet/hasBlockArea", {blockStart: accountPoster.blockStart, blockEnd: accountPoster.blockEnd}, {root: true})
        }
      }
    }
  }
};