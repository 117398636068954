// WIP: Optimize the display by figuring out which image is visible and loading only visible ones
function visibleImages(images, width, height, start, end) {
  // Set defaults
  if (width === undefined) width = 42;
  if (height === undefined) height = 24;
  if (start === undefined) start = (image) => image.start;
  if (end === undefined) end = (image) => image.end;

  let filteredImages = [];
  let uniqueBlocksIndices = {};

  // Create a full size blocks array
  let blocks = {};
  for (let i = 0; i < width; i++) {
    for (let j = 0; j < height; j++) {
      blocks[translateCoorsToId(i, j)] = true;
    }
  }

  // Go through all images top down
  for (const image of images) {
    let imageBlocks = [];
    let hasVisibleBlocks = false;

    // Check every block of an image
    for (const x of range(start(image).x, end(image).x, 1)) {
      for (const y of range(start(image).y, end(image).y, 1)) {
        uniqueBlocksIndices[x + "-" + y] = true;
        imageBlocks.push(translateCoorsToId(x, y));

        // If the block is not yet covered, set is covered and make the image visible
        if (blocks[translateCoorsToId(x, y)] !== undefined) {
          delete blocks[translateCoorsToId(x, y)];
          hasVisibleBlocks = true;
        }
      }
    }

    // If image is visible, do not filter it out
    if (hasVisibleBlocks) {
      filteredImages.push(image);
    }

    // If there are no more blocks to be visible, stop the check
    if (Object.keys(blocks).length === 0) {
      break;
    }
  }

  let retObj = {
    filteredImages: filteredImages,
    uniqueBlocksIndices: Object.keys(uniqueBlocksIndices).length,
  };

  return retObj;
}

function translateCoorsToId(x, y) {
  return x * 100 + y + "";
}

function testVisibleImages() {
  const images = [
    { start: { x: 0, y: 0 }, end: { x: 1, y: 1 }, url: "0", visible: true },
    { start: { x: 1, y: 1 }, end: { x: 3, y: 3 }, url: "1", visible: true },
    { start: { x: 1, y: 1 }, end: { x: 2, y: 2 }, url: "2", visible: false },
    { start: { x: 0, y: 3 }, end: { x: 0, y: 3 }, url: "3", visible: true },
    { start: { x: 0, y: 2 }, end: { x: 1, y: 2 }, url: "4", visible: true },
    { start: { x: 2, y: 0 }, end: { x: 3, y: 1 }, url: "5", visible: true },
    { start: { x: 1, y: 3 }, end: { x: 2, y: 3 }, url: "6", visible: false },
  ];

  const filteredImages = visibleImages(images, 4, 4);

  const imageIsVisible = (image) => image.visible === true;

  console.assert(
    filteredImages.length === 5,
    "There should be exactly 5 filtered images"
  );
  console.assert(
    filteredImages.every(imageIsVisible),
    "All the filtered images should be visible"
  );
}

const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

function resizeImage(file, maxSize) {
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement("canvas");

  const resize = () => {
    let { width, height } = image;

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(image, 0, 0, width, height);

    const dataUrl = canvas.toDataURL("image/jpeg");

    return dataURItoBlob(dataUrl);
  };

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error("Not an image"));
      return;
    }

    reader.onload = (readerEvent) => {
      image.onload = () => ok([resize(), readerEvent.target.result]);
      image.src = readerEvent.target.result;
    };

    reader.readAsDataURL(file);
  });
}

function dataURItoBlob(dataURI) {
  const bytes =
    dataURI.split(",")[0].indexOf("base64") >= 0
      ? atob(dataURI.split(",")[1])
      : unescape(dataURI.split(",")[1]);
  const mime = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
}

export { visibleImages, testVisibleImages, resizeImage };
