export const chicken26x26 = [
  [
    { y: 0, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 168, prevColor: "gold" },
    { y: 280, x: 168, prevColor: "gold" },
    { y: 308, x: 168, prevColor: "gold" },
    { y: 336, x: 168, prevColor: "gold" },
    { y: 364, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 196, prevColor: "#DE581AFF" },
    { y: 252, x: 196, prevColor: "gold" },
    { y: 280, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 196, prevColor: "gold" },
    { y: 336, x: 196, prevColor: "gold" },
    { y: 364, x: 196, prevColor: "gold" },
    { y: 392, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 224, prevColor: "gold" },
    { y: 280, x: 224, prevColor: "gold" },
    { y: 308, x: 224, prevColor: "gold" },
    { y: 336, x: 224, prevColor: "gold" },
    { y: 364, x: 224, prevColor: "gold" },
    { y: 392, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 252, prevColor: "gold" },
    { y: 280, x: 252, prevColor: "gold" },
    { y: 308, x: 252, prevColor: "gold" },
    { y: 336, x: 252, prevColor: "gold" },
    { y: 364, x: 252, prevColor: "gold" },
    { y: 392, x: 252, prevColor: "gold" },
    { y: 420, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 280, prevColor: "gold" },
    { y: 280, x: 280, prevColor: "gold" },
    { y: 308, x: 280, prevColor: "gold" },
    { y: 336, x: 280, prevColor: "gold" },
    { y: 364, x: 280, prevColor: "gold" },
    { y: 392, x: 280, prevColor: "gold" },
    { y: 420, x: 280, prevColor: "gold" },
    { y: 448, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 308, prevColor: "gold" },
    { y: 280, x: 308, prevColor: "gold" },
    { y: 308, x: 308, prevColor: "gold" },
    { y: 336, x: 308, prevColor: "gold" },
    { y: 364, x: 308, prevColor: "gold" },
    { y: 392, x: 308, prevColor: "gold" },
    { y: 420, x: 308, prevColor: "gold" },
    { y: 448, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 336, prevColor: "gold" },
    { y: 308, x: 336, prevColor: "gold" },
    { y: 336, x: 336, prevColor: "gold" },
    { y: 364, x: 336, prevColor: "gold" },
    { y: 392, x: 336, prevColor: "gold" },
    { y: 420, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 364, prevColor: "gold" },
    { y: 308, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 364, prevColor: "gold" },
    { y: 392, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 392, prevColor: "gold" },
    { y: 280, x: 392, prevColor: "gold" },
    { y: 308, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 392, prevColor: "gold" },
    { y: 364, x: 392, prevColor: "gold" },
    { y: 392, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
  ],
];

export const monkey26x26 = [
  [
    { y: 0, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 168, prevColor: "#1BFF00FF" },
    { y: 364, x: 168, prevColor: "#1BFF00FF" },
    { y: 392, x: 168, prevColor: "#1BFF00FF" },
    { y: 420, x: 168, prevColor: "#1BFF00FF" },
    { y: 448, x: 168, prevColor: "#1BFF00FF" },
    { y: 476, x: 168, prevColor: "#1BFF00FF" },
    { y: 504, x: 168, prevColor: "#1BFF00FF" },
    { y: 532, x: 168, prevColor: "#1BFF00FF" },
    { y: 560, x: 168, prevColor: "#1BFF00FF" },
    { y: 588, x: 168, prevColor: "#1BFF00FF" },
    { y: 616, x: 168, prevColor: "#1BFF00FF" },
    { y: 644, x: 168, prevColor: "#1BFF00FF" },
    { y: 672, x: 168, prevColor: "#1BFF00FF" },
    { y: 700, x: 168, prevColor: "#1BFF00FF" },
  ],
  [
    { y: 0, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 196, prevColor: "#1BFF00FF" },
    { y: 308, x: 196, prevColor: "#1BFF00FF" },
    { y: 336, x: 196, prevColor: "#1BFF00FF" },
    { y: 364, x: 196, prevColor: "#1BFF00FF" },
    { y: 392, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 224, prevColor: "#1BFF00FF" },
    { y: 252, x: 224, prevColor: "#1BFF00FF" },
    { y: 280, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 252, prevColor: "#1BFF00FF" },
    { y: 168, x: 252, prevColor: "#1BFF00FF" },
    { y: 196, x: 252, prevColor: "#1BFF00FF" },
    { y: 224, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 252, prevColor: "#AD472BFF" },
    { y: 308, x: 252, prevColor: "#A84328FF" },
    { y: 336, x: 252, prevColor: "#A84328FF" },
    { y: 364, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 280, prevColor: "#1BFF00FF" },
    { y: 84, x: 280, prevColor: "#1BFF00FF" },
    { y: 112, x: 280, prevColor: "#1BFF00FF" },
    { y: 140, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 280, prevColor: "#A84328FF" },
    { y: 224, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 280, prevColor: "#A84328FF" },
    { y: 280, x: 280, prevColor: "gold" },
    { y: 308, x: 280, prevColor: "#B88678FF" },
    { y: 336, x: 280, prevColor: "gold" },
    { y: 364, x: 280, prevColor: "#A84328FF" },
    { y: 392, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 308, prevColor: "#1BFF00FF" },
    { y: 56, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 308, prevColor: "#A84328FF" },
    { y: 224, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 308, prevColor: "#A84328FF" },
    { y: 308, x: 308, prevColor: "#B88678FF" },
    { y: 336, x: 308, prevColor: "#A84328FF" },
    { y: 364, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 336, prevColor: "#A84328FF" },
    { y: 252, x: 336, prevColor: "#A84328FF" },
    { y: 280, x: 336, prevColor: "#A84328FF" },
    { y: 308, x: 336, prevColor: "#A84328FF" },
    { y: 336, x: 336, prevColor: "#A84328FF" },
    { y: 364, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 364, prevColor: "#A84328FF" },
    { y: 308, x: 364, prevColor: "#A84328FF" },
    { y: 336, x: 364, prevColor: "#A84328FF" },
    { y: 364, x: 364, prevColor: "#A84328FF" },
    { y: 392, x: 364, prevColor: "#A84328FF" },
    { y: 420, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 392, prevColor: "#A84328FF" },
    { y: 308, x: 392, prevColor: "#A84328FF" },
    { y: 336, x: 392, prevColor: "#A84328FF" },
    { y: 364, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 392, prevColor: "#A84328FF" },
    { y: 448, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 420, prevColor: "#A84328FF" },
    { y: 308, x: 420, prevColor: "#A84328FF" },
    { y: 336, x: 420, prevColor: "#A84328FF" },
    { y: 364, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 448, prevColor: "#A84328FF" },
    { y: 280, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 448, prevColor: "#A84328FF" },
    { y: 364, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 476, prevColor: "#A84328FF" },
    { y: 252, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 476, prevColor: "#A84328FF" },
    { y: 364, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 504, prevColor: "#A84328FF" },
    { y: 392, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
  ],
];

export const hippo26x26 = [
  [
    { y: 0, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 0, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 28, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 56, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 84, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 112, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 140, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 168, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 196, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 224, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 252, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 280, prevColor: "#5AA0C9FF" },
    { y: 224, x: 280, prevColor: "#5AA0C9FF" },
    { y: 252, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 280, prevColor: "#5AA0C9FF" },
    { y: 504, x: 280, prevColor: "#5AA0C9FF" },
    { y: 532, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 280, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 308, prevColor: "#5AA0C9FF" },
    { y: 224, x: 308, prevColor: "#5AA0C9FF" },
    { y: 252, x: 308, prevColor: "#5AA0C9FF" },
    { y: 280, x: 308, prevColor: "#5AA0C9FF" },
    { y: 308, x: 308, prevColor: "#5AA0C9FF" },
    { y: 336, x: 308, prevColor: "#5AA0C9FF" },
    { y: 364, x: 308, prevColor: "#5AA0C9FF" },
    { y: 392, x: 308, prevColor: "#5AA0C9FF" },
    { y: 420, x: 308, prevColor: "#5AA0C9FF" },
    { y: 448, x: 308, prevColor: "#5AA0C9FF" },
    { y: 476, x: 308, prevColor: "#5AA0C9FF" },
    { y: 504, x: 308, prevColor: "#5AA0C9FF" },
    { y: 532, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 308, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 336, prevColor: "#5AA0C9FF" },
    { y: 224, x: 336, prevColor: "#5AA0C9FF" },
    { y: 252, x: 336, prevColor: "#5AA0C9FF" },
    { y: 280, x: 336, prevColor: "#5AA0C9FF" },
    { y: 308, x: 336, prevColor: "#5AA0C9FF" },
    { y: 336, x: 336, prevColor: "#5AA0C9FF" },
    { y: 364, x: 336, prevColor: "#5AA0C9FF" },
    { y: 392, x: 336, prevColor: "#5AA0C9FF" },
    { y: 420, x: 336, prevColor: "#5AA0C9FF" },
    { y: 448, x: 336, prevColor: "#5AA0C9FF" },
    { y: 476, x: 336, prevColor: "#5AA0C9FF" },
    { y: 504, x: 336, prevColor: "#5AA0C9FF" },
    { y: 532, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 336, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 364, prevColor: "#5AA0C9FF" },
    { y: 252, x: 364, prevColor: "#5AA0C9FF" },
    { y: 280, x: 364, prevColor: "#5AA0C9FF" },
    { y: 308, x: 364, prevColor: "#5AA0C9FF" },
    { y: 336, x: 364, prevColor: "#5AA0C9FF" },
    { y: 364, x: 364, prevColor: "#5AA0C9FF" },
    { y: 392, x: 364, prevColor: "#5AA0C9FF" },
    { y: 420, x: 364, prevColor: "#5AA0C9FF" },
    { y: 448, x: 364, prevColor: "#5AA0C9FF" },
    { y: 476, x: 364, prevColor: "#5AA0C9FF" },
    { y: 504, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 364, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 392, prevColor: "#5AA0C9FF" },
    { y: 252, x: 392, prevColor: "#5AA0C9FF" },
    { y: 280, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 392, prevColor: "#5AA0C9FF" },
    { y: 336, x: 392, prevColor: "#5AA0C9FF" },
    { y: 364, x: 392, prevColor: "#5AA0C9FF" },
    { y: 392, x: 392, prevColor: "#5AA0C9FF" },
    { y: 420, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 392, prevColor: "#5AA0C9FF" },
    { y: 476, x: 392, prevColor: "#5AA0C9FF" },
    { y: 504, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 392, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 420, prevColor: "#5AA0C9FF" },
    { y: 252, x: 420, prevColor: "#5AA0C9FF" },
    { y: 280, x: 420, prevColor: "#5AA0C9FF" },
    { y: 308, x: 420, prevColor: "#5AA0C9FF" },
    { y: 336, x: 420, prevColor: "#5AA0C9FF" },
    { y: 364, x: 420, prevColor: "#5AA0C9FF" },
    { y: 392, x: 420, prevColor: "#5AA0C9FF" },
    { y: 420, x: 420, prevColor: "#5AA0C9FF" },
    { y: 448, x: 420, prevColor: "#5AA0C9FF" },
    { y: 476, x: 420, prevColor: "#5AA0C9FF" },
    { y: 504, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 420, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 448, prevColor: "#5AA0C9FF" },
    { y: 224, x: 448, prevColor: "#5AA0C9FF" },
    { y: 252, x: 448, prevColor: "#5AA0C9FF" },
    { y: 280, x: 448, prevColor: "#5AA0C9FF" },
    { y: 308, x: 448, prevColor: "#5AA0C9FF" },
    { y: 336, x: 448, prevColor: "#5AA0C9FF" },
    { y: 364, x: 448, prevColor: "#5AA0C9FF" },
    { y: 392, x: 448, prevColor: "#5AA0C9FF" },
    { y: 420, x: 448, prevColor: "#5AA0C9FF" },
    { y: 448, x: 448, prevColor: "#5AA0C9FF" },
    { y: 476, x: 448, prevColor: "#5AA0C9FF" },
    { y: 504, x: 448, prevColor: "#5AA0C9FF" },
    { y: 532, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 448, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 476, prevColor: "#5AA0C9FF" },
    { y: 224, x: 476, prevColor: "#5AA0C9FF" },
    { y: 252, x: 476, prevColor: "#568EDBFF" },
    { y: 280, x: 476, prevColor: "#1268DEFF" },
    { y: 308, x: 476, prevColor: "#1268DEFF" },
    { y: 336, x: 476, prevColor: "#5AA0C9FF" },
    { y: 364, x: 476, prevColor: "#5AA0C9FF" },
    { y: 392, x: 476, prevColor: "#1268DEFF" },
    { y: 420, x: 476, prevColor: "#1268DEFF" },
    { y: 448, x: 476, prevColor: "#568EDBFF" },
    { y: 476, x: 476, prevColor: "#5AA0C9FF" },
    { y: 504, x: 476, prevColor: "#5AA0C9FF" },
    { y: 532, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 476, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 504, prevColor: "#5AA0C9FF" },
    { y: 224, x: 504, prevColor: "#5AA0C9FF" },
    { y: 252, x: 504, prevColor: "#5AA0C9FF" },
    { y: 280, x: 504, prevColor: "#5AA0C9FF" },
    { y: 308, x: 504, prevColor: "#5AA0C9FF" },
    { y: 336, x: 504, prevColor: "#5AA0C9FF" },
    { y: 364, x: 504, prevColor: "#5AA0C9FF" },
    { y: 392, x: 504, prevColor: "#5AA0C9FF" },
    { y: 420, x: 504, prevColor: "#5AA0C9FF" },
    { y: 448, x: 504, prevColor: "#5AA0C9FF" },
    { y: 476, x: 504, prevColor: "#5AA0C9FF" },
    { y: 504, x: 504, prevColor: "#5AA0C9FF" },
    { y: 532, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 504, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 532, prevColor: "#6EA3EBFF" },
    { y: 224, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 532, prevColor: "whitesmoke" },
    { y: 280, x: 532, prevColor: "whitesmoke" },
    { y: 308, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 532, prevColor: "whitesmoke" },
    { y: 448, x: 532, prevColor: "whitesmoke" },
    { y: 476, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 532, prevColor: "#6EA3EBFF" },
    { y: 532, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 532, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 560, prevColor: "#6EA3EBFF" },
    { y: 224, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 560, prevColor: "whitesmoke" },
    { y: 280, x: 560, prevColor: "whitesmoke" },
    { y: 308, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 560, prevColor: "whitesmoke" },
    { y: 448, x: 560, prevColor: "whitesmoke" },
    { y: 476, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 560, prevColor: "#6EA3EBFF" },
    { y: 532, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 560, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 588, prevColor: "#6EA3EBFF" },
    { y: 224, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 588, prevColor: "#6EA3EBFF" },
    { y: 532, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 588, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 616, prevColor: "#6EA3EBFF" },
    { y: 224, x: 616, prevColor: "#6EA3EBFF" },
    { y: 252, x: 616, prevColor: "#6EA3EBFF" },
    { y: 280, x: 616, prevColor: "#6EA3EBFF" },
    { y: 308, x: 616, prevColor: "#6EA3EBFF" },
    { y: 336, x: 616, prevColor: "#6EA3EBFF" },
    { y: 364, x: 616, prevColor: "#6EA3EBFF" },
    { y: 392, x: 616, prevColor: "#6EA3EBFF" },
    { y: 420, x: 616, prevColor: "#6EA3EBFF" },
    { y: 448, x: 616, prevColor: "#6EA3EBFF" },
    { y: 476, x: 616, prevColor: "#6EA3EBFF" },
    { y: 504, x: 616, prevColor: "#6EA3EBFF" },
    { y: 532, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 616, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 644, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 672, prevColor: "rgba(0, 0, 0, 1)" },
  ],
  [
    { y: 0, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 28, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 56, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 84, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 112, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 140, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 168, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 196, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 224, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 252, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 280, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 308, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 336, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 364, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 392, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 420, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 448, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 476, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 504, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 532, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 560, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 588, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 616, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 644, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 672, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
    { y: 700, x: 700, prevColor: "rgba(0, 0, 0, 1)" },
  ],
];
