<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn block color="black" dark v-bind="attrs" v-on="on">
        Pixel Art
        <v-icon class="ml-2">mdi-auto-fix</v-icon>
      </v-btn>
    </template>
    <v-card class="cyan darken-4">
      <v-toolbar dark color="primary">
        <v-img
          @click="dialog = false"
          alt="1000Blocks Logo"
          class="shrink"
          contain
          src="../../assets/UI-icon_BACK.svg"
          transition="scale-transition"
          width="40"
          style="
            cursor: pointer;
          "
        />
        <v-spacer />
        <v-toolbar-title>
          PIXEL ART
          <v-icon class="ml-2">mdi-auto-fix</v-icon>
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <PixelDrawer />
      <div
        v-if="!$vuetify.breakpoint.mobile"
        class="pt-8 d-flex flex-row justify-center align-center"
      >
        <v-btn
          @click="
            dialog = false;
            exportDrawing();
          "
          class="black--text mr-2"
          width="120"
          style="
          background: #18dfd6;
          "
          >SAVE</v-btn
        >
        <v-btn @click="dialog = false" class="white--text" width="120"
          >CANCEL</v-btn
        >
      </div>
      <v-bottom-navigation grow app v-if="$vuetify.breakpoint.mobile">
        <v-btn
          max-width="none"
          @click="
            dialog = false;
            exportDrawing();
          "
          ><span class="black--text">
            SAVE
          </span>
        </v-btn>
        <v-btn max-width="none" @click="dialog = false"
          ><span class="white--text">
            CANCEL
          </span></v-btn
        >
      </v-bottom-navigation>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PixelDrawer from "../info-panel/PixelDrawer.vue";
import { resizeImage } from "../../services/imagesService";

export default {
  name: "PixelDrawerDialog",
  components: {
    PixelDrawer,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState("pixel", ["stage"]),
  },
  methods: {
    ...mapActions("pixel", ["setPixelDataUrl", "setPixelFile"]),
    async exportDrawing() {
      if (this.stage) {
        const dataURL = this.stage.toDataURL({ pixelRatio: 1 });
        const blob = this.dataURItoBlob(dataURL);
        const pixelFile = new File([blob], "pixelArt", {
          type: "image/jpeg",
          lastModified: new Date().getTime(),
        });

        //***************
        // resize process
        const newFileDataUrl = await resizeImage(pixelFile, 512); // Accepts only file
        const newBlob = this.dataURItoBlob(newFileDataUrl[1]);
        const pixelResizedFile = new File([newBlob], "pixelArt", {
          type: "image/jpeg",
          lastModified: new Date().getTime(),
        });

        pixelResizedFile.isPixelArt = true;

        this.setPixelDataUrl({
          base64: newFileDataUrl[1],
          timestamp: new Date().getTime(),
        });

        this.setPixelFile(pixelResizedFile);
        localStorage.setItem("pixelBase64", newFileDataUrl);

        //***************
      }
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      let mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      // write the bytes of the string to a typed array
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
  },
};
</script>
<style lang="css" scoped>
.v-item-group.v-bottom-navigation .v-btn {
  background: #18dfd6;
  font-size: 16px;
  border-top: 1px solid white;
}

.v-item-group.v-bottom-navigation .v-btn:last-child {
  background: #333333;
  font-size: 16px;
  border-left: 1px solid white;
}
</style>
