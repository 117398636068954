<template>
  <div class="side-panel-title d-flex align-center pa-2">
    <v-btn small plain class="pa-0" style="min-width: 0" @click="goToSpaces">
      <v-icon>
        mdi-apps
      </v-icon>
    </v-btn>

    <h1 class="space-title px-2 text-uppercase">{{ spaceTitle }}</h1>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    spaceTitle: String,
  },
  methods: {
    ...mapActions("space", ["clearSelection"]),
    goToSpaces() {
      this.clearSelection();
      this.$router.push("/spaces");
    },
  },
};
</script>

<style>
.side-panel-title {
  background: black;
  border: 1px solid #292929;
}
.space-title {
  color: white;
  font-weight: 700;
}
</style>
