<template>
  <v-layer v-if="show">
    <v-rect ref="drawingBox" :config="drawBox" />
    <v-rect v-if="selected.previewImage" :config="previewBackground" />
    <v-image v-if="selected.previewImage" :config="preview" />
    <v-group v-if="!selected.previewImage">
      <BlockPrice
        v-for="blockPrice in blockPrices"
        :key="blockPrice.blockId"
        v-bind="blockPrice"
        v-bind:blockSize="blockSize"
      />
    </v-group>
  </v-layer>
</template>

<script>
import { mapState } from "vuex";
import BlockPrice from "./BlockPrice.vue";

export default {
  props: {
    blockSize: Number,
  },
  components: {
    BlockPrice,
  },
  data() {
    return {
      drawingBox: {
        fill: "#00FFD4",
        stroke: "#00FFD4",
        strokeWidth: 1,
        opacity: this.$isMobile() ? 1 : 0.4,
      },
    };
  },
  computed: {
    ...mapState("mode", ["mode"]),
    ...mapState("space", ["selected"]),

    show() {
      return (
        this.mode === "draw" || this.mode === "confirm" || this.mode === "buy"
      );
    },

    blockPrices() {
      return this.selected.prices;
    },

    previewBackground() {
      const { x, y, width, height } = this.drawBox;
      return {
        x,
        y,
        width,
        height,
        fill: "black",
      };
    },

    preview() {
      const { x, y, width, height } = this.drawBox;

      const img = new Image();
      img.src = this.$store.state.space.selected.previewImage;

      // return (img.onload = () => {
      const scaleX = this.drawBox.width / img.naturalWidth;
      const scaleY = this.drawBox.height / img.naturalHeight;
      const scale = Math.min(scaleX, scaleY);

      const fillPatternScale = {
        x: scale,
        y: scale,
      };
      const fillPatternOffset = {
        x: (img.naturalWidth * scale) / 2 - this.drawBox.width / 2,
        y: (img.naturalHeight * scale) / 2 - this.drawBox.height / 2,
      };
      const fillPatternImage = img;

      return {
        x,
        y,
        width,
        height,
        fillPatternOffset,
        fillPatternImage,
        fillPatternScale,
        fillPatternRepeat: "no-repeat",
      };
    },

    drawBox() {
      return {
        ...this.drawingBox,
        ...this.denormalizedStart,
        ...this.denormalizedSize,
      };
    },

    denormalizedStart() {
      const coor = this.$store.state.drawing.start;
      if (coor) {
        return {
          x: coor.x * this.blockSize,
          y: coor.y * this.blockSize,
        };
      } else {
        return undefined;
      }
    },

    denormalizedSize() {
      const size = this.$store.state.drawing.size;
      if (size) {
        return {
          width: size.width * this.blockSize,
          height: size.height * this.blockSize,
        };
      } else {
        return undefined;
      }
    },
  },

  methods: {
    blockStart: function(coor) {
      return {
        x: Math.floor(coor.x / this.blockSize),
        y: Math.floor(coor.y / this.blockSize),
      };
    },
    blockEnd: function(coor) {
      return {
        x: Math.floor(coor.x / this.blockSize),
        y: Math.floor(coor.y / this.blockSize),
      };
    },
  },
};
</script>

<style></style>
