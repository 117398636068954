<template>
  <div class="side-panel-nav pa-2 d-flex">
    <template v-if="mode === 'view'">
      <div>
        <label
          class="ma-0 grey--text text-uppercase text-body-2"
          style="line-height: 100%"
          >My blocks:</label
        >
        <p class="ma-0 text-body-2" style="line-height: 100%">
          {{ blocksOwned }}
        </p>
      </div>
      <v-spacer />
      <v-btn
        :disabled="!claimable"
        class="accent black--text darken-1"
        @click="setMode('draw')"
      >
        Claim blocks
      </v-btn>
    </template>
    <template v-else>
      <div>
        <label
          class="ma-0 grey--text text-uppercase text-body-2"
          style="line-height: 100%"
          >Claim:</label
        >
        <p class="ma-0 text-body-2" style="line-height: 100%">
          {{ size }} Blocks
        </p>
      </div>
      <v-spacer />
      <v-btn @click="clearSelection">
        Cancel
      </v-btn>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  props: {
    claimable: Boolean,
  },
  computed: {
    ...mapState("mode", ["mode"]),
    ...mapGetters("wallet", ["blocksOwned"]),
    ...mapState("space", ["currentSpace"]),
    ...mapState("space", ["selected"]),
    size() {
      return this.selected.count;
    },
  },
  methods: {
    ...mapActions("mode", ["setMode"]),
    ...mapActions("space", ["clearSelection"]),
  },
};
</script>

<style>
.side-panel-nav {
  background: black;
}
</style>
