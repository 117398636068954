<template>
  <v-container v-if="!connected" class="footer-panel pa-0">
    <ConnectButton v-if="!connected" block footer />
  </v-container>

  <v-container v-else class="pa-0">
    <div class="footer-grid">
      <div class="black d-flex flex-column align-center">
        <span class="grey--text text-body-2">Mint price:</span>
        <span>{{ priceOfOne }} BNB</span>
      </div>
      <div class="black d-flex flex-column align-center">
        <span class="grey--text text-body-2">My collection:</span>
        <span>{{ ownedCount }}/10</span>
      </div>
      <v-btn
        class="accent darken-1 black--text"
        style="grid-column: 1 / span 2; height: 100%"
        @click="$emit('goToMint')"
        :loading="loading.mint"
        :disabled="!connected || !allowedToMint"
      >
        Mint
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ConnectButton from "../../components/cross-space/ConnectButton.vue";

export default {
  components: {
    ConnectButton,
  },
  computed: {
    ...mapState("wallet", ["connected"]),
    ...mapState("mint", ["loading", "ownedCount", "allowedToMint"]),
    ...mapGetters("mint", ["priceOfOne"]),
  },
  methods: {
    ...mapActions("mint", ["mint"]),
  },
};
</script>

<style scoped>
.footer-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  padding-top: 2px;
}
</style>
