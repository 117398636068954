<template>
  <div class="canvas" ref="canvas">
    <v-stage v-on="stageEvents" ref="stage" :config="stage">
      <Marks :blockSize="blockSize" v-if="showMarks" />
      <Grid
        :width="canvas.width"
        :height="canvas.height"
        :rows="24"
        :cols="42"
        :blockSize="blockSize"
      />
      <v-layer ref="posterLayer">
        <Poster v-bind="staticBlockArea" v-bind:blockSize="blockSize" />
        <Poster
          v-for="poster in blockAreas"
          :key="poster.z"
          v-bind="poster"
          v-bind:blockSize="blockSize"
          :scrolling="scrolling"
          :poster="poster"
        />
      </v-layer>
      <DrawingBox :blockSize="blockSize" />
      <DraggingBox :blockSize="blockSize" :scale="scale" />
    </v-stage>
  </div>
</template>

<script>
const height = 1000;

// Calculating the proportionate width (canvas is in 42:24 scale)
// Taking into account the 24pt padding
const paddedCanvasHeight = height;
const paddedCanvasWidth = ((height - 24) / 24) * 42 + 24;

// const padding = 12;

// const canvasWidth = paddedCanvasWidth - padding * 2;
// const canvasHeight = paddedCanvasHeight - padding * 2;

import Marks from "./Marks.vue";
import Grid from "./Grid.vue";
import Poster from "./Poster.vue";
import DrawingBox from "./DrawingBox.vue";
import DraggingBox from "./DraggingBox.vue";

import { mapState, mapActions } from "vuex";

import Konva from "konva";

export default {
  props: {
    blockAreas: Array,
    canvasImageUrl: String,
  },
  components: {
    Marks,
    Grid,
    Poster,
    DrawingBox,
    DraggingBox,
  },
  data() {
    return {
      buyDialog: false,

      padding: 12,
      showMarks: !this.$isMobile(),

      scale: 1,
      blocksWidth: 42,
      blocksHeight: 24,
      blockSize: 32,

      stageEvents: {
        mousedown: this.mouseDown,
        mouseup: this.mouseUp,
        mousemove: this.mouseMove,
        touchstart: this.touchStart,
        touchend: this.touchEnd,
        touchmove: this.touchMove,
      },
      newPoster: {
        drawing: false,
        startBlockId: undefined,
        endBlockId: undefined,
        drawStart: undefined,
      },

      scrolling: false,
    };
  },
  computed: {
    ...mapState("mode", ["mode"]),
    ...mapState("space", ["selected"]),

    markPadding() {
      return this.showMarks ? 12 : 0;
    },

    stage() {
      return {
        width: paddedCanvasWidth * this.scale,
        height: paddedCanvasHeight * this.scale,
        scale: {
          x: this.scale,
          y: this.scale,
        },
        offset: {
          x: -this.padding - this.markPadding,
          y: -this.padding - this.markPadding,
        },
        draggable: false,
      };
    },

    canvas() {
      return {
        width: paddedCanvasWidth - this.padding * 2 - this.markPadding,
        height: paddedCanvasHeight - this.padding * 2 - this.markPadding,
      };
    },

    bgConf() {
      return {
        x: this.padding,
        y: this.padding,
        image: null,
        width: paddedCanvasWidth - this.padding * 2 - this.markPadding,
        height: paddedCanvasHeight - this.padding * 2 - this.markPadding,
        listening: false,
      };
    },

    previewBackground() {
      const { x, y, width, height } = this.drawingBox;
      return {
        x,
        y,
        width,
        height,
        fill: "black",
      };
    },
    preview() {
      const { x, y, width, height } = this.drawingBox;

      const img = new Image();
      img.src = this.$store.state.space.selected.previewImage;

      const scaleX = this.drawingBox.width / img.naturalWidth;
      const scaleY = this.drawingBox.height / img.naturalHeight;
      const scale = Math.min(scaleX, scaleY);

      const fillPatternScale = {
        x: scale,
        y: scale,
      };
      const fillPatternOffset = {
        x: (img.naturalWidth * scale) / 2 - this.drawingBox.width / 2,
        y: (img.naturalHeight * scale) / 2 - this.drawingBox.height / 2,
      };
      const fillPatternImage = img;

      return {
        x,
        y,
        width,
        height,
        fillPatternOffset,
        fillPatternImage,
        fillPatternScale,
        fillPatternRepeat: "no-repeat",
      };
    },

    staticBlockArea() {
      return {
        blockStart: {
          x: 0,
          y: 0,
        },
        blockEnd: {
          x: 3,
          y: 1,
        },
        publicUrl: this.canvasImageUrl,
      };
    },

    blockPrices() {
      return this.selected.prices;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$isMobile()) {
        this.fitStageIntoParentContainer();
        window.addEventListener("resize", this.fitStageIntoParentContainer);
      }
      // this.braveBrowserHack();
    });

    const img = new Image();
    img.src = this.$store.state.space.selected.image;
    img.onload = function() {
      img.naturalWidth;
      this.preview.fillPatternImage = img;
    };
  },
  created() {
    this.setPixelRatio();

    const wideSize = (this.canvas.width * this.scale) / this.blocksWidth;
    const highSize = (this.canvas.height * this.scale) / this.blocksHeight;
    this.blockSize = wideSize < highSize ? wideSize : highSize;

    // this.stage.width = this.blockSize * this.blocksWidth;
    // this.stage.height = this.blockSize * this.blocksHeight;
  },
  methods: {
    ...mapActions("mode", ["setMode"]),

    setPixelRatio() {
      if (this.$isMobile()) {
        Konva.pixelRatio = 2;
      }
    },

    braveBrowserHack() {
      const scale = this.scale;
      const layer = this.$refs.posterLayer.getNode();
      layer._getIntersection = function(pos) {
        const rects = layer.getChildren().map((child) => {
          return child.getChildren(function(node) {
            return node.getClassName() === "Rect";
          })[0];
        });
        for (const rect of rects.reverse()) {
          if (
            rect &&
            rect.attrs &&
            Math.round(pos.x) >= (rect.attrs.x + this.padding) * scale &&
            Math.round(pos.x) <=
              (rect.attrs.x + this.padding + rect.attrs.width) * scale &&
            Math.round(pos.y) >= (rect.attrs.y + this.padding) * scale &&
            Math.round(pos.y) <=
              (rect.attrs.y + this.padding + rect.attrs.height) * scale
          ) {
            return { shape: rect };
          }
        }
        return {};
      };
    },

    fitStageIntoParentContainer() {
      var container = this.$refs.canvas;
      // now we need to fit stage into parent
      var containerWidth = container.clientWidth;
      var containerHeight = container.clientHeight;

      // to do this we need to scale the stage
      var scaleX = containerWidth / paddedCanvasWidth;
      var scaleY = containerHeight / paddedCanvasHeight;

      var scale = Math.min(scaleX, scaleY);

      this.scale = scale;
    },

    normalizeCoor(coor) {
      return {
        x: Math.floor(coor.x / this.blockSize),
        y: Math.floor(coor.y / this.blockSize),
      };
    },

    mouseDown: function(event) {
      const { x, y } = this.getCoordinates(event);
      const currentCoor = {
        x: x - (this.padding + this.markPadding) * this.scale,
        y: y - (this.padding + this.markPadding) * this.scale,
      };
      if (this.mode === "draw" || this.mode === "confirm") {
        this.$store.dispatch(
          "drawing/startDrawing",
          this.normalizeCoor(currentCoor)
        );
      }
    },
    mouseUp: function(event) {
      const { x, y } = this.getCoordinates(event);
      const currentCoor = {
        x: x - (this.padding + this.markPadding) * this.scale,
        y: y - (this.padding + this.markPadding) * this.scale,
      };
      if (this.mode === "draw" || this.mode === "confirm") {
        this.$store.dispatch(
          "drawing/stopDrawing",
          this.normalizeCoor(currentCoor)
        );

        this.buyDialog = true;
        this.setMode("confirm");
      }
    },
    mouseMove: function(event) {
      const { x, y } = this.getCoordinates(event);
      const currentCoor = {
        x: x - (this.padding + this.markPadding) * this.scale,
        y: y - (this.padding + this.markPadding) * this.scale,
      };
      if (this.mode === "draw" || this.mode === "confirm") {
        this.$store.dispatch(
          "drawing/drawChange",
          this.normalizeCoor(currentCoor)
        );
      }
    },
    touchStart: function(event) {
      if (this.$isMobile() && this.mode === "draw") {
        const currentCoor = this.getCoordinates(event);

        this.$store.dispatch("drawing/selectBlockArea", {
          startCoor: this.normalizeCoor(currentCoor),
          endCoor: this.normalizeCoor(currentCoor),
        });

        this.setMode("confirm");
      }
    },
    touchEnd: function() {
      this.scrollStopped();
    },
    touchMove: function() {
      this.scrollActive();
    },

    scrollActive() {
      this.scrolling = true;
    },

    scrollStopped() {
      // Hack to disable the tap when scrolling
      setTimeout(() => {
        this.scrolling = false;
      }, 100);
    },

    getCoordinates: function(event) {
      let currentCoor;
      if (this.$isMobile()) {
        const touch = event.evt.changedTouches[0];
        const pos = event.evt.target.getBoundingClientRect();
        currentCoor = {
          x: touch.clientX * this.scale - pos.x,
          y: touch.clientY * this.scale - pos.y,
        };
      } else {
        currentCoor = {
          x: event.evt.offsetX / this.scale,
          y: event.evt.offsetY / this.scale,
        };
      }
      return currentCoor;
    },
  },
};
</script>

<style scoped></style>
