<template>
  <div v-if="space.loading" class="preloader-wrapper">
    <Preloader></Preloader>
  </div>
  <div
    v-else-if="$isMobile() && !alreadySeen && spaceUpdate.visible"
    style="height: 100%; padding-top: 10px; width: 100%;"
  >
    <SpaceUpdate
      :closeSpaceUpdate="closeSpaceUpdate"
      :spaceMessage="spaceUpdate.message"
      :imageUrl="spaceUpdate.imageUrl"
    />
  </div>
  <div
    v-else
    v-bind:class="{ mainx: $isMobile(), 'main-desktop': !$isMobile() }"
  >
    <Onboarding
      v-bind="currentSpace.config"
      v-if="currentSpace.config.membersOnly && currentSpace.config.active"
    ></Onboarding>

    <BackgroundStatic
      :backgroundImageUrl="currentSpace.config.backgroundImageUrl"
    />

    <!-- This is duplicated in spaces. It should go to separate component -->
    <v-snackbar
      v-if="!currentSpace.backendAndContractSynced"
      v-model="snackbarEmergency"
      vertical
      bottom
      color="warning"
      timeout="-1"
      content-class="text-center"
    >
      Your image did not upload properly, please re-upload the same image to
      display it on the space!
      <template v-slot:action="{ attrs }">
        <div class="d-flex" v-bind="{ attrs }">
          <v-btn
            class="warning darken-1 black--text flex-grow-1 mx-2"
            @click="snackbarEmergency = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            class="primary darken-1 flex-grow-1 mx-2"
            @click="
              emergencyUploadDialog = true;
              snackbarEmergency = false;
            "
          >
            Re-upload image
          </v-btn>
        </div>
      </template>
    </v-snackbar>
    <EmergencyUploadDialog v-model="emergencyUploadDialog" />

    <FlyingMascot
      v-if="currentSpace.config.mascot"
      :mascotImageUrl="currentSpace.config.mascotImageUrl"
    />

    <Canvas
      class="canvas d-flex justify-center align-center"
      :blockAreas="blockAreas"
      :canvasImageUrl="this.currentSpace.config.canvasImageUrl"
      ref="canvas"
    />

    <div class="sidebar" v-if="!$isMobile()">
      <SpaceUpdate
        v-if="!alreadySeen && spaceUpdate.visible"
        :closeSpaceUpdate="this.closeSpaceUpdate"
        :spaceMessage="spaceUpdate.message"
        :imageUrl="spaceUpdate.imageUrl"
      />
      <SidePanel v-else />
    </div>

    <v-footer v-if="$isMobile()" fixed padless inset>
      <FooterPanel />
    </v-footer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { getSpaceBySlug } from "../services/spaces";
import { database } from "../services/firebase";

import Preloader from "../components/Preloader.vue";
import BackgroundStatic from "../components/helpers/BackgroundStatic.vue";
import SidePanel from "../components/space/SidePanel.vue";
import FooterPanel from "../components/space/FooterPanel.vue";
import Canvas from "../components/space/Canvas.vue";
import EmergencyUploadDialog from "../components/dialogs/EmergencyUploadDialog.vue";
import Onboarding from "../components/dialogs/Onboarding.vue";
import FlyingMascot from "../components/helpers/FlyingMascot.vue";
import SpaceUpdate from "../components/space/side-panel/SpaceUpdate.vue";

export default {
  name: "CanvasWrapper",
  components: {
    Preloader,
    BackgroundStatic,
    SidePanel,
    FooterPanel,
    Canvas,
    EmergencyUploadDialog,
    Onboarding,
    FlyingMascot,
    SpaceUpdate,
  },

  data() {
    return {
      emergencyUploadDialog: false,
      snackbarEmergency: true,
      onBoardingDialog: true,
      spaceUpdate: {
        visible: false,
        message: "",
        imageUrl: "",
      },
    };
  },

  methods: {
    ...mapActions("space", ["loadSpace", "setSpaceSwitcher"]),
    closeSpaceUpdate() {
      const uniqueHash =
        this.spaceUpdate.message.hashCode().toString() +
        this.currentSpace.spaceId;
      localStorage[uniqueHash] = true;
      this.spaceUpdate.visible = false;
      this.spaceUpdate.message = "";
      this.spaceUpdate.imageUrl = "";
    },
  },

  created() {
    this.setSpaceSwitcher(false);
  },

  mounted() {
    database
      .ref(`message-space-${this.currentSpace.spaceId + 1}`)
      .on("value", (message) => {
        this.spaceUpdate.visible = message.val().visible;
        this.spaceUpdate.message = message.val().text;
        this.spaceUpdate.imageUrl = message.val().imageUrl;
      });
  },

  watch: {
    spaceConfig(current) {
      this.loadSpace(current.spaceId);
    },
  },

  computed: {
    ...mapState("space", ["currentSpace"]),
    ...mapGetters("space", ["sortedBlockAreas"]),
    space() {
      return this.$store.state.space.spaces[this.spaceId];
    },

    spaceConfig() {
      return getSpaceBySlug(this.$route.params.spaceSlug);
    },

    spaceId() {
      return this.spaceConfig.spaceId;
    },

    blockAreas() {
      return this.sortedBlockAreas(this.spaceId);
    },
    alreadySeen() {
      // cached space update value needs to be unique per space
      const flag =
        localStorage[
          this.spaceUpdate.message.hashCode().toString() +
            this.currentSpace.spaceId
        ] || false;

      return flag;
    },
  },
};

String.prototype.hashCode = function() {
  var hash = 0,
    i,
    chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
</script>

<style scoped>
.mainx {
  display: flex;
  width: 100vw;
  height: calc(100vh - 64px);
  justify-content: center;
}

.main-desktop {
  display: grid;
  grid-template-rows: 1fr minmax(0, auto) 1fr;
  grid-template-columns: minmax(0, auto) 290px;
  grid-template-areas: "canvas panel";
  height: calc(100vh - 64px);
  justify-content: center;
  padding: 12px;
}

.sidebar {
  width: 280px;
  margin: 6% 0 3% 0;
  background: black;
  z-index: 0;
  background-color: #1f2725;
  border: 1px solid dimgray;

  display: flex;
  flex-direction: column;
  grid-row: 2;
}

.canvas {
  grid-row: 2;
}

@media only screen and (max-width: 600px) {
  .mainx {
    position: absolute;
    left: 0;
    right: 0;
    padding-bottom: 88px; /* To accomodate the footer */
    overflow: scroll;
    height: auto;
    justify-content: normal;
  }
  .canvas {
    flex: 1 0 auto;
    overflow: scroll;
  }
}

.preloader-wrapper {
  background-color: black;
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
  height: 100%;
}
</style>
