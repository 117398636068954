export const NETWORKS = {
  1: 'Main Net',
  2: 'Deprecated Morden test network',
  3: 'Ropsten test network',
  4: 'Rinkeby test network',
  42: 'Kovan test network',
  56: {
    id: 56,
    name: 'Binance Smart Chain',
    rpcUrl: 'https://bsc-dataseed.binance.org/',
    currency: 'BNB'
  },
  97: {
    id: 97,
    name: 'Binance Smart Chain TestNet',
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    currency: 'BNB'
  },
  4447: 'Truffle Develop Network',
  5777: 'Ganache Blockchain'
 }