<template>
  <v-layer v-if="show">
    <v-rect ref="drawingBox" :config="drawBox" />

    <template v-if="selected.previewImage">
      <v-rect :config="previewBackground" />
      <v-image :config="preview" />
    </template>

    <v-group v-if="!selected.previewImage">
      <BlockPrice
        v-for="blockPrice in blockPrices"
        :key="blockPrice.blockId"
        v-bind="blockPrice"
        v-bind:blockSize="blockSize"
      />
    </v-group>

    <v-group>
      <v-image
        @dragmove="move($event)"
        @mousedown="resizeMouseDown($event)"
        ref="moveHandle"
        :config="moveHandleConfig"
      />
      <v-image
        v-on="resizeStageEvents"
        @dragmove="resize($event)"
        @mousedown="resizeMouseDown($event)"
        ref="resizeHandle"
        :config="resizeHandleConfig"
      />
    </v-group>
  </v-layer>
</template>

<script>
import _ from "lodash";
import * as drawingService from "../../services/drawingService.js";
import { mapState } from "vuex";
import BlockPrice from "./BlockPrice.vue";

export default {
  props: {
    blockSize: Number,
    scale: Number,
  },
  components: {
    BlockPrice,
  },
  data() {
    return {
      drawingBox: {
        fill: "#00FFD4",
        stroke: "#00FFD4",
        strokeWidth: 1,
        opacity: this.$isMobile() ? 1 : 0.4,
      },
      resizeStageEvents: {
        mousedown: this.resizeMouseDown,
      },

      handleSize: 20,

      moveHandleImage: null,
      resizeHandleImage: null,
    };
  },

  created() {
    const moveImage = new window.Image();
    moveImage.src = require("@/assets/UI-icon_MOVE.svg");
    moveImage.onload = () => {
      // set image only when it is loaded
      this.moveHandleImage = moveImage;
    };

    const resizeImage = new window.Image();
    resizeImage.src = require("@/assets/UI-icon_RESIZE.svg");
    resizeImage.onload = () => {
      // set image only when it is loaded
      this.resizeHandleImage = resizeImage;
    };
  },

  mounted() {},

  computed: {
    ...mapState("mode", ["mode"]),
    ...mapState("space", ["selected"]),

    show() {
      return this.mode === "confirm";
    },

    moveHandleConfig() {
      const { x, y } = this.drawBox;
      return {
        image: this.moveHandleImage,
        x: x - this.handleSize / 2,
        y: y - this.handleSize / 2,
        width: this.handleSize,
        height: this.handleSize,
        draggable: true,
        dragBoundFunc: this.moveDragBound,
      };
    },

    resizeHandleConfig() {
      const { x, y, width, height } = this.drawBox;
      return {
        image: this.resizeHandleImage,
        x: x + width - this.handleSize / 2,
        y: y + height - this.handleSize / 2,
        width: this.handleSize,
        height: this.handleSize,
        draggable: true,
        dragBoundFunc: this.resizeDragBound,
      };
    },

    blockPrices() {
      return this.selected.prices;
    },

    previewBackground() {
      const { x, y, width, height } = this.drawBox;
      return {
        x,
        y,
        width,
        height,
        fill: "black",
      };
    },

    preview() {
      const { x, y, width, height } = this.drawBox;

      const img = new Image();
      img.src = this.$store.state.space.selected.previewImage;

      const scaleX = this.drawBox.width / img.naturalWidth;
      const scaleY = this.drawBox.height / img.naturalHeight;
      const scale = Math.min(scaleX, scaleY);

      const fillPatternScale = {
        x: scale,
        y: scale,
      };
      const fillPatternOffset = {
        x: (img.naturalWidth * scale) / 2 - this.drawBox.width / 2,
        y: (img.naturalHeight * scale) / 2 - this.drawBox.height / 2,
      };
      const fillPatternImage = img;

      return {
        x,
        y,
        width,
        height,
        fillPatternOffset,
        fillPatternImage,
        fillPatternScale,
        fillPatternRepeat: "no-repeat",
      };
    },

    drawBox() {
      return {
        ...this.drawingBox,
        ...this.denormalizedStart,
        ...this.denormalizedSize,
      };
    },

    denormalizedStart() {
      const coor = this.$store.state.drawing.start;
      if (coor) {
        return {
          x: coor.x * this.blockSize,
          y: coor.y * this.blockSize,
        };
      } else {
        return undefined;
      }
    },

    denormalizedSize() {
      const size = this.$store.state.drawing.size;
      if (size) {
        return {
          width: size.width * this.blockSize,
          height: size.height * this.blockSize,
        };
      } else {
        return undefined;
      }
    },
  },

  methods: {
    blockStart: function(coor) {
      return {
        x: Math.floor(coor.x / this.blockSize),
        y: Math.floor(coor.y / this.blockSize),
      };
    },
    blockEnd: function(coor) {
      return {
        x: Math.floor(coor.x / this.blockSize),
        y: Math.floor(coor.y / this.blockSize),
      };
    },

    move(options) {
      this.positionChange(
        options.target,
        options.target.attrs.x,
        options.target.attrs.y
      );

      const { width, height } = this.drawBox;

      const normalizedStart = drawingService.normalizeCoor(
        {
          x: options.target.attrs.x + this.handleSize / 2,
          y: options.target.attrs.y + this.handleSize / 2,
        },
        this.blockSize
      );

      const size = this.$store.state.drawing.size;

      this.draw(
        normalizedStart.x,
        normalizedStart.y,
        size.width - 1,
        size.height - 1
      );

      const resize = this.$refs.resizeHandle;
      this.positionChange(
        resize.getNode(),
        options.target.attrs.x + width,
        options.target.attrs.y + height
      );
    },

    resizeMouseDown: function() {
      this.$store.dispatch("drawing/suppressDrawing", true);
    },

    resize(options) {
      const { x, y } = this.drawBox;

      const offset = this.$refs.drawingBox
        .getNode()
        .getStage()
        .getOffset();

      const normalizedStart = drawingService.normalizeCoor(
        { x, y },
        this.blockSize
      );
      const normalizedEnd = drawingService.normalizeCoor(
        {
          x: options.target.attrs.x + this.handleSize / 2 - offset.x,
          y: options.target.attrs.y + this.handleSize / 2 - offset.y,
        },
        this.blockSize
      );

      const maxLimits = this.$store.state.space.currentSpace.config.maxLimits;

      const constrainedEnd = drawingService.constrainResize(
        normalizedStart,
        normalizedEnd,
        maxLimits
      );

      this.positionChange(
        options.target,
        constrainedEnd.x * this.blockSize,
        constrainedEnd.y * this.blockSize
      );

      const width = normalizedEnd.x - normalizedStart.x - 1;
      const height = normalizedEnd.y - normalizedStart.y - 1;

      this.draw(normalizedStart.x, normalizedStart.y, width, height);
    },

    moveDragBound(pos) {
      const size = this.$store.state.drawing.size;

      const scaledBlockSize = this.blockSize * this.scale;

      const xMul = this.$isMobile() ? 42 : 43;
      const yMul = this.$isMobile() ? 24 : 25;
      const min = this.$isMobile() ? 0 : 10;

      const set = {
        x: this.clamp(
          pos.x,
          min,
          xMul * scaledBlockSize - size.width * scaledBlockSize
        ),
        y: this.clamp(
          pos.y,
          min,
          yMul * scaledBlockSize - size.height * scaledBlockSize
        ),
      };

      return set;
    },

    resizeDragBound(pos) {
      const scaledBlockSize = this.blockSize * this.scale;

      // the minimum limit is the point where Dragging Box has size of 1 block
      const minX =
        this.denormalizedStart.x * this.scale +
        scaledBlockSize +
        this.handleSize / 2;
      const minY =
        this.denormalizedStart.y * this.scale +
        scaledBlockSize +
        this.handleSize / 2;

      const set = {
        x: this.clamp(pos.x, minX, 43 * scaledBlockSize),
        y: this.clamp(pos.y, minY, 25 * scaledBlockSize),
      };

      return set;
    },

    positionChange(target, x, y) {
      target.x(this.gridSnap(x));
      target.y(this.gridSnap(y));
    },

    gridSnap(number) {
      return Math.ceil(
        Math.round(number / this.blockSize) * this.blockSize -
          this.handleSize / 2
      );
    },

    clamp: (num, min, max) => Math.min(Math.max(num, min), max),

    draw: _.throttle(
      function(x, y, width, height) {
        const normalizedStartCoor = { x, y };
        const normalizedEndCoor = { x: x + width, y: y + height };
        this.$store.dispatch("drawing/selectBlockArea", {
          startCoor: normalizedStartCoor,
          endCoor: normalizedEndCoor,
        });
      },
      function() {
        return this.$isMobile() ? 33 : 5;
      }
    ),
  },
};
</script>

<style></style>
