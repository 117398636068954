import { Decimal } from "decimal.js";

export function truncate(str, maxDecimalDigits, roundDown) {
  if (roundDown === undefined) roundDown = true;
  const num = new Decimal(str);
  if (roundDown) {
    return num.toFixed(maxDecimalDigits, Decimal.ROUND_HALF_DOWN);
  } else {
    return num.toPrecision(maxDecimalDigits, Decimal.ROUND_HALF_UP);
  }
}