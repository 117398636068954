<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    width="400"
    persistent
  >
    <v-card>
      <RetroCardTitle closable @close="$emit('input', false)">{{
        title
      }}</RetroCardTitle>

      <v-card-text>
        <v-row
          class="ma-3 mt-4 d-flex justify-center"
          v-if="loading.approvalCheck"
        >
          <v-progress-circular
            :size="50"
            color="accent"
            indeterminate
          ></v-progress-circular>
        </v-row>

        <template v-else>
          <v-row class="mt-4 d-flex justify-center" v-if="!approved">
            <p>In order to stake or withdraw, you need to approve the Vault.</p>
            <v-btn
              class="ma-3"
              outlined
              color="accent darken-1"
              @click="$emit('approve')"
              :loading="loading.approve"
            >
              Approve
            </v-btn>
          </v-row>

          <template v-else>
            <v-row class="mt-6" justify="center">
              <v-col cols="12" xl="12">
                <h1 class="accent--text text-body-1 text-uppercase">Stake</h1>
                <div class="d-flex flex-column">
                  <div
                    class="ma-0 mb-2 w-100 d-flex justify-space-between align-center"
                  >
                    Balance: {{ balance }}
                    <v-spacer />
                    <v-btn
                      class="pa-0 ml-2"
                      outlined
                      small
                      color="accent"
                      @click="setMaxStake"
                      :disabled="disabled"
                    >
                      MAX
                    </v-btn>
                  </div>
                  <v-text-field
                    class="mb-0"
                    :rules="[rules.min, rules.max]"
                    outlined
                    v-model="internalStakeValue"
                    :disabled="disabled"
                  >
                    <template v-slot:append>
                      <p class="ma-1">{{ tokenSymbol }}</p>
                    </template>
                  </v-text-field>
                </div>
                <v-btn
                  color="accent darken-2"
                  block
                  @click.native="confirmStake()"
                  :disabled="isStakeValueInvalid()"
                >
                  Stake
                </v-btn>
              </v-col>

              <v-col cols="12" xl="12" v-if="stakeMessage">
                <v-alert dense outlined text type="info" color="yellow">
                  <span class="text-body-2 yellow--text text-center"
                    >When you stake, previously collected {{ reward }} BNB will
                    be harvested.</span
                  >
                </v-alert>
              </v-col>

              <v-col cols="12" xl="12">
                <v-divider horizontal></v-divider>
              </v-col>

              <v-col cols="12" xl="12">
                <h1 class="accent--text text-body-1 text-uppercase">
                  Withdraw
                </h1>
                <div class="d-flex flex-column">
                  <div
                    class="ma-0 mb-2 w-100 d-flex justify-space-between align-center"
                  >
                    Staked: {{ staked }}
                    <v-spacer />
                  </div>
                </div>
                <v-btn
                  color="accent darken-2"
                  block
                  @click.native="confirmWithdraw()"
                  :disabled="!(staked > 0)"
                >
                  Withdraw all
                </v-btn>
              </v-col>

              <v-col cols="12" xl="12" v-if="withdrawMessage">
                <v-alert dense outlined text type="info" color="yellow">
                  <span class="text-body-2 yellow--text text-center"
                    >When you withdraw, <strong>1%</strong> of the withdrawn BLS
                    tokens are burned.</span
                  >
                </v-alert>
              </v-col>

              <v-col cols="12" xl="12" v-if="liquidityPoolLink">
                <v-alert
                  dense
                  outlined
                  color="primary lighten-2"
                  class="text-center"
                >
                  <a
                    class="text-body-2 accent--text text-center"
                    :href="liquidityPoolLink"
                    target="_blank"
                    >> Provide liquidity</a
                  >
                </v-alert>
              </v-col>
            </v-row>
          </template>
        </template>
      </v-card-text>

      <!-- <v-card-actions>
        <v-row class="mb-6" justify="center">
          <v-col cols="6" xl="6">
            <v-btn color="accent" block @click.native="confirmStake()">
              Stake
            </v-btn>
          </v-col>
          <v-col cols="6" xl="6">
            <v-btn color="accent" block @click.native="confirmWithdraw()">
              Withdraw
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import RetroCardTitle from "../helpers/RetroCardTitle.vue";
export default {
  components: { RetroCardTitle },
  props: {
    value: Boolean,
    title: String,
    balance: String,
    staked: String,
    loading: Object,
    approved: Boolean,
    reward: String,
    stakeMessage: Boolean,
    withdrawMessage: Boolean,
    liquidityPoolLink: String,
    tokenSymbol: String,
  },
  data() {
    return {
      // internalDialog: this.dialog,
      // internalValue: 0,
      internalStakeValue: 0,
      internalWithdrawValue: 0,
      disabled: false,
    };
  },

  methods: {
    setMaxStake() {
      this.internalStakeValue = this.balance;
    },

    confirmStake() {
      this.$emit("confirmStake", this.internalStakeValue);
      this.$emit("input", false);
    },

    confirmWithdraw() {
      this.$emit("confirmWithdraw", this.staked);
      this.$emit("input", false);
    },
    isStakeValueInvalid() {
      let error =
        this.internalStakeValue.length == 0 || // empty field
        isNaN(this.internalStakeValue) || // not a valid number
        this.internalStakeValue === 0 || // equal to 0
        !(this.balance > 0); // no balance

      if (!error) {
        error = parseFloat(this.balance) < parseFloat(this.internalStakeValue); // insufficient balance
      }

      return error;
    },
  },
  computed: {
    rules() {
      return {
        min: () => {
          return true;
        },
        max: () => {
          return true;
        },
      };
    },
  },
};
</script>

<style></style>
