import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";
import axios from "axios";

const baseUri = process.env.VUE_APP_FUNCTIONS_URI;

function toHex(string) {
  return string
    .split("")
    .map((c) =>
      c
        .charCodeAt(0)
        .toString(16)
        .padStart(2, "0")
    )
    .join("");
}

// Step 1. Retrieve connected account securely
export async function requestAccount() {
  const provider = await detectEthereumProvider();

  if (!provider) {
    throw new Error(
      "Please install Metamask or use a desktop browser with Metamask"
    );
  }

  return await provider.request({ method: "eth_requestAccounts" });
}

// Step 2. Get the nonce of the connected account
export async function getUserNonce(address) {
  const checkSumAddress = ethers.utils.getAddress(address);

  const res = await axios.post(`${baseUri}/getNonceToSign`, {
    walletAddress: checkSumAddress,
  });

  return res.data.data;
}

// Step 3. Get the user to sign the nonce with their private key
export async function signRequest(address, nonce) {
  const provider = await detectEthereumProvider();

  if (!provider) {
    throw new Error(
      "Please install Metamask or use a desktop browser with Metamask"
    );
  }

  const checkSumAddress = ethers.utils.getAddress(address);

  return await provider.request({
    method: "personal_sign",
    params: [`0x${toHex(nonce)}`, checkSumAddress],
  });
}

export async function getProfile(address) {
  const checkSumAddress = ethers.utils.getAddress(address);

  const res = await axios.get(`${baseUri}/getProfile`, {
    params: { walletAddress: checkSumAddress },
  });

  return res.data.data;
}

export async function setProfile(address, signature, args) {
  let formData = new FormData();
  if (args.displayName || args.displayName === "") {
    formData.append("displayName", args.displayName);
  }
  if (args.twitterHandle || args.twitterHandle === "") {
    formData.append("twitterHandle", args.twitterHandle);
  }
  if (args.profilePicture || args.profilePicture === "") {
    formData.append("profilePicture", args.profilePicture);
  }

  const checkSumAddress = ethers.utils.getAddress(address);

  const res = await axios.post(`${baseUri}/setProfile`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    params: { walletAddress: checkSumAddress, signature },
  });

  return res.data.data;
}

export async function getNfts(ids) {
  const res = await axios.get(`${baseUri}/getNft`, {
    params: { ids },
  });

  return res.data.data;
}
