<template>
  <v-container class="d-flex align-stretch flex-column">
    <div class="box mt-4">
      <h1 class="box-title no-border">
        INVITE A FRIEND, EARN BNBs!
      </h1>

      <!-- <div class="d-flex justify-center ma-2" v-if="!connected">
        <ConnectButton mini />
      </div> -->

      <v-container
        class="d-flex align-center"
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      >
        <v-container
          class="box-link ma-4 d-flex flex-grow-1"
          :class="{ 'flex-column': $vuetify.breakpoint.xs }"
        >
          <span class="flex-grow-1 text-break ml-2" v-if="!connected"
            >Connect with your wallet to get the referral link</span
          >
          <span class="flex-grow-1 text-break ml-2" v-else>{{ refLink }}</span>
          <ConnectButton mini v-if="!connected" class="ma-2" />
          <v-btn
            v-else
            color="yellow black--text ma-2"
            @click="copy(refLink)"
            :block="$vuetify.breakpoint.xs"
          >
            Copy
          </v-btn>
        </v-container>
        <ShareNetwork
          style="width: 100%; flex-basis: 0"
          v-if="connected"
          network="twitter"
          :url="refLink"
          title="Mint a Space Ape. You get 5% discount on all your mints!"
          hashtags="spaceapes,1000blocks,nft,bsc"
        >
          <v-btn :block="$vuetify.breakpoint.smAndDown">Share on Twitter</v-btn>
        </ShareNetwork>
      </v-container>
      <p class="ma-4 text-subtitle-2">
        <!-- Share the link above to your friends!<br /> -->
        <!-- <ul style="list-style: circle;"> -->
          <ol>- For every mint from your referrals you will get 10% of the mint amount.</ol>
          <ol>- Referral Mint Rewards will be airdropped to your wallet.</ol>
          <ol>- In case we mint all 2101 SpaceApes, the TOP 5 supporters will get 4.2 BNB each!</ol>
        <!-- </ul> -->
      </p>
    </div>

    <div class="box mt-4" v-if="connected">
      <div class="no-border pa-4 d-flex"
      :class="{'flex-column': $vuetify.breakpoint.smAndDown}">
        <div class="mr-6">
        <span class="yellow--text text-uppercase mr-2">My Position</span>
        <span class="white--text"
          >#{{ me.position }} ({{ me.refCount }}
          {{ me.refCount === 1 ? "mint" : "mints" }})</span
        >
        </div>
        <div class="mr-6">
          <span class="yellow--text text-uppercase mr-2"
            >Referral MINT REWARDS</span
          >
          <span class="white--text">{{ truncate(me.refReward, 4) }} BNB</span>
        </div>
        <div class="mr-6">
          <span class="yellow--text text-uppercase mr-0">
            Minted
          </span>
          <span class="white--text">{{ mintedCount }}/{{ maxCount }}</span>
        </div>
      </div>
    </div>

    <div class="box mt-4">
      <h1 class="box-title no-border">
        TOP 10 SUPPORTERS
      </h1>

      <div
        class="d-flex top-border"
        style="height: 120px;"
        v-for="wallet in topSupporters"
        :key="wallet.address"
      >
        <!-- <img style="aspect-ratio: 1/1" src="@/assets/no_nft.png" /> -->
        <div class="ma-7 flex-grow-1 d-flex align-center flex-wrap">
          <p class="ma-1 flex-grow-1 flex-shrink-1">
            {{ shortenAddress(wallet.address) }}
          </p>
          <p class="ma-1 yellow--text">
            {{ wallet.refCount }} {{ wallet.refCount === 1 ? "mint" : "mints" }}
          </p>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { db } from "../../services/firebase";
import { truncate } from "../../services/helpers";
import { mapGetters, mapState } from "vuex";
import ConnectButton from "../../components/cross-space/ConnectButton.vue";

export default {
  data() {
    return {
      supporters: [],
    };
  },
  computed: {
    ...mapState("mint", ["refLink", "mintedCount", "maxCount"]),
    ...mapState("wallet", ["connected"]),
    ...mapGetters("wallet", ["address"]),

    me() {
      if (this.connected) {
        return this.supporters.find(
          (wallet) => wallet.address === this.address
        );
      } else {
        return undefined;
      }
    },

    topSupporters() {
      return this.supporters.slice(0, 10);
    },
  },
  components: { ConnectButton },
  methods: {
    truncate(num, dec) {
      return truncate(num, dec);
    },
    copy(text) {
      navigator.clipboard.writeText(text);
    },
    shortenAddress(address) {
      return address && `${address.slice(0, 6)}...${address.slice(-4)}`;
    },
  },
  mounted() {
    this.unsubscribe = db
      .collection("nft-referral")
      .orderBy("refCount", "desc")
      // .limit(10)
      .onSnapshot((snap) => {
        this.supporters = [];
        snap.forEach((doc) =>
          this.supporters.push({
            ...doc.data(),
            position: this.supporters.length + 1,
          })
        );
      });
  },

  destroyed() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.promo-top {
  border: 1px solid #555555;
}

.box {
  background-color: black;
  border: 1px solid #555555;
}

.box-title {
  color: #1abfb8;
  padding: 14px;
  border-bottom: 1px solid #555555;
  font-size: 1.125rem;
}

.box-link {
  background-color: #45410f;
  border: 2px solid #897426;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
