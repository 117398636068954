<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <span class="line flex-grow-1"></span>
      <h1 class="title-text text-subtitle-1 mx-4"><slot></slot></h1>
      <span class="line flex-grow-1"></span>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.title-text {
  text-transform: uppercase;
  color: #c4c4c4;
}
.line {
  height: 1px;
  background-color: #979797;
}
</style>
