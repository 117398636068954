// import { blocksContract, signedBlocksContract } from "../crypto/ethers.js"
import { BigNumber, ethers } from "ethers";
import { truncate } from '../services/helpers'
import * as managerService from '../crypto/managerService.js'


export default { 
  namespaced: true,
  state: {
    loading: {
      info: true,
      harvest: false,
      harvestOld: false
    },
    userReward: 0.0,
    userRewardInWei: BigNumber.from(0),
    userRewardOldInWei: BigNumber.from(0),
    secPerBlock: 3,
    baseBlsPerBlockPerSec: BigNumber.from(0)
  },
  mutations: {
    poolInfoLoading(state, loading) {
      state.loading.info = loading
    },
    harvestLoading(state, loading) {
      state.loading.harvest = loading;
    },
    harvestOldLoading(state, loading) {
      state.loading.harvestOld = loading;
    },
    setUserReward(state, rewardInWei) {
      state.userRewardInWei = rewardInWei;
    },
    setUserRewardOld(state, rewardInWei) {
      state.userRewardOldInWei = rewardInWei;
    },
    clearUserReward(state) {
      state.userRewardInWei = BigNumber.from(0);
    },
    setBlsPerSec(state, blsPerBlock) {
      state.baseBlsPerBlockPerSec = blsPerBlock.div(state.secPerBlock);
    },
    stopRewards(state) {
      clearInterval(state.interval);
      state.userRewardInWei = BigNumber.from(0);
      state.baseBlsPerBlockPerSec = BigNumber.from(0);
    },
    setInterval(state, interval) {
      state.interval = interval;
    }
  },
  getters: {
    userReward(state) {
      return truncate(ethers.utils.formatEther(state.userRewardInWei), 6)
    },
    userRewardOld(state) {
      return state.userRewardOldInWei ? truncate(ethers.utils.formatEther(state.userRewardOldInWei), 6) : undefined
    },
    blsPerSec(state, getters, rootState) {
      return ethers.utils.formatEther(state.baseBlsPerBlockPerSec.mul(rootState.wallet.blocksOwned))
    },
    userRewardAsNumber(state) {
      return state.userRewardInWei.toNumber();
    },
    blsPerSecAsNumber(state) {
      return state.blsPerSec.toNumber();
    }
  },
  actions: {
    async getUserReward({commit, rootState}) {

      const provider = rootState.wallet.ethers.provider;

      const interval = setIntervalAndExecute(async function() {
        
        const managerContract = rootState.space.currentContracts.manager;
        try {
          const pendingBlsTokens = await managerService.pendingBlsTokens(managerContract);
          commit("setUserReward", pendingBlsTokens);

          const lastBlockNumber = await provider.getBlockNumber();
          const lastRewardsBlock = await managerService.lastRewardsBlock(managerContract);
          const rewardsFinishedBlockNumber = lastRewardsBlock.toNumber();
  
          if (lastBlockNumber > rewardsFinishedBlockNumber) {
            commit("setBlsPerSec", BigNumber.from(0));
          } else {
            const blsPerBlockAreaPerBlock = await managerService.blsPerBlockAreaPerBlock(managerContract);
            commit("setBlsPerSec", blsPerBlockAreaPerBlock);
          }
        } catch(err) {
          // dispatch("error/setErrorMessage", err.message, {root: true});
        }
      }, 6000);

      commit("setInterval", interval);

      commit("poolInfoLoading", false);
    },
    
    async getUserRewardOld({commit, rootState}) {

      // const provider = rootState.wallet.ethers.provider;

      const interval = setIntervalAndExecute(async function() {
        
        const managerContract = rootState.space.currentContracts.managerOld;
        if (managerContract) {
          try {
            const pendingBlsTokens = await managerService.pendingBlsTokens(managerContract);
            commit("setUserRewardOld", pendingBlsTokens);
          } catch(err) {
            // dispatch("error/setErrorMessage", err.message, {root: true});
          }
        }
      }, 6000);

      commit("setInterval", interval);

      commit("poolInfoLoading", false);
    },

    async harvestReward({commit, dispatch, rootState}) {
     
      commit("harvestLoading", true);
      try {
        const signedManagerContract = rootState.space.currentSignedContracts.manager;
        await managerService.claimBlsTokens(signedManagerContract);

        commit("clearUserReward");
        dispatch("wallet/getBlsBalance", null, {root: true})        
      } catch(err) {
        dispatch("error/setErrorMessage", "Currently not able to harvest, all good things take time.", {root: true})
      } finally {
        commit("harvestLoading", false);
      }
    },
    
    async harvestRewardOld({commit, dispatch, rootState}) {
     
      commit("harvestOldLoading", true);
      try {
        const signedManagerContract = rootState.space.currentSignedContracts.managerOld;
        await managerService.claimBlsTokens(signedManagerContract);

        dispatch("wallet/getBlsBalance", null, {root: true})        
      } catch(err) {
        dispatch("error/setErrorMessage", "Currently not able to harvest, all good things take time.", {root: true})
      } finally {
        commit("harvestOldLoading", false);
      }
    }
  }
};

function setIntervalAndExecute(fn, t) {
  fn();
  return(setInterval(fn, t));
}