<template>
  <v-container class="py-3">
    <!-- MOBILE -->
    <OverviewToolbar
      class="overview-mobile-menu pt-2 pb-2 black"
      v-if="$isMobile()"
    />

    <LegacyOverviewGrid />

    <OverviewTitle>Spaces</OverviewTitle>
    <Spaces active />

    <OverviewTitle>Archive</OverviewTitle>
    <Spaces archived />
  </v-container>
</template>

<script>
import OverviewTitle from "../components/overview/OverviewTitle.vue";
import Spaces from "../components/overview/Spaces.vue";
import LegacyOverviewGrid from "../components/overview/LegacyOverviewGrid.vue";
import OverviewToolbar from "../components/overview/OverviewToolbar.vue";

export default {
  components: {
    Spaces,
    OverviewTitle,
    LegacyOverviewGrid,
    OverviewToolbar,
  },
  created() {
    // this.$store.dispatch("vault/load");
  },
};
</script>

<style lang="scss">
.mobile-menu {
  // position: fixed;
  // width: 100%;
  z-index: 1;
}
</style>
