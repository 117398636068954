<template>
  <v-container
    :class="
      $vuetify.breakpoint.mobile
        ? 'd-flex flex-column align-start pa-0 ma-0 pt-6'
        : 'd-flex flex-row align-start pt-7'
    "
  >
    <!-- NFT -->
    <v-row
      :class="$vuetify.breakpoint.mobile ? 'mt-10 mb-10 pt-5 pb-10' : 'pa-0'"
      no-gutters
      :style="$vuetify.breakpoint.mobile ? 'width: 100%' : 'width: 70%;'"
    >
      <v-col
        v-for="t in maxItems"
        :key="t"
        @click="showNft(t)"
        cols="12"
        sm="3"
      >
        <FastNFT v-if="token(t) && connected" :nft="token(t)" />
        <!-- EMPTY -->
        <EmptyNFT v-else />
      </v-col>
    </v-row>
    <v-card
      class="black d-flex flex-column align-start "
      width="280"
      style="border: 1px solid grey !important;"
      v-if="!$isMobile()"
    >
      <v-card-title
        class="accent--text text-h6 pa-3"
        style="border-bottom: 1px solid grey !important; width: 100%;"
      >
        Status
      </v-card-title>
      <ConnectButton mini v-if="!connected" class="ma-2 align-self-center" />
      <v-card class="black pa-3" v-else width="100%">
        <div class="section d-flex flex-column">
          <div class="yellow--text text-body-1">Minted</div>
          <div class="white--text font-weight-bold text-h6">
            {{ ownedCount }}/10
          </div>
          <v-btn
            color="yellow"
            class="mt-2 mb-4 black--text"
            width="100%"
            block
            to="/mint/overview?mint=true"
            :disabled="!allowedToMint"
            >MINT</v-btn
          >
          <v-btn class="mb-2" block to="/mint/overview">
            BACK TO OVERVIEW
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-footer v-if="$isMobile()" fixed padless inset>
      <MintFooter @goToMint="$router.push('/mint/overview?mint=true')" />
    </v-footer>

    <NftDialog v-model="nftDialog" :details="nftDialogDetails" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ConnectButton from "../../components/cross-space/ConnectButton.vue";
import MintFooter from "../../components/mint/MintFooter.vue";
import NftDialog from "../../components/dialogs/NftDialog.vue";
import FastNFT from "./FastNft.vue";
import EmptyNFT from "./EmptyNft.vue";

export default {
  data() {
    return {
      nftDialog: false,
      nftDialogDetails: null,
    };
  },
  computed: {
    ...mapState("mint", [
      "loading",
      "ownedCount",
      "tokensList",
      "tokens",
      "allowedToMint",
    ]),
    ...mapGetters("mint", ["priceOfOne"]),
    ...mapState("wallet", ["connected"]),
    maxItems() {
      return Math.max(this.ownedCount, 10);
    },
  },
  methods: {
    ...mapActions("mint", ["mint"]),
    token(i) {
      return this.tokens && this.tokens[i - 1];
    },
    showNft(i) {
      const token = this.token(i);
      if (token && token.attributes) {
        this.nftDialogDetails = token;
        this.nftDialog = true;
      }
    },
  },
  components: {
    ConnectButton,
    MintFooter,
    NftDialog,
    FastNFT,
    EmptyNFT,
  },
};
</script>
