<template>
  <div>
    <div
      id="arken-root"
      data-chain="bsc"
      data-mode="dark"
      data-theme_color="#18dfd6"
      data-theme_text_color="#ffffff"
      data-base_token_address="0x34aa9099d924f3fb2377ff20d81b235311c15346"
      data-quote_token_address="0xe9e7cea3dedca5984780bafc599bd69add087d56"
      data-external_top_token_address="[]"
      data-custom_image_token='{"0xf2da079867785018e6d823dd0ff332f05aafe485":"https://dl.airtable.com/.attachmentThumbnails/bdaedb51ff140d3c86d43d700e0f20c4/400999e3","0x5d3a84b69d9dad4a1516e9f99cc5c55b13cf5406":"https://dl.airtable.com/.attachmentThumbnails/b52cf5ee3b652c8b288eb4791bea8562/6f5a2130","0x589f16e0a4cc4e205dabfe2610e718c3ba2a5fa3":"https://dl.airtable.com/.attachmentThumbnails/17b0fcf68bbd40ca1aebd0d7dd8e01d5/f9d7a12a","0xbc718075f36a37459d855bdaac5a3f057eb2d98b":"https://dl.airtable.com/.attachmentThumbnails/d9d913ca3ea993c258f224e8a771d024/b8bfdeb7","0xcd743f1a54f4b665286864c4867a7216805fe09a":"https://dl.airtable.com/.attachmentThumbnails/0a7278fba48ccd4e0a130d078a4544a1/ca4947d3","0xd06b9c961a6aa58a1b936239a30081f48881d708":"https://dl.airtable.com/.attachmentThumbnails/dcd4e05fb775e6e57dfcda85dec8add3/ef5036d4"}'
      data-widget_type="graph"
      data-graph_range="1"
      data-max_width="500px"
      data-height="200px"
      data-background_color_light="#ffffff"
      data-background_color_dark="#000000"
    ></div>
    <component
      :is="'script'"
      src="https://widget.arken.finance/widget.js"
      async
    ></component>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#arken-root {
  height: 310px;
}
</style>
