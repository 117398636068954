
export default { 
  namespaced: true,
  state: {
    mode: "view",
    modes: [
      "view",
      "draw",
      "confirm",
      "buy",
      "loading",
      "summary"
    ]
  },
  mutations: {
    startViewing(state) {
      state.mode = "view";
    },
    startDrawing(state) {
      state.mode = "draw";
    },
    startConfirming(state) {
      state.mode = "confirm";
    },
    startBuying(state) {
      state.mode = "buy";
    },
    startLoading(state) {
      state.mode = "loading";
    },
    startSummary(state) {
      state.mode = "summary";
    },
    setMode(state, mode) {
      if (state.modes.includes(mode)) {
        state.mode = mode;
      }
    },
  },
  actions: {
    setMode({commit}, mode) {
      commit("setMode", mode)
    }
  }
};
