<template>
  <div
    class="v-card__title accent darken-1 black--text text-body-1 text-uppercase px-3 py-2 d-flex"
  >
    <span class="flex-grow-1 flex-shrink-1"><slot></slot></span>
    <img
      v-if="closable"
      src="@/assets/UI-icon_CLOSE.svg"
      width="22"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
export default {
  props: {
    closable: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
