<template>
  <div class="flex-row-reverse justify-center align-center">
    <v-btn
      v-if="footer && !mini"
      color="accent darken-1 primary--text"
      dark
      v-show="!connected"
      :block="block"
      height="42"
      class="pa-0"
      min-width="0"
      @click="autoConnectCheck"
    >
      Connect
    </v-btn>
    <v-btn
      v-if="!footer && !mini"
      color="black"
      dark
      v-show="!connected"
      :block="block"
      height="42"
      class="pa-0"
      min-width="0"
      @click="autoConnectCheck"
    >
      <v-img src="@/assets/Info-icon_WALLET-disconnected.svg" width="36" />
    </v-btn>

    <v-btn v-if="mini" class="mini-connect" small @click="autoConnectCheck"
      >Connect</v-btn
    >
    <div class="d-flex flex-row justify-center align-center">
      <Avatar />

      <v-btn
        v-show="connected"
        color="black"
        class="pa-0"
        :block="block"
        height="42"
        min-width="0"
        @click="disconnectWallet()"
      >
        <div class="d-flex align-center">
          <v-img
            class="mr-2"
            src="@/assets/Info-icon_WALLET-connected.svg"
            width="36"
            v-if="!footer"
          />
          <div
            v-if="!$isMobile() || footer"
            class="d-flex flex-column align-start"
          >
            <p class="ma-0 font-weight-bold">{{ readableAddress }}</p>
            <p v-if="!footer" class="ma-0 grey--text" style="font-size: 0.8rem">
              BNB:{{ bnbBalance }} BLS:{{ blsBalance }}
            </p>
          </div>
        </div>
      </v-btn>
    </div>

    <ConnectDialog v-model="dialog"></ConnectDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Avatar from "./Avatar.vue";
import ConnectDialog from "../dialogs/ConnectDialog.vue";

export default {
  components: { ConnectDialog, Avatar },
  data() {
    return {
      dialog: false,
      deepLink: process.env.VUE_APP_METAMASK_DEEPLINK,
    };
  },
  props: {
    block: Boolean,
    footer: Boolean,
    mini: Boolean,
  },
  computed: {
    ...mapState("wallet", ["connected"]),
    ...mapGetters("wallet", ["readableAddress", "bnbBalance", "blsBalance"]),
  },
  methods: {
    ...mapActions("wallet", ["connectWallet", "disconnectWallet"]),
    setDialog(bool) {
      this.dialog = bool;
    },
    autoConnectCheck() {
      if (
        (window.ethereum !== undefined || window.web3 !== undefined) &&
        this.$isMobile()
      ) {
        this.connectWallet();
      } else {
        this.setDialog(true);
      }
    },
  },
};
</script>

<style>
.mini-connect {
  border: 1px solid #444;
}
</style>
