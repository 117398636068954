<template>
  <div>
    <div class="section d-flex flex-column">
      <div class="mint-title text-uppercase pa-0">
        Select mint amount
      </div>
      <div class="collection-grid mt-4">
        <div
          class="mint-item"
          :class="{ 'selected-item': amount === i }"
          v-for="i in 10"
          :key="i"
          @click="amount = i"
        >
          {{ i }}
        </div>
      </div>
    </div>
    <div class="section d-flex flex-column" v-if="discount > 0">
      <div class="label">Full price per mint</div>
      <div class="value">{{ fullPriceOfOne }} BNB</div>
    </div>
    <div class="section d-flex flex-column" v-if="discount > 0">
      <div class="label">Discount</div>
      <div class="value">{{ discount }}%</div>
    </div>
    <div class="section d-flex flex-column">
      <div class="label">Price per mint</div>
      <div class="value">{{ priceOfOne }} BNB</div>
    </div>
    <div class="section d-flex flex-column">
      <div class="label">Total</div>
      <div class="value">{{ priceOfMany(amount) }} BNB</div>
    </div>
    <div class="section d-flex mt-2">
      <v-btn class="flex-grow-1 mr-1" @click="$emit('cancel')">Cancel</v-btn>
      <v-btn
        class="flex-grow-1 ml-1"
        color="accent black--text"
        @click="mint(amount)"
        :loading="loading.mint"
        :disabled="!connected || !allowedToMint"
        >Confirm</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      amount: 1,
    };
  },
  computed: {
    ...mapState("mint", [
      "loading",
      "maxCount",
      "mintedCount",
      "allowedToMint",
      "discount",
      "discountType",
    ]),
    ...mapGetters("mint", ["priceOfOne", "priceOfMany", "fullPriceOfOne"]),
    ...mapState("wallet", ["connected"]),
  },
  methods: {
    ...mapActions("mint", ["mint"]),
  },
  watch: {
    "loading.mint"(value) {
      if (value === false) {
        this.$emit("cancel");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mint-sidebar {
  width: 280px;
  margin: 24px;
  margin-left: 0;
  z-index: 0;
  background-color: black;
  border: 1px solid #555555;

  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.mint-title {
  color: #1abfb8;
  padding: 12px;
  border-bottom: 1px solid #555555;
  font-size: 1.125rem;
}

.collection-grid {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 20%;
  gap: 6px;
  .collection-item,
  .mint-item {
    width: calc(20% - 5px);
    aspect-ratio: 1;
    background-color: #2e2e2e;
    border: 1px solid #555555;
  }
  .mint-item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.selected-item {
      background-color: #015653;
      border: 1px solid #05827d;
    }
  }
}
</style>
