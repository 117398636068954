<template>
  <v-container>
    <v-row>
      <v-col cols="12" offset="0" sm="8" offset-sm="2" md="6" offset-md="0">
        <img :src="image" width="100%" />
      </v-col>
      <v-col cols="12" md="6">
        <div class="section bottom-border">
          <div class="label">Space Ape</div>
          <div class="value">{{ cleanedName }}</div>
        </div>
        <div class="section bottom-border">
          <div class="label">Lineage</div>
          <div class="value">{{ lineage }}</div>
        </div>
        <div class="section bottom-border">
          <div class="label">
            Rarity
            <span style="color: #bbbb99">(Beta / Subject to change)</span>
          </div>
          <!-- <div class="value">{{ rarityClass.name }} ({{ rarity }})</div> -->
          <!-- <div class="rarity d-flex mt-2">
            <span class="mr-2">Common</span>
            <div
              class="rarity-point"
              v-for="clazz in rarityClasses"
              :key="clazz.index"
              :class="{ active: rarityClass.index >= clazz.index }"
            ></div>
            <span class="ml-2">Ultra Rare</span>
          </div> -->
          <div class="rarity d-flex mt-2">
            <span class="mr-2">Common</span>
            <div class="rarity-percent">
              <div
                class="rarity-percent-full"
                :style="rarityPercentStyle"
              ></div>
            </div>
            <span class="ml-2">Ultra Rare</span>
          </div>
        </div>
        <div class="section bottom-border">
          <div class="label">Traits</div>
          <v-row dense>
            <v-col
              cols="12"
              sm="4"
              md="6"
              lg="4"
              v-for="trait in cleanedAttributes"
              :key="trait.trait_type"
            >
              <div class="trait fill-height d-flex flex-column">
                <div class="trait-name">{{ trait.trait_type }}</div>
                <div class="trait-value">{{ trait.value }}</div>
                <v-spacer />
                <div class="trait-percent mt-1">
                  {{ traitPercentage(trait) }}% occurance
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IPFSGatewayTools from "@pinata/ipfs-gateway-tools/dist/browser";
// import rarityArray from "../../services/rarity_1.json";
import rarityArray from "../../services/rarity.json";
import traitsArray from "../../services/traits.json";

export default {
  props: {
    details: Object,
  },
  data() {
    return {
      // rarityClasses: {
      //   0: {
      //     index: 0,
      //     name: "Common+",
      //     range: [10762, 50000],
      //   },
      //   1: {
      //     index: 1,
      //     name: "Rare",
      //     range: [9501, 10761],
      //   },
      //   2: {
      //     index: 2,
      //     name: "Super Rare",
      //     range: [8177, 9500],
      //   },
      //   3: {
      //     index: 3,
      //     name: "Ultra Rare",
      //     range: [0, 8176],
      //   },
      // },
    };
  },
  computed: {
    lineage() {
      const rank = this.details.attributes.find(
        (trait) => trait.trait_type === "Lineage"
      );
      return rank.value || "none";
    },
    cleanedName() {
      return this.details.name.split(" - ")[1];
    },
    cleanedAttributes() {
      return this.details.attributes
        .filter((trait) => trait.trait_type !== "Generation")
        .filter((trait) => trait.trait_type !== "Lineage");
    },
    image() {
      if (this.details && this.details.image) {
        const gatewayTools = new IPFSGatewayTools();
        return gatewayTools.convertToDesiredGateway(
          this.details.image,
          "https://ipfs.io"
        );
      } else {
        return require("@/assets/no_nft.png");
      }
    },
    rarity() {
      return rarityArray.find((rarity) => rarity.id === this.details.tokenId)
        .rarity;
    },
    rarityPercentStyle() {
      return {
        width: this.rarity + "%",
      };
    },
    // rarityClass() {
    //   for (let clazz in this.rarityClasses) {
    //     if (
    //       this.rarity >= this.rarityClasses[clazz].range[0] &&
    //       this.rarity <= this.rarityClasses[clazz].range[1]
    //     ) {
    //       return this.rarityClasses[clazz];
    //     }
    //   }
    //   return undefined;
    // },
  },
  methods: {
    traitPercentage(selectedTrait) {
      const trait = traitsArray.find(
        (t) => t.trait_type === selectedTrait.trait_type
      );
      if (trait) {
        const value = trait.values.find((v) => v.name === selectedTrait.value);
        if (value) {
          return value.percent;
        }
      }
      return "-";
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  .rarity {
    font-size: 0.9rem;
    font-weight: bold;
  }

  .rarity-point {
    margin-top: 2px;
    width: 42px;
    height: 16px;
    background: #555555;

    &.active {
      background: #ffff00;
    }
  }

  .rarity-percent {
    margin-top: 2px;
    width: 168px;
    height: 16px;
    background: #555555;

    .rarity-percent-full {
      height: 16px;
      background: #ffff00;
    }
  }

  .trait {
    background-color: #45410f;
    border: 2px solid #897426;
    padding: 8px;
    color: white;

    font-size: 0.725rem;
    text-align: center;

    .trait-name {
      font-weight: bold;
    }

    .trait-value {
      line-height: 0.9rem;
    }
    .trait-percent {
      line-height: 0.9rem;
      color: #bbbb88;
    }
  }
}
</style>
