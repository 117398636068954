<template>
  <v-dialog
    value="true"
    fullscreen
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <template v-slot:default="dialog">
      <v-card>
        <RetroCardTitle
          closable
          @close="
            dialog.value = false;
            setMode('view');
          "
        >
          CLAIM BLOCKS
        </RetroCardTitle>
        <v-card-text>
          <ClaimInfo
            :spaceId="spaceId"
            :claimToken="claimToken"
            :claimFixed="claimFixed"
            :takeover="takeover"
            :takeoverToken="takeoverToken"
            :pixelArt="pixelArt"
          />
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import RetroCardTitle from "../../helpers/RetroCardTitle.vue";
import ClaimInfo from "../side-panel/ClaimInfo.vue";
export default {
  components: {
    RetroCardTitle,
    ClaimInfo,
  },
  props: {
    spaceId: Number,
    claimToken: String,
    claimFixed: Boolean,
    takeover: Boolean,
    takeoverToken: String,
    pixelArt: Boolean,
  },
  methods: {
    ...mapActions("mode", ["setMode"]),
  },
};
</script>

<style></style>
