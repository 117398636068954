<template>
  <v-dialog :value="value" @input="$emit('input', $event)" width="500">
    <v-card>
      <RetroCardTitle closable @close="setDialog(false)"
        >Connect Wallet</RetroCardTitle
      >
      <div
        :class="
          !$isMobile()
            ? 'd-flex justify-center align-center py-5'
            : 'flex-column'
        "
      >
        <v-card-actions class="flex-column" v-show="!$isMobile()">
          <v-btn
            height="auto"
            width="auto"
            @click="
              connectWallet();
              setDialog(false);
            "
          >
            <v-img
              contain
              src="@/assets/UI-icon_METAMASK.svg"
              height="150px"
              width="150px"
            ></v-img>
          </v-btn>
          <v-card-text class="text-center">
            Connect with Metamask
          </v-card-text>
        </v-card-actions>
        <v-divider vertical v-show="!$isMobile()"></v-divider>
        <v-card-actions v-show="$isMobile()">
          <v-btn
            height="auto"
            width="auto"
            @click="
              openDeepLink();
              setDialog(false);
            "
          >
            <v-img
              height="auto"
              width="auto"
              contain
              src="@/assets/UI-icon_METAMASK.svg"
            ></v-img>
          </v-btn>
          <v-card-text class="text-left">
            Connect via Metamask Browser
          </v-card-text>
        </v-card-actions>
        <v-card-actions :class="!$isMobile() ? 'flex-column' : 'flex-row'">
          <v-btn
            height="auto"
            width="auto"
            @click="
              useWalletConnect();
              setDialog(false);
            "
          >
            <v-img
              contain
              src="@/assets/UI-icon_WALLETCONNECT.svg"
              :height="!$isMobile() ? '150px' : 'auto'"
              :width="!$isMobile() ? '150px' : 'auto'"
            ></v-img>
          </v-btn>
          <v-card-text :class="!$isMobile() ? 'text-center' : 'text-left'">
            Connect via WalletConnect
          </v-card-text>
        </v-card-actions>
      </div>
      <v-divider v-show="!$isMobile()"></v-divider>
      <v-card-actions v-show="!$isMobile()">
        <v-btn
          color="accent lighten-1 primary--text"
          @click="
            addTokenToWallet();
            setDialog(false);
          "
        >
          Add to Metamask
        </v-btn>
        <v-card-text>
          Add the BLS token automatically
        </v-card-text>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import RetroCardTitle from "../helpers/RetroCardTitle.vue";

export default {
  components: {
    RetroCardTitle,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      deepLink: process.env.VUE_APP_METAMASK_DEEPLINK,
    };
  },
  methods: {
    ...mapActions("wallet", [
      "connectWallet",
      "useWalletConnect",
      "addTokenToWallet",
    ]),

    setDialog(bool) {
      this.$emit("input", bool);
    },
    openDeepLink() {
      window.open(this.deepLink);
    },
  },
};
</script>

<style></style>
