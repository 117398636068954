export const space01 = {
  manager: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER01,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_MANAGER01))
  },
  space: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE01,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_SPACE01))
  }
}

export const space02 = {
  manager: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER02,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_MANAGER02))
  },
  space: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE02,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_SPACE02))
  },
  managerOld: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER02_OLD,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_MANAGER02_OLD))
  }
}

export const space03 = {
  manager: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER03,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_MANAGER03))
  },
  space: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE03,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_SPACE03))
  }
}

export const space04 = {
  manager: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER04,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_MANAGER04))
  },
  space: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE04,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_SPACE04))
  }
}

export const space05 = {
  manager: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER05,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_MANAGER05))
  },
  space: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE05,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_SPACE05))
  }
}

export const space06 = {
  manager: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER06,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_MANAGER06))
  },
  space: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE06,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_SPACE06))
  }
}

export const space07 = {
  manager: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER07,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_MANAGER07))
  },
  space: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE07,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_SPACE07))
  }
}

export const space08 = {
  manager: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER08,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_MANAGER08))
  },
  space: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE08,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_SPACE08))
  }
}

export const space09 = {
  manager: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER09,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_MANAGER09))
  },
  space: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE09,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_SPACE09))
  }
}

export const space10 = {
  manager: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER10,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_MANAGER10))
  },
  space: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE10,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_SPACE10))
  }
}

export const space11 = {
  manager: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER11,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_MANAGER11))
  },
  space: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE11,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_SPACE11))
  }
}

export const space12 = {
  manager: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER12,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_MANAGER12))
  },
  space: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE12,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_SPACE12))
  }
}

export const space13 = {
  manager: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_MANAGER13,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_MANAGER13))
  },
  space: {
    address: process.env.VUE_APP_CONTRACT_ADDRESS_SPACE13,
    abi: JSON.parse(window.atob(process.env.VUE_APP_CONTRACT_ABI_SPACE13))
  }
}

