export default {
  namespaced: true,
  state: {
    showError: false,
    errorMessage: "",
    showSuccess: false,
    successMessage: "",
  },
  mutations: {
    setErrorMessage(state, errorMessage) {
      state.errorMessage = errorMessage;
      state.showError = true;
    },
    clearErrorMessage(state) {
      state.errorMessage = "";
      state.showError = false;
    },
    setSuccessMessage(state, successMessage) {
      state.successMessage = successMessage;
      state.showSuccess = true;
    },
    clearSuccessMessage(state) {
      state.successMessage = "";
      state.showSuccess = false;
    },
  },
  actions: {
    setErrorMessage({ commit }, errorMessage) {
      commit("setErrorMessage", errorMessage);
      setTimeout(() => {
        commit("clearErrorMessage");
      }, 7000);
    },
    setSuccessMessage({ commit }, successMessage) {
      commit("setSuccessMessage", successMessage);
      setTimeout(() => {
        commit("clearSuccessMessage");
      }, 7000);
    },
  },
};
