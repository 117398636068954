<template>
  <v-layer ref="marks">
    <!-- <v-circle
      :radius="8.5"
      :x="selectedTopCircleX"
      :y="-15.5"
      :fill="'#18dfd6'"
    ></v-circle>
    <v-circle
      :radius="9"
      :x="-15.5"
      :y="blockSize / 2 + blockSize * 7"
      :fill="'#18dfd6'"
    ></v-circle> -->
    <v-text
      v-for="i in 42"
      :key="`column-${i}`"
      :config="{
        ...topMarkConfig,
        text: String(i),
        x: blockSize * (i - 1),
        //fill: i === selectedTop ? 'black' : 'white',
      }"
    ></v-text>

    <v-text
      v-for="i in 24"
      :key="`row-${i}`"
      :config="{
        ...leftMarkConfig,
        text: letterFromNumber(i),
        y: blockSize * i,
        //fill: i + 1 === selectedLeft ? 'black' : 'white',
      }"
    ></v-text>
  </v-layer>
</template>

<script>
export default {
  props: ["blockSize", "coor"],
  data() {
    return {
      selectedTop: 32,
      selectedLeft: 9,

      topMarkConfig: {
        fill: "white",
        fontSize: 12,
        fontStyle: "bold",
        width: this.blockSize,
        align: "center",
        y: 0,
        offset: {
          y: 20,
        },
      },
      leftMarkConfig: {
        fill: "white",
        fontSize: 12,
        fontStyle: "bold",
        width: 16,
        height: this.blockSize,
        // lineHeight: this.blockSize,
        align: "center",
        x: 0,
        offset: {
          x: 23,
          y: 25,
        },
      },
    };
  },
  computed: {
    selectedTopCircleX() {
      return this.blockSize / 2 + (this.selectedTop - 1) * this.blockSize;
    },

    selectedLeftCircleY() {
      return this.blockSize / 2 + (this.selectedLeft - 1) * this.blockSize;
    },
  },
  methods: {
    letterFromNumber(number) {
      return String.fromCharCode(65 + number - 1);
    },
  },

  mounted() {},
};
</script>

<style></style>
