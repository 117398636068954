<template>
  <!-- <number :from="start" :to="end" :format="format" :duration="durationInSec" /> -->
  <span>{{ displayedAmount }}</span>
</template>

<script>
export default {
  props: {
    startAmount: {
      type: Number,
      default: 0,
    },
    incrementPerSec: {
      type: Number,
      default: 0,
    },
    decimals: {
      type: Number,
      default: 0,
      validator(decimals) {
        return decimals >= 0;
      },
    },
  },
  data() {
    return {
      duration: 3600,
      separator: ",",
      decimalSeparator: ".",

      currentAmount: 0,
      currentStartAmount: 0,
      currentDuration: 0,

      timestamp: 0,
      startTimestamp: 0,
      animationFrame: 0,
    };
  },
  watch: {
    startAmount() {
      this.cancelAnimation();
      this.currentAmount = this.startAmount;
      this.start();
    },
    // incrementPerSec() {

    // }
  },
  computed: {
    endAmount() {
      return this.startAmount + this.incrementPerSec * this.duration;
    },

    displayedAmount() {
      return `${this.formatedAmount}`;
    },

    formatedAmount() {
      const regex = /(\d+)(\d{3})/;
      let numberString = this.currentAmount.toFixed(this.decimals);
      numberString += "";
      let numberArray = numberString.split(".");
      let numbers = numberArray[0];
      let decimals =
        numberArray.length > 1 ? this.decimalSeparator + numberArray[1] : "";
      let isNumber = !isNaN(parseFloat(this.separator));
      if (this.separator && !isNumber) {
        while (regex.test(numbers))
          numbers = numbers.replace(regex, "$1" + this.separator + "$2");
      }

      return numbers + decimals;
    },
  },
  methods: {
    start() {
      this.cancelAnimation();
      this.currentStartAmount = this.startAmount;
      this.startTimestamp = null;
      this.currentDuration = this.duration * 1000;
      this.animationFrame = window.requestAnimationFrame(this.counting);
    },

    counting(timestamp) {
      // Calculate time change
      this.timestamp = timestamp;
      if (!this.startTimestamp) this.startTimestamp = timestamp;
      let progress = timestamp - this.startTimestamp;

      // Calculate the current amount (start + fraction of change)
      this.currentAmount =
        this.currentStartAmount +
        (this.endAmount - this.currentStartAmount) *
          (progress / this.currentDuration);

      // Adjust te current amount, if it reaches the end
      this.currentAmount =
        this.currentAmount > this.endAmount
          ? this.endAmount
          : this.currentAmount;

      // Repeat the look until it reaches the end
      if (progress < this.currentDuration)
        this.animationFrame = window.requestAnimationFrame(this.counting);
      else this.$emit("finished");
    },

    cancelAnimation() {
      if (this.animationFrame) window.cancelAnimationFrame(this.animationFrame);
    },
  },

  mounted() {
    this.currentAmount = this.startAmount;
    this.start();
    this.$emit("mounted");
  },

  unmounted() {
    this.cancelAnimation();
  },
};
</script>

<style></style>
