import { BigNumber, ethers } from "ethers";
import { truncate } from "../services/helpers";
import {
  getEthers,
  connectToContracts,
  getAccount,
  getBalance,
  connectWC,
  isWCConnected,
  wcEvents,
} from "../crypto/getEthers";
import * as connect from "../crypto/connect.js";
// import * as posterService from '../services/posterService.js'
import * as tokenService from "../crypto/tokenService.js";
import * as managerService from "../crypto/managerService.js";

export default {
  namespaced: true,
  state: {
    supportedNetworkId: 97,

    ethers: {
      provider: null,
      signer: null,
      networkId: null,

      // contract: null,
      // signedContract: null,

      // poolContract: null,
      // signedPoolContract: null,

      spacesContracts: {
        0: {
          space: null,
          manager: null,
          managerOld: null,
        },
      },
      signedSpacesContracts: {
        0: {
          space: null,
          manager: null,
          managerOld: null,
        },
      },

      tokenContract: null,
      signedTokenContract: null,

      vaultContract: null,
      signedVaultContract: null,
    },

    connected: false,
    account: null,
    balanceInWei: 0,

    balances: {
      bnb: BigNumber.from(0),
      bls: BigNumber.from(0),
      lpToken: BigNumber.from(0),
    },

    error: null,
    hasBlockArea: false,
    blockArea: null,

    blocksOwned: BigNumber.from(0),
  },
  mutations: {
    setUpEthers(state, ethers) {
      state.ethers = ethers;
    },
    web3Supported(state) {
      state.web3Supported = true;
    },

    connectWallet(state, account) {
      state.connected = true;
      state.account = account;
    },
    disconnectWallet(state) {
      state.connected = false;
      state.account = null;

      // WalletConnect provider removal
      // Unlike Metamask Extension or In-App, WC does not inject API
      state.ethers.provider = null;
    },

    setBalance(state, balanceInWei) {
      state.balanceInWei = balanceInWei;
      state.balances.bnb = balanceInWei;
    },

    setBnbBalance(state, balance) {
      state.balances.bnb = balance;
    },
    setBlsBalance(state, balance) {
      state.balances.bls = balance;
    },
    setLPBalance(state, balance) {
      state.balances.lpToken = balance;
    },

    setWalletError(state, errorMessage) {
      state.error = errorMessage;
    },

    hasBlockArea(state, blockArea) {
      state.hasBlockArea = true;
      state.blockArea = blockArea;
    },

    setBlocksOwned(state, blocksOwned) {
      state.blocksOwned = blocksOwned;
    },

    backendAndContractSynced(state, synced) {
      state.backendAndContractSynced = synced;
    },
  },
  getters: {
    balance(state) {
      return truncate(ethers.utils.formatEther(state.balances.bnb), 4);
    },
    bnbBalance(state) {
      return truncate(ethers.utils.formatEther(state.balances.bnb), 2);
    },
    blsBalance(state) {
      return truncate(ethers.utils.formatEther(state.balances.bls), 2);
    },
    lpTokenBalance(state) {
      return truncate(ethers.utils.formatEther(state.balances.lpToken), 2);
    },
    blsBalanceFull(state) {
      return ethers.utils.formatEther(state.balances.bls);
    },
    lpTokenBalanceFull(state) {
      return ethers.utils.formatEther(state.balances.lpToken);
    },
    address(state) {
      return state.account && ethers.utils.getAddress(state.account);
    },
    readableAddress(state) {
      const address = state.account && ethers.utils.getAddress(state.account);
      return address && `${address.slice(0, 6)}...${address.slice(-4)}`;
    },
    blocksOwned(state) {
      return state.blocksOwned ? state.blocksOwned.toNumber() : 0;
    },
  },
  actions: {
    async preConnect() {},

    async setUpEthers({ commit, dispatch }) {
      try {
        const eth = await getEthers();
        commit("setUpEthers", eth);
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      }
    },
    async setUpWC({ commit, dispatch }) {
      try {
        const eth = await isWCConnected(dispatch);

        if (eth) {
          commit("setUpEthers", eth);
          wcEvents(eth.provider, dispatch);
        }
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      }
    },
    async connectWallet({ commit, dispatch, state }) {
      if (connect.isOnCorrectNetwork(state.ethers.networkId)) {
        const eth = await connectToContracts();
        commit("setUpEthers", eth);
        dispatch("space/setSpaceContracts", eth.spacesContracts, { root: true, });
        dispatch("space/setSignedSpaceContracts", eth.signedSpacesContracts, { root: true, });
        dispatch("pool/setPoolContracts", eth.poolsContracts, { root: true, });
        dispatch("pool/setSignedPoolContracts", eth.signedPoolsContracts, { root: true, });

        const account = await getAccount();
        if (account) {
          commit("connectWallet", account);

          dispatch("mint/createReferral", account, { root: true });

          dispatch("poster/checkWalletForPoster", null, { root: true });
          dispatch("getBnbBalance");
          dispatch("getBlsBalance");
          dispatch("getLPBalance");
          dispatch("getBlocksOwned");

          dispatch("vault/fetchUserInfo", null, { root: true });
          dispatch("vault/fetchRewards", null, { root: true });
          dispatch("vault/checkForApproval", null, { root: true });

          dispatch("farm/fetchUserInfo", null, { root: true });
          dispatch("farm/fetchRewards", null, { root: true });
          dispatch("farm/checkForApproval", null, { root: true });

          dispatch("pool/fetchUserInfo", null, { root: true });
          dispatch("pool/fetchRewards", null, { root: true });
          dispatch("pool/checkForApproval", null, { root: true });
          // dispatch("vault/allStakedTokens", null, {root: true}); // Already called in a loop

          dispatch("harvest/getUserReward", null, { root: true });
          dispatch("harvest/getUserRewardOld", null, { root: true });

          dispatch("mint/loadMintData", null, { root: true });

          dispatch("space/backendAndContractSynced", {}, { root: true });

          connect.watchOnNetworkChange();
          connect.watchOnAccountChange();
        }
      } else {
        try {
          await connect.requestNetworkChange();
        } catch (err) {
          dispatch("error/setErrorMessage", err.message, { root: true });
        }
      }
    },

    async disconnectWallet({ commit, dispatch }) {
      commit("disconnectWallet");

      // remove from localStorage, ready for next connect
      localStorage.removeItem("walletconnect");

      // prevent mint store values from spilling across account swaps
      dispatch("mint/resetMintState", null, { root: true });

      commit(
        "wallet/hasBlockArea",
        { blockStart: null, blockEnd: null },
        { root: true }
      );

      commit("setBnbBalance", BigNumber.from(0));
      commit("setBlsBalance", BigNumber.from(0));
      commit("setLPBalance", BigNumber.from(0));
      commit("harvest/stopRewards", null, { root: true });
      commit("vault/stopRewards", null, { root: true });
      commit("vault/setDeposited", BigNumber.from(0), { root: true });
    },

    /**
     * Simplifies the adding of the token to Metamask
     */
    async addTokenToWallet({ dispatch }) {
      try {
        connect.addTokenToMetamask(dispatch);
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      }
    },

    /**
     * When accessed, will prompt the QR Modal
     */
    async useWalletConnect({ commit, dispatch }) {
      try {
        // Provider set up via WalletConnect lib
        const wcObj = await connectWC(dispatch);
        commit("setUpEthers", wcObj);
        dispatch("space/setSpaceContracts", wcObj.spacesContracts, {
          root: true,
        });
        dispatch("space/setSignedSpaceContracts", wcObj.signedSpacesContracts, {
          root: true,
        });

        // There's always one account, currently selected one
        const account = wcObj.provider.provider.accounts[0];
        commit("connectWallet", account);

        dispatch("poster/checkWalletForPoster", null, { root: true });
        dispatch("getBnbBalance");
        dispatch("getBlsBalance");
        dispatch("getLPBalance");
        dispatch("getBlocksOwned");

        dispatch("vault/fetchUserInfo", null, { root: true });
        dispatch("vault/fetchRewards", null, { root: true });
        dispatch("vault/checkForApproval", null, { root: true });

        dispatch("farm/fetchUserInfo", null, { root: true });
        dispatch("farm/fetchRewards", null, { root: true });
        dispatch("farm/checkForApproval", null, { root: true });

        dispatch("harvest/getUserReward", null, { root: true });
        dispatch("space/backendAndContractSynced", {}, { root: true });

        // Detect network change
        connect.watchOnNetworkChangeWC(wcObj.provider);
        connect.watchOnAccountChangeWC(wcObj.provider);
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      }
    },

    async getBnbBalance({ commit, state }) {
      try {
        const balance = await getBalance(state.ethers.provider, state.account);
        commit("setBnbBalance", balance);
      } catch (err) {
        console.log(err);
      }
    },

    async getBlsBalance({ commit, state, rootState }) {
      const tokenContract = rootState.wallet.ethers.tokenContract;
      const address = state.account;
      try {
        const balance = await tokenService.tokenBalance(tokenContract, address);
        commit("setBlsBalance", balance);
      } catch (err) {
        console.log(err);
      }
    },

    async getLPBalance({ commit, state, rootState }) {
      const LPTokenContract = rootState.wallet.ethers.LPTokenContract;
      const address = state.account;
      try {
        const balance = await tokenService.tokenBalance(
          LPTokenContract,
          address
        );
        commit("setLPBalance", balance);
      } catch (err) {
        console.log(err);
      }
    },

    async getBlocksOwned({ commit, dispatch, rootState }) {
      const address = ethers.utils.getAddress(rootState.wallet.account);

      const managerContract = rootState.space.currentContracts.manager;

      try {
        const blocksOwned = await managerService.blocksOwned(
          managerContract,
          address
        );
        commit("setBlocksOwned", blocksOwned);
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      }
    },

    // async backendAndContractSynced({ commit, rootState }) {
    //   const address = ethers.utils.getAddress(rootState.wallet.account);

    //   const synced = await posterService.backendAndContractSynced(0, address);
    //   commit("backendAndContractSynced", synced)
    // },

    setWalletError({ commit }, errorMessage) {
      commit("setWalletError", errorMessage);
    },
  },
};
