<template>
  <div
    class="d-flex flex-column align-center"
    v-bind:class="{ centered: centered }"
  >
    <img
      class="py-8 px-0"
      src="@/assets/UI-illustration-LOADING.png"
      width="240"
    />
    <p class="mt-6">
      <span class="yellow--text">{{ loadingStep }}</span> {{ phrase }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    centered: Boolean,
  },
  data() {
    return {
      phraseId: 0,
      phrases: [
        "Collecting the bits",
        "Rolling blocks",
        "Grinding gears",
        "Making parents proud",
        "Repelling COVID",
        "Slowing the speed",
        "Having fun",
        "Wrapping gifts",
        "Much wait",
        "Tweeting progress",
        "Tightening screws",
        "Igniting your bags",
      ],

      loadingStepId: 0,
      // loadingSteps: [".", "..", "..."],
      loadingSteps: ["/", "-", "\\", "|"],
      loadingStepsSpeed: 200,
    };
  },
  computed: {
    phrase() {
      return this.phrases[this.phraseId];
    },
    loadingStep() {
      return this.loadingSteps[this.loadingStepId];
    },
  },
  methods: {
    setNextRandomId() {
      this.phraseId = Math.floor(Math.random() * (this.phrases.length - 1));
    },
    setNextLoadingStep() {
      this.loadingStepId =
        this.loadingStepId < this.loadingSteps.length - 1
          ? this.loadingStepId + 1
          : 0;
    },
  },
  created() {
    this.loadingInterval = setInterval(
      () => this.setNextLoadingStep(),
      this.loadingStepsSpeed
    );
    this.interval = setInterval(() => this.setNextRandomId(), 3000);
  },
  destroyed() {
    clearInterval(this.loadingInterval);
    clearInterval(this.interval);
  },
};
</script>

<style>
.centered {
  height: 90vh;
  justify-content: center;
}
</style>
