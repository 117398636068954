<template>
  <v-container v-if="!connected" class="footer-panel pa-0">
    <ConnectButton v-if="!connected" block footer />
  </v-container>

  <v-container v-else class="footer-panel pa-0">
    <template v-if="mode === 'view'">
      <ViewPanel
        :claimable="config.claimable"
        :rewards="config.rewards"
        :rewardsToken="config.rewardsToken"
      />
    </template>
    <template v-if="mode === 'draw'">
      <DrawPanel :maxArea="config.maxLimits.area" />
    </template>
    <template v-if="mode === 'confirm'">
      <ConfirmPanel
        :maxArea="config.maxLimits.area"
        :takeover="config.takeover"
        :takeoverToken="config.takeoverToken"
      />
    </template>
    <template v-if="mode === 'buy'">
      <BuyDialog
        :spaceId="config.spaceId"
        :claimToken="config.claimToken"
        :claimFixed="config.claimFixed"
        :takeover="config.takeover"
        :takeoverToken="config.takeoverToken"
        :pixelArt="config.pixelArt"
      />
    </template>
    <template v-if="mode === 'loading'">
      <LoadingDialog />
    </template>
    <template v-if="mode === 'summary'">
      <SummaryDialog />
    </template>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import ConnectButton from "../../components/cross-space/ConnectButton.vue";

import ViewPanel from "../space/footer-panel/ViewPanel.vue";
import DrawPanel from "../space/footer-panel/DrawPanel.vue";
import ConfirmPanel from "../space/footer-panel/ConfirmPanel.vue";
import BuyDialog from "../space/footer-panel/BuyDialog.vue";
import LoadingDialog from "../space/footer-panel/LoadingDialog.vue";
import SummaryDialog from "../space/footer-panel/SummaryDialog.vue";

export default {
  components: {
    ConnectButton,
    ViewPanel,
    DrawPanel,
    ConfirmPanel,
    BuyDialog,
    LoadingDialog,
    SummaryDialog,
  },
  computed: {
    ...mapState("mode", ["mode"]),
    ...mapState("wallet", ["connected"]),
    ...mapState("space", ["currentSpace"]),

    config() {
      return this.currentSpace.config;
    },
  },
};
</script>

<style>
.footer-panel {
  background-color: #cccccc;
}
</style>
