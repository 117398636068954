import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#333333",
        secondary: "#E2E5D4",
        accent: "#18DFD6",
      },
    }
  },
  options: {
    customProperties: true
  }
});
