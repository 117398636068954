<template>
  <div class="overview-grid">
    <img
      v-if="!$isMobile()"
      @click="$router.push('/space-01')"
      class="image border-right"
      src="@/assets/Space-01-covered.jpg"
      style="object-fit: cover; overflow:none; width:100%; height:100%;"
    />
    <div class="content d-flex flex-column pa-6">
      <h1 class="text-title">
        Earn cryptos by co-creating spaces on the blockchain
      </h1>
      <p>
        Each SPACE has a 1000 blocks for users to cover with their art. <br />
        Each SPACE has different rules who can cover what and when. <br />
        In every SPACE users get rewarded to participate!
      </p>
    </div>
    <a
      class="tutorial d-flex align-center pa-4 top-border"
      href="https://www.youtube.com/watch?v=NjmcuCzrURk&ab_channel=BJB"
      target="_blank"
    >
      <img
        class="mr-6 ml-2"
        src="@/assets/ils_Brand-Video.svg"
        width="42"
        height="42"
      />
      <div class="d-flex flex-column">
        <p class="text-subtitle-2 text-uppercase white--text ma-0">
          How it works
        </p>
        <p class="text-subtitle-2 lightgrey--text ma-0">Video tutorials</p>
      </div>
      <v-spacer />
      <img class="caron" src="@/assets/mock2.png" width="50" height="50" />
    </a>
    <router-link
      class="billboard d-flex pa-4 align-center top-border"
      to="/space-01"
    >
      <img
        class="mr-6 ml-2"
        src="@/assets/ils_Brand-Billboard.svg"
        width="42"
        height="42"
      />
      <div class="d-flex flex-column">
        <p class="text-subtitle-2 text-uppercase white--text ma-0">
          Explore infinite billboard
        </p>
        <p class="text-subtitle-2 lightgrey--text ma-0">
          Our special SPACE for advertising
        </p>
      </div>
      <v-spacer />
      <img class="caron" src="@/assets/mock2.png" width="50" height="50" />
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    vaultTextSize() {
      return this.$vuetify.breakpoint.md ? "medium" : "large";
    },

    ...mapState("wallet", ["connected"]),
    ...mapGetters("wallet", {
      vaultBalance: "blsBalanceFull",
      farmBalance: "lpTokenBalanceFull",
    }),

    ...mapState("mint", ["ownedCount", "maxCount", "mintedCount"]),

    ...mapGetters("vault", [
      "tokenPrice",
      "rewardsPoolInflow",
      "rewardsPoolOutflow",
    ]),

    ...mapState("vault", {
      vaultLoading: "loading",
      vaultApproved: "approved",
    }),
    ...mapGetters("vault", {
      vaultStaked: "deposited",
      vaultStakedFull: "depositedFull",
      vaultPercentage: "vaultPercentage",
      vaultReward: "reward",
      vaultRewardsPerSec: "rewardsPerSec",
    }),
    vaultRewardNumber() {
      return parseFloat(this.vaultReward);
    },
    vaultRewardsPerSecNumber() {
      return parseFloat(this.vaultRewardsPerSec);
    },

    ...mapState("farm", {
      farmLoading: "loading",
      farmApproved: "approved",
    }),
    ...mapGetters("farm", {
      farmStaked: "deposited",
      farmStakedFull: "depositedFull",
      farmPercentage: "vaultPercentage",
      farmReward: "reward",
      farmRewardsPerSec: "rewardsPerSec",
      farmAPR: "APR",
    }),
    farmRewardNumber() {
      return parseFloat(this.farmReward);
    },
    farmRewardsPerSecNumber() {
      return parseFloat(this.farmRewardsPerSec);
    },
  },

  methods: {
    ...mapActions("wallet", ["connectWallet", "addTokenToWallet"]),

    ...mapActions("vault", {
      vaultHarvestRewards: "harvestRewards",
    }),
    ...mapActions("farm", {
      farmHarvestRewards: "harvestRewards",
    }),

    vaultApprove() {
      this.$store.dispatch("vault/approveVault");
    },
    vaultDeposit(value) {
      this.$store.dispatch("vault/deposit", value);
    },
    vaultWithdraw(value) {
      this.$store.dispatch("vault/withdraw", value);
    },

    farmApprove() {
      this.$store.dispatch("farm/approve"); // ??
    },
    farmDeposit(value) {
      this.$store.dispatch("farm/deposit", value);
    },
    farmWithdraw(value) {
      this.$store.dispatch("farm/withdraw", value);
    },
  },
};
</script>

<style lang="scss">
.flex-1 {
  flex: 1 1 0;
}

a.link {
  text-transform: uppercase;
  text-decoration-line: none;
  color: #a2fffb !important;

  .caron {
    color: #ffff00;
  }
}

.overview-grid {
  display: grid;
  margin-bottom: 25px;
  // grid-template-columns: 1fr 1fr;
  grid-auto-columns: 50%;
  // grid-auto-rows: 1fr;
  grid-template-areas:
    "img  cont"
    "img  tut"
    "img  bill";

  & > * {
    background-color: black;
  }

  border: 1px solid #444;

  .image {
    grid-area: img;
  }
  .content {
    grid-area: cont;
  }
  .tutorial {
    grid-area: tut;
  }
  .billboard {
    grid-area: bill;
  }
}

.border-bottom {
  border-bottom: 1px solid #444;
}

.border-right {
  border-right: 1px solid #444;
}

.text-title {
  text-transform: uppercase;
  color: #18dfd6;
  line-height: 2.4rem;
}

.lightgrey--text {
  color: #999999;
}

.caron {
  content: ">";
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .overview-grid {
    grid-template-columns: 1fr;
    grid-template-areas:
      "img "
      "cont"
      "tut"
      "bill";
    //     margin-bottom: 5px;
    //     grid-template-areas:
    //       "graph    sapes   pool"
    //       "graph    sapes   vault"
    //       "graph    sapes   yields"
    //       "support  support support";
  }

  //   .support {
  //     height: min-content;
  //   }

  //   .flex-flip {
  //     flex-direction: column;
  //   }

  //   .links-grid {
  //     grid-template-columns: repeat(3, auto);
  //   }
}

// @media #{map-get($display-breakpoints, 'sm-and-down')} {
//   .overview-grid {
//     grid-auto-rows: auto;
//     margin-bottom: 5px;
//     grid-template-areas:
//       "pool     pool"
//       "vault    yields"
//       "graph    sapes"
//       "graph    sapes"
//       "graph    sapes"
//       "support  support";
//   }

//   .flex-flip {
//     flex-direction: row;
//   }
//   .rewards-pool,
//   .vault,
//   .yields {
//     height: min-content;
//   }
// }

// @media #{map-get($display-breakpoints, 'xs-only')} {
//   .overview-grid {
//     grid-auto-rows: auto;
//     margin-bottom: 5px;
//     grid-template-areas:
//       "pool"
//       "vault"
//       "yields"
//       "graph"
//       "graph"
//       "graph"
//       "sapes"
//       "sapes"
//       "support";
//   }
//   .rewards-pool,
//   .vault,
//   .yields {
//     height: min-content;
//   }
//   .support {
//     display: none;
//   }
//   .links-grid {
//     grid-template-columns: repeat(1, auto);
//   }
// }
</style>
