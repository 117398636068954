<template>
  <div class="view-panel-grid">
    <v-btn
      v-if="blsRewardNumberOld && rewards"
      style="grid-column: 1 / span 2"
      tile
      text
      height="100%"
      color="primary"
      @click="harvestRewardOld()"
      :loading="loading.harvestOld"
    >
      <v-icon dark class="ma-2">
        mdi-rake
      </v-icon>
      Harvest legacy rewards
    </v-btn>
    <div class="black d-flex flex-column align-center">
      <span class="grey--text text-body-2">My Blocks:</span>
      <span>{{ blocksOwned }}</span>
    </div>
    <div class="black d-flex flex-column align-center flex-grow-1">
      <span class="grey--text text-body-2">Rewards:</span>
      <span>
        <FastCounter
          class="ma-0"
          :startAmount="blsRewardNumber"
          :incrementPerSec="blsRewardsPerSecNumber"
          :decimals="6"
        />
        {{ rewardsToken }}
      </span>
    </div>
    <v-btn
      large
      :disabled="!claimable"
      color="accent darken-1 pa-2"
      class="black--text flex-grow-1"
      @click="setMode('draw')"
      >Claim blocks</v-btn
    >
    <v-btn large color="primary pa-2 flex-grow-1" @click="harvestReward"
      >Harvest</v-btn
    >
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import FastCounter from "../../helpers/FastCounter.vue";

export default {
  components: {
    FastCounter,
  },
  props: {
    claimable: Boolean,
    rewards: Boolean,
    rewardsToken: String,
  },
  computed: {
    ...mapGetters("wallet", ["blocksOwned"]),

    ...mapState("harvest", ["loading"]),
    ...mapGetters("harvest", ["userReward", "userRewardOld", "blsPerSec"]),
    blsRewardNumber() {
      return parseFloat(this.userReward);
    },
    blsRewardsPerSecNumber() {
      return parseFloat(this.blsPerSec);
    },
    blsRewardNumberOld() {
      return this.userRewardOld ? parseFloat(this.userRewardOld) : 0;
    },
  },
  methods: {
    ...mapActions("mode", ["setMode"]),
    ...mapActions("harvest", ["harvestReward", "harvestRewardOld"]),
  },
};
</script>

<style scoped>
.view-panel-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  padding-top: 2px;
}
</style>
