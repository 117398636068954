<template>
  <v-container class="my-2">
    <v-row>
      <v-col cols="12" class="py-1">
        <label class="side-label text-uppercase text-body-2">Cover</label>
        <ImageUpload v-on:change="file = $event" />
      </v-col>

      <v-col cols="12" class="py-1" v-if="pixelArt">
        <PixelDrawerDialog />
      </v-col>

      <v-col cols="12" class="py-1">
        <label class="side-label text-uppercase text-body-2">
          Caption / Link
        </label>
        <input class="text-input" v-model="text" :maxlength="textMaxLength" />
      </v-col>

      <v-col cols="12" class="py-1" v-if="!claimFixed">
        <label class="side-label text-uppercase text-body-2"
          >Min. Investment</label
        >
        <p class="mb-0">{{ minPosterPrice }} BNB</p>
      </v-col>

      <v-col cols="12" class="py-1">
        <label class="side-label text-uppercase text-body-2">Claim Fee</label>
        <PricePicker
          :symbol="claimToken"
          :disabled="claimFixed"
          v-model="purchasePrice"
          @isValid="isValid($event)"
        />
      </v-col>

      <v-col cols="12" class="py-1" v-if="takeover">
        <label class="side-label text-uppercase text-body-2"
          >Takeover Cost</label
        >
        <PricePicker
          :symbol="takeoverToken"
          disabled
          :value="selected.priceBLS"
          @isValid="isValid($event)"
        />
      </v-col>

      <v-btn
        v-if="!spaceApproved"
        outlined
        color="accent darken-1"
        class="flex-grow-1 mx-3 mt-3"
        @click="approveSpace"
        :loading="loading.approveSpace"
      >
        Approve
      </v-btn>

      <v-col cols="12" class="d-flex py-3">
        <v-btn
          color="primary lighten-1"
          class="mr-2"
          style="min-width: 0"
          @click="setMode('draw')"
          >&lt;</v-btn
        >

        <!-- <p>In order to stake or withdraw, you need to approve the Vault.</p> -->
        <v-btn
          color="accent"
          class="black--text flex-grow-1"
          @click="preClaimCheck"
          :disabled="disableClaim || !spaceApproved"
        >
          Confirm
        </v-btn>
      </v-col>

      <TextConfirmDialog v-model="textConfirmDialog" @confirm="claim()" />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ImageUpload from "../../info-panel/ImageUpload.vue";
import PricePicker from "../../space/PricePicker.vue";
import PixelDrawerDialog from "../../dialogs/PixelDrawerDialog.vue";
import TextConfirmDialog from "../../dialogs/TextConfirmDialog.vue";

export default {
  components: {
    ImageUpload,
    PricePicker,
    PixelDrawerDialog,
    TextConfirmDialog,
  },
  props: {
    spaceId: Number,
    claimToken: String,
    claimFixed: Boolean,
    takeover: Boolean,
    takeoverToken: String,
    pixelArt: Boolean,
  },
  data() {
    return {
      showTakeoverCost: true,

      textConfirmDialog: false,

      file: null,

      text: "",
      textMaxLength: 64,

      purchasePrice: "0",
      takeoverPrice: "0",
    };
  },
  computed: {
    ...mapState("space", ["loading", "spaceApproved"]),
    ...mapGetters("space", ["minPosterPrice", "selected"]),
    ...mapState("pixel", ["pixelDataUrl", "pixelFile"]),

    disableClaim() {
      return !(this.file !== null);
    },
  },
  watch: {
    async pixelFile(val) {
      this.file = val;
    },
  },
  methods: {
    ...mapActions("mode", ["setMode"]),
    ...mapActions("space", ["confirmClaim", "approveSpace"]),

    preClaimCheck() {
      if (this.text && this.text.length > 0) {
        this.claim();
      } else {
        this.textConfirmDialog = true;
      }
    },

    claim() {
      this.confirmClaim({
        spaceId: this.spaceId,
        file: this.file,
        purchasePrice: this.purchasePrice,
        takeoverPrice: this.takeover ? this.selected.priceBLS : undefined,
        text: this.text,
      });
      this.setMode("loading");
    },
  },
  created() {
    this.$store.dispatch("space/checkForSpaceApproval");
  },
  mounted() {
    this.purchasePrice =
      (this.claimFixed ? this.selected.price : this.minPosterPrice) ||
      this.purchasePrice;
  },
};
</script>

<style lang="scss">
.text-input {
  background-color: #2f3534;
  border: 1px solid #979797;

  width: 100%;
  padding: 8px;
  color: white;

  &[disabled] {
    background-color: #757575;
  }
}
</style>
