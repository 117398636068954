<template>
  <div class="h-100">
    <!-- MOBILE -->
    <SpaceToolbar class="mobile-menu pt-2 pb-2 black" v-if="$isMobile()" />

    <div
      class="h-100"
      :class="$isMobile() && !isPoster() ? 'canvas-wrapper' : null"
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getSpaceBySlug } from "../services/spaces";
import SpaceToolbar from "../components/cross-space/SpaceToolbar.vue";

export default {
  methods: {
    ...mapActions("space", ["loadSpace", "setSpaceSwitcher"]),
    getIconColor(action) {
      return this.$route.name == action ? "yellow" : "";
    },
    isPoster() {
      return this.$route.name === "Poster";
    },
  },
  components: {
    SpaceToolbar,
  },
  created() {
    this.setSpaceSwitcher(false);

    this.loadSpace(this.spaceConfig.spaceId);
  },
  watch: {
    spaceConfig(current) {
      this.loadSpace(current.spaceId);
    },
  },
  computed: {
    ...mapState("space", ["currentSpace"]),
    spaceConfig() {
      return getSpaceBySlug(this.$route.params.spaceSlug);
    },
  },
};
</script>

<style lang="scss">
.mobile-menu {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.canvas-wrapper {
  padding-top: 50px;
}

.h-100 {
  height: 100%;
}
</style>
