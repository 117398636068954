<template>
  <v-dialog
    style="z-index: 9000"
    :value="value"
    @input="$emit('input', $event)"
    width="1000"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <RetroCardTitle closable @close="$emit('input', false)"></RetroCardTitle>
    <v-card-text class="black">
      <NftDetails :details="details" />
    </v-card-text>
  </v-dialog>
</template>

<script>
import RetroCardTitle from "../helpers/RetroCardTitle.vue";
import NftDetails from "../../views/mint/NftDetails.vue";
export default {
  components: {
    RetroCardTitle,
    NftDetails,
  },
  props: {
    value: Boolean,
    details: Object,
  },
};
</script>
