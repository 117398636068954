import { utils, BigNumber } from "ethers";
import { truncate } from "../services/helpers";
import { visibleImages } from "../services/imagesService";
import blockService from "@/services/blockService.js";
import * as posterService from "../services/posterService.js";
import * as spaceService from "../crypto/spaceService.js";
import * as tokenService from "../crypto/tokenService.js";
import { spaces } from "../services/spaces.js";

const INIT_BLOCKS_COUNT = 1000;

export default {
  namespaced: true,
  state: {
    spaceSwitcher: true,
    currentSpace: {
      spaceId: 0,
      blockAreas: [],
      timeline: [],
      poster: null,
      visibleBlockAreas: [],
      loading: false,
      blocksOwned: 0,
      backendAndContractSynced: true,
    },
    currentContracts: {
      space: null,
      manager: null,
      managerOld: null,
    },
    currentSignedContracts: {
      space: null,
      manager: null,
      managerOld: null,
    },
    spaces: spaces
      .map((spaceConfig) => ({
        spaceId: spaceConfig.spaceId,
        blockAreas: [],
        poster: null,
        timeline: [],
        visibleBlockAreas: [],
        loading: false,
        blocksOwned: 0,
        backendAndContractSynced: true,

        config: spaceConfig,
      }))
      .reduce(function(map, obj) {
        map[obj.spaceId] = obj;
        return map;
      }, {}),

    selected: {
      count: 0,
      price: BigNumber.from(0),
      priceBLS: BigNumber.from(0),
      blocks: [],
      prices: [],
    },

    spaceApproved: false,

    loading: {
      imageCheck: false,
      posterBuy: false,
      imageUpload: false,
      approvalCheck: false,
      approveSpace: false,
    },
  },
  getters: {
    sortedBlockAreas(state) {
      return (spaceId) => {
        return state.spaces[spaceId].visibleBlockAreas.sort(
          (a, b) => a.z - b.z
        );
      };
    },

    sortedBlockAreasByLikesDesc(state) {
      return (spaceId) => {
        return state.spaces[spaceId].visibleBlockAreas.sort(
          (a, b) => b.likes - a.likes
        );
      };
    },

    loading(state) {
      return (
        state.loading.imageCheck ||
        state.loading.posterBuy ||
        state.loading.imageUpload
      );
    },

    minPosterPrice(state) {
      return truncate(
        utils.formatEther(
          state.selected.price
            ? state.selected.price.add(BigNumber.from(1e14))
            : BigNumber.from(0)
        ),
        4
      );
    },

    selected(state) {
      return {
        ...state.selected,
        price: truncate(utils.formatEther(state.selected.price), 4),
        priceBLS: truncate(utils.formatEther(state.selected.priceBLS), 0),
      };
    },
  },
  mutations: {
    setSpaceLoading(state, { spaceId, loading }) {
      state.spaces[spaceId].loading = loading;
    },
    setBlockAreas(state, { spaceId, posters }) {
      state.spaces[spaceId].blockAreas = posters;
    },
    setPoster(state, { spaceId, poster }) {
      state.spaces[spaceId].poster = poster;
    },
    setTimeline(state, { spaceId, posters }) {
      state.spaces[spaceId].timeline = posters;
    },
    setVisibleBlockAreas(state, { spaceId, visibleBlockAreas }) {
      state.spaces[spaceId].visibleBlockAreas = visibleBlockAreas;
    },
    setInitialBlocksCount(state, { spaceId, uncoveredBlocks }) {
      state.spaces[spaceId].uncoveredBlocks = uncoveredBlocks;
    },
    backendAndContractSynced(state, { spaceId, synced }) {
      state.spaces[spaceId].backendAndContractSynced = synced;
    },

    setCurrentSpace(state, spaceId) {
      state.currentSpace = state.spaces[spaceId];
    },
    setCurrentContracts(state, contracts) {
      state.currentContracts = contracts;
    },
    setSpaceSwitcher(state, switcher) {
      state.spaceSwitcher = switcher;
    },
    setCurrentSignedContracts(state, contracts) {
      state.currentSignedContracts = contracts;
    },

    previewImage(state, dataUrl) {
      state.selected.previewImage = dataUrl;
    },
    stopPreviewingImage(state) {
      state.selected.previewImage = null;
    },
    setPosterCoorAndSize(state, posterCoorAndSize) {
      const { width, height } = posterCoorAndSize.size;
      state.selected = {
        ...state.selected,
        ...posterCoorAndSize,
        count: width * height,
      };
    },
    setSelectionPrices(state, selectedBlocks) {
      state.selected.blocks = selectedBlocks;

      // ** Special gift on Halloween **
      // Remove when all blocks on Space 2 are covered
      // This hardcodes the value of purchase price on Space 02, because of a trick or treat on the smart contract
      const adjustedBlocks = selectedBlocks.map((block) => {
        if (block.price.eq(BigNumber.from("6600000000000000"))) {
          return {
            ...block,
            price: BigNumber.from("4200000000000000"),
          };
        } else {
          return block;
        }
      });

      state.selected.prices = adjustedBlocks.map((selectedBlock) => ({
        blockId: selectedBlock.blockNumber,
        priceString: utils.formatUnits(selectedBlock.price, "ether"),
        price: selectedBlock.price,
        priceBLS: selectedBlock.priceBls || BigNumber.from(0),
      }));

      let price = adjustedBlocks.reduce(
        (a, b) => a.add(b.price),
        BigNumber.from(0)
      );
      let priceBLS = adjustedBlocks.reduce(
        (a, b) => a.add(b.priceBls || BigNumber.from(0)),
        BigNumber.from(0)
      );

      // Add 0.0001 to min price
      // if (posterPriceInWei.gte(5e13)) { // Only in case of min price being bigger than 0.00005
      // price = price.add(BigNumber.from(1e14))
      // }
      state.selected.price = price;
      state.selected.priceBLS = priceBLS;
    },
    clearSelection(state) {
      state.selected = {
        count: 0,
        price: BigNumber.from(0),
        priceBLS: BigNumber.from(0),
        blocks: [],
        prices: [],
      };
    },
    setLastPromise(state, value) {
      state.lastPromise = value;
    },

    approveSpace(state) {
      state.spaceApproved = true;
    },

    startLoadingImageCheck(state) {
      state.loading.imageCheck = true;
    },
    stopLoadingImageCheck(state) {
      state.loading.imageCheck = false;
    },
    startLoadingPosterBuy(state) {
      state.loading.posterBuy = true;
    },
    stopLoadingPosterBuy(state) {
      state.loading.posterBuy = false;
    },
    startLoadingImageUpload(state) {
      state.loading.imageUpload = true;
    },
    stopLoadingImageUpload(state) {
      state.loading.imageUpload = false;
    },
    startLoadingApprovalCheck(state) {
      state.loading.approvalCheck = true;
    },
    stopLoadingApprovalCheck(state) {
      state.loading.approvalCheck = false;
    },
    startLoadingApprove(state) {
      state.loading.approveSpace = true;
    },
    stopLoadingApprove(state) {
      state.loading.approveSpace = false;
    },
  },
  actions: {
    setSpaceContracts({ commit, state }, spacesContracts) {
      const spaceId = state.currentSpace.spaceId;
      commit("setCurrentContracts", spacesContracts[spaceId]);
    },
    setSignedSpaceContracts({ commit, state }, signedSpacesContracts) {
      const spaceId = state.currentSpace.spaceId;
      commit("setCurrentSignedContracts", signedSpacesContracts[spaceId]);
    },

    clearSelection({ commit, dispatch }) {
      commit("clearSelection");
      commit("drawing/clearDrawing", null, { root: true });
      dispatch("mode/setMode", "view", { root: true });
    },

    async setSpaceSwitcher({ commit }, switcher) {
      commit("setSpaceSwitcher", switcher);
    },

    clearPoster({ commit }, spaceId) {
      commit("setPoster", { spaceId, poster: null });
    },

    async loadPoster({ commit }, { spaceId, posterId }) {
      const [poster] = await posterService.getUniquePosters(spaceId, [
        posterId,
      ]);

      commit("setPoster", { spaceId, poster });
    },

    async loadTimeline({ commit }, { spaceId, latestN, order }) {
      commit("setCurrentSpace", spaceId);
      commit("setSpaceLoading", { spaceId, loading: true });

      const posters = await posterService.getLatestPosters(
        spaceId,
        latestN,
        order
      );

      commit("setTimeline", { spaceId, posters });

      commit("setSpaceLoading", { spaceId, loading: false });
    },

    async loadSpace({ commit, dispatch, rootState }, spaceId) {
      commit("setCurrentSpace", spaceId);
      commit("setSpaceLoading", { spaceId, loading: true });

      if (rootState.wallet.account) {
        dispatch("backendAndContractSynced", { spaceId });
      }

      const posters = await posterService.getPosters(spaceId);
      commit("setBlockAreas", { spaceId, posters });

      // Optimization filter
      const reversePosters = posters.reverse();
      const retObj = visibleImages(
        reversePosters,
        undefined,
        undefined,
        (poster) => poster.blockStart,
        (poster) => poster.blockEnd
      );

      try {
        const visibleBlockAreas = await posterService.loadImages(
          spaceId,
          retObj.filteredImages
        );
        commit("setVisibleBlockAreas", { spaceId, visibleBlockAreas });
      } finally {
        commit("setSpaceLoading", { spaceId, loading: false });
      }

      commit("setInitialBlocksCount", {
        spaceId,
        uncoveredBlocks: INIT_BLOCKS_COUNT - retObj.uniqueBlocksIndices,
      });

      commit("setCurrentSpace", spaceId);
      commit(
        "setCurrentContracts",
        rootState.wallet.ethers.spacesContracts[spaceId]
      );
      if (rootState.wallet.ethers.signedSpacesContracts) {
        commit(
          "setCurrentSignedContracts",
          rootState.wallet.ethers.signedSpacesContracts[spaceId]
        );
      }

      commit("harvest/stopRewards", null, { root: true });
      dispatch("harvest/getUserReward", null, { root: true });

      if (rootState.wallet.connected) {
        dispatch("wallet/getBlocksOwned", null, { root: true });
      }
    },

    async silentlyLoadSpace({ commit, dispatch }, spaceId) {
      dispatch("backendAndContractSynced", { spaceId });

      const posters = await posterService.getPosters(spaceId);
      commit("setBlockAreas", { spaceId, posters });

      // Optimization filter
      const reversePosters = posters.reverse();
      const retObj = visibleImages(
        reversePosters,
        undefined,
        undefined,
        (poster) => poster.blockStart,
        (poster) => poster.blockEnd
      );

      try {
        const visibleBlockAreas = await posterService.loadImages(
          spaceId,
          retObj.filteredImages
        );
        commit("setVisibleBlockAreas", { spaceId, visibleBlockAreas });
      } finally {
        commit("setSpaceLoading", { spaceId, loading: false });
      }

      commit("setInitialBlocksCount", {
        spaceId,
        uncoveredBlocks: INIT_BLOCKS_COUNT - retObj.uniqueBlocksIndices,
      });

      commit("setCurrentSpace", spaceId);
    },

    async selectBlockArea(
      { commit, dispatch, state },
      { startCoor, endCoor, size }
    ) {
      commit("setPosterCoorAndSize", { startCoor, endCoor, size });

      const startBlockId = blockService.blockId(startCoor);
      const endBlockId = blockService.blockId(endCoor);

      const promise = spaceService.calculateBlockPrices(
        state.currentContracts.space,
        `${startBlockId}`,
        `${endBlockId}`
      );
      commit("setLastPromise", promise);

      try {
        const prices = await promise;

        if (promise == state.lastPromise) {
          commit("setSelectionPrices", prices);
          commit("setLastPromise", null);
        }
      } catch (err) {
        console.log(err);
        if (promise == state.lastPromise) {
          commit("setLastPromise", null);
          if (err.code === "CALL_EXCEPTION") {
            dispatch(
              "error/setErrorMessage",
              "Please use the Binance Smart Chain Testnet and refresh the page",
              { root: true }
            );
          }
          throw err;
        }
      }
    },

    async checkForSpaceApproval({ commit, dispatch, state, rootState }) {
      commit("startLoadingApprovalCheck");
      const address = utils.getAddress(rootState.wallet.account);
      const tokenContract = rootState.wallet.ethers.tokenContract;
      try {
        const spaceContract = state.currentContracts.space;
        const hasAllowance = await tokenService.hasAllowance(
          tokenContract,
          address,
          spaceContract
        );
        // There is some allowance, assume it's full
        if (hasAllowance) {
          commit("approveSpace");
        }
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      } finally {
        commit("stopLoadingApprovalCheck");
      }
    },

    async approveSpace({ commit, dispatch, state, rootState }) {
      const signedTokenContract = rootState.wallet.ethers.signedTokenContract;
      try {
        commit("startLoadingApprove");
        const spaceContract = state.currentContracts.space;
        await tokenService.approve(signedTokenContract, spaceContract);
        commit("approveSpace");
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      } finally {
        commit("stopLoadingApprove");
      }
    },

    async confirmClaim(
      { commit, dispatch, state },
      { spaceId, file, text, purchasePrice, takeoverPrice }
    ) {
      // Validate selectedArea and text
      if (!state.selected.startCoor) {
        dispatch(
          "error/setErrorMessage",
          "Block area was not selected. Select a block area before purchasing.",
          { root: true }
        );
        dispatch("mode/setMode", "view", { root: true });
        return;
      }

      if (text.length > 64) {
        dispatch(
          "error/setErrorMessage",
          "Your text is too long. Keep it simple and below 64 characters.",
          { root: true }
        );
        dispatch("mode/setMode", "view", { root: true });
        return;
      }

      // Calculate image hash
      var hash = await posterService.fileMD5Hash(file);
      var purchasePriceInWei = utils.parseEther(purchasePrice);
      var takeoverPriceInWei = takeoverPrice
        ? utils.parseEther(takeoverPrice)
        : undefined;

      // Checking image
      commit("startLoadingImageCheck");
      try {
        if (!file.isPixelArt) await posterService.checkImage(spaceId, file);
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
        dispatch("mode/setMode", "view", { root: true });
        return;
      } finally {
        commit("stopLoadingImageCheck");
      }

      // Buying poster on the proper space
      commit("startLoadingPosterBuy");
      try {
        const startBlockId = blockService.blockId(state.selected.startCoor);
        const endBlockId = blockService.blockId(state.selected.endCoor);

        // TODO: Specify space
        await buyBlockArea(
          state.currentSignedContracts.space,
          startBlockId,
          endBlockId,
          hash,
          purchasePriceInWei,
          takeoverPriceInWei
        );
        dispatch("imageUpload", { spaceId, file, text });
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
        dispatch("mode/setMode", "view", { root: true });
        return;
      } finally {
        commit("stopLoadingPosterBuy");
      }
    },

    async imageUpload(
      { commit, dispatch, rootState },
      { spaceId, file, text, emergencyUpload }
    ) {
      // Uploading image
      commit("startLoadingImageUpload");
      try {
        await posterService.imageUpload(
          spaceId,
          file,
          text,
          rootState.wallet.account,
          emergencyUpload
        );
        dispatch("wallet/backendAndContractSynced", null, { root: true });
        dispatch("silentlyLoadSpace", spaceId);
        dispatch("mode/setMode", "summary", { root: true });
      } catch (err) {
        const message =
          err.response.data.message ||
          "The image upload failed. Please, try again later.";
        dispatch("error/setErrorMessage", message, { root: true });
        dispatch("mode/setMode", "view", { root: true });
      } finally {
        commit("stopLoadingImageUpload");
      }
    },

    async backendAndContractSynced({ commit, state, rootState }, { spaceId }) {
      if (spaceId === undefined) {
        spaceId = state.currentSpace.spaceId;
      }

      const address = utils.getAddress(rootState.wallet.account);
      const synced = await posterService.backendAndContractSynced(
        spaceId,
        address
      );
      commit("backendAndContractSynced", { spaceId, synced });
    },

    async emergencyUpload({ dispatch, state }, { file, text }) {
      const { spaceId } = state.currentSpace;

      if (text.length > 64) {
        dispatch(
          "error/setErrorMessage",
          "Your text is too long. Keep it simple and below 64 characters.",
          { root: true }
        );
        return;
      }

      dispatch("imageUpload", { spaceId, file, text, emergencyUpload: true });
    },
  },
};

async function buyBlockArea(
  signedSpaceContract,
  startBlockId,
  endBlockId,
  hash,
  price,
  takeoverPrice
) {
  try {
    await spaceService.buyBlockArea(
      signedSpaceContract,
      startBlockId,
      endBlockId,
      hash,
      price,
      takeoverPrice
    );
  } catch (err) {
    console.log(err);
    // Elaborate error handling
    switch (err.code) {
      case 4001:
        throw new Error("Transaction was rejected.");
      case -32603:
        if (err.data.code === 3) {
          throw new Error(err.data.message);
        } else if (err.data.code === -32000) {
          throw new Error("Insufficient funds on the wallet.");
        }
    }
  }
}
