import { firebase } from "@firebase/app";
// import 'firebase/auth'
// import 'firebase/firestore'
import "firebase/functions";
import "firebase/analytics";
import "firebase/database";
import "firebase/firestore";

// firebase init - add your own config here
var firebaseConfig = {
  apiKey:
    process.env.VUE_APP_FIREBASE_API_KEY ||
    "AIzaSyAmfhIuW7xSHhXqoGQBll8lrTpPbPjYRvg",
  authDomain:
    process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || "blocks-f3a86.firebaseapp.com",
  databaseURL:
    process.env.VUE_APP_FIREBASE_DATABASE_URL ||
    "https://blocks-f3a86-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || "blocks-f3a86",
  storageBucket:
    process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || "blocks-f3a86.appspot.com",
  messagingSenderId:
    process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || "1085693424171",
  appId:
    process.env.VUE_APP_FIREBASE_APP_ID ||
    "1:1085693424171:web:d7946c6cee10308914f06e",
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID || "G-6K0WKZPS49",
};
firebase.initializeApp(firebaseConfig);

// utils
// const auth = firebase.auth()
const db = firebase.firestore();
const functions = firebase.functions();
const analytics = firebase.analytics();
const database = firebase.database();
const increment = firebase.firestore.FieldValue.increment;

// export utils/refs
export {
  // auth,
  db,
  database,
  functions,
  analytics,
  increment
};
