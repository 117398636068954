<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    width="500"
    persistent
  >
    <v-card>
      <RetroCardTitle closable @close="$emit('input', false)">
        Rules of {{ spaceName }}
      </RetroCardTitle>

      <v-card-text v-if="withTitles">
        <v-row
          v-for="list in this.content"
          :key="list.title"
          class="mt-6"
          justify="center"
        >
          <h1 class="accent--text text-uppercase text-body-1">
            {{ list.title }}
          </h1>
          <v-col v-for="item in list.content" :key="item" cols="12" xl="12">
            {{ item }}
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-else>
        <v-row class="mt-6" justify="center">
          <v-col v-for="item in this.content" :key="item" cols="12">
            <div>
              {{ item }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import RetroCardTitle from "../helpers/RetroCardTitle.vue";
export default {
  components: { RetroCardTitle },
  props: {
    value: Boolean,
    spaceName: String,
    content: Array,
  },
  data() {
    return {};
  },
  computed: {
    withTitles() {
      const content = this.content;
      return (
        Array.isArray(content) &&
        content[0] !== undefined &&
        typeof content[0] === "object" &&
        !Array.isArray(content[0]) &&
        content[0] !== null &&
        content[0].title !== undefined
      );
    },
  },
};
</script>

<style></style>
