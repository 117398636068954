<template>
  <article class="pool d-flex" :class="{ 'flex-column': $isMobile() }">
    <div class="pool-image flex-1">
      <v-img class="image" :aspect-ratio="43 / 24" :src="imageUrl" />
      <div class="active" v-if="active"></div>
      <div class="inactive" v-else></div>
    </div>
    <div
      class="pool-details flex-1 pa-3"
      :class="{ 'top-border': $isMobile(), 'left-border': !$isMobile() }"
    >
      <div class="section">
        <div class="label">Pool</div>
        <div class="value">{{ name }}</div>
      </div>
      <template v-if="connected">
        <div class="section">
          <div class="label">APR</div>
          <div class="value">{{ poolAPR }}%</div>
        </div>
        <div class="section">
          <div class="label">Staked</div>
          <div class="value">{{ poolStaked }}</div>
        </div>
        <div class="section d-flex align-center">
          <v-btn block small @click="poolDialog = true">
            <p class="text-wrap" style="width: min-content; margin: auto;">
              Stake/&nbsp;Withdraw
            </p>
          </v-btn>
        </div>
        <div class="section">
          <div class="label">Yields</div>
          <div class="value">
            <FastCounter
              class="flex-1 font-weight-bold"
              :startAmount="poolRewardNumber"
              :incrementPerSec="poolRewardsPerSecNumber"
              :decimals="5"
            />
          </div>
        </div>
        <div class="section d-flex align-center">
          <v-btn block small @click="poolHarvestRewards()">Harvest</v-btn>
        </div>
      </template>

      <div class="connect d-flex" v-else>
        <ConnectButton mini />
      </div>
    </div>

    <DepositWithdrawDialog
      v-model="poolDialog"
      :title="`Staking Pool ${name}`"
      :balance="poolBalance"
      :staked="poolStakedFull"
      :loading="poolLoading"
      :approved="poolApproved"
      :reward="poolReward"
      tokenSymbol="BLS"
      @approve="poolApprove"
      @confirmStake="poolDeposit"
      @confirmWithdraw="poolWithdraw"
    />
  </article>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ConnectButton from "../cross-space/ConnectButton.vue";
import DepositWithdrawDialog from "../dialogs/DepositWithdrawDialog.vue";
import FastCounter from "../helpers/FastCounter.vue";
export default {
  components: {
    DepositWithdrawDialog,
    FastCounter,
    ConnectButton,
  },
  props: {
    name: String,
    APR: String,
    staked: String, // ??
    active: Boolean,
    image: String,
    yields: String,
    to: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      poolDialog: false,
    };
  },
  asyncComputed: {
    async imageUrl() {
      return await this.image;
    },
  },
  computed: {
    ...mapState("wallet", ["connected"]),
    ...mapGetters("wallet", {
      poolBalance: "blsBalanceFull", // ????
    }),

    ...mapState("pool", {
      poolLoading: "loading",
      poolApproved: "approved",
    }),
    ...mapGetters("pool", {
      poolStaked: "deposited",
      poolStakedFull: "depositedFull",
      poolPercentage: "vaultPercentage",
      poolReward: "reward",
      poolRewardsPerSec: "rewardsPerSec",
      poolAPR: "APR",
    }),
    poolRewardNumber() {
      return parseFloat(this.poolReward);
    },
    poolRewardsPerSecNumber() {
      return parseFloat(this.poolRewardsPerSec);
    },
  },

  methods: {
    poolHarvestRewards() {
      this.$store.dispatch("pool/harvestRewards");
    },
    poolApprove() {
      this.$store.dispatch("pool/approve");
    },
    poolDeposit(value) {
      this.$store.dispatch("pool/deposit", value);
    },
    poolWithdraw(value) {
      this.$store.dispatch("pool/withdraw", value);
    },
  },
};
</script>

<style scoped lang="scss">
a:link {
  text-decoration: none;
}

.pool {
  border: 1px solid #444;
  text-decoration: none;
  color: white;

  .pool-image {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr;
    .grid {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
    }
    .image {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
    }
    .overlay {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
      background-color: black;
      opacity: 1;
    }
    .active,
    .inactive,
    .in-progress {
      grid-row: 1;
      grid-column: 1;
      z-index: 2;
    }
    .active {
      background-color: rgba(0, 220, 0, 1);
      width: 12px;
      height: 12px;
      margin: 18px;
      border-radius: 50%;
      box-shadow: 0 0 0 6px rgba(0, 200, 0, 0.3), 0 0 0 3px rgba(0, 200, 0, 0.6);

      animation-name: pulse;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
    .inactive {
      background-color: rgb(67, 67, 67);
      width: 12px;
      height: 12px;
      margin: 18px;
      border-radius: 50%;
      box-shadow: 0 0 0 6px rgba(67, 67, 67, 0.3),
        0 0 0 3px rgba(67, 67, 67, 0.6);
    }

    @keyframes pulse {
      from {
        background-color: rgba(170, 250, 170, 1);
        box-shadow: 0 0 0 8px rgba(0, 200, 0, 0.3),
          0 0 0 4px rgba(0, 200, 0, 0.6);
      }
      to {
        background-color: rgba(0, 220, 0, 1);
        box-shadow: 0 0 0 4px rgba(0, 200, 0, 0.3),
          0 0 0 2px rgba(0, 200, 0, 0.6);
      }
    }

    .in-progress {
      margin: 10px;
      width: 30px;
      height: 12px;

      &:before {
        content: "</>";
        font-size: 16px;
        font-weight: bold;
        color: #1abfb8;
      }
    }
  }
  .pool-details {
    position: relative;
    background: black;

    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-rows: 1fr;
  }
  .inactive-label {
    font-size: 0.75rem;
    color: gray;
  }
  .connect {
    position: absolute;
    grid-column: 1/3;
    grid-row: 1/2;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
</style>
