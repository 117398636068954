<template>
  <div class="mini-canvas" ref="miniCanvas">
    <v-stage ref="stage" :config="stage">
      <MiniGrid
        :width="grid.width"
        :height="grid.height"
        :rows="rows"
        :cols="cols"
        :blockSize="blockSize"
      />
      <v-layer>
        <v-rect ref="drawingBox" :config="drawBox" />
      </v-layer>
    </v-stage>
  </div>
</template>

<script>
const canvasWidth = 224;
const canvasHeight = 224;

import MiniGrid from "./MiniGrid.vue";

import { mapState, mapGetters } from "vuex";

export default {
  props: {
    width: Number,
    height: Number,
    rows: Number,
    cols: Number,
  },
  components: {
    MiniGrid,
  },
  data() {
    return {
      scale: 1,
      blocksWidth: 7,
      blocksHeight: 7,
      blockSize: 32,
      bgConf: {
        x: 0,
        y: 0,
        image: null,
        width: canvasWidth,
        height: canvasHeight,
        listening: false,
      },
      stage: {
        width: canvasWidth,
        height: canvasHeight,
        draggable: false,
      },
      grid: {
        width: canvasWidth,
        height: canvasHeight,
      },
      newPoster: {
        drawing: false,
        startBlockId: undefined,
        endBlockId: undefined,
        drawStart: undefined,
      },
      drawingBoxStyle: {
        fill: "#00FFD4",
        stroke: "#00FFD4",
        strokeWidth: 1,
        opacity: this.$isMobile() ? 1 : 0.4,
      },
    };
  },
  watch: {},
  computed: {
    ...mapGetters("poster", ["sortedPosters"]),
    ...mapState("mode", ["mode"]),
    ...mapState("space", ["selected"]),

    blockPrices() {
      return this.selected.prices;
    },

    offsetX() {
      return canvasWidth - this.cols * this.blockSize;
    },

    drawBox() {
      return {
        ...this.drawingBoxStyle,
        x: 0,
        y: 0,
        ...this.denormalizedSize,
      };
    },

    denormalizedSize() {
      if (this.width) {
        return {
          width: this.width * this.blockSize,
          height: this.height * this.blockSize,
        };
      } else {
        return undefined;
      }
    },
  },
  mounted() {
    this.stage.offset = {
      x: -(canvasWidth - this.cols * this.blockSize) / 2,
      y: -(canvasHeight - this.rows * this.blockSize) / 2,
    };

    // this.stage.scale = {
    //   x: canvasWidth / (this.cols * this.blockSize),
    //   y: canvasHeight / (this.rows * this.blockSize),
    // };

    this.$nextTick(() => {
      this.fitStageIntoParentContainer();
    });
  },
  created() {
    const wideSize = (canvasWidth - 2 * this.scale) / this.blocksWidth;
    const highSize = (canvasHeight - 2 * this.scale) / this.blocksHeight;
    this.blockSize = wideSize < highSize ? wideSize : highSize;
    this.stage.width = this.blockSize * this.blocksWidth;
    this.stage.height = this.blockSize * this.blocksHeight;
  },
  methods: {
    fitStageIntoParentContainer() {
      var container = this.$refs.miniCanvas;
      // now we need to fit stage into parent
      var containerWidth = container.offsetWidth;
      // to do this we need to scale the stage
      var scale = containerWidth / canvasWidth;

      this.stage.width = canvasWidth * scale;
      this.stage.height = canvasHeight * scale;
      this.stage.scale = { x: scale, y: scale };
      this.scale = scale;
    },

    normalizeCoor(coor) {
      return {
        x: Math.floor(coor.x / this.blockSize),
        y: Math.floor(coor.y / this.blockSize),
      };
    },

    mouseDown: function(event) {
      const currentCoor = {
        x: event.evt.offsetX / this.scale,
        y: event.evt.offsetY / this.scale,
      };

      if (this.mode === "draw") {
        this.$store.dispatch(
          "drawing/startDrawing",
          this.normalizeCoor(currentCoor)
        );
      }
    },
    mouseUp: function(event) {
      const currentCoor = {
        x: event.evt.offsetX / this.scale,
        y: event.evt.offsetY / this.scale,
      };
      if (this.mode === "draw") {
        this.$store.dispatch(
          "drawing/stopDrawing",
          this.normalizeCoor(currentCoor)
        );

        this.buyDialog = true;
      }
    },
    mouseMove: function(event) {
      const currentCoor = {
        x: event.evt.offsetX / this.scale,
        y: event.evt.offsetY / this.scale,
      };
      if (this.mode === "draw") {
        this.$store.dispatch(
          "drawing/drawChange",
          this.normalizeCoor(currentCoor)
        );
      }
    },
  },
};
</script>

<style>
.mini-canvas {
  /*background-color: #333333;*/
  margin: 0;
  padding: 0;
  width: 100%;
}
</style>
