<template>
  <div class="confirm-panel-grid">
    <div class="info-box accent d-flex flex-column align-center justify-center">
      <p class="ma-0 black--text">
        {{ selected.count }} / {{ maxArea }} blocks selected
      </p>
      <p class="ma-0 black--text">
        CF: <span class="font-weight-bold">{{ selected.price }} BNB</span>
        <span v-if="takeover">
          - TOF:
          <span class="font-weight-bold">
            {{ selected.priceBLS }} {{ takeoverToken }}
          </span>
        </span>
      </p>
    </div>
    <v-btn
      block
      large
      color="accent darken-1 primary--text pa-2"
      @click="setMode('buy')"
      >Confirm</v-btn
    >
    <v-btn block large color="primary pa-2" @click="cancelButton()"
      >Cancel</v-btn
    >
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    maxArea: Number,
    takeover: Boolean,
    takeoverToken: String,
  },
  computed: {
    ...mapGetters("space", ["selected"]),
  },
  methods: {
    ...mapActions("mode", ["setMode"]),
    ...mapActions("space", ["clearSelection"]),
    cancelButton() {
      this.setMode("view");
      this.clearSelection();
    },
  },
};
</script>

<style scoped lang="scss">
.confirm-panel-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 2px;

  .info-box {
    grid-column: 1 / span 2;
  }
}
</style>
