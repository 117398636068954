import { BigNumber } from "ethers";

export async function deposit(signedContract, amount) {
  const transaction = await signedContract.deposit(amount);
  const receipt = await transaction.wait();

  if (!receipt.status) {
    throw Error("Transaction was not successful");
  }
}

export async function withdraw(signedContract, amount) {
  const transaction = await signedContract.withdraw(amount);
  const receipt = await transaction.wait();

  if (!receipt.status) {
    throw Error("Transaction was not successful");
  }
}

export async function harvestRewards(signedContract) {
  console.log(signedContract)
  const transaction = await signedContract.withdraw(BigNumber.from(0));
  const receipt = await transaction.wait();

  if (!receipt.status) {
    throw Error("Transaction was not successful");
  }
}

export async function currentReward(contract, address) {
  const currentReward = await contract.pendingReward(address);
  return currentReward;
}

export async function userInfo(contract, address) {
  // {amount = deposited tokens, rewardDebt}
  const userInfo = await contract.userInfo(address);
  return userInfo;
}

export async function rewardsPerBlock(contract) {
  const rewardsPerBlock = await contract.rewardPerBlock();
  return rewardsPerBlock;
}

export async function totalStakedTokens(contract) {
  const totalStakedTokens = await contract.totalStaked();
  return totalStakedTokens;
}