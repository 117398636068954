export default {
  namespaced: true,
  state: {
    stage: null,
    pixelFile: null,
    pixelDataUrl: {
      base64: "",
      timestamp: "",
    },
  },
  mutations: {
    updateStage(state, stage) {
      state.stage = stage;
    },
    updatePixelFile(state, pixelFile) {
      state.pixelFile = pixelFile;
    },
    updatePixelDataUrl(state, pixelDataUrl) {
      state.pixelDataUrl = pixelDataUrl;
    },
  },
  actions: {
    setStage({ commit }, stage) {
      commit("updateStage", stage);
    },
    setPixelFile({ commit }, pixelFile) {
      commit("updatePixelFile", pixelFile);
    },
    setPixelDataUrl({ commit }, pixelDataUrl) {
      commit("updatePixelDataUrl", pixelDataUrl);
    },
  },
};
