export default {
  namespaced: true,
  state: {
    buyModal: {
      visible: false,
      modalComponent: null
    }
  },
  mutations: {
    showBuyModal(state, modalComponent) {
      state.buyModal.visible = true
      state.buyModal.modalComponent = modalComponent
    },
    hideBuyModal(state) {
      state.buyModal.visible = false
    }
  }
};