<template>
  <div class="promo">
    <img src="@/assets/mint/promo-title.svg" class="title ma-3" />
    <img src="@/assets/mint/albert4.svg" class="albert" />

    <div
      class="fact black--text text-uppercase ml-3 mb-3 mt-1"
      :class="{
        'text-subtitle-2 mb-4': $vuetify.breakpoint.lgAndDown,
        'text-h6 mb-8': $vuetify.breakpoint.xlOnly,
      }"
    >
      <div>
        <span class="accent--text">Extremely</span>
        <span class="white--text font-weight-bold">rare</span>
      </div>
      <div>
        <span class="accent--text">Extremely</span>
        <span class="yellow--text font-weight-bold">powerful</span>
      </div>
      <div>
        <span class="accent--text">Smart</span>
        <span class="white--text font-weight-bold text-none">NFTs</span>
      </div>
    </div>
    <div
      class="cta black--text text-uppercase ml-3 mb-3 mt-1"
      :class="{
        'text-subtitle-2 mb-4': $vuetify.breakpoint.lgAndDown,
        'text-h6 mb-8': $vuetify.breakpoint.xlOnly,
      }"
    >
      <a
        class="text-subtitle-2"
        href="https://1000blocks.medium.com/extremely-rare-extremely-powerful-1000blocks-first-smart-nft-collection-84e8562e59f8"
        target="_blank"
      >
        <span class="caron">></span> Learn more
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
a:link {
  text-decoration: none;
}

.promo {
  background-color: #000;
  border: 1px solid #444;
  text-decoration: none;

  background: url("~@/assets/Space2.png");
  background-position-y: 200;
  background-size: cover;

  position: relative !important;

  display: grid;
  grid-template-rows: 1fr auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  position: absolute;
  overflow: hidden;

  margin: 0 -13px;

  .albert {
    position: absolute;
    right: -50px;
    top: -30px;
    width: 60%;
    max-width: 250px;
  }

  .title {
    grid-row: 1;
    min-width: 120px;
    max-width: 180px;
  }

  .fact {
    grid-row: 3;
  }

  .cta {
    grid-row: 4;
    grid-column: 1 / span 3;
    font-size: 1.6rem;
    line-height: 1rem;

    a {
      color: #a2fffb;
    }

    .caron {
      color: #ffff00;
    }
  }

  @import "~vuetify/src/styles/settings/_variables";

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .albert {
      max-width: 160px;
    }
    .cta {
      margin-top: 12px;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-only')} {
    .albert {
      position: absolute;
      right: -40px;
      top: -70px;
      width: 50%;
      max-width: 250px;
    }
    .title {
      grid-row: 1;
      min-width: 140px;
      max-width: 180px;
    }

    .fact {
      grid-row: 1;
      grid-column: 2;
    }

    .cta {
      display: flex;
      gap: 20px;
      margin-top: -10px;
    }
  }

  @media #{map-get($display-breakpoints, 'md-only')} {
    .albert {
      top: -14px;
    }
  }
  @media #{map-get($display-breakpoints, 'lg-only')} {
    .albert {
      top: -14px;
    }
  }

  @media #{map-get($display-breakpoints, 'xl-only')} {
    .albert {
      right: -66px;
      max-width: 240px;
    }
    .title {
      grid-row: 1;
      min-width: 150px;
      max-width: 200px;
    }
    .cta {
      font-size: 2.4rem;
      line-height: 2rem;
    }
  }
}
</style>
