import SparkMD5 from "spark-md5";
import axios from "axios";
// import web3 from "../../contracts/web3.js";
// import yourContract from "../../contracts/yourContract.js";

export async function getUniquePosters(spaceId, posterUniqueIds ) {
  const baseUri = process.env.VUE_APP_FUNCTIONS_URI;
  const res = await axios.get(`${baseUri}/getPosters`, {
    params: { spaceId, posterUniqueIds },
  });
  
  return res.data.data;
}

export async function getPosters(spaceId) {
  const baseUri = process.env.VUE_APP_FUNCTIONS_URI;
  const res = await axios.get(`${baseUri}/getPosters`, {
    params: { spaceId },
  });
  return res.data.data;
}

export async function getLatestPosters(spaceId, latestN, order) {
  const baseUri = process.env.VUE_APP_FUNCTIONS_URI;
  const res = await axios.get(`${baseUri}/getPosters`, {
    params: { spaceId, latestN, order },
  });
  return res.data.data;
}

export async function checkImage(spaceId, file) {
  let formData = new FormData();
  formData.append("file", file);

  const baseUri = process.env.VUE_APP_FUNCTIONS_URI;

  const res = await axios
    .post(`${baseUri}/checkPosterFile`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      params: { spaceId },
    })
    .catch(() => {
      throw new Error("The image upload failed. Please, try again later.");
    });

  if (res.data.data.action === "accept") {
    return true;
  } else {
    throw new Error("The image is not safe, and will not be uploaded.");
  }
}

export async function spaceImageUrl(spaceId) {
  const baseUri = process.env.VUE_APP_FUNCTIONS_URI;
  const response = await axios.get(`${baseUri}/getPostersCanvas`, {
    params: { spaceId },
  });
  return response.data.data.canvasUrl;
}

export async function imageUpload(
  spaceId,
  file,
  imageText,
  walletAddress,
  emergencyUpload
) {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("imageText", imageText);
  formData.append("walletAddress", walletAddress);
  if (emergencyUpload === true) {
    formData.append("emergencyUpload", "true");
  }

  const baseUri = process.env.VUE_APP_FUNCTIONS_URI;
  return await axios.post(`${baseUri}/uploadPosterFile`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    params: { spaceId },
  });
}

export async function backendAndContractSynced(spaceId, walletAddress) {
  let formData = new FormData();
  formData.append("walletAddress", walletAddress);

  const baseUri = process.env.VUE_APP_FUNCTIONS_URI;
  const response = await axios.post(
    `${baseUri}/backendAndContractSynced`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
      params: { spaceId },
    }
  );

  return response.data.data.exists;
}

export async function likePoster(spaceId, walletAddress, posterUniqueId) {
  let formData = new FormData();
  formData.append("walletAddress", walletAddress);
  formData.append("posterUniqueId", posterUniqueId);

  const baseUri = process.env.VUE_APP_FUNCTIONS_URI;
  const response = await axios.post(`${baseUri}/likePoster`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    params: { spaceId },
  });
  return response.data;
}

export async function loadImages(spaceId, posters) {
  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const loadingImages = posters.map((poster) => {
    return toDataURL(`${poster.publicUrl}?v=1`)
      .then(
        (dataUrl) =>
          new Promise((resolve, reject) => {
            const image = new window.Image();
            image.src = dataUrl;
            image.onload = () => {
              resolve({ ...poster, imageElement: image, dataUrl });
            };
            image.onerror = () => {
              reject();
            };
          })
      )
      .catch((err) => console.log(err));
  });

  return await Promise.all(loadingImages);
}

export async function whitelistReferral(address) {
  const baseUri = process.env.VUE_APP_FUNCTIONS_URI;
  return await axios.get(`${baseUri}/whitelistReferral`, {
    params: { wallet: address },
  });
}

export async function fileMD5Hash(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();

    reader.onload = (e) => {
      var hash = SparkMD5.ArrayBuffer.hash(e.target.result);
      resolve(hash);
    };

    reader.onerror = (e) => {
      reject(e);
    };

    reader.readAsArrayBuffer(file);
  });
}
