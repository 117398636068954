import { BigNumber, utils } from "ethers";
import { truncate } from '../services/helpers'
import * as tokenService from "../crypto/tokenService";
import * as stakingService from "../crypto/stakingService";
import * as priceService from "../crypto/priceService";

export default {
  namespaced: true,
  state: {
    approved: false,

    deposited: BigNumber.from(0),
    reward: BigNumber.from(0),

    loading: {
      approvalCheck: false,
      approve: false,
      deposit: false,
      withdraw: false,
      harvest: false,
    },

    secPerBlock: 3,
    baseRewardsPerSec: BigNumber.from(0),

    rewardsPoolInflow: 0,
    rewardsPoolOutflow: 0,
    takeoverReward: BigNumber.from(0),
    allStakedTokens: BigNumber.from(0),

    tokenPrice: BigNumber.from(0),
  },
  getters: {
    deposited: (state) => truncate(utils.formatEther(state.deposited), 6),
    depositedFull: (state) => utils.formatEther(state.deposited),
    reward: (state) => utils.formatEther(state.reward, 18),
    rewardsPerSec(state) {
      return utils.formatUnits(
        state.baseRewardsPerSec.mul(state.deposited),
        36
      ); // Multiply by number of deposited, then normalize to ether unit.
    },
    rewardsPoolInflow: (state) => state.rewardsPoolInflow.toFixed(2),
    rewardsPoolOutflow: (state) => state.rewardsPoolOutflow.toFixed(2),
    takeoverReward: (state) => utils.formatEther(state.takeoverReward),
    stakingReward: (state) => {
      return state.reward.eq(0)
        ? BigNumber.from(0)
        : utils.formatEther(state.reward.sub(state.takeoverReward));
    },
    allStakedTokens: (state) => utils.formatEther(state.allStakedTokens),
    vaultPercentage: (state) => {
      return state.allStakedTokens.eq(0)
        ? BigNumber.from(0)
        : utils.formatUnits(
            state.deposited.mul(10000).div(state.allStakedTokens),
            2
          );
    },
    tokenPrice: (state) => truncate(utils.formatEther(state.tokenPrice), 4),
  },
  mutations: {
    approveVault(state) {
      state.approved = true;
    },
    setDeposited(state, deposited) {
      state.deposited = deposited;
    },
    setVaultReward(state, reward) {
      state.reward = reward;
    },
    setRewardsPerBlockPerToken(state, rewardsPerBlockPerToken) {
      // Smart Contract Magic (divided by 1e12 in SC)
      //2227000000 * 1000000 = 2227000000000000 = 0.002227000000000000
      state.baseRewardsPerSec = rewardsPerBlockPerToken
        .mul(1e6)
        .div(state.secPerBlock);
    },
    stopRewards(state) {
      clearInterval(state.interval);
      state.reward = BigNumber.from(0);
      state.baseRewardsPerSec = BigNumber.from(0);
    },
    setInterval(state, interval) {
      state.interval = interval;
    },

    setRewardsPoolFlows(state, flowsData) {
      state.rewardsPoolInflow = flowsData.Inflow;
      state.rewardsPoolOutflow = flowsData.Outflow;
    },
    setTakeoverReward(state, takeoverReward) {
      state.takeoverReward = takeoverReward;
    },
    setAllStakedTokens(state, allStakedTokens) {
      state.allStakedTokens = allStakedTokens;
    },
    setTokenPrice(state, price) {
      state.tokenPrice = price;
    },

    loadingApprovalCheck(state, loading) {
      state.loading.approvalCheck = loading;
    },
    loadingApprove(state, loading) {
      state.loading.approve = loading;
    },
    loadingDeposit(state, loading) {
      state.loading.deposit = loading;
    },
    loadingWithdraw(state, loading) {
      state.loading.withdraw = loading;
    },
    loadingHarverst(state, loading) {
      state.loading.harvest = loading;
    },
  },
  actions: {
    async load({commit}) {
      const flowsData = await stakingService.rewardsPoolFlows();
      commit("setRewardsPoolFlows", flowsData);
    },

    async checkForApproval({ commit, dispatch, rootState }) {
      commit("loadingApprovalCheck", true);
      const address = utils.getAddress(rootState.wallet.account);
      const tokenContract = rootState.wallet.ethers.tokenContract;
      try {
        const vaultContract = rootState.wallet.ethers.vaultContract;
        const hasAllowance = await tokenService.hasAllowance(
          tokenContract,
          address,
          vaultContract
        );
        // There is some allowance, assume it's full
        if (hasAllowance) {
          commit("approveVault");
        }
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      } finally {
        commit("loadingApprovalCheck", false);
      }
    },

    async approveVault({ commit, dispatch, rootState }) {
      const signedTokenContract = rootState.wallet.ethers.signedTokenContract;
      try {
        commit("loadingApprove", true);
        const vaultContract = rootState.wallet.ethers.vaultContract;

        await tokenService.approve(signedTokenContract, vaultContract);
        commit("approveVault");
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      } finally {
        commit("loadingApprove", false);
      }
    },

    async fetchUserInfo({ commit, dispatch, rootState }) {
      const address = utils.getAddress(rootState.wallet.account);
      try {
        const userInfo = await stakingService.userInfo(address);
        commit("setDeposited", userInfo.amount);
        commit("setTakeoverReward", userInfo.takeoverReward);
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      }
    },

    async deposit({ commit, dispatch }, value) {
      const amount = utils.parseEther(value);
      try {
        commit("loadingDeposit", true);
        await stakingService.deposit(amount);
        dispatch("fetchUserInfo");
        dispatch("allStakedTokens");
        dispatch("wallet/getBlsBalance", null, { root: true });
        dispatch("wallet/getBnbBalance", null, { root: true });
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      } finally {
        commit("loadingDeposit", false);
      }
    },

    async withdraw({ commit, dispatch }, value) {
      const amount = utils.parseEther(value);
      try {
        commit("loadingWithdraw", true);
        await stakingService.withdraw(amount);
        dispatch("fetchUserInfo");
        dispatch("allStakedTokens");
        dispatch("wallet/getBlsBalance", null, { root: true });
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      } finally {
        commit("loadingWithdraw", false);
      }
    },

    async fetchRewards({ commit, dispatch, rootState }) {
      const address = utils.getAddress(rootState.wallet.account);

      const interval = setIntervalAndExecute(async function() {
        const pendingBlsTokens = await stakingService.currentReward(address);
        commit("setVaultReward", pendingBlsTokens);

        const rewardsPerBlockPerToken = await stakingService.rewardsPerBlockPerToken();
        commit("setRewardsPerBlockPerToken", rewardsPerBlockPerToken);

        dispatch("allStakedTokens");
      }, 6000);

      commit("setInterval", interval);
    },

    async harvestRewards({ commit, dispatch }) {
      try {
        commit("loadingHarverst", true);
        await stakingService.harvestRewards();
        commit("setVaultReward", BigNumber.from(0));
        dispatch("wallet/getBnbBalance", null, { root: true });
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      } finally {
        commit("loadingHarverst", false);
      }
    },

    async allStakedTokens({ commit, dispatch, rootState }) {
      const tokenContract = rootState.wallet.ethers.tokenContract;
      const vaultContract = rootState.wallet.ethers.vaultContract;
      try {
        const allStakedTokens = await tokenService.allStakedTokens(
          tokenContract,
          vaultContract
        );
        commit("setAllStakedTokens", allStakedTokens);
      } catch (err) {
        dispatch("error/setErrorMessage", err.message, { root: true });
      }
    },

    async tokenPrice({ commit }) {
      // try {
      const price = await priceService.getBLSPrice();
      commit("setTokenPrice", price);
      // } catch (err) {
      // dispatch("error/setErrorMessage", err.message, {root: true})
      // }
    },
  },
};

function setIntervalAndExecute(fn, t) {
  fn();
  return setInterval(fn, t);
}
