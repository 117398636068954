<template>
  <router-link v-if="!disabled" :to="to">
    <article class="space d-flex flex-column">
      <div class="space-image">
        <div class="overlay" v-if="disabled"></div>
        <img class="grid" src="@/assets/grid.png" width="100%" />
        <v-img
          class="image"
          :aspect-ratio="42 / 24"
          :src="imageUrl"
          gradient="to bottom, rgba(0,0,0,0.8) 8%, rgba(0,0,0,.0) 70%"
        />
        <p class="space-title">{{ title }}</p>
        <div class="active" v-if="active"></div>
        <div class="inactive" v-else></div>
      </div>
      <div class="space-details top-border d-flex pa-3">
        <div class="space-description d-flex flex-column">
          <h2 class="codename-text">Codename</h2>
          <span class="space-title text-subtitle-2">
            {{ subtitle }}
            <span class="inactive-label" v-if="!active">Inactive</span>
          </span>
        </div>
      </div>
    </article>
  </router-link>
  <span v-else>
    <article class="space d-flex flex-column">
      <div class="space-image">
        <div class="overlay" v-if="disabled"></div>
        <img class="grid" src="@/assets/grid.png" width="100%" />
        <v-img
          class="image"
          :aspect-ratio="42 / 24"
          :src="imageUrl"
          gradient="to bottom, rgba(0,0,0,0.8) 8%, rgba(0,0,0,.0) 70%"
        />
        <p class="space-title">{{ title }}</p>
        <div class="active" v-if="active"></div>
        <div class="inactive" v-else></div>
      </div>
      <div class="space-details top-border d-flex pa-3">
        <div class="space-description d-flex flex-column">
          <h2 class="codename-text">Codename</h2>
          <span class="space-title text-subtitle-2">
            {{ subtitle }}
            <span class="inactive-label" v-if="!active">Inactive</span>
          </span>
        </div>
      </div>
    </article>
  </span>
</template>

<script>
export default {
  props: {
    spaceId: Number,
    title: String,
    subtitle: String,
    image: Promise,
    active: Boolean,
    to: {
      type: String,
      default: "",
    },
    disabled: Boolean,
  },
  asyncComputed: {
    async imageUrl() {
      return await this.image;
    },
  },
};
</script>

<style scoped lang="scss">
a:link {
  text-decoration: none;
}

.space {
  border: 1px solid #444;
  text-decoration: none;
  transition: border-width 0.1s;

  &:hover {
    border-width: 3px;
  }

  .space-image {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr auto;
    .grid {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
    }
    .image {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
    }
    .overlay {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
      background-color: black;
      opacity: 1;
    }
    .active,
    .inactive,
    .in-progress {
      grid-row: 1;
      grid-column: 2;
      z-index: 2;
    }
    .active {
      background-color: rgba(0, 220, 0, 1);
      width: 12px;
      height: 12px;
      margin: 18px;
      border-radius: 50%;
      box-shadow: 0 0 0 6px rgba(0, 200, 0, 0.3), 0 0 0 3px rgba(0, 200, 0, 0.6);

      animation-name: pulse;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
    .inactive {
      background-color: rgb(67, 67, 67);
      width: 12px;
      height: 12px;
      margin: 18px;
      border-radius: 50%;
      box-shadow: 0 0 0 6px rgba(67, 67, 67, 0.3),
        0 0 0 3px rgba(67, 67, 67, 0.6);
    }

    @keyframes pulse {
      from {
        background-color: rgba(170, 250, 170, 1);
        box-shadow: 0 0 0 8px rgba(0, 200, 0, 0.3),
          0 0 0 4px rgba(0, 200, 0, 0.6);
      }
      to {
        background-color: rgba(0, 220, 0, 1);
        box-shadow: 0 0 0 4px rgba(0, 200, 0, 0.3),
          0 0 0 2px rgba(0, 200, 0, 0.6);
      }
    }

    .in-progress {
      margin: 10px;
      width: 30px;
      height: 12px;
      // margin: 16px;
      // background-color: rgba(0, 220, 0, 1);
      // width: 12px;
      // height: 12px;
      // margin: 18px;
      // border-radius: 50%;
      // box-shadow: 0 0 0 6px rgba(0, 200, 0, 0.3), 0 0 0 3px rgba(0, 200, 0, 0.6);
      &:before {
        content: "</>";
        font-size: 16px;
        font-weight: bold;
        color: #1abfb8;
      }
    }

    .space-title {
      grid-row: 1;
      grid-column: 1;
      z-index: 2;
      margin: 12px;
    }
  }
  .space-details {
    background: black;

    .codename-text {
      color: yellow;
      text-transform: uppercase;
      font-size: 0.75rem;
    }
  }
  .inactive-label {
    font-size: 0.75rem;
    color: gray;
  }
}
</style>
