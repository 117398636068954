<template>
  <nav>
    <SidePanelTitle :spaceTitle="config.name" />
    <SidePanelNav v-if="connected" :claimable="config.claimable" />
    <template v-if="mode === 'view'">
      <SpaceInfo
        :spaceName="config.name"
        :rulesContent="config.rules"
        :rewardsPerBlock="config.rewardsPerBlock"
        :rewards="config.rewards"
        :rewardsToken="config.rewardsToken"
      />
    </template>
    <template v-if="mode === 'draw'">
      <DrawInfo />
    </template>
    <template v-if="mode === 'confirm'">
      <ConfirmInfo />
    </template>
    <template v-if="mode === 'buy'">
      <ClaimInfo
        :spaceId="config.spaceId"
        :claimToken="config.claimToken"
        :claimFixed="config.claimFixed"
        :takeover="config.takeover"
        :takeoverToken="config.takeoverToken"
        :pixelArt="config.pixelArt"
      />
    </template>
    <template v-if="mode === 'loading'">
      <LoadingInfo />
    </template>
    <template v-if="mode === 'summary'">
      <SummaryInfo />
    </template>
  </nav>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SpaceInfo from "../space/side-panel/SpaceInfo.vue";
import DrawInfo from "../space/side-panel/DrawInfo.vue";
import ClaimInfo from "../space/side-panel/ClaimInfo.vue";
import SidePanelTitle from "../space/side-panel/SidePanelTitle.vue";
import SidePanelNav from "../space/side-panel/SidePanelNav.vue";
import LoadingInfo from "../space/side-panel/LoadingInfo.vue";
import SummaryInfo from "../space/side-panel/SummaryInfo.vue";
import ConfirmInfo from "./side-panel/ConfirmInfo.vue";

export default {
  components: {
    SidePanelTitle,
    SidePanelNav,
    SpaceInfo,
    DrawInfo,
    ClaimInfo,
    LoadingInfo,
    SummaryInfo,
    ConfirmInfo,
  },
  computed: {
    ...mapState("mode", ["mode"]),
    ...mapState("wallet", ["connected"]),
    ...mapState("space", ["currentSpace"]),

    config() {
      return this.currentSpace.config;
    },
  },
  methods: {
    ...mapActions("mode", ["setMode"]),
  },
};
</script>
